import React, { createContext, useState, useEffect, useContext } from 'react';
import CommonType from './types/CommonType';
import ServiceRequest from '../service/ServiceRequest';
import { NOTIFICATION_TYPES, NotificationContext } from './NotificationContext';
import { LoadingContext } from './LoadingContext';
import LanguageType from './types/LangugaeType';

export const LanguageContext = createContext({
  languages: [] as LanguageType[],
});

const LanguageProvider: React.FC<CommonType> = (props) => {
  const [languages, setLanguagesData] = useState<LanguageType[]>([]);
  const { setType, setMessage } = useContext(NotificationContext);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    ServiceRequest.getLanguages()
      .then((response) => {
        setLanguagesData(response.data);
      })
      .catch((error) => {
        console.error('ERROR ', error);
        setType(NOTIFICATION_TYPES.FAILED);
        setMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <LanguageContext.Provider value={{ languages }}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
