/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  FilledInput,
  Select,
  TextField,
  FormHelperText,
} from '@mui/material';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { device } from '../../styles/Device';
import { ThemeContext } from '../../styles/ThemeContext';
import { DhlIcon } from '@dhl-official/ui-libraries/react-library';
import { cancelCircle } from '@dhl-official/ui-libraries/assets/icons';
import { isEmpty } from 'lodash';

export interface OrderStatusFilterItem {
  label: string;
  selected?: boolean;
  value: string;
}

export interface OrderStatusFilterProperties {
  orderStatuses: OrderStatusFilterItem[];
  updateSelected: (selected: string[]) => void;
  multiple: boolean;
  title: any;
  error: any;
  value: any;
  onChange: (event: any) => void;
  onChangeSearch: (search: string) => void;
}

const MainWrapper = styled.div`
  width: 50%;
  @media ${device.mobile} {
    height: 80%;
  }
  @media ${device.tablet} {
    height: 100%;
  }
`;

const ItemWrapper = styled.div`
  width: 100%;
`;

const LeftItem = styled.div`
  float: left;
  display: flex;
  align-items: center;
  z-index: 0;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  sx: {
    '&& .Mui-selected': {
      backgroundColor: 'white',
    },
  },
  autoFocus: false,
};

const TtFormControl = styled((props: any) => <FormControl {...props} />)(
  ({ theme }) => ({
    '& label': {
      top: '15px',
    },
  })
);

const chevronDownSvg =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxOC40MTQgMi4wNDMgOC40NTdsMS40MTQtMS40MTRMMTIgMTUuNTg2bDguNTQzLTguNTQzIDEuNDE0IDEuNDE0eiIvPgo8L3N2Zz4K';

const SelectIcon = styled.div`
  background-image: url(${chevronDownSvg});
  background-position: center;
  width: 16px;
  height: 16px;
  color: rgb(25, 25, 25);
`;

const TtSelect = styled(Select)`
  width: 100%;
`;

const MyIcon = styled(DhlIcon)`
  color: #d40511;
`;

const InputPane = styled.div`
  max-height: 100px;
  overflow-y: auto;
`;

const HelperTextDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
`;

const MultiSelectDropDown = ({
  orderStatuses,
  updateSelected,
  multiple = true,
  title = '',
  error = { message: '' },
  value = '',
  onChange,
  onChangeSearch
}: OrderStatusFilterProperties): React.JSX.Element => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectAll, setSelectAll] = useState(false);
  const inputPaneRef = useRef<HTMLDivElement | null>(null);
  const { theme, fontSize } = useContext(ThemeContext);

  const fontSizePivot =
    fontSize === 1 ? 1.75 : fontSize === 3 ? 2.25 : fontSize;

  const selectedOrderTypes = isEmpty(value)
    ? orderStatuses
        .filter((status) => status.selected)
        .map((status) => status.value)
    : value;

  const displayedOptions = useMemo(
    () =>
      orderStatuses
        .filter(
          (orderStatus) =>
            searchText.length === 0 ||
            orderStatus.label.toLowerCase().includes(searchText)
        )
        .sort((status1, status2) =>
          status1.label.toLowerCase().localeCompare(status2.label.toLowerCase())
        ),
    [searchText, orderStatuses]
  );

  useEffect(() => {
    onChangeSearch(searchText);
  }, [searchText]);

  const onChangeSelectAll = (): void => {
    if (selectAll) {
      updateSelected([]);
    } else {
      updateSelected(orderStatuses.map((itm) => itm.value));
    }
    setSelectAll(!selectAll);
  };

  return (
    <MainWrapper theme={theme}>
      <TtFormControl sx={{ m: 1, width: '96%', margin: 0 }}>
        <InputLabel
          id="order-status-label"
          style={{ fontSize: `${fontSizePivot * 5.5}px` }}
        >
          {title}
        </InputLabel>
        <TtSelect
          labelId="order-status-label"
          id="order-status-select"
          multiple={multiple}
          IconComponent={SelectIcon}
          variant="filled"
          value={selectedOrderTypes}
          onChange={onChange}
          onClose={() => setSearchText('')}
          input={<FilledInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <InputPane
              ref={inputPaneRef}
              onMouseDown={(e) => {
                // If the interacts with scrollbar using mouse, prevent the select from gaining focus.
                if (
                  inputPaneRef.current != null &&
                  inputPaneRef.current.getBoundingClientRect().x +
                    inputPaneRef.current?.clientWidth <
                    e.clientX
                ) {
                  e.stopPropagation();
                }
              }}
            >
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(selected as string[]).map((value) => {
                  const coData = orderStatuses.find(
                    (itm) => itm.value === value
                  );
                  return (
                    <Chip
                      key={value}
                      label={coData !== undefined ? coData.label : value}
                      onMouseDown={(event) => event.stopPropagation()}
                      onDelete={() =>
                        updateSelected(
                          (selected as string[]).filter((itm) => itm !== value)
                        )
                      }
                    />
                  );
                })}
              </Box>
            </InputPane>
          )}
          MenuProps={MenuProps}
        >
          <ListSubheader>
            <TextField
              size="small"
              autoFocus={true}
              placeholder="Search"
              fullWidth
              onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
            <ItemWrapper>
              <LeftItem>
                <Checkbox checked={selectAll} onClick={onChangeSelectAll} />
                <strong>Select All</strong>
              </LeftItem>
            </ItemWrapper>
            <div style={{ clear: 'both' }}></div>
          </ListSubheader>
          {displayedOptions.map((listItem) => (
            <MenuItem key={listItem.value} value={listItem.value}>
              <ItemWrapper>
                <LeftItem>
                  <Checkbox
                    checked={selectedOrderTypes.includes(listItem.value)}
                  />
                  {listItem.label}
                </LeftItem>
              </ItemWrapper>
            </MenuItem>
          ))}
        </TtSelect>

        {error?.message && (
          <HelperTextDiv>
            <MyIcon src={cancelCircle} size="1.1rem" />
            <FormHelperText>{error.message}</FormHelperText>{' '}
          </HelperTextDiv>
        )}
      </TtFormControl>
    </MainWrapper>
  );
};

export { MultiSelectDropDown };
