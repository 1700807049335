import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import { UserContext } from '../../contexts/UserContext';
import { useParams } from 'react-router-dom';
import DhlText from '../../components/DhlText';
import AppItem from './components/AppItem';
import { PATHS } from '../App/constants';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';

const PageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 180px);
  padding: 16px;
  background-color: ${(props) => props.theme.secondaryBg};
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 8px;
`;

const SectionWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const AppWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

function AppListPage(): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { apps } = user;
  const { resources } = user;
  const { appId } = useParams();

  const myAppDetails = apps
    .concat(resources)
    .find((appDetail) => String(appDetail.id) === String(appId));
  // eslint-disable-next-line no-debugger
  if (myAppDetails !== undefined) {
    const mySections: any[] = [];
    myAppDetails.childApps?.forEach((myApp) => {
      if (!mySections.includes(myApp.category) && myApp.category !== undefined)
        mySections.push(myApp.category);
    });

    const myBreadCrumbList = [
      { label: 'App.breadcrumb.home', path: PATHS.HOME },
      { label: myAppDetails.name, path: '' },
    ];

    return (
      <PageWrapper theme={theme}>
        <TopWrapper theme={theme}>
          <DhlBreadCrumbs list={myBreadCrumbList} />
        </TopWrapper>

        <HeaderWrapper>
          <DhlText size="lg" weight={800} title={myAppDetails.name} />
        </HeaderWrapper>

        {mySections.length > 0 &&
          mySections.map((section) => {
            const mySectionApps = myAppDetails.childApps?.filter(
              (myApp: any) => myApp.category === section
            );

            return (
              <SectionWrapper key={section}>
                <DhlText size="md" weight={600} title={section} />

                <AppWrapper>
                  {mySectionApps.length > 0 &&
                    mySectionApps.map((secDetails) => (
                      <AppItem key={secDetails.id} app={secDetails} />
                    ))}
                </AppWrapper>
              </SectionWrapper>
            );
          })}
      </PageWrapper>
    );
  } else {
    return <></>;
  }
}

export default AppListPage;
