/* eslint-disable no-console */
import React, {ReactElement, useContext, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CSSTransition} from 'react-transition-group';
import FileInput from '../FileInput';
import {Accept} from "react-dropzone";
import {FormHelperText, IconButton, Typography} from "@mui/material";
import {NOTIFICATION_TYPES, NotificationContext} from "../../contexts/NotificationContext";
import {useTranslation} from "react-i18next";
import {resizeImage, toBase64} from "./functions";
import "./image-upload.css";

interface Props {
    maxWidth: number;
    maxHeight: number;
    dropzoneText: any;
    additionalDropzoneText?: any;
    caption: any;
    setData: Function;
    isUploading: boolean;
    data?: {
        content: string;
        mimeType: string;
    };
    error?: string | any;
    required?: boolean;
    disabled?: boolean;
    maxSize: number;
}

const acceptedFiles: Accept = {
    'image/jpeg': [],
    'image/png': []
}

const ImageUpload = (props: Props): ReactElement => {

    const [animation, setAnimation] = useState(true);
    const {content, mimeType} = props.data ?? {};
    const {maxSize} = props;
    const {setType, setMessage} = useContext(NotificationContext);
    const {t} = useTranslation();

    const validate = <T extends File>(files: T[]): boolean => (files?.length > 0 && files[0] != null && files[0].size < maxSize);

    const onDropHandler = <T extends File>(files: T[]): void => {
        if (validate(files)) {
            const file = files[0];
            toBase64(file)
                .then((data: any): void => {
                        resizeImage(data, props.maxHeight, props.maxWidth, false)
                            .then(data2 => {
                                const newMimeType = data2.substring(
                                    data2.indexOf(':') + 1,
                                    data2.indexOf(';'),
                                );
                                const newContent = data2.substring(data2.indexOf(',') + 1);
                                setAnimation(true);
                                props.setData({content: newContent, mimeType: newMimeType});
                            })
                            .catch(e => {
                                console.error(e);
                                setType(NOTIFICATION_TYPES.FAILED);
                                setMessage(t('App.components.ImageUpload.errorDuringDrag'))
                            });
                    }
                )
                .catch(e => {
                    console.error(e);
                    setType(NOTIFICATION_TYPES.FAILED);
                    setMessage(t('App.components.ImageUpload.errorDuringDrag'))
                })
            ;
        } else {
            setType(NOTIFICATION_TYPES.FAILED);
            setMessage(t('App.components.ImageUpload.errorDuringConversionToBase64'));
        }
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <Typography
                display="inline"
                gutterBottom
                variant="caption"
                color={props?.error != null ? 'error' : 'initial'}
            >
                {props.caption}
                {props?.required === true ? '* ' : ' '}
                {props?.error != null && (
                    <FormHelperText error style={{display: 'inline'}}>
                        {props.error}
                    </FormHelperText>
                )}
            </Typography>
            <div className="image-upload">
                <FileInput
                    isDisabled={props.isUploading || props?.disabled === true}
                    onDrop={file => {
                        onDropHandler(file);
                    }}
                    dropzoneText={props.dropzoneText}
                    additionalDropzoneText={props.additionalDropzoneText}
                    accept={acceptedFiles}
                    maxFiles={1}
                    customClass="image-dropzone"
                    hideIcon
                />

                <CSSTransition
                    in={animation && content != null}
                    timeout={300}
                    classNames="image-animation"
                    onExited={() => props.setData(undefined)}
                    unmountOnExit
                >
                    <div className="image-upload__preview">
                        <img src={`data:${mimeType ?? ''};base64,${content ?? ''}`} alt="preview"/>

                        <div className="image-upload__preview__delete">
                            <IconButton
                                aria-label="delete"
                                onClick={() => setAnimation(false)}
                                disabled={props.isUploading || props.disabled}
                            >
                                {props?.disabled !== true && (
                                    <FontAwesomeIcon color="#D40511" icon="times-circle"/>
                                )}
                            </IconButton>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
};

export default ImageUpload;
