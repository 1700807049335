/* eslint-disable react/prop-types */
import React from 'react';
import DateTimeLabel from "../../DateTimeLabel";

export default class UpdatedCell extends React.PureComponent {
    render() {
        if (this.props.data && this.props.data.updated) {
            return <DateTimeLabel datetime={this.props.data.updated} textSize={"13px"} />;
        }
        return <span />;
    }
}
