// @flow
import React, { useContext } from 'react';
import { TypeMenu } from './TypeMenu';
import MenuItemLanguage from './MenuItem';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import ClickAwayListener from 'react-click-away-listener';
import { isArray } from 'lodash';

const ContextWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 100px;
  cursor: pointer;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const PopupWrapper = styled.div`
  width: 100%;
  min-width: 250px;
  max-height: calc(100vh - 100px);
  min-height: 100px;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.primaryText};
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 45px;
  left: 40%;
  margin-left: -80px;
  overflow: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  :before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 40%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${(props) => props.theme.background}
      transparent;
  }
`;

export const iconTypeEnum = {
  BASE64: 'base64',
  NONE: 'none',
};

const MenuContext: React.FC<TypeMenu> = (props) => {
  const {
    items = [],
    isMenuOpen,
    title,
    toggleMenu,
    iconType = iconTypeEnum.NONE,
    onSelect,
    isBrandingObjectShowing,
    brandingTextColor,
  } = props;

  const { theme } = useContext(ThemeContext);
  return (
    <ClickAwayListener onClickAway={() => toggleMenu(false)}>
      <ContextWrapper theme={theme}>
        {isArray(items) && items
          .filter((l: any) => l.code === title)
          .map((l: any) => (
            <MenuItemLanguage
              key={`icon-item-label`}
              label={l.name}
              icon={l.icon}
              iconType={iconTypeEnum.BASE64}
              onSelect={() => toggleMenu(true)}
              item={l}
              type="label"
              isBrandingObjectShowing={isBrandingObjectShowing}
              brandingTextColor={brandingTextColor}
            />
          ))}

        {isMenuOpen && (
          <PopupWrapper theme={theme}>
            {items.map((app: any, index: number) => (
              <MenuItemLanguage
                {...props}
                key={`icon-item-${index}`}
                label={app.name}
                icon={app.icon}
                iconType={iconType}
                onSelect={onSelect}
                item={app}
                type="options"
              />
            ))}
          </PopupWrapper>
        )}
      </ContextWrapper>
    </ClickAwayListener>
  );
};

export default MenuContext;
