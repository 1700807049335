/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  getExternalUsersHistoryTable,
  getTemplate,
  getFile,
  postFile,
} from './upload.service';
import { LoadingContext } from '../../contexts/LoadingContext';
import {
  NOTIFICATION_TYPES,
  NotificationContext,
} from '../../contexts/NotificationContext';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';
import { PATHS } from '../App/constants';
import DhlButton from '../../components/DhlButton';
import { ThemeContext } from '../../styles/ThemeContext';
import DhlText from '../../components/DhlText';
import Upload from '../../components/FileUpload/Upload';
import Table from '../../components/Table';
import { getHeight } from '../../components/Table/utils';

import FileSaver from 'file-saver';
import { FileLinkData, UploadsHistoryItem } from './types';
import DateColumn from '../../components/Table/cell/DateColumn';
import UploadStatus from '../../components/FileUpload/UploadStatus';
import { DhlLoader } from '@dhl-official/ui-libraries/react-library';

const PageWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.background};
`;

const PageBlock = styled.div`
  width: 95%;
  margin-left: 2%;
  display: block;
`;

const HeaderWrapper = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-left: 2%;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonItem = styled(DhlButton)`
  margin: 10px;
`;

const myBreadCrumbList = [
  { label: 'App.breadcrumb.home', path: PATHS.HOME },
  { label: 'App.breadcrumb.createExternalUser', path: PATHS.EXTERNAL_USER },
  { label: 'App.breadcrumb.uploadUser', path: '' },
];

const UploadUser = (): JSX.Element => {
  const theme = useContext(ThemeContext);
  const { isLoading, setLoading } = useContext(LoadingContext);
  const { setType, setMessage } = useContext(NotificationContext);
  const [files, setFiles] = useState<any>([]);
  const { t } = useTranslation();
  const [uploadData, setUploadData] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    if (uploadData) {
      setIsUploading(true);
      files.forEach((file: any) => {
        const formData = new FormData();
        formData.append('file', file[0]);
        postFile(formData)
          .then((res) => {
            processFile(file);
            setType(NOTIFICATION_TYPES.SUCCESS);
            setMessage(t('App.page.upload.uploadSuccess'));
          })
          .catch((error) => {
            setType(NOTIFICATION_TYPES.FAILED);
            if(error?.response?.data?.message !== undefined){
              setMessage(error.response.data.message);
            } else {
              setMessage(
                t('App.page.upload.errorDuringUpload', { filename: file[0].name })
              );
            }
            
            processFile(file[0]);
          })
          .finally(() => {
            setLoading(false);
            setUploadData(false);
            setIsUploading(false);
          });
      });
    }
  }, [uploadData]);

  const downloadTemplate = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    getTemplate()
      .then((res) => {
        FileSaver.saveAs(res.data, 'useruploadtemplate.xlsx');
      })
      .catch((error) => {
        console.error(error);
        setType(NOTIFICATION_TYPES.FAILED);
        setMessage(error.message);
      });
  };

  const downloadFile = (
    e: React.MouseEvent<HTMLElement>,
    props: FileLinkData
  ): void => {
    e.preventDefault();
    if (props.id !== undefined && props.id !== null && props.id > 0) {
      getFile(props.id, props.failed)
        .then((res) => {
          FileSaver.saveAs(res.data, props.fileName);
        })
        .catch((error) => {
          setType(NOTIFICATION_TYPES.FAILED);
          setMessage(error.message);
        });
    }
  };

  class FileLink extends React.PureComponent<UploadsHistoryItem> {
    render(): JSX.Element {
      const data = {
        id: this.props.data?.id,
        failed: false,
        fileName: this.props.data?.fileName,
      };
      return (
        <a href="#" onClick={(e) => downloadFile(e, data)}>
          {this.props?.data?.fileName}
        </a>
      );
    }
  }

  class FileLinkFailed extends React.PureComponent<UploadsHistoryItem> {
    render(): JSX.Element {
      const data = {
        id: this.props.data?.id,
        failed: true,
        fileName: this.props.data?.fileNameFailed,
      };
      return (
        <a href="#" onClick={(e) => downloadFile(e, data)}>
          {this.props?.data?.fileNameFailed}
        </a>
      );
    }
  }

  const columnDefs = [
    {
      headerName: t('App.page.upload.fileName'),
      field: 'fileName',
      filter: false,
      suppressMenu: true,
      cellRenderer: FileLink,
    },
    {
      headerName: t('App.page.upload.failedOnly'),
      field: 'fileNameFailed',
      cellRenderer: FileLinkFailed,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: t('App.page.upload.recordsCreated'),
      field: 'recordsCreated',
      filter: false,
      suppressMenu: true,
    },
    {
      headerName: t('App.page.upload.recordsFailed'),
      field: 'recordsFailed',
      filter: false,
      suppressMenu: true,
    },
    {
      headerName: t('App.page.upload.created'),
      field: 'created',
      cellRendererFramework: DateColumn,
      sort: 'desc',
      filter: false,
      suppressMenu: true,
    },

    {
      headerName: t('App.page.upload.createdBy'),
      field: 'createdBy',
      filter: false,
      suppressMenu: true,
    },
    {
      headerName: t('App.page.upload.status'),
      field: 'status',
      cellRendererFramework: UploadStatus,
      filter: false,
      suppressMenu: true,
    },
    {
      headerName: t('App.page.upload.errorMessage'),
      field: 'errorMessage',
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
  ];

  const processFile = (file: any): void => {
    const newFiles = files.filter((item: any) => item.name !== file.name);
    if (newFiles.length === 0) {
      setLoading(false);
    }
    setFiles(newFiles);
  };

  const handleUpload = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setLoading(true);
    setUploadData(true);
  };

  return (
    <PageWrapper theme={theme}>
      <HeaderWrapper theme={theme}>
        <DhlBreadCrumbs list={myBreadCrumbList} />
        <TopWrapper theme={theme}>
          <DhlText size="md" title={t('App.page.upload.title')} weight={800} />

          <ButtonWrapper>
            <ButtonItem
              title={t('App.page.upload.getTemplate')}
              variant="primary"
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                downloadTemplate(e)
              }
              size="sm"
            />

            <ButtonItem
              title={t('App.page.upload.upload')}
              variant="primary"
              size="sm"
              onClick={(e: React.MouseEvent<HTMLElement>) => handleUpload(e)}
              isDisabled={files.length === 0 || isLoading}
            />
          </ButtonWrapper>
        </TopWrapper>
      </HeaderWrapper>

      <PageBlock theme={theme}>
        {isUploading && <DhlLoader size="sm" />}
        {!isUploading && (
          <Upload
            files={files}
            onFileAdded={(file: any) => setFiles([...files, file])}
            setFiles={setFiles}
          />
        )}
        <Table
          columnDefs={columnDefs}
          dataKey="data"
          totalKey="total"
          id="uploadsHistory"
          resizeColumn={false}
          autoSizeColumns={false}
          sizeColumnsToFit
          showActionPanel={false}
          isUploading={isUploading}
          rowHeight={getHeight(1)}
          getData={async (request: any) =>
            await getExternalUsersHistoryTable(request)
          }
        />
      </PageBlock>
    </PageWrapper>
  );
};

export default UploadUser;
