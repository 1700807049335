import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import { TypeSnackbar } from './TypeSnackbar';
import { NOTIFICATION_TYPES } from '../../contexts/NotificationContext';
import {
  DhlIcon,
  DhlIconWrapper,
} from '@dhl-official/ui-libraries/react-library';
import { IconContext } from '../../assets/IconContext';

const getBgColor = (props: any): string => {
  switch (props.type) {
    case NOTIFICATION_TYPES.SUCCESS:
      return props.theme.color.green['400'];

    case NOTIFICATION_TYPES.FAILED:
      return props.theme.color.red['400'];

    default:
      return props.theme.color.yellow['500'];
  }
};

const SnackbarWrapper = styled.div`
  display: flex;
  position: fixed;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  top: 20px;
  right: 10px;
  z-index: 4;
  padding: 20px;
  color: ${(props) => props.theme.color.white[400]};
  border-radius: 4px;
  transition: 0.5s;
  background-color: ${(props) => getBgColor(props)};
`;

const IconWrapper = styled(DhlIconWrapper)`
  margin-right: 10px;
`;

const DhlSnackbar: React.FC<TypeSnackbar> = (props) => {
  const { theme } = useContext(ThemeContext);
  const { getIcon } = useContext(IconContext);

  return (
    <SnackbarWrapper {...props} theme={theme}>
      <IconWrapper size="md">
        <DhlIcon
          src={getIcon(
            props.type === NOTIFICATION_TYPES.SUCCESS
              ? 'check'
              : props.type === NOTIFICATION_TYPES.FAILED
                ? 'cancel'
                : 'info',
            'light'
          )}
        />
      </IconWrapper>
      {props.message}{' '}
    </SnackbarWrapper>
  );
};

export default DhlSnackbar;
