/* eslint-disable react/prop-types */
import React from 'react';
import DhlText from '../../DhlText';
import styled from 'styled-components';
import {DhlInputField} from '@dhl-official/ui-libraries/react-library';

const MyInputField = styled(DhlInputField)`
  input {
    width: 100px;
    height: 35px;
  }
`;

export default class SeqNoColumn extends React.PureComponent {
    render() {
        const {value, isEditable, data = {}, onChangeValue} = this.props;
        return isEditable && data.active ? (
            <MyInputField
                value={data.sequenceNo === null ? '' : data.sequenceNo}
                type="number"
                inputEvent={(e) =>
                    onChangeValue({...data, sequenceNo: e.target.value})
                }
            />
        ) : (
            <DhlText title={value}/>
        );
    }
}
