/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  BreadCrumbsWrapper,
  ButtonItem,
  ButtonWrapper,
  HeaderWrapper, isRoleCoAdminOnly,
  PageWrapper,
} from '../common';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';
import DhlText from '../../components/DhlText';
import { PATHS } from '../App/constants';
import { ThemeContext } from '../../styles/ThemeContext';
import { useTranslation } from 'react-i18next';
import ServiceRequest from '../../service/ServiceRequest';
import { LoadingContext } from '../../contexts/LoadingContext';
import {
  NOTIFICATION_TYPES,
  NotificationContext,
} from '../../contexts/NotificationContext';
import BrandingObject from './BrandingObject';
import BrandingImages from './BrandingImages';
import {UserContext} from "../../contexts/UserContext";

interface IFormInputs {
  name: string;
  stylesheetId: number;
  stylesheetName: string;
  active: boolean;
  notes: string;
  backgroundColor: string;
  textColor: string;
  showBannerOnMainPage: boolean;
}

const myBreadCrumbList = [
  { label: 'App.breadcrumb.home', path: PATHS.HOME },
  { label: 'App.page.Brandings.title', path: PATHS.BRANDING_LIST },
  { label: 'App.breadcrumb.branding', path: '' },
];

function Branding(): ReactElement {
  const { id: brandingId } = useParams();
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const { setType, setMessage } = useContext(NotificationContext);
  const { user } = useContext(UserContext);
  const isMobile = window.innerWidth < 600;

  const [brandingData, setBrandingData] = useState<any>(null);

  const isCoAdminRole = isRoleCoAdminOnly(user?.roles);

  useEffect(() => {
    if (brandingId != null) {
      ServiceRequest.processRequest({
        setLoading,
        setType,
        setMessage,
        inputData: brandingId,
        request: ServiceRequest.getBrandingObject,
        responseHandler: (response) => {
          setBrandingData(response.data);
        },
      });
    }
  }, [brandingId]);

  const { handleSubmit, control, reset, getValues } = useForm<IFormInputs>(
    brandingId != null
      ? {
          defaultValues: {
            name: 'n/a',
            stylesheetId: 0,
            stylesheetName: 'n/a',
            active: true,
            notes: 'n/a',
            backgroundColor: 'n/a',
            textColor: 'n/a',
          },
        }
      : {
          defaultValues: {
            active: true,
            showBannerOnMainPage: true,
          },
        }
  );

  useEffect(() => {
    reset(brandingData);
  }, [brandingData]);

  const onSubmitData: SubmitHandler<IFormInputs> = (data) => {
    const objectRequest = {
      ...data,
    };

    ServiceRequest.processRequest({
      setLoading,
      setType,
      setMessage,
      inputData: { brandingId, data: objectRequest },
      request:
        brandingId != null
          ? ServiceRequest.saveBrandingObject
          : ServiceRequest.createBrandingObject,
      responseHandler: (response) => {
        setType(NOTIFICATION_TYPES.SUCCESS);
        setMessage(t('App.page.Branding.brandingSaved'));
        if (brandingId == null) {
          navigate(
            PATHS.BRANDING_OBJECT_DETAIL_DETAIL.replace(
              ':id',
              response?.data?.id
            )
          );
        } else {
          setBrandingData(response.data);
        }
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <PageWrapper theme={theme} id="page_wrapper">
        <BreadCrumbsWrapper>
          <DhlBreadCrumbs list={myBreadCrumbList} />
        </BreadCrumbsWrapper>

        <HeaderWrapper id="header_wrapper">
          <DhlText
            size="md"
            weight={800}
            title={t('App.page.Brandings.title')}
          />

          <ButtonWrapper>
            <ButtonItem
              title={t('App.page.Branding.exit')}
              variant="outline"
              size={isMobile ? "xs" : "sm"}
              onClick={() => navigate(PATHS.BRANDING_LIST)}
            />
            {!isCoAdminRole && <ButtonItem
              title={t('App.common.save')}
              variant="primary"
              size={isMobile ? "xs" : "sm"}
              type="submit"
            />}
          </ButtonWrapper>
        </HeaderWrapper>

        <BrandingObject control={control} brandingData={brandingData} getValues={getValues}/>

        <BrandingImages control={control} />
      </PageWrapper>
    </form>
  );
}

export default Branding;
