import React, { useContext } from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import { ThemeContext } from '../../../styles/ThemeContext';
import { useTranslation } from 'react-i18next';
import DhlButton from '../../../components/DhlButton';
import { DhlImage } from '@dhl-official/ui-libraries/react-library';
import { IconContext } from '../../../assets/IconContext';
import { device } from '../../../styles/Device';

const ComponentWrapper = styled.div<{ type: string }>`
  border: 2px solid
    ${(props) => (props.type === 'error' ? '#eb131e' : '#78bd22')};
  background-color: ${(props) =>
    props.type === 'error'
      ? 'rgba(235, 19, 30, 0.1)'
      : 'rgba(120, 189, 34, 0.1)'};
  width: 80%;
  @media ${device.mobile} {
    // height: 40vh;
  }
  @media ${device.tablet} {
    // height: 40vh;
    flex-wrap: nowrap;
  }
  @media ${device.laptop} {
    // height: 23vh;
    flex-wrap: nowrap;
  }
  @media ${device.desktop} {
    // height: 23vh;
    flex-wrap: nowrap;
  }
  margin: 20px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media ${device.mobile} {
    width: 10%;
  }
  @media ${device.tablet} {
    width: 30%;
  }
  height: 100%;
`;

const TitleText = styled(DhlText)`
  margin-top: 10px;
  margin-left: 10px;
`;

const SubTitleText = styled(DhlText)`
  margin-top: 10px;
  margin-left: 10px;
`;

const PrimaryButton = styled(DhlButton)`
  margin-top: 30px;
  margin-left: 10px;
`;

const PrimaryImage = styled(DhlImage)`
  @media ${device.mobile} {
    width: 120px;
    height: 100px;
  }
  @media ${device.tablet} {
    width: 220px;
    height: 200px;
  }
  margin-top: -30px;
  margin-right: -40px;
`;

function ErrorComponent(props: any): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { getIcon } = useContext(IconContext);
  const { t } = useTranslation();
  const { errorData, onClickPrimaryButton } = props;
  return (
    <ComponentWrapper theme={theme} type={errorData.type}>
      <InnerWrapper>
        <TitleText size="lg" weight={800} title={t(errorData.title)} />
        <SubTitleText size="sm" weight={400} title={t(errorData.subTitle)} />
        <PrimaryButton size="sm" title={t(errorData.buttonText)} onClick={onClickPrimaryButton} />
      </InnerWrapper>
      <ImageWrapper>
        <PrimaryImage src={getIcon(errorData.image)} />
      </ImageWrapper>
    </ComponentWrapper>
  );
}

export default ErrorComponent;
