/* eslint-disable */
import React, {useContext} from 'react';
import {ThemeContext} from "../../../styles/ThemeContext";
import styled from "styled-components";
import DhlText from "../../../components/DhlText";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const RowWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 8px; 
  padding: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
`;

const ItemWrapper = styled.div`
  width: 48%;
  margin: 4px;
  display: flex;
  flex-direction: column;
`;

const ItemSubTitle = styled(DhlText)`
  margin-top: 6px;
`;

const ItemTags = styled.div`
  width: 100%;
  padding: 4px;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Tag = styled.div`
  width: fit-content;
  padding: 4px;
  margin: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 12px;
  background-color: #e6e6e6;
  border: 1px solid #666666;
  border-radius: 4px;
`;

function RoleComponents(props: any): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {t} = useTranslation();
    const {userData} = props;
    let uniqueRoles: string[] = [];

    console.log("USER DATA ROLES ===> ", userData);

    const roles = userData?.user_roles_and_permissions?.roles;

    if(roles!== undefined && roles!== null && roles?.length > 0) {
        const allRoles: string[] = [];
        roles.forEach((roleData: any) => {
            if(!roleData.role.includes("ROLE_"))
            allRoles.push(roleData.role);
        });
        uniqueRoles = allRoles?.filter((item,index) => allRoles?.indexOf(item) === index);
    }


    return (
        <Wrapper theme={theme}>
            {uniqueRoles?.length > 0 && uniqueRoles.map((roleName: string) => {
                const allData : any[] = roles.filter((roleData: any) => roleData.role === roleName);
                console.log("ALL DATA => ", allData);
                return <RowWrapper key={`role-${roleName}`}>
                <ItemWrapper>
                    <DhlText title={roleName} size={"md"} weight={600}/>
                    <ItemSubTitle title={t("App.page.Users.Role")} size={"md"}/>
                </ItemWrapper>

                <ItemWrapper>
                    <DhlText title={t("App.page.Users.Regions")} size={"md"} weight={600}/>
                    <ItemTags> {allData.length > 0 ?allData.map((item: any) => item?.region !== null && <Tag key={`roleRegion-${item?.region}`}>{item?.region}</Tag>) : null}</ItemTags>
                </ItemWrapper>
            </RowWrapper>})}
        </Wrapper>

    );
}

export default RoleComponents;
