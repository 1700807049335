import React, { useContext } from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import { ThemeContext } from '../../../styles/ThemeContext';
import BannerImage from '../../../assets/images/banner_privacy.png';
import { device } from '../../../styles/Device';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  width: 100%;
  z-index: 1;
  @media ${device.mobile} {
    height: 20%;
  }
  @media ${device.tablet} {
    height: 20%;
  }
  @media ${device.laptop} {
    height: 20vh;
  }
  @media ${device.desktop} {
    height: 30vh;
  }
  min-height: 250px;
  background-image: url(${BannerImage});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.color.black[500]} 30%,
    ${(props) => props.theme.color.black[500]} 50%,
    ${(props) => props.theme.color.black[500]},
    ${(props) => props.theme.color.black[10]},
    transparent
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.mobile} {
    align-items: center;
    padding-left: ${(props) => props.theme.spacing.xs};
  }
  @media ${device.tablet} {
    align-items: flex-start;
    padding-left: ${(props) => props.theme.spacing.xxxl};
  }
`;

function TopContent(props: any): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InnerWrapper theme={theme}>
        <DhlText
          id="welcome_text"
          size="xxxl"
          weight={800}
          color="#ffffff"
          title={t('App.page.privacyNotice.title')}
        />
      </InnerWrapper>
    </Wrapper>
  );
}

export default TopContent;
