import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../../../styles/ThemeContext';
import TypeRow from './TypeRow';
import TableHeaderCell from '../TableHeaderCell';
import TableNormalCell from '../TableNormalCell';
import TypeNormalCell from '../TableNormalCell/TypeNormalCell';
import TypeHeaderCell from '../TableHeaderCell/TypeHeaderCell';

const TableRowWrapper = styled.div`
  width: 100%;
  min-height: 70px;
  display: table-row;
  clear: both;
`;

export function getTableRowData(
  rowData: any,
  columnData: TypeHeaderCell
): TypeNormalCell {
  const normalCellData = {
    id: rowData[columnData.field],
    label: rowData[columnData.field],
    isSelectable: columnData.isSelectable,
    item: null,
  };
  return normalCellData;
}

function TableRow(props: TypeRow): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const {
    id,
    columns,
    isHeaderRow,
    data,
    onCheckChange,
    isCheck,
  } = props;

  return (
    <TableRowWrapper key={id} theme={theme}>
      {isHeaderRow &&
        columns !== undefined &&
        columns.map((columnData) => (
          <TableHeaderCell
            key={columnData.id}
            isCheck={isCheck}
            checkChange={onCheckChange}
            {...columnData}
          />
        ))}

      {!isHeaderRow &&
        columns !== undefined &&
        data !== undefined &&
        columns.map((columnData, index) => (
          <TableNormalCell
            key={`group_row_${index.toString()}`}
            checkChange={onCheckChange}
            isCheck={isCheck}
            {...getTableRowData(data, columnData)}
          />
        ))}
    </TableRowWrapper>
  );
}

export default TableRow;
