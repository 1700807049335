import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../../styles/ThemeContext';
import BottomImage from '../../../assets/images/bottom_image.png';
import { device } from '../../../styles/Device';

const Wrapper = styled.div`
  @media ${device.mobile} {
    width: 600px;
    height: 600px;
    margin-top: -10%;
    margin-bottom: -10%;
  }
  @media ${device.tablet} {
    width: 450px;
    height: 450px;
    margin-top: -10%;
    margin-bottom: -10%;
  }
  @media ${device.laptop} {
    width: 500px;
    height: 500px;
    margin-top: -10%;
    margin-bottom: -10%;
  }
  @media ${device.desktop} {
    width: 750px;
    height: 750px;
    margin-top: -15%;
    margin-bottom: -15%;
  }
  @media ${device.largeDesktop} {
    width: 1050px;
    height: 1050px;
  }
  margin: -15px;
  border-radius: 50%;
  border: 3px dashed ${(props) => props.theme.color.yellow[300]};
  background-color: ${(props) => props.theme.color.yellow[10]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper2 = styled.div`
  @media ${device.mobile} {
    width: 470px;
    height: 470px;
  }
  @media ${device.tablet} {
    width: 350px;
    height: 350px;
  }
  @media ${device.laptop} {
    width: 400px;
    height: 400px;
  }
  @media ${device.desktop} {
    width: 650px;
    height: 650px;
  }
  @media ${device.largeDesktop} {
    width: 900px;
    height: 900px;
  }
  border-radius: 50%;
  border: 3px dashed ${(props) => props.theme.color.yellow[300]};
  background-color: ${(props) => props.theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper3 = styled.div`
  @media ${device.mobile} {
    width: 340px;
    height: 340px;
  }
  @media ${device.tablet} {
    width: 250px;
    height: 250px;
  }
  @media ${device.laptop} {
    width: 300px;
    height: 300px;
  }
  @media ${device.desktop} {
    width: 550px;
    height: 550px;
  }
  @media ${device.largeDesktop} {
    width: 750px;
    height: 750px;
  }
  height: 600px;
  border-radius: 50%;
  border: 3px dashed ${(props) => props.theme.color.yellow[300]};
  background-color: ${(props) => props.theme.color.yellow[10]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper4 = styled.div`
  @media ${device.mobile} {
    width: 210px;
    height: 210px;
  }
  @media ${device.tablet} {
    width: 150px;
    height: 150px;
  }
  @media ${device.laptop} {
    width: 200px;
    height: 200px;
  }
  @media ${device.desktop} {
    width: 450px;
    height: 450px;
  }
  @media ${device.largeDesktop} {
    width: 600px;
    height: 600px;
  }
  border-radius: 50%;
  border: 3px dashed ${(props) => props.theme.color.yellow[300]};
  background-color: ${(props) => props.theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerImage = styled.div`
  @media ${device.mobile} {
    width: 200px;
    height: 200px;
  }
  @media ${device.tablet} {
    width: 150px;
    height: 150px;
  }
  @media ${device.laptop} {
    width: 300px;
    height: 300px;
  }
  @media ${device.desktop} {
    width: 420px;
    height: 420px;
  }
  @media ${device.largeDesktop} {
    width: 550px;
    height: 550px;
  }
  padding: -10px;
  background-image: url(${BottomImage});
  background-repeat: no-repeat;
  background-size: contain;
`;

function DottedCircles(): JSX.Element {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper theme={theme}>
      <Wrapper2 theme={theme}>
        <Wrapper3 theme={theme}>
          <Wrapper4 theme={theme}>
            <InnerImage />
          </Wrapper4>
        </Wrapper3>
      </Wrapper2>
    </Wrapper>
  );
}

export default DottedCircles;
