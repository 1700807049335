/* eslint-disable */
import styled from 'styled-components';
import {device} from '../styles/Device';
import DhlButton from '../components/DhlButton';
import {
    DhlDropdown,
    DhlInputField,
    DhlTextAreaField,
} from '@dhl-official/ui-libraries/react-library';
import React, {ReactElement} from 'react';
import JobFunctionType from '../contexts/types/JobFunctionType';
import JobRoleType from '../contexts/types/JobRoleType';
import CountryType from '../contexts/types/CountryType';
import LanguageType from '../contexts/types/LangugaeType';
import {isEmpty} from '../utils';
import RegionType from '../contexts/types/RegionType';
import {TextareaAutosize} from '@mui/base';
import DhlText from "../components/DhlText";

const PageWrapper = styled.div`
  min-width: 70vw;
  min-height: calc(100vh - 180px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: ${(props) => props.theme.secondaryBg};
`;

const PageWrapper2 = styled.div`
  @media ${device.mobile} {
    min-height: 50vh;
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    min-height: 65vh;
    min-width: 70vw;
    flex-wrap: nowrap;
  }
  @media ${device.laptop} {
    min-height: 65vh;
    min-width: 70vw;
    flex-wrap: nowrap;
  }
  @media ${device.desktop} {
    min-height: 78vh;
    min-width: 70vw;
    flex-wrap: nowrap;
  }
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondaryBg};
`;

const TopImageWrapper = styled.div<{ BannerImage: any }>`
  width: 100%;
  height: 91px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  background: ${(props) => `url(${props.BannerImage})`};
  background-repeat: no-repeat;
  background-size: auto 120%;
`;

export const TitleData = styled(DhlText)`
    margin-left: 20px;
    margin-top: 18px; 
`;

const BreadCrumbsWrapper = styled.div`
  width: 100%;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  padding-left: 16px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonItem = styled(DhlButton)`
  margin-left: 10px;
`;

const BottomWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    flex-wrap: nowrap;
  }
`;

const Button = styled(DhlButton)`
  margin: 10px;
`;

export const MainFormWrapper = styled.div`
  width: 100%;
  padding: 16px;
  margin: 4px;
  background-color: ${(props) => props.theme.background};
  box-shadow: 1px 1px 2px 1px #e6e6e6;
`;

export const FormField = styled(DhlInputField)`
  width: 100%;
  display: block;
  @media ${device.mobile} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const TextAreaFieldMaterial = styled(TextareaAutosize)`
  width: 100%;
  display: block;
  font-family: Delivery, Verdana, sans-serif;
  font-size: 16px;  
  border-radius: var(--dui-size-radius-md);
  border: var(--dui-size-space-1) solid var(--dui-color-gray-500);
  padding-left: 13px;
  padding-top: 22px;
  @media ${device.mobile} {
    margin-top: -38px;
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    margin-top: -54px;
    margin-bottom: 32px;
  }
`;

export const Dropdown = styled(DhlDropdown)`
  width: 100%;
  display: block;
  @media ${device.mobile} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

interface YesNoInput {
    value: string;
}

export const YesNo = ({value}: YesNoInput): ReactElement => {
    const yes = ['y', 't', '1', 'true'];
    const no = ['n', 'f', '0', 'false'];
    let result = '';
    if (yes.includes(`${value}`.toLowerCase())) {
        result = 'Yes';
    } else if (no.includes(`${value}`.toLowerCase())) {
        result = 'No';
    }
    return <span>{result}</span>;
};

export const outerSpacing = 8;
export const innerSpacing = 8;

const filterInner = (
    filter: string,
    allData:
        | JobFunctionType[]
        | JobRoleType[]
        | CountryType[]
        | LanguageType[]
        | RegionType[]
        | any[],
    idProperty: string
): any[] => {
    if (isEmpty(filter)) {
        return allData.map((data) => ({
            label: data.name,
            value: String(data[idProperty]),
        }));
    } else {
        const allList = allData.filter((data: { name: string }) =>
            data.name.toLowerCase().includes(filter.toLowerCase())
        );
        return allList.map((data) => ({
            label: data.name,
            value: String(data[idProperty]),
        }));
    }
};

const DEFAULT_FILTER_PARAMS = {
    buttons: ['apply', 'reset'],
    filterOptions: [
        'contains',
        'notContains',
        'startsWith',
        'endsWith',
        'equals',
        'notEqual',
    ],
};

const isRoleCoAdminOnly = (roles: any[]): boolean => {
    if (roles.length > 0) {
        if (roles.length === 2) {
            const found1 = roles.find(v => ('ROLE_COADMIN' === v));
            const found2 = roles.find(v => ('COADMIN' === v));
            return !isEmpty(found1) && !isEmpty(found2);
        }
    }
    return false;
}

export {
    PageWrapper,
    PageWrapper2,
    BreadCrumbsWrapper,
    HeaderWrapper,
    ButtonWrapper,
    ButtonItem,
    BottomWrapper,
    filterInner,
    DEFAULT_FILTER_PARAMS,
    TopImageWrapper,
    Button,
    isRoleCoAdminOnly
};
