/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import { UserContext } from '../../contexts/UserContext';
import useMediaQuery from '../../styles/MediaQueryHooks';

const ItemWrapper = styled.div`
  height: 100%;
  &:after {
    display: block;
    position: relative;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 60%,
      ${(props) => props.theme.brandingColor} 70%
    );
    margin-top: -150px;
    height: 150px;
    width: 100%;
    content: '';
  }
`;

const ItemImage = styled.img`
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
`;

const BrandingHeader: React.FC = () => {
  const theme = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const customDesktop = useMediaQuery('(min-width: 1550px)');
  const customTablet = useMediaQuery('(min-width: 1280px)');

  const brandingColor =
    user?.brandingObject?.backgroundColor !== undefined &&
    (user.brandingObject.backgroundColor !== undefined) !== null
      ? user.brandingObject.backgroundColor
      : null;

  // no branding object or all logos are empty
  if (
    user.brandingObject === undefined ||
    user.brandingObject === null ||
    ((user.brandingObject.tinyLogo === null ||
      user.brandingObject.tinyLogo === undefined) &&
      (user.brandingObject.mediumLogo === null ||
        user.brandingObject.mediumLogo === undefined) &&
      (user.brandingObject.bigLogo === null ||
        user.brandingObject.bigLogo === undefined))
  ) {
    return null;
  } else {
    let brandingUrl;
    let bigLogoMimeType;
    let bigLogoContent;

    // assign biggest availabe big logo
    if (
      user.brandingObject.bigLogo === null ||
      user.brandingObject.bigLogo === undefined
    ) {
      if (
        user.brandingObject.mediumLogo !== null ||
        user.brandingObject.mediumLogo !== undefined
      ) {
        bigLogoMimeType = user.brandingObject.mediumLogo.mimeType;
        bigLogoContent = user.brandingObject.mediumLogo.content;
      } else if (
        user.brandingObject.tinyLogo !== null ||
        user.brandingObject.tinyLogo !== undefined
      ) {
        bigLogoMimeType = user.brandingObject.tinyLogo.mimeType;
        bigLogoContent = user.brandingObject.tinyLogo.content;
      }
    } else {
      bigLogoMimeType = user?.brandingObject?.bigLogo?.mimeType;
      bigLogoContent = user?.brandingObject?.bigLogo?.content;
    }

    if (customDesktop) {
      brandingUrl = `data:${bigLogoMimeType};base64,${bigLogoContent}`;
    } else if (customTablet) {
      brandingUrl = `data:${user.brandingObject.mediumLogo.mimeType};base64,${user.brandingObject.mediumLogo.content}`;
    } else {
      brandingUrl = `data:${user.brandingObject.tinyLogo?.mimeType};base64,${user.brandingObject.tinyLogo?.content}`;
    }

    return (
      <ItemWrapper theme={{ ...theme, brandingColor }}>
        <ItemImage alt={user.brandingObject.name} src={brandingUrl} />
      </ItemWrapper>
    );
  }
};

export default BrandingHeader;
