import React, { createContext, useState } from 'react';
import CommonType from './types/CommonType';

export const LoadingContext = createContext({
  isLoading: false,
  isUserLoading: false,
  setIsUserLoading: (data: boolean) => {},
  setLoading: (data: boolean) => {},
  loadings: 0,
  addLoading: () => {},
  removeLoading: () => {},
});

const LoadingProvider: React.FC<CommonType> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [loadings, setLoadings] = useState(0);

  const setLoading = (data: boolean): void => {
    setIsLoading(data);
  };

  const addLoading = ():void => {
      setLoadings((v) => v + 1);
  };
  const removeLoading = ():void => {
      setLoadings((v) => v - 1);
  };

  return (
    <LoadingContext.Provider
      value={{ isLoading, setLoading, isUserLoading, setIsUserLoading, loadings, addLoading, removeLoading }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
