/* eslint-disable @typescript-eslint/strict-boolean-expressions,@typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-base-to-string */

import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {
    NOTIFICATION_TYPES,
    NotificationContext,
} from '../../contexts/NotificationContext';
import {LoadingContext} from '../../contexts/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {ThemeContext} from '../../styles/ThemeContext';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';
import {
    BottomWrapper,
    BreadCrumbsWrapper,
    ButtonItem,
    ButtonWrapper,
    HeaderWrapper,
    PageWrapper,
} from '../common';
import {PATHS} from '../App/constants';
import DhlText from '../../components/DhlText';
import {useTranslation} from 'react-i18next';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {device} from '../../styles/Device';
import {DhlInputField, DhlDropdown} from '@dhl-official/ui-libraries/react-library';
import ImageUpload from '../../components/ImageUpload';
import {isArray, isEmpty} from 'lodash';
import ServiceRequest from '../../service/ServiceRequest';
import {MultiSelectDropDown} from '../../components/MultiSelectDropDown';
import {CustomerObjectContext} from "../../contexts/CustomerObjectContext";
import DhlMultiSelect from "../../components/DhlMultiSelect";
import {types} from "sass";
import Number = types.Number;

const LeftFormWrapper = styled.div`
  width: 100%;
  padding: 16px;
  margin: 4px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};
  box-shadow: 1px 1px 2px 1px #e6e6e6;
`;

const FormWrapper = styled.div`
  width: 100%;
  min-width: 200px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const FormField = styled(DhlInputField)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const FormSingleDropDown = styled(DhlDropdown)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const FormDropDown = styled(MultiSelectDropDown)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const FormDropDown2 = styled(DhlMultiSelect)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const myBreadCrumbList = [
    {label: 'App.breadcrumb.home', path: PATHS.HOME},
    {label: 'App.breadcrumb.news', path: PATHS.NEWS_LIST},
];

interface IFormInputs {
    header: string;
    url: string;
    image: any;
    customerObjectIds: string[];
    visibility: any | null;
    regionVisibility: any[] | null;
    coVisibility: any[] | null;
    countryVisibility: any[] | null;
}

function NewsListUpdatePage(): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {t} = useTranslation();
    const {setLoading, addLoading, removeLoading} = useContext(LoadingContext);
    const {setType, setMessage} = useContext(NotificationContext);
    const {customerObjectList, isCoLoading} = useContext(CustomerObjectContext);
    const [customerObjectData, setCustomerObject] = useState<any>([]);
    const [breadCrumbsData, setBreadCrumbsData] = useState<any>([]);
    const [editNewsData, setEditNewsData] = useState<any>({});
    const navigate = useNavigate();
    const location = useLocation();
    const [visibilityRegions, setVisibilityRegions] = useState<any>([]);
    const [visibilityCountries, setVisibilityCountries] = useState<any>([]);
    const [regionsLoading, setRegionsLoading] = useState<boolean>(false);
    const [visibilityCos, setVisibilityCos] = useState<any>([]);
    const [countriesLoading, setCountriesLoading] = useState<boolean>(false);
    const VISIBILITY_VALUES = [{value: "region", label: "Region"}, {value: "co", label: "Customer"}, {value: "country", label: "Country"}];

    // console.log("UE====? ", customerObjectList);

    const regexHeader = /[\\/[\]:¦<>+=;,*?!@#$%^&*(){}"]/;

    const isEditMode =
        location.state !== null && Object.keys(location.state).length > 0;

    const {
        handleSubmit,
        control,
        setValue,
        setError,
        watch
    } = useForm<IFormInputs>({
        defaultValues: {
            header: '',
            url: '',
            image: {},
            customerObjectIds: [],
            visibility: null
        },
    });

    const watchVisibility = watch('visibility');

    const getNewsDetails = (newsId: string | Number): void => {
        addLoading();
        ServiceRequest.getNewsDetails(newsId.toString())
            .then((response) => {
                const myUpdatedList: any[] = [];
                response?.data?.customerObjectIds.forEach((resList: any) => {
                    myUpdatedList.push(customerObjectList.find((custData: any) => custData.value.toString() === resList.toString()));
                })
                const myResponse = {
                    ...response.data,
                    customerObjectIds: myUpdatedList,
                }
                setEditNewsData(myResponse);
            })
            .catch((error) => {
                setType(NOTIFICATION_TYPES.FAILED);
                setMessage(error.message);
            })
            .finally(() => {
                removeLoading();
                updateSettings();
            });
    };

    const updateSettings = (): void => {
        if (location.pathname === PATHS.NEWS_ADD)
            setBreadCrumbsData([
                ...myBreadCrumbList,
                {label: 'App.breadcrumb.addNews', path: ''},
            ]);

        if (location.pathname === PATHS.NEWS_EDIT) {
            setBreadCrumbsData([
                ...myBreadCrumbList,
                {label: 'App.breadcrumb.editNews', path: ''},
            ]);

            if (isEditMode) {
                setValue('header', location?.state?.header);
                setValue('url', location?.state?.url);
                if(location?.state?.regionsVisibility) {
                    setValue('visibility', VISIBILITY_VALUES.find(v => v.value === 'region'));
                    if(visibilityRegions) {
                        setValue('regionVisibility', visibilityRegions.filter((r:any) => location?.state?.regionsVisibility.includes(1*r.value)))
                    }
                } else if(location?.state?.customerObjectsVisibility) {
                    setValue('visibility', VISIBILITY_VALUES.find(v => v.value === 'co'));
                    if(visibilityCos) {
                        const selected = visibilityCos.filter((r:any) => location?.state?.customerObjectsVisibility.includes(1*r.value));
                        setValue('coVisibility', selected);
                    }
                } else if(location?.state?.countriesVisibility) {
                    setValue('visibility', VISIBILITY_VALUES.filter(v => v.value === 'country'));
                    if(visibilityCountries) {
                        setValue('countryVisibility', visibilityCountries.find((r:any) => location?.state?.countriesVisibility.includes(1*r.value)));
                    }
                }
                if (
                    location?.state?.image === null ||
                    location?.state?.image === undefined
                )
                    setValue('image', {});
                else setValue('image', location?.state?.image);
            }
        }
    };

    useEffect(() => {
        // alert(watchVisibility);
    }, [watchVisibility]);

    useEffect(() => {
        if (customerObjectList.length > 0) {
            setCustomerObject(customerObjectList);
        }
        return () => {
            setBreadCrumbsData(myBreadCrumbList);
        };
    }, []);

    useEffect(() => {
        setCustomerObject(customerObjectList);
        setVisibilityCos(customerObjectList.map((co: any) => ({
            label: co.label,
            value: String(co.value),
        })));
    }, [customerObjectList])

    useEffect(() => {
        if (!isEmpty(editNewsData) && Object.keys(editNewsData).length > 0) {
            setValue('customerObjectIds', editNewsData.customerObjectIds);
            setValue('header', editNewsData.header);
            setValue('url', editNewsData.url);
            if(editNewsData.regionsVisibility) {
                setValue('visibility', VISIBILITY_VALUES.find(v => v.value === 'region'));
                if(visibilityRegions) {
                    setValue('regionVisibility', visibilityRegions.filter((r:any) => editNewsData.regionsVisibility.includes(1*r.value)))
                }
            } else if(editNewsData.customerObjectsVisibility) {
                setValue('visibility', VISIBILITY_VALUES.find(v => v.value === 'co'));
                if(visibilityCos) {
                    setValue('coVisibility', visibilityCos.filter((r:any) => editNewsData.customerObjectsVisibility.includes(1*r.value)))
                }
            } else if(editNewsData.countriesVisibility) {
                setValue('visibility', VISIBILITY_VALUES.find(v => v.value === 'country'));
                if(visibilityCountries) {
                    setValue('countryVisibility', visibilityCountries.filter((r:any) => editNewsData.countriesVisibility.includes(1*r.value)))
                }
            }
            if (editNewsData.image === null || editNewsData.image === undefined)
                setValue('image', {});
            else setValue('image', editNewsData.image);
        }
    }, [editNewsData, visibilityRegions, visibilityCos, visibilityCountries]);

    useEffect(
        () =>
            ServiceRequest.processRequest({
                setLoading,
                setType,
                setMessage,
                inputData: {},
                request: ServiceRequest.getRegions,
                responseHandler: (response) => {
                    setVisibilityRegions(response.data.map((region: any) => ({
                        label: region.name,
                        value: String(region.regionId),
                    })));
                },
                prefetch: () => setRegionsLoading(true),
                postfetch: () => setRegionsLoading(false)
            }),
        []
    );

    useEffect(
        () =>
            ServiceRequest.processRequest({
                setLoading,
                setType,
                setMessage,
                inputData: {},
                request: ServiceRequest.getCountries,
                responseHandler: (response) => {
                    setVisibilityCountries(response.data.map((c: any) => ({
                        label: c.name,
                        value: String(c.id),
                    })));
                },
                prefetch: () => setCountriesLoading(true),
                postfetch: () => setCountriesLoading(false)
            }),
        []
    );

    useEffect(() => {
        if (isEditMode && customerObjectData.length > 0 && isEmpty(editNewsData)) {
            getNewsDetails(location.state?.id);
        }
    }, [customerObjectData]);

    const onSubmitData: SubmitHandler<IFormInputs> = (data) => {
        let isError = false;
        if (
            data.image === undefined ||
            data.image === null ||
            Object.keys(data.image).length === 0
        ) {
            delete data.image;
        }

        if (regexHeader.test(data.header)) {
            isError = true;
            setError("header", {
                type: "special_char",
                message: "Special characters not allowed.",
            })
        }

        const myCoIds = data.customerObjectIds.map((codata: any) => codata.value);
        let regionsVisibility = null;
        let customerObjectsVisibility = null;
        let countriesVisibility = null;
        if(data.visibility?.value !== 'region') {
            data.regionVisibility = null;
        }
        if(data.visibility?.value !== 'co') {
            data.coVisibility = null;
        }
        if(data.visibility?.value !== 'country') {
            data.countryVisibility = null;
        }
        switch(data.visibility?.value) {
            case 'region':
                regionsVisibility = data?.regionVisibility?.map((d: any) => d.value);
                break;
            case 'co':
                customerObjectsVisibility = data?.coVisibility?.map((d: any) => d.value);
                break;
            case 'country':
                countriesVisibility = data?.countryVisibility?.map((d: any) => d.value);
                break;
        }

        if (!isError) {
            setLoading(true);
            if (isEditMode) {
                const objectRequest = {
                    ...editNewsData,
                    ...data,
                    customerObjectIds: myCoIds,
                    header: data.header.trim(),
                    id: location.state?.id,
                    regionsVisibility: regionsVisibility,
                    customerObjectsVisibility: customerObjectsVisibility,
                    countriesVisibility: countriesVisibility
                };

                ServiceRequest.updateNews(objectRequest)
                    .then((_) => {
                        setType(NOTIFICATION_TYPES.SUCCESS);
                        setMessage(t('App.page.news.success'));
                        navigate(-1);
                    })
                    .catch((error) => {
                        setType(NOTIFICATION_TYPES.FAILED);
                        if(error?.response?.data?.message !== undefined){
                            setMessage(error.response.data.message);
                        } else {
                            setMessage(error.message);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                const objectRequest = {
                    ...data,
                    customerObjectIds: myCoIds,
                    header: data.header.trim(),
                    active: false,
                    regionsVisibility: regionsVisibility,
                    customerObjectsVisibility: customerObjectsVisibility,
                    countriesVisibility: countriesVisibility
                };
                ServiceRequest.createNews(objectRequest)
                    .then((_) => {
                        setType(NOTIFICATION_TYPES.SUCCESS);
                        setMessage(t('App.page.news.success'));
                        navigate(-1);
                    })
                    .catch((error) => {
                        setType(NOTIFICATION_TYPES.FAILED);
                        if(error?.response?.data?.message !== undefined){
                            setMessage(error.response.data.message);
                        } else {
                            setMessage(error.message);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const blockInvalidChars = (event: any): void => {
        const {key} = event;
        if (regexHeader.test(key)) {
            event.preventDefault();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitData)}>
            <PageWrapper theme={theme}>
                <BreadCrumbsWrapper>
                    <DhlBreadCrumbs list={breadCrumbsData}/>
                </BreadCrumbsWrapper>
                <HeaderWrapper>
                    <DhlText size="md" weight={800} title={t('App.breadcrumb.news')}/>

                    <ButtonWrapper>
                        <ButtonItem
                            title={t('App.common.cancel')}
                            variant="outline"
                            size="sm"
                            dataAriaLabel={t('App.common.cancel')}
                            onClick={() => navigate(-1)}
                        />

                        <ButtonItem
                            title={t('App.common.save')}
                            variant="primary"
                            size="sm"
                            dataAriaLabel={t('App.common.save')}
                            type="submit"
                        />
                    </ButtonWrapper>
                </HeaderWrapper>

                <BottomWrapper>
                    <LeftFormWrapper theme={theme}>
                        <FormWrapper>

                            <Controller
                                name="customerObjectIds"
                                control={control}
                                rules={{required: true}}
                                render={({
                                             field: {value, onChange, ...propsField},
                                             fieldState: {error},
                                         }) => (
                                    <FormDropDown2
                                        {...propsField}
                                        multiple
                                        loading={isCoLoading}
                                        value={value}
                                        label={`${t('App.page.ExternalUser.customerObject')} *`}
                                        error={
                                            error != null &&
                                            isEmpty(value) && {
                                                type: 'invalid',
                                                message: t('App.page.Branding.required'),
                                            }
                                        }
                                        options={customerObjectData}
                                        onChange={(_: any, newVal: string) => {
                                            onChange(newVal);
                                        }}
                                    />
                                )}
                            />
                        </FormWrapper>

                        <FormWrapper>
                            <Controller
                                name="header"
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: 100,
                                }}
                                render={({
                                             field: {onChange, onBlur, ...propsField},
                                             fieldState: {error},
                                         }) => (
                                    <FormField
                                        {...propsField}
                                        dataId="header-inputfield"
                                        type="text"
                                        blurEvent={onBlur}
                                        inputEvent={onChange}
                                        onKeyPress={blockInvalidChars}
                                        validation={
                                            error != null && {
                                                type: 'invalid',
                                                message:
                                                    error.type === 'required'
                                                        ? t('App.page.Branding.required')
                                                        : error.type === 'special_char'
                                                            ? error.message
                                                            : t('App.page.news.errorMaxLength'),
                                            }
                                        }
                                        variant={{
                                            label: `${t('App.page.news.newsHeader')} *`,
                                            // placeholder: `${t('App.page.news.newsHeader')} *`,
                                            type: 'static',
                                        }}
                                    />
                                )}
                            />
                        </FormWrapper>

                        <FormWrapper>
                            <Controller
                                name="url"
                                control={control}
                                render={({field: {onChange, onBlur, ...propsField}}) => (
                                    <FormField
                                        {...propsField}
                                        dataId="url-inputfield"
                                        type="text"
                                        blurEvent={onBlur}
                                        inputEvent={onChange}
                                        variant={{
                                            label: `${t('App.page.news.url')}`,
                                            // placeholder: `${t('App.page.news.url')}`,
                                            type: 'static',
                                        }}
                                    />
                                )}
                            />
                        </FormWrapper>






                        <FormWrapper>
                            <Controller
                                name="visibility"
                                control={control}
                                render={({
                                             field: {value, onChange, onBlur, ...propsField},
                                             fieldState: {error},
                                         }) => (
                                    <FormDropDown2
                                        {...propsField}
                                        blurEvent={onBlur}
                                        onChange={(_: any, newVal: any) => {
                                            onChange(newVal);
                                        }}
                                        dataId="visibility-inputfield"
                                        value={value}
                                        label={`${t('App.page.news.newsVisibility')}`}
                                        options={VISIBILITY_VALUES}
                                    />
                                )}
                            />
                        </FormWrapper>

                        {watchVisibility?.value === "region" && <FormWrapper><Controller
                            name="regionVisibility"
                            control={control}
                            rules={{ required: true }}
                            render={({
                                         field: { onChange, onBlur, value, ...propsField },
                                         fieldState: { error },
                                     }) => (
                                <FormDropDown2
                                    {...propsField}
                                    multiple
                                    dataId="regionVisibility-inputfield"
                                    options={visibilityRegions}
                                    value={value}
                                    blurEvent={onBlur}
                                    onChange={(_: any, newVal: string) => {
                                        onChange(newVal);
                                    }}
                                    loading={regionsLoading}
                                    validation={
                                        error != null && {
                                            type: 'invalid',
                                            message: t('App.page.news.newsVisibility.region.required'),
                                        }
                                    }
                                    placeholder={`${t(
                                        'App.page.news.newsVisibility.region'
                                    )} *`}
                                    label={`${t('App.page.news.newsVisibility.region')} *`}
                                />
                            )}
                        /></FormWrapper>}

                        {watchVisibility?.value === 'co' && <FormWrapper><Controller
                            name="coVisibility"
                            control={control}
                            rules={{ required: true }}
                            render={({
                                         field: { onChange, onBlur, value, ...propsField },
                                         fieldState: { error },
                                     }) => (
                                <FormDropDown2
                                    {...propsField}
                                    multiple
                                    dataId="coVisibility-inputfield"
                                    options={visibilityCos}
                                    value={value}
                                    blurEvent={onBlur}
                                    onChange={(_: any, newVal: string) => {
                                        onChange(newVal);
                                    }}
                                    loading={isCoLoading}
                                    validation={
                                        error != null && {
                                            type: 'invalid',
                                            message: t('App.page.news.newsVisibility.co.required'),
                                        }
                                    }
                                    placeholder={`${t(
                                        'App.page.news.newsVisibility.co'
                                    )} *`}
                                    label={`${t('App.page.news.newsVisibility.co')} *`}
                                />
                            )}
                        /></FormWrapper>}

                        {watchVisibility?.value === 'country' && <FormWrapper><Controller
                            name="countryVisibility"
                            control={control}
                            rules={{ required: true }}
                            render={({
                                         field: { onChange, onBlur, value, ...propsField },
                                         fieldState: { error },
                                     }) => (
                                <FormDropDown2
                                    {...propsField}
                                    multiple
                                    dataId="countryVisibility-inputfield"
                                    options={visibilityCountries}
                                    value={value}
                                    blurEvent={onBlur}
                                    onChange={(_: any, newVal: string) => {
                                        onChange(newVal);
                                    }}
                                    loading={countriesLoading}
                                    validation={
                                        error != null && {
                                            type: 'invalid',
                                            message: t('App.page.news.newsVisibility.country.required'),
                                        }
                                    }
                                    placeholder={`${t(
                                        'App.page.news.newsVisibility.country'
                                    )} *`}
                                    label={`${t('App.page.news.newsVisibility.country')} *`}
                                />
                            )}
                        /></FormWrapper>}









                        <FormWrapper>
                            <Controller
                                name="image"
                                control={control}
                                render={({
                                             field: {onChange, onBlur, value, ...propsField},
                                             // formState,
                                             // fieldState,
                                         }) => (
                                    <ImageUpload
                                        {...propsField}
                                        caption={t('App.page.news.image')}
                                        dropzoneText={`${t('App.page.Branding.max')} 200 x 150 ${t(
                                            'App.page.Branding.pixels'
                                        )}`}
                                        additionalDropzoneText={`${t(
                                            'App.page.Branding.maxSize'
                                        )} 500kB`}
                                        isUploading={false}
                                        maxWidth={200}
                                        maxHeight={150}
                                        maxSize={512000}
                                        disabled={false}
                                        data={value}
                                        setData={onChange}
                                    />
                                )}
                            />
                        </FormWrapper>
                    </LeftFormWrapper>
                </BottomWrapper>
            </PageWrapper>
        </form>
    );
}

export default NewsListUpdatePage;
