import React, {useContext, useEffect, useState} from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import {ThemeContext} from '../../../styles/ThemeContext';
import {useTranslation} from "react-i18next";
import {Button} from "../../common";
import {device} from "../../../styles/Device";
import {GridApi} from "ag-grid-community";
import {FormControl, MenuItem, Select, TextField} from "@mui/material";
import {IconContext} from "../../../assets/IconContext";
import {DhlIcon} from "@dhl-official/ui-libraries/react-library";

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  min-width: 100%;
  border-top: 1px solid #ebebeb;
  background-color: ${(props) => props.theme.background};
  @media ${device.mobile} {
  flex-wrap: wrap;
  justify-content: center;
  }
  @media ${device.tablet} {
  flex-wrap: nowrap;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobile} {
    width : 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media ${device.tablet} {
    width : 40%;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
 @media ${device.mobile} {
    width : 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media ${device.tablet} {
    width : 60%;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 4px;
`;

const MyButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const MyButton = styled.div<{ isSelected: boolean; isDisabled: boolean }>`
  width: 24px;
  height: 24px;
  margin: 2px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-weight: ${(props) => (props.isSelected ? `600` : '400')};
  color: ${(props) => props.isSelected ? props.theme.color.black[400] : props.isDisabled ? props.theme.color.grey[400] : props.theme.color.grey[600]};
  border-bottom: ${(props) => (props.isSelected ? `3px solid #d40511` : '')};
  :hover {
    cursor: ${(props) => props.isDisabled ? "" : "pointer"};
    font-weight: ${(props) => props.isDisabled ? "500" : "600"};
    color: ${(props) => props.isDisabled ? props.theme.color.grey[400] : props.theme.color.black[400]};
  }
`;

interface TypePagination {
    totalResults: number;
    api: GridApi,
    onPaginationChanged: Function;
    isFilterUpdate?: boolean;
    updateIsFilter?: Function;
}

interface TypePaging {
    api: GridApi,
    selectedPage: number;
    changeSelectedPage: Function;
    totalPageCount: number;
    pageArray: number[];
    changePageArray: Function;
}

const ButtonWrapper = (props: TypePaging): JSX.Element => {
    const {theme} = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const {selectedPage, changeSelectedPage, totalPageCount, pageArray, changePageArray} = props;

    const isDisableNext = totalPageCount === selectedPage;
    const isDisablePrevious = selectedPage === 1;

    console.log("SELECTED PAGE ==> ", selectedPage);

    return (
        <MyButtonGroup>
            <MyButton
                theme={theme}
                isDisabled={isDisablePrevious}
                isSelected={false}
                onClick={() => {
                    if (!isDisablePrevious) {
                        changeSelectedPage(1);
                    }
                }}
            >
                <DhlIcon size={"0.7rem"} src={getIcon("doubleArrowLeft")}/>
            </MyButton>

            <MyButton
                theme={theme}
                isDisabled={isDisablePrevious}
                isSelected={false}
                onClick={() => {
                    if (!isDisablePrevious) {
                        if ((selectedPage - 1) < pageArray[0])
                            changePageArray([selectedPage - 4, selectedPage - 3, selectedPage - 2, selectedPage - 1]);
                        changeSelectedPage(selectedPage - 1);
                    }
                }}
            >
                <DhlIcon size={"0.7rem"} src={getIcon("singleArrowLeft")}/>
            </MyButton>

            {pageArray.map((pageNo: number) => totalPageCount < pageNo ? null : <MyButton
                key={`My_page_${pageNo}`}
                theme={theme}
                isDisabled={selectedPage === pageNo || totalPageCount < pageNo}
                isSelected={selectedPage === pageNo}
                onClick={() => {
                    if (selectedPage !== pageNo && totalPageCount >= pageNo) changeSelectedPage(pageNo);
                }}
            >
                {pageNo}
            </MyButton>)}

            <MyButton
                theme={theme}
                isDisabled={isDisableNext}
                isSelected={false}
                onClick={() => {
                    if (!isDisableNext) {
                        if ((selectedPage + 1) > pageArray[3])
                            changePageArray([selectedPage + 1, selectedPage + 2, selectedPage + 3, selectedPage + 4]);
                        changeSelectedPage(selectedPage + 1);
                    }
                }}
            >
                <DhlIcon size={"0.7rem"} src={getIcon("singleArrowRight")}/>
            </MyButton>
            <MyButton
                theme={theme}
                isDisabled={isDisableNext}
                isSelected={false}
                onClick={() => {
                    if (!isDisableNext)
                        changeSelectedPage(totalPageCount);
                }}
            >
                <DhlIcon size={"0.7rem"} src={getIcon("doubleArrowRight")}/>
            </MyButton>
        </MyButtonGroup>
    );
};

function Pagination(props: TypePagination): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {t} = useTranslation();
    const {api, totalResults, onPaginationChanged, isFilterUpdate, updateIsFilter} = props;
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pageArray, setPageArray] = useState([1, 2, 3, 4]);
    const totalPageCount = Math.ceil(totalResults / pageSize);
    const selectedPage = pageNo < 1 ? 1 : pageNo;

    const pageList = [
        {label: '10', value: '10'},
        {label: '20', value: '20'},
        {label: '50', value: '50'},
        {label: '100', value: '100'},
        {label: '150', value: '150'}
    ];

    const handleChangePageSize = (e: any): void => {
        const value = Number(e.target.value);
        setPageSize(value);
        onPaginationChanged({pageNo, pageSize: value});
    };

    useEffect(() => {
        if (isFilterUpdate === true) {
            changePageNo(1);
            if (updateIsFilter !== undefined)
                updateIsFilter(false);
        }
    }, [isFilterUpdate])

    const validatePage = (page: number): void => {
        const isNumberExist = pageArray.includes(page);

        if (!isNumberExist) {
            let myUpdatedNumber = page;
            do {
                myUpdatedNumber++;
            }
            while (myUpdatedNumber % 4 !== 0);

            setPageArray([myUpdatedNumber - 3, myUpdatedNumber - 2, myUpdatedNumber - 1, myUpdatedNumber]);
        }
    }

    const changePageNo = (page: any): void => {
        const myPageNo = Number(page);
        setPageNo(myPageNo);
        validatePage(myPageNo);
        onPaginationChanged({pageNo: myPageNo, pageSize});
    }

    return (
        <BoxWrapper theme={theme}>
            <LeftWrapper>
                <DhlText
                    size="sm"
                    title={`( Showing ${api?.paginationGetPageSize()} out of ${totalResults} results )`}
                    color={"#666666"}/>
            </LeftWrapper>

            <RightWrapper>
                <ContentWrapper>
                    <DhlText size="sm" title={t('App.component.table.showingRecords')} color={"#666666"}/>

                    <FormControl sx={{m: 1, minWidth: 120}} size="small">
                        <Select
                            value={pageSize}
                            placeholder={'Select'}
                            onChange={handleChangePageSize}
                            defaultValue={10}
                            style={{height: 35, width: 80, paddingBottom: 8}}
                        >
                            {pageList.map((selectItem) => <MenuItem key={selectItem.value} value={selectItem.value}>
                                <em>{selectItem.label}</em>
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                </ContentWrapper>

                <ContentWrapper>
                    <ButtonWrapper
                        selectedPage={selectedPage}
                        totalPageCount={totalPageCount}
                        changeSelectedPage={changePageNo}
                        api={api}
                        pageArray={pageArray}
                        changePageArray={(arr: number[]) => setPageArray(arr)}
                    />
                </ContentWrapper>

                <ContentWrapper>
                    <DhlText size="sm" title={t('App.page.Customers.goToPage')} color={"#666666"}/>
                    <TextField
                        id="outlined-size-small"
                        sx={{
                            "& .MuiInputBase-root": {
                                height: "35px",
                                paddingBottom: "8px",
                                width: "60px",
                                marginLeft: "10px"
                            }
                        }}
                        value={pageNo}
                        size="small"
                        onChange={(event: any) => setPageNo(event.target.value)}
                    />

                    <Button
                        title={'Go'}
                        size={'sm'}
                        variant={'outline'}
                        onClick={() => changePageNo(pageNo)}/>
                </ContentWrapper>
            </RightWrapper>
        </BoxWrapper>
    );
}

export default Pagination;