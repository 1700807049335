import React, { createContext, useState, useEffect, useContext } from 'react';
import CommonType from './types/CommonType';
import ServiceRequest from '../service/ServiceRequest';
import { NOTIFICATION_TYPES, NotificationContext } from './NotificationContext';
import { LoadingContext } from './LoadingContext';
import CountryType from './types/CountryType';

export const CountryContext = createContext({
  countries: [] as CountryType[],
});

const CountryProvider: React.FC<CommonType> = (props) => {
  const [countries, setCountriesData] = useState<CountryType[]>([]);
  const { setType, setMessage } = useContext(NotificationContext);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    ServiceRequest.getCountries()
      .then((response) => {
        setCountriesData(response.data);
      })
      .catch((error) => {
        setType(NOTIFICATION_TYPES.FAILED);
        setMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <CountryContext.Provider value={{ countries }}>
      {props.children}
    </CountryContext.Provider>
  );
};

export default CountryProvider;
