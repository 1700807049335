/* eslint-disable @typescript-eslint/restrict-template-expressions */
// @flow
import React, { useContext } from 'react';
import { TypeMenu } from './TypeMenu';
import MenuItemApp from './MenuItem';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import ClickAwayListener from 'react-click-away-listener';
import { UserContext } from '../../contexts/UserContext';
// import DhlText from '../DhlText';
import { useTranslation } from 'react-i18next';
// import { getBackUrl } from '../../utils';
import { DhlIconWrapper } from '@dhl-official/ui-libraries/react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';

const ContextWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 60px;
  margin-left: 0px;
  cursor: pointer;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const PopupWrapper = styled.div`
  width: 100%;
  min-width: 200px;
  min-height: 100px;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.primaryText};
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  top: 45px;
  right: 10%;
  margin-left: -80px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  :before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 15%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${(props) => props.theme.background}
      transparent;
  }
`;

const MyIconWrapper = styled(DhlIconWrapper)`
  margin-left: 10px;
  cursor: pointer;
  z-index: 0;
`;

export const iconTypeEnum = {
  BASE64: 'base64',
  NONE: 'none',
};

const AppContext: React.FC<TypeMenu> = (props) => {
  const { isMenuOpen, toggleMenu } = props;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isMenuOpen) return toggleMenu(false);
      }}
    >
      <ContextWrapper theme={theme}>
        <MyIconWrapper size="lg" onClick={() => toggleMenu(true)}>
          <FontAwesomeIcon icon={'ellipsis-vertical'} size="2xl" />
        </MyIconWrapper>

        {isMenuOpen && (
          <PopupWrapper theme={theme}>
            <MenuItemApp
              key={`menu-msc-item`}
              label={t('App.dscApplication')}
              onSelect={() => {}}
              icon="dsc_application"
              isHeader
            />

            <MenuItemApp
              key={`menu-msc-item`}
              label={t('App.applicationName')}
              onSelect={() => navigate('/#')}
              icon="home"
            />

            {user?.apps?.map((appItem) => {
              if (appItem.list === true) {
                return (
                  <MenuItemApp
                    key={`menu-item ${appItem.id}`}
                    label={`${appItem.name}`}
                    onSelect={() =>
                      window.open(
                        `${process.env.REACT_APP_BASE_URL}/app/${appItem.id}`,
                        '_self'
                      )
                    }
                    icon={appItem.icon}
                  />
                );
              } else if (
                appItem.childApps !== null &&
                appItem.childApps?.length > 0
              ) {
                return appItem.childApps?.map((childAppItem) => (
                  <MenuItemApp
                    key={`menu-item ${childAppItem.id}`}
                    label={`${childAppItem.name}`}
                    onSelect={() => {user?.impersonated !== undefined && user?.impersonated ? window.open(childAppItem.link, '_self') : window.open(childAppItem.link)}} // to keep impersonation session, open the link as _self
                    icon={childAppItem.icon}
                  />
                ));
              } else {
                return (
                  <MenuItemApp
                    key={`menu-item ${appItem.id}`}
                    label={`${appItem.name}`}
                    onSelect={() => {user?.impersonated !== undefined && user?.impersonated ? window.open(appItem.link, '_self') : window.open(appItem.link)}} // to keep impersonation session, open the link as _self
                    icon={appItem.icon}
                  />
                );
              }
            })}
          </PopupWrapper>
        )}
      </ContextWrapper>
    </ClickAwayListener>
  );
};

export default AppContext;
