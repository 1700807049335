/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {ThemeContext} from '../../../styles/ThemeContext';
import {device} from '../../../styles/Device';
import {UserContext} from '../../../contexts/UserContext';
import AppItem from './AppItem';
import ApplicationType from '../../../contexts/types/ApplicationType';
import DhlText from '../../../components/DhlText';
import {useTranslation} from 'react-i18next';
import VideoItem from './VideoItem';
import ServiceRequest, {logAuditEvent} from '../../../service/ServiceRequest';
import {ACTION_BUSINESS_SERVICE} from '../../App/constants';
import {LoadingContext} from '../../../contexts/LoadingContext';
import NewsContent from './NewsContent';
import InsightsItem from './InsightsItem';
import {IconContext} from '../../../assets/IconContext';
import {RatingModal} from './RatingModal';
import {NOTIFICATION_TYPES, NotificationContext} from '../../../contexts/NotificationContext';
import InsightsModel from "./InsightsModel";
// import SurveyContent from './SurveyContent';

const DISPLAYED_INSIGHTS = 4

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 16px;
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    flex-wrap: nowrap;
  }
  margin-bottom: 10px;
`;

const LeftWrapper = styled.div`
  /* @media ${device.mobile} {
    width: 100%;
  }
  @media ${device.laptop} {
    width: 75%;
  }
  @media ${device.largeDesktop} {
    width: 80%;
  } */
  display: flex;
  margin-right: auto;
  flex-direction: column;
`;

// TODO - style properly
const RightWrapper = styled.div`
  /* @media ${device.mobile} {
    width: 100%;
  }
  @media ${device.laptop} {
    width: 25%;
  }
  @media ${device.largeDesktop} {
    width: 20%;
  } */
  @media ${device.mobile} {
    width: 100%;
    align-items: center;
  }
  @media ${device.tablet} {
    width: 300px;
  }
  display: flex;
  margin-left: auto;
  flex-direction: column;
  padding: 10px;
`;

const LeftAppWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const HeaderWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 16px;
  margin-top: 20px;
  position: relative;
  left: -35px;
`;

const ButtonWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
  position: relative;
  left: -25px;
  :hover {
  cursor: pointer;
  }
`;

const HeaderRedLine = styled.div`
  margin-left: 10px;
  margin-right: 7px;
  margin-top: 3px;
  /* height: 5px;*/
  background-color: #d50511;
  height: 35px;
  width: 3px;
  /* width: 100px; 
  background:  0% 0% no-repeat padding-box;*/
`;

const ItemImage = styled.img`
  width: 300px;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
`;

const FeedbackDiv = styled.div<{ src: any }>`  
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 140px;
  @media ${device.mobile} {
    background-image: ${(props) => `url(${props.src})`};
    background-size: 40px 40px;
    background-repeat: no-repeat;
    text-indent: 1000%;
    width: 40px;
    right: 20px;
    background-color: none;
  }
  @media ${device.laptop} {
    background-image: none;
    text-indent: 0%;
    width: 100px;
    right: 0px;
    background-color: ${(props) => props.theme.color.red[400]};
    transform: rotate(-90deg);
    box-shadow: -5px -3px 12px 3px #d3d3d3;
  }
  color: white;
  font-size: 12px;
  font-weight: 600;
  :hover {
    z-index: 2;
    cursor: pointer;
  }
`;

interface BannerProps {
    pointer: boolean;
    onClick: any;
    key: number;
    alt: string;
    src: string;
}

const Banner = styled.img<BannerProps>`
  width: 300px;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: ${(p) => (p.pointer ? 'pointer' : 'default')};
`;

const BannerSeparator = styled.div`
  width: 300px;
  height: 20px;
`;

function BottomContent(): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {user, insightsList, mediaTypes} = useContext(UserContext);
    const {getIcon} = useContext(IconContext);
    const {isLoading, setLoading} = useContext(LoadingContext);
    const {setType, setMessage} = useContext(NotificationContext);
    const [isRatingDialogOpen, setIsRatingDialogOpen] = useState(false);
    const [clearForm, setClearForm] = useState(false);
    const [displayInsights, setDisplayInsights] = useState<boolean>(false);
    const {apps = []} = user;
    const {t} = useTranslation();

    const myDhlResources = [] as ApplicationType[];
    const myDhlVideos = [] as ApplicationType[];

    interface MediaType {
        id?: number;
        typeId?: string;
        mimeType?: string;
    }


    const getMediaType = (
        mediaTypes: MediaType[],
        mediaTypeId: Number
    ): MediaType | undefined => {
        for (const mediaType of mediaTypes) {
            if (mediaType.id === mediaTypeId) {
                return mediaType;
            }
        }
    };

    const bannerClick = (banner: ApplicationType): void => {
        void logAuditEvent(ACTION_BUSINESS_SERVICE, banner.id);
        if (banner.link != null) {
            window.open(banner.link, '_blank', 'noopener,noreferrer');
        }
    };

    user.mediaApps?.forEach((mediaData) => {
        if (!isLoading) {
            if (
                mediaTypes.length > 0 &&
                mediaData.typeId !== undefined &&
                mediaData.typeId !== null
            ) {
                const mediaType: MediaType | undefined = getMediaType(
                    mediaTypes,
                    mediaData.typeId
                );

                if (
                    mediaType?.mimeType !== undefined &&
                    mediaType?.mimeType !== null &&
                    mediaType.mimeType.includes('video')
                ) {
                    myDhlVideos.push({
                        id: mediaData.id,
                        appIconNameLabelId: mediaData.appIconNameLabelId,
                        category: '',
                        childApps: [],
                        link: mediaData.link,
                        icon: mediaData.icon,
                        shortDescLabelId: '',
                        name: mediaData.name,
                        shortDesc: '',
                        list: false,
                        shortDescriptions: [],
                    });
                } else {
                    myDhlResources.push({
                        id: mediaData.id,
                        appIconNameLabelId: mediaData.appIconNameLabelId,
                        category: '',
                        childApps: [],
                        link: mediaData.link,
                        icon: mediaData.icon,
                        shortDescLabelId: '',
                        name: mediaData.name,
                        shortDesc: '',
                        list: false,
                        shortDescriptions: [],
                    });
                }
            } else {
                myDhlResources.push({
                    id: mediaData.id,
                    appIconNameLabelId: mediaData.appIconNameLabelId,
                    category: '',
                    childApps: [],
                    link: mediaData.link,
                    icon: mediaData.icon,
                    shortDescLabelId: '',
                    name: mediaData.name,
                    shortDesc: '',
                    shortDescriptions: [],
                });
            }
        }
    });

    user.resources?.forEach((resource) => {
        myDhlResources.push(resource);
    });

    const handleFeedback = (): void => {
        setIsRatingDialogOpen(true);
    };

    const submitRating = (myRequest: any): void => {
        setLoading(true);
        ServiceRequest.submitRating(myRequest)
            .then((response) => {
                const result = response.data;
                setType(NOTIFICATION_TYPES.SUCCESS);
                setMessage(t('App.page.Feedback.success'));
                setClearForm(true);
                // eslint-disable-next-line no-console
                console.log('RESULT ', result);
            })
            .catch((error) => {
                setType(NOTIFICATION_TYPES.FAILED);
                setMessage(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
                setIsRatingDialogOpen(false);
            });
    };

    return (
        <Wrapper theme={theme}>
            <FeedbackDiv src={getIcon('Feedback')} theme={theme} onClick={handleFeedback}>
                {t('App.common.feedback')}
            </FeedbackDiv>

            <LeftWrapper>
                <LeftAppWrapper>
                    {apps.map((appData: ApplicationType) => (
                        <AppItem
                            key={appData.id}
                            app={{
                                ...appData,
                            }}
                        />
                    ))}
                </LeftAppWrapper>

                {myDhlResources.length > 0 && (
                    <>
                        <HeaderWrapper>
                            <HeaderRedLine/>
                            <DhlText
                                size="xl"
                                title={t('App.page.Home.DhlResources')}
                                weight={800}
                            />
                        </HeaderWrapper>

                        <LeftAppWrapper>
                            {myDhlResources.map((resourceItem) => (
                                <AppItem key={resourceItem.id} app={{...resourceItem}}/>
                            ))}
                        </LeftAppWrapper>
                    </>
                )}

                {insightsList?.length > 0 && (
                    <>
                        <HeaderWrapper>
                            <HeaderRedLine/>
                            <DhlText
                                size="xl"
                                title={t('App.page.Home.DhlInsights')}
                                weight={800}
                            />
                        </HeaderWrapper>

                        <LeftAppWrapper>
                            {insightsList.map((resourceItem: any, index: number) => index < DISPLAYED_INSIGHTS &&
                                <InsightsItem key={resourceItem?.title} itemDetails={{...resourceItem}}/>
                            )}
                        </LeftAppWrapper>
                    </>
                )}

                {insightsList?.length > DISPLAYED_INSIGHTS + 1 && (
                    <ButtonWrapper onClick={() => setDisplayInsights(true)}>
                        <DhlText
                            size="md"
                            weight={600}
                            title={"View More Insights   >"}
                            color={'#d40511'}
                        />
                    </ButtonWrapper>
                )}

                {/* {user.insights?.length > 0 && ( */}
                {/*    <> */}
                {/*        <HeaderWrapper> */}
                {/*            <HeaderRedLine/> */}
                {/*            <DhlText */}
                {/*                size="xl" */}
                {/*                title={t('App.page.Home.DhlInsights')} */}
                {/*                weight={800} */}
                {/*            /> */}
                {/*        </HeaderWrapper> */}

                {/*        <LeftAppWrapper> */}
                {/*            {user.insights?.map((insightItem: any) => ( */}
                {/*                <InsightItem key={insightItem.id} app={{...insightItem}}/> */}
                {/*            ))} */}
                {/*        </LeftAppWrapper> */}
                {/*    </> */}
                {/* )} */}

                {myDhlVideos.length > 0 && (
                    <>
                        <HeaderWrapper>
                            <HeaderRedLine/>
                            <DhlText
                                size="xl"
                                title={t('App.page.Home.myScVideos')}
                                weight={800}
                            />
                        </HeaderWrapper>

                        <LeftAppWrapper>
                            {myDhlVideos.map((resourceItem) => (
                                <VideoItem key={resourceItem.id} app={resourceItem}/>
                            ))}
                        </LeftAppWrapper>
                    </>
                )}
            </LeftWrapper>

            <RightWrapper>
                {user.brandingObject !== undefined && user.brandingObject?.homepageImage !== undefined &&
                    user.brandingObject?.homepageImage !== null && (
                        <ItemImage
                            key={user.brandingObject.homepageImage.id}
                            alt={user.brandingObject.name}
                            src={`data:${user.brandingObject.homepageImage.mimeType};base64,${user.brandingObject.homepageImage.content}`}
                        />
                    )}
                {user.banners != null &&
                    user.banners.length > 0 &&
                    user.banners.map((banner) => (
                        <>
                            <BannerSeparator/>
                            <Banner
                                data-tracking="banner"
                                pointer={banner.link != null}
                                onClick={() => bannerClick(banner)}
                                key={banner.id}
                                alt={banner.name}
                                src={banner.imageLink}
                            />{' '}
                        </>
                    ))}
                <NewsContent/>
                {isRatingDialogOpen && (
                    <RatingModal
                        isOpen={isRatingDialogOpen}
                        handleClose={() => setIsRatingDialogOpen(false)}
                        currentRating={0}
                        submitRating={submitRating}
                        clearForm={clearForm}
                        setClearForm={setClearForm}
                    />
                )}
                <InsightsModel
                    isOpen={displayInsights}
                    handleClose={() => setDisplayInsights(false)}
                    insightsListData={insightsList.slice(DISPLAYED_INSIGHTS)}
                    offset={DISPLAYED_INSIGHTS}
                />
                {/*  <SurveyContent /> */}
            </RightWrapper>
        </Wrapper>
    );
}

export default BottomContent;
