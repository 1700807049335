import React, {useContext, useState} from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import {ThemeContext} from '../../../styles/ThemeContext';
import {
    DhlIcon,
    DhlIconWrapper,
    DhlImage,
} from '@dhl-official/ui-libraries/react-library';
import {IconContext} from '../../../assets/IconContext';
import {useTranslation} from 'react-i18next';
import {isEmpty} from '../../../utils';
import ApplicationType from '../../../contexts/types/ApplicationType';
import ClickAwayListener from 'react-click-away-listener';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useNavigate} from 'react-router-dom';
import {ACTION_BUSINESS_SERVICE, PATHS} from '../../App/constants';
import {logAuditEvent} from '../../../service/ServiceRequest';
import {device} from "../../../styles/Device";

const BoxWrapper = styled.div`
   @media ${device.mobile} {
    width: 100%;
  margin: 8px;
  }
  @media ${device.laptop} {
    width: 250px;
  margin: 16px;
  }
  height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-shadow: 1px 1px 2px 1px #e6e6e6;
  background-color: ${(props) => props.theme.background};
  border-radius: 4px;
  transition: 0.2s;
  :hover {
    box-shadow: 1px 1px 2px 1px #999999;
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
   @media ${device.mobile} {
    width: 90%;
  }
  @media ${device.laptop} {
    width: 200px;
  }
  height: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const ImageWrapper = styled(FontAwesomeIcon)`
  width: 30px;
  height: 30px;
  color: ${(props) => props.theme.color.red[400]};
`;

const DhlImageWrapper = styled(DhlImage)`
  width: 40px;
  height: 40px;
`;

const MenuWrapper = styled.div`
  width: 20px;
  height: 100%;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const MenuIConWrapper = styled(DhlIconWrapper)`
  width: 20px;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const MenuICon = styled(DhlIcon)`
  width: 20px;
  height: 20px;
`;

const MenuItemWrapper = styled.div`
  min-width: 140px;
  min-height: 40px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 10px;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  :hover {
    cursor: pointer;
    background-color: #f9f9f9;
    color: #d40511;
  }
`;

const PopupMenuWrapper = styled.div`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.primaryText};
  height: fit-content;
  border-radius: 6px;
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 4;
  top: 35%;
  @media ${device.mobile} {
    left: 7%
  }
  @media ${device.laptop} {
    left: 17%;
  }
  margin-left: -156px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
`;

const MyText = styled(DhlText)`
  margin-top: 10px;
`;

const StyledText = styled.text`
  font-size: 10px;
  font-weight: 600;
`;

export interface AppItemProperties {
    app: ApplicationType;
}

function AppItem({app}: AppItemProperties): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {
        appIconNameLabelId,
        shortDescLabelId,
        name,
        shortDesc,
        childApps,
        link,
        icon,
        integrated,
        list,
        id,
        shortDescriptions
    } = app;

    const onClickBox = (): void => {
        if (list !== null && list !== undefined && list) {
            void logAuditEvent(ACTION_BUSINESS_SERVICE, id);
            let mscUrl = PATHS.APP_DETAILS;
            mscUrl = mscUrl.replace(':appId', String(id));
            navigate(mscUrl);
        } else {
            void logAuditEvent(ACTION_BUSINESS_SERVICE, id);
            if (childApps !== null && childApps?.length > 0 && list === false)
                setTimeout(() => {
                    setIsMenuOpen(true);
                }, 50);
            else if (
                (childApps === undefined || childApps === null || childApps?.length === 0) &&
                !isEmpty(link)
            ) {
                if (integrated != null && integrated) {
                    window.open(link, '_self');
                } else {
                    window.open(link, '_blank', 'noopener,noreferrer');
                }
            }
        }
    };

    const getShortDescWithTranslation = (): string => {
        if (shortDescriptions !== undefined && shortDescriptions.length > 0 && shortDescriptions !== null) {
            const translation = shortDescriptions.find(sd =>
                sd.language === i18n.language
            );
            if (translation !== undefined && !isEmpty(translation)) {
                return translation?.shortDescription;
            }
        }
        return shortDesc;
    }

    return (
        <BoxWrapper
            data-tracking={name}
            theme={theme} onClick={onClickBox}>
            {isEmpty(icon) ? (
                <DhlImageWrapper src={getIcon('applicationIcon')}/>
            ) : (
                <ImageWrapper theme={theme} icon={icon} size="lg"/>
            )}
            <TextWrapper>
                <DhlText
                    size="sm"
                    weight={600}
                    title={!isEmpty(appIconNameLabelId) ? t(appIconNameLabelId) : name}
                />
                <MyText
                    size="sm"
                    color="secondary"
                    title={!isEmpty(shortDescLabelId) ? t(shortDescLabelId) : getShortDescWithTranslation()}
                />
            </TextWrapper>

            {childApps !== null && childApps?.length > 0 && !(list ?? false) ? (
                <ClickAwayListener
                    onClickAway={() => {
                        if (isMenuOpen) setIsMenuOpen(false);
                    }}
                >
                    <MenuWrapper>
                        <MenuIConWrapper
                            onClick={() => {
                                void logAuditEvent(ACTION_BUSINESS_SERVICE, id);
                                setIsMenuOpen(true);
                            }}
                        >
                            <MenuICon src={getIcon('moreVertical')}/>
                        </MenuIConWrapper>
                    </MenuWrapper>
                </ClickAwayListener>
            ) : (
                <MenuWrapper/>
            )}

            {isMenuOpen && (
                <PopupMenuWrapper theme={theme} data-tracking={app.name}>
                    {childApps !== null &&
                        childApps?.length > 0 &&
                        childApps?.map((childItem) => {
                            const clickHandler = (): void => {
                                void logAuditEvent(ACTION_BUSINESS_SERVICE, childItem.id);
                                if (
                                    childItem.integrated != null &&
                                    Boolean(childItem.integrated)
                                ) {
                                    window.open(childItem.link, '_self');
                                } else {
                                    window.open(
                                        childItem.link,
                                        '_blank',
                                        'noopener,noreferrer'
                                    );
                                }
                            };
                            return (
                                <MenuItemWrapper
                                    theme={theme}
                                    key={childItem.id}
                                    onTouchEnd={clickHandler}
                                    onClick={clickHandler}
                                >
                                    <StyledText>{childItem.name}</StyledText>
                                </MenuItemWrapper>
                            );
                        })}
                </PopupMenuWrapper>
            )}
        </BoxWrapper>
    );
}

export default AppItem;
