/* eslint-disable react/prop-types */

import React from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { minYear, maxYear, dateFormat, rangeOption } from './constants';
import DateFilterPickers from './DateFilterPickers';

export default class DateFilter extends React.PureComponent {
  ref = undefined;

  colId = '';

  updateAndNotifyAgGrid = this.updateGrid;

  constructor(props) {
    super(props)
    const { api, column: { colId }, debounceMs } = this.props
    this.colId = colId
    const filtersModel = api.getFilterModel();
    const model = filtersModel && filtersModel[colId] || null;
    let operator = 'equals'; let startDate = null; let endDate = null;
    if (model) {
      const { dateFrom, dateTo, type } = model
      startDate = this.dateFromString(dateFrom)
      endDate = this.dateFromString(dateTo)
      operator = type
    }
    this.state = {
      dateFrom: startDate,
      dateTo: endDate,
      error: false,
      type: operator,
      isFilterActive: this.getActiveFlag(startDate, endDate, operator)
    }

    if (debounceMs) {
      this.updateAndNotifyAgGrid = debounce(this.updateGrid, debounceMs)
    }
  }

  getActiveFlag(startDate, endDate, type) {
    if (type === rangeOption) {
      return (startDate !== null && endDate !== null)
    }
    return startDate !== null
  }

  dateFromString(value) {
    let date = null
    if (!value) return date
    date = moment(value);
    if (!date.isValid()) return null;
    return date
  }

  setModel(model) {
    const { filterChangedCallback } = this.props
    if (!model) {
      this.setState({ dateFrom: null, dateTo: null, isFilterActive: false }, filterChangedCallback);
      return;
    }
    const { dateFrom, dateTo, type } = model
    // ag-grid will call us here when it needs this component to update the date that it holds.
    if (!dateFrom || (!dateTo && type === rangeOption))  {
      this.setState({ dateFrom: null, dateTo: null, isFilterActive: false }, filterChangedCallback);
      return;
    }
    const startDate = this.dateFromString(dateFrom);
    const endDate = this.dateFromString(dateTo);

    this.setState({
      dateFrom: startDate,
      dateTo: endDate,
      type,
      isFilterActive: this.getActiveFlag(startDate, endDate, type),
    },
    filterChangedCallback,
    );
  }

  onFloatingFilterChanged(change= {}) {
    const { model } = change;
    const { dateFrom, dateTo, type } = model;

    if (
      (dateFrom && dateTo) ||
      type !== rangeOption ||
      (!dateFrom && !dateTo)
    ) {
      this.setModel(model);
      return;
    }

    this.setState({
      dateFrom: this.dateFromString(dateFrom),
      dateTo: this.dateFromString(dateTo)
    })
  }

  getModel() {
    // ag-grid will call us here when in need to check what the current date value is hold by this
    // component.
    const { dateFrom, dateTo, type } = this.state;
    if (!dateFrom || (type === rangeOption && !dateTo)) return null;
    const newModel = {
      filterType: 'date',
      type,
      dateFrom: dateFrom && dateFrom.format(dateFormat) || null,
      dateTo: dateTo && dateTo.format(dateFormat) || null
    }
    return newModel
  }

  isFilterActive() {
    return this.state.isFilterActive
  }

  render() {
    const { dateFrom, dateTo, type, error } = this.state;
    const { filterOptions } = this.props;
    const startDate = dateFrom ? dateFrom.toDate() : null;
    const endDate = dateTo ? dateTo.toDate(): null;
    return <DateFilterPickers
      type={type}
      error={error}
      startDate={startDate}
      endDate={endDate}
      filterOptions={filterOptions}
      onTypeChange={this.onTypeChange}
      onDateFromChange={this.onDateFromChange}
      onDateToChange={this.onDateToChange}
      column={this.colId}
    />
  }

  isPopup() {
    return true;
  }

  updateGrid(date, type) {
    const { error } = this.state
    if (date) {
      if (!date.isValid) {
        !error && this.setState({ error: true })
        return
      }
      const year = date.year();
      if (year < minYear || year > maxYear) {
        !error && this.setState({ error: true })
        return
      }
      error && this.setState({ error: false })
      this.props.filterChangedCallback()
    } else {
      error && this.setState({ error: false })
      this.props.filterChangedCallback()
    }
  }

  onDateFromChange = (setModel) => (value) => {
    const date = value && moment(value) || null;
    const { type, dateTo } = this.state;
    this.setState(
      { dateFrom: date, isFilterActive: this.getActiveFlag(date, dateTo, type) },
      () => {
        if (type === rangeOption && !dateTo) {
          setModel({[this.colId]: { dateTo, dateFrom: date, type}})
          return;
        }
        this.updateAndNotifyAgGrid(date, type);
      }
    );
  };

  onDateToChange = (setModel) => (value) => {
    const date = value && moment(value) || null;
    const { type, dateFrom } = this.state;
    this.setState(
      { dateTo: date, isFilterActive: this.getActiveFlag(dateFrom, date, type )},
      () => {
        if (!dateFrom) {
          setModel({[this.colId]: { dateFrom, dateTo: date, type }})
          return
        }
        this.updateAndNotifyAgGrid(date, type);
      }
    );
  };

  onTypeChange = (setModel) => (e) => {
    const type = e.target.value
    const { dateFrom, dateTo } = this.state
    this.setState({ type, isFilterActive: this.getActiveFlag(dateFrom, dateTo, type )}, () => {
      if (dateFrom && (dateTo || type !== rangeOption)) {
        this.updateAndNotifyAgGrid(dateFrom, type);
      } else {
        setModel({ [this.colId]: { dateFrom, dateTo, type } });
      }
    },
    );
  };
}
