import React, { ReactNode, createContext, useContext } from 'react';
import { IconProps } from './types/IconProps';
import { ThemeContext } from '../styles/ThemeContext';
import { fetchIcon } from './Icons';
import { isEmpty } from '../utils';

export const IconContext = createContext({
  getIcon: (iconName: string, providedTheme?: string) : any => {},
});

const IconProvider: React.FC<IconProps> = (props) => {
  const { isDarkTheme } = useContext(ThemeContext);

  const getIcon = (iconName: string, providedTheme?: string): ReactNode => {
    let mySelectedTheme = isDarkTheme;
    
    if (!isEmpty(providedTheme)) {
      if (providedTheme === 'light') mySelectedTheme = false;
      if (providedTheme === 'dark') mySelectedTheme = true;
    }
    return fetchIcon(iconName, mySelectedTheme);
  };

  return (
    <IconContext.Provider value={{ getIcon }}>
      {props.children}
    </IconContext.Provider>
  );
};

export default IconProvider;
