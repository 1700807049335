// @flow
import React from 'react';
import moment from 'moment';
import { DEFAULT_TIMEZONE_OBJECT } from '../../pages/App/constants';
import styled from 'styled-components';

type Props = {
  format?: string,
  datetime: string,
  tz?: string | number,
  tzSpecified?: boolean,
  timezone?: Object,
  textSize?: string,
};

const SpanText = styled.span`
  font-size: ${(props) => (props.textSize ? props.textSize : '16px')};
`;

function formatOffset(offset) {
  if (!offset) {
    return '+00:00';
  }
  let s = offset < 0 ? '-' : '+';
  const hours = Math.floor(offset / 60);
  const minutes = offset % 60;

  s += `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  return s;
}
// export const DATE_TIME_FORMAT = 'L LT';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const TimeLabel = ({
  datetime,
  tz,
  tzSpecified,
  timezone,
  format,
  textSize,
}: Props) => {
  if (!datetime) {
    return null;
  }

  if (!timezone) {
    timezone = DEFAULT_TIMEZONE_OBJECT; // eslint-disable-line
  }

  const m = moment(datetime.replace(/"/g, ''));

  if (!format) {
    format = DATE_TIME_FORMAT; // eslint-disable-line
  }

  const originalOffset = tz || '0000';
  tz = tz || 'UTC'; // eslint-disable-line

  if (!tzSpecified) {
    return (
      <SpanText title={`${m.utcOffset(0).format(format)} ${tz}`} textSize={textSize}>
        {m.utcOffset(0).format(format)}
      </SpanText>
    );
  }

  const utcTime = m.utcOffset(originalOffset);
  const ms = utcTime.valueOf();
  let tzInfo = utcTime.format(format);

  let timezoneOffset = timezone.offset;
  const { intervals } = timezone;
  // check intervals?
  if (intervals && intervals.length) {
    for (let i = 0; i < intervals.length; i += 1) {
      if (intervals[i].startMillis <= ms && ms <= intervals[i].endMillis) {
        timezoneOffset = intervals[i].offset;
        break;
      }
    }
  }

  if (!timezoneOffset) {
    return <SpanText title={`${tzInfo} ${tz}`} textSize={textSize}>{m.format(format)}</SpanText>;
  }

  try {
    tzInfo = m.utcOffset(timezoneOffset).format(format);
    return (
      <SpanText title={`${tzInfo} ${formatOffset(timezoneOffset)}`} textSize={textSize}>
        {tzInfo}
      </SpanText>
    );
  } catch (e) {
    console.error(e);
    // TODO?
  }
  return <SpanText title={`${tzInfo} ${tz}`} textSize={textSize}>error</SpanText>;
};

export default TimeLabel;
