/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import { ThemeContext } from '../../../styles/ThemeContext';
import { useTranslation } from 'react-i18next';
import {
  DhlDropdown,
  DhlImage,
  DhlInputField,
  DhlSwitch,
} from '@dhl-official/ui-libraries/react-library';
import { IconContext } from '../../../assets/IconContext';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { device } from '../../../styles/Device';
import { CountryContext } from '../../../contexts/CountryContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import DhlButton from '../../../components/DhlButton';
import { Scrollbar } from 'smooth-scrollbar-react';
import { UserContext } from '../../../contexts/UserContext';
import ServiceRequest from '../../../service/ServiceRequest';
import {
  NOTIFICATION_TYPES,
  NotificationContext,
} from '../../../contexts/NotificationContext';
import { isValidPhoneNumber } from '../../../utils';
import { LoadingContext } from '../../../contexts/LoadingContext';
import { filterInner } from '../../common';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../App/constants';

const BoxWrapper = styled.div`
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  display: block;
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  overflow: hidden;
`;

const BoxContent = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing.md};
  box-shadow: 1px 1px 2px 1px #e6e6e6;
  background-color: ${(props) => props.theme.secondaryBg};
  margin-left: 10%;
  margin-top: 2%;
  margin-bottom: 5%;
  overflow: auto;
`;

const ComponentWrapper = styled.div`
  border: 1px solid #78bd22;
  background-color: rgba(120, 189, 34, 0.2);
  width: 95%;
  height: 100px;
  margin: ${(props) => props.theme.spacing.md};
  padding: ${(props) => props.theme.spacing.sm};
  display: flex;
  flex-direction: row;
`;

const TitleWrapper = styled.div`
  width: 95%;
  margin-left: ${(props) => props.theme.spacing.md};
  margin-right: ${(props) => props.theme.spacing.md};
  padding: ${(props) => props.theme.spacing.xs};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const ButtonWrapper = styled.div`
  width: 95%;
  padding: ${(props) => props.theme.spacing.xs};
  margin-top: ${(props) => props.theme.spacing.xs};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

const FormWrapper = styled.div`
  width: 95%;
  min-width: 200px;
  margin-left: ${(props) => props.theme.spacing.md};
  margin-right: ${(props) => props.theme.spacing.md};
  padding: ${(props) => props.theme.spacing.sm};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: ${(props) => props.theme.spacing.sm};
`;

const TitleText = styled(DhlText)`
  margin-top: 10px;
`;

const SubTitleText = styled(DhlText)`
  margin-top: 5px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 20%;
  margin-top: -20px;
`;

const PrivacyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: black;
  font-size: 12px;
  margin-top: 6px;
`;

const PrivacyText = styled.div`
  display: flex;
  flex-direction: row;
  color: red;
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
`;

const PrimaryImage = styled(DhlImage)`
  height: 120px;
  width: 130px;
`;

const FormField = styled(DhlInputField)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const FormDropDown = styled(DhlDropdown)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const CheckboxWrapper = styled.div`
  @media ${device.mobile} {
    width: 95%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

interface IFormInputs {
  phoneNumber: string;
  country: any;
  companyName: string;
  jobRole: string;
  jobFunction: string;
  language: any;
  isSubscribed : boolean;
}

function ProfilePopup(): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { getIcon } = useContext(IconContext);
  const { setLoading } = useContext(LoadingContext);
  const { countries } = useContext(CountryContext);
  const { languages } = useContext(LanguageContext);
  const { setMessage, setType } = useContext(NotificationContext);
  const { user, setUser } = useContext(UserContext);
  const [jobRoles, setJobRoles] = useState([]);
  const [jobFunctions, setJobFunction] = useState([]);
  const [countryFilter, setCountryFilter] = useState('');
  const [languageFilter, setLanguageFilter] = useState('');
  const [jobFunctionFilter, setJobFunctionFilter] = useState('');
  const [jobRoleFilter, setJobRoleFilter] = useState('');
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      language: '',
      country: '',
      phoneNumber: '',
      companyName: '',
      jobRole: '',
      jobFunction: '',
      isSubscribed: false,
    },
  });

  useEffect(() => {
    if (!user.profileUpdated) {
      // GET USER PROFILE
      ServiceRequest.getProfile()
        .then((response) => {
          setValue('phoneNumber', response.data.phone);
          setValue('language', response.data.languageId);
          setValue('jobFunction', response.data.jobFunctionId);
          setValue('jobRole', response.data.jobRoleId);
          setValue('companyName', response.data.company);
          setValue('country', response.data.countryId);
          setValue('isSubscribed', response.data.isSubscribed);
        })
        .catch((error) => {
          setMessage(error);
          setType(NOTIFICATION_TYPES.FAILED);
        });

      // GET JOB ROLES
      ServiceRequest.getJobRole()
        .then((response) => {
          setJobRoles(response.data);
        })
        .catch((error) => {
          setMessage(error);
          setType(NOTIFICATION_TYPES.FAILED);
        });

      // GET JOB FUNCTIONS
      ServiceRequest.getJobFunction()
        .then((response) => {
          setJobFunction(response.data);
        })
        .catch((error) => {
          setMessage(error);
          setType(NOTIFICATION_TYPES.FAILED);
        });
    }
  }, [user]);

  const onSubmitData: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    const objectRequest = {
      languageId: data.language,
      countryId: data.country,
      phone: data.phoneNumber,
      company: data.companyName,
      jobRoleId: data.jobRole,
      jobFunctionId: data.jobFunction,
      isSubscribed: data.isSubscribed,
    };

    setLoading(true);

    ServiceRequest.updateProfile(objectRequest)
      .then((response) => {
        if (response.status === 200) {
          setMessage(t('App.page.profile.successUpdate'));
          setType(NOTIFICATION_TYPES.SUCCESS);
          setUser({ ...user, profileUpdated: true }); //  set
          //  TODO
        } else {
          setMessage(`ERROR :- ${response.status}`);
          setType(NOTIFICATION_TYPES.FAILED);
        }
      })
      .catch((error) => {
          if(error?.response?.data?.message !== undefined){
              setMessage(error.response.data.message);
          } else {
              setMessage(error.message);
          }
          setType(NOTIFICATION_TYPES.FAILED);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const myJobFunctionData = useMemo(
    () => filterInner(jobFunctionFilter, jobFunctions, 'id'),
    [jobFunctions, jobFunctionFilter]
  );
  const myJobRoleData = useMemo(
    () => filterInner(jobRoleFilter, jobRoles, 'id'),
    [jobRoles, jobRoleFilter]
  );
  const myCountryData = useMemo(
    () => filterInner(countryFilter, countries, 'id'),
    [countries, countryFilter]
  );
  const myLanguageData = useMemo(
    () => filterInner(languageFilter, languages, 'languageId'),
    [languages, languageFilter]
  );

  if (user.profileUpdated) {
    return <></>;
  }

  return (
    <BoxWrapper theme={theme}>
      <Scrollbar>
        <BoxContent theme={theme}>
          <ComponentWrapper theme={theme}>
            <InnerWrapper theme={theme}>
              <TitleText
                size="sm"
                weight={600}
                title={t('App.page.profile.almostDone')}
              />
              <SubTitleText
                size="xs"
                weight={400}
                title={t('App.page.profile.popupSubTitle')}
              />
            </InnerWrapper>
            <ImageWrapper>
              <PrimaryImage src={getIcon('rocket')} />
            </ImageWrapper>
          </ComponentWrapper>

          <TitleWrapper theme={theme}>
            <DhlText
              size="sm"
              weight={600}
              title={t('App.page.profile.profileDetails')}
            />
          </TitleWrapper>

          <form onSubmit={handleSubmit(onSubmitData)}>
            <FormWrapper theme={theme}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{ validate: (value) => isValidPhoneNumber(value) }}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormField
                    {...propsField}
                    dataId="phoneNumber-inputfield"
                    validation={
                      errors.phoneNumber != null && {
                        type: 'invalid',
                        message: 'Invalid Phone Number.',
                      }
                    }
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    variant={{
                      label: t('App.page.ExternalUser.phoneNumber'),
                      type: 'animated',
                    }}
                  />
                )}
              />

              <Controller
                name="companyName"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormField
                    {...propsField}
                    dataId="companyName-inputfield"
                    type="text"
                    validation={
                      errors.companyName != null && {
                        type: 'invalid',
                      }
                    }
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    variant={{
                      label: t('App.page.ExternalUser.companyName'),
                      type: 'animated',
                    }}
                  />
                )}
              />

              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="country-inputfield"
                    data={myCountryData}
                    validation={
                      errors.country != null && {
                        type: 'invalid',
                      }
                    }
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    filterChangeEvent={(filter: string) =>
                      setCountryFilter(filter)
                    }
                    label={t('App.page.ExternalUser.country')}
                    showFilter
                    filterPlaceholder={t('App.page.ExternalUser.searchCountry')}
                  />
                )}
              />

              <Controller
                name="jobRole"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="jobRole-inputfield"
                    data={myJobRoleData}
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    filterChangeEvent={(filter: string) =>
                      setJobRoleFilter(filter)
                    }
                    label={t('App.page.ExternalUser.jobRole')}
                    showFilter
                    filterPlaceholder={t('App.common.search')}
                  />
                )}
              />

              <Controller
                name="jobFunction"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="jobFunction-inputfield"
                    data={myJobFunctionData}
                    filterChangeEvent={(filter: string) =>
                      setJobFunctionFilter(filter)
                    }
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    label={t('App.page.ExternalUser.jobFunction')}
                    showFilter
                    filterPlaceholder={t('App.common.search')}
                  />
                )}
              />

              <CheckboxWrapper>
                <DhlText
                  size="sm"
                  weight={600}
                  title={t('App.page.profile.optIn')}
                />

                <Controller
                  name="isSubscribed"
                  control={control}
                  render={({ field: { onChange, value, ...propsField } }) => (
                    <DhlSwitch
                      {...propsField}
                      dataId="emailSwitch-inputfield"
                      isChecked={value}
                      changeEvent={onChange}
                      validation={{
                        type: 'valid',
                      }}
                    />
                  )}
                />
              </CheckboxWrapper>
            </FormWrapper>

            <TitleWrapper theme={theme}>
              <DhlText
                size="sm"
                weight={600}
                title={t('App.page.profile.preference')}
              />
            </TitleWrapper>

            <FormWrapper theme={theme}>
              <Controller
                name="language"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="language-inputfield"
                    data={myLanguageData}
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    filterChangeEvent={(filter: string) =>
                      setLanguageFilter(filter)
                    }
                    validation={
                      errors.language != null && {
                        type: 'invalid',
                      }
                    }
                    label={t('App.page.ExternalUser.language')}
                    showFilter
                    filterPlaceholder={t(
                      'App.page.ExternalUser.searchLanguage'
                    )}
                  />
                )}
              />
            </FormWrapper>

            <TitleWrapper theme={theme}>
              <DhlText size="sm" title={t('App.page.profile.concern')} />
              <PrivacyWrapper>
                {t('App.profilePopup.privacyPolicy')}{' '}
                <PrivacyText onClick={() => navigate(PATHS.PRIVACY_NOTICE)}>
                  {t('App.page.privacyNotice.title')}
                </PrivacyText>
              </PrivacyWrapper>
            </TitleWrapper>

            <ButtonWrapper theme={theme}>
              <DhlButton title={t('App.page.profile.continue')} type="submit" />
            </ButtonWrapper>
          </form>
        </BoxContent>
      </Scrollbar>
    </BoxWrapper>
  );
}

export default ProfilePopup;
