/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
// @flow
import React, { useContext } from 'react';
import { TypeMenu } from './TypeMenu';
import MenuItem from './MenuItem';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import ClickAwayListener from 'react-click-away-listener';
import { UserContext } from '../../contexts/UserContext';
import DhlText from '../DhlText';
import { useTranslation } from 'react-i18next';
import Item from './Item';
import { logout, validateDHLEmailDomain } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { PATHS, ROLES } from '../../pages/App/constants';
import { NOTIFICATION_TYPES, NotificationContext } from '../../contexts/NotificationContext';

const ContextWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 100px;
  z-index: 4;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const PopupWrapper = styled.div`
  width: 100%;
  min-width: 200px;
  min-height: 100px;
  max-height: calc(100vh - 100px);
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.primaryText};
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  top: 10%;
  left: 40%;
  margin-left: -80px;
  overflow-y: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const PopupDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 8px;
  background-color: ${(props) => props.theme.divider};
`;

const HeaderText = styled(DhlText)`
  width: 100%;
  margin-top: 8px;
  cursor: null;
`;

export const iconTypeEnum = {
  BASE64: 'base64',
  NONE: 'none',
};

const ProfileContext: React.FC<TypeMenu> = (props) => {
  const { isMenuOpen, toggleMenu, isBrandingObjectShowing, brandingTextColor, setImpersonatorModal } = props;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const {setType, setMessage} = useContext(NotificationContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isMenuOpen) return toggleMenu(false);
      }}
    >
      <ContextWrapper theme={theme}>
        {
          <MenuItem
            key={`profile-item`}
            label={`${user.firstName}`}
            type="label"
            role={user.roles?.length > 0 ? user.roles[0] : ""}
            icon="fa-circle-user"
            onSelect={() => toggleMenu(true)}
            isProfile={true}
            isBrandingObjectShowing={isBrandingObjectShowing}
            brandingTextColor={brandingTextColor}
          />
        }

        {isMenuOpen && (
          <PopupWrapper theme={theme}>
            <MenuItem
              key={`profile-item`}
              label={`${user.firstName}`}
              onSelect={() => toggleMenu(false)}
              role={user.roles?.length > 0 ? user.roles[0] : ""}
              icon="fa-circle-user"
              isProfile={true}
            />

            <HeaderText
              size={'xs'}
              weight={600}
              title={t('App.page.Home.myOptions')}
              color="#b2b2b2"
            />

            <PopupDivider theme={theme} />

            <Item
              label={t('App.page.Home.profile')}
              onSelect={() => {
                if(user?.impersonated !== undefined && user?.impersonated){
                  setMessage(t('App.page.Impersonator.impersonation.notAllowedInImpersonationMode'));
                  setType(NOTIFICATION_TYPES.FAILED);
                } else {
                  toggleMenu(false);
                  navigate(PATHS.PROFILE);
                }
              }}
              icon="fa-user"
            />

            {user.adminMenuLinks != null && user.adminMenuLinks.length > 0 && (
              <>
                <HeaderText
                  size={'xs'}
                  weight={600}
                  title={t('App.page.Home.adminOptions')}
                  color="#b2b2b2"
                />

                <PopupDivider theme={theme} />
              </>
            )}

            {user.adminMenuLinks && user.adminMenuLinks.map((menuItem) => (
              <Item
                key={menuItem.name}
                label={menuItem.name}
                onSelect={() => {
                  if (menuItem.name === 'Create External User') {
                    toggleMenu(false);
                    navigate(PATHS.EXTERNAL_USER);
                  } else if (user?.impersonated && (menuItem.name === 'Users' || menuItem.name === 'Groups' || menuItem.name === 'Templates' || menuItem.name === 'Business Functions')) {
                    toggleMenu();
                    // raf apps are not allowed in impersonation mode
                    setType(NOTIFICATION_TYPES.FAILED);
                    setMessage(t('App.page.Impersonator.impersonation.notAllowedInImpersonationMode'))
                  } else if (menuItem.internal) {
                    toggleMenu(false);
                    navigate(menuItem.url);
                  } else {
                    toggleMenu(false);
                    window.open(
                      `/${process.env.REACT_APP_MSC_PATH}${menuItem.url}`,
                      '_self'
                    );
                  }
                }}
                icon={menuItem.icon}
              />
            ))}

            { ((user?.impersonator && validateDHLEmailDomain(user?.email)) ?? false) &&
              (
                <Item
                  label={t('App.page.Home.impersonator')}
                  key={'App.page.Home.impersonator.menu'}
                  // url={undefined}
                  icon={'fas fa-people-arrows'}
                  onSelect={() => {
                    toggleMenu(false);
                    if(setImpersonatorModal !== undefined){
                      setImpersonatorModal(true)
                    }
                  }}
                />
              )
            }

            {user.roles && user.roles.includes(ROLES.MSCADMIN) &&
              <Item
                key={'users-dscum'}
                label={"Users (DSCUM)"}
                onSelect={() => {
                  toggleMenu(false);
                  navigate(PATHS.USERS);
                }}
                icon="fa-user"
              />
            }

            <PopupDivider theme={theme} />

            <Item
              label={t('App.page.Home.logout')}
              onSelect={() => {
                toggleMenu(false);
                logout();
              }}
              icon="right-from-bracket"
            />
          </PopupWrapper>
        )}
      </ContextWrapper>
    </ClickAwayListener>
  );
};

export default ProfileContext;
