/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';
import { PATHS } from '../App/constants';
import { ThemeContext } from '../../styles/ThemeContext';
import TopContent from './components/TopContent';
import DhlText from '../../components/DhlText';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.secondaryBg};
`;

const PageBlock = styled.div`
  width: 95%;
  margin-left: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  padding: 12px;
  background-color: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.color.grey[100]};
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: 10px;
`;

const CookieTable = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  margin-top: 20px;
  margin-left: 12px;
`;

const CookieTableCell = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 4px;
`;

const CookieTableBody = styled.tbody`
  height: 50px;
  overflow: auto;
`;

const HeaderText = styled(DhlText)`
  margin-top: 20px;
`;

const NormalText = styled(DhlText)`
  margin-top: 10px;
  margin-left: 12px;
`;

const NormalText2 = styled(DhlText)`
  margin-top: 10px;
  margin-left: 24px;
`;

const ExtraSpace = styled.div`
  margin-top: 16px;
`;

const myBreadCrumbList = [
  { label: 'App.breadcrumb.home', path: PATHS.HOME },
  { label: 'App.breadcrumb.privacyNotice', path: '' },
];

const tableData = [
  {
    name: 'everest_g_v2',
    lifeSpan: 'PERSISTENT',
    hostName: 'everesttech.net',
    category: 'Analytics Technologies',
    description:
      'This domain is owned by Adobe. The main business activity is: Advertising',
  },
  {
    name: 'ELQSTATUS',
    lifeSpan: 'PERSISTENT',
    hostName: 'eloqua.com',
    category: 'Analytics Technologies',
    description:
      'This domain is owned by Oracle for the delivery of online marketing services that were previously branded under the Eloqua name.',
  },
  {
    name: 'everest_session_v2',
    lifeSpan: 'SESSION',
    hostName: 'everesttech.net',
    category: 'Analytics Technologies',
    description:
      'This domain is owned by Adobe. The main business activity is: Advertising',
  },
  {
    name: 'AMCV_',
    lifeSpan: 'PERSISTENT',
    hostName: 'dhl.com',
    category: 'Analytics Technologies',
    description:
      'This is a pattern type cookie name associated with Adobe Marketing Cloud. It stores a unique visitor identifier, and uses an organization identifier to allow a company to track users across their domains and services.',
  },
  {
    name: 'AMCV_9D88879D5579828F7F000101%40AdobeOrg',
    lifeSpan: 'PERSISTENT',
    hostName: 'dhl.com',
    category: 'Analytics Technologies',
    description:
      'This is a pattern type cookie name associated with Adobe Marketing Cloud. It stores a unique visitor identifier, and uses an organization identifier to allow a company to track users across their domains and services.',
  },
  {
    name: 'ELOQUA',
    lifeSpan: 'PERSISTENT',
    hostName: 'eloqua.com',
    category: 'Analytics Technologies',
    description:
      'Eloqua Business Marketing platform - this cookie collects and transfers contact info from webforms to internal databases.',
  },
  {
    name: 'Demdex',
    lifeSpan: 'PERSISTENT',
    hostName: 'demdex.net',
    category: 'Analytics Technologies',
    description:
      'This cookie helps Adobe Audience Manger perform basic functions such as visitor identification, ID synchronization, segmentation, modeling, reporting, etc.',
  },
  {
    name: 'AMCVS_',
    lifeSpan: 'SESSION',
    hostName: 'dhl.com',
    category: 'Analytics Technologies',
    description:
      'This is a pattern type cookie name associated with Adobe Marketing Cloud. It stores a unique visitor identifier, and uses an organization identifier.',
  },
  {
    name: 's_ccst',
    lifeSpan: 'PERSISTENT',
    hostName: 'mysupplychain.dhl.com',
    category: 'Analytics Technologies',
    description:
      'This cookie is set and read by the JavaScript code to determine if cookies are enabled (set to “True”)',
  },
  {
    name: 'dpm',
    lifeSpan: 'PERSISTENT',
    hostName: 'dpm.demdex.net',
    category: 'Analytics Technologies',
    description:
      'Stores the fact that a call from Adobe or the ID service is passing in visitor data for synchronization or requesting an ID.',
  },
  {
    name: 's_xxxx',
    lifeSpan: 'SESSION',
    hostName: 'dhl.com',
    category: 'Performance Technologies',
    description:
      'This domain is owned by Adobe/Omniture. The main business activity is: Analytics',
  },
  {
    name: 's_sq',
    lifeSpan: 'SESSION',
    hostName: 'mysupplychain.dhl.com',
    category: 'Performance Technologies',
    description:
      'Adobe Site Catalyst cookie, stores information about the previous link clicked within the site',
  },
  {
    name: 'test',
    lifeSpan: 'SESSION',
    hostName: 'dhl.com',
    category: 'Performance Technologies',
    description: 'Adobe Analytics site tracking.',
  },
  {
    name: 'ESTSSC',
    lifeSpan: 'SESSION',
    hostName: 'mysupplychain.dhl.com',
    category: 'Strictly Necessary Technologies',
    description:
      'This cookie belongs to Microsoft and is deployed by the Azure Active Directory.',
  },
  {
    name: 'rcookie',
    lifeSpan: 'PERSISTENT',
    hostName: 'mysupplychain.dhl.com',
    category: 'Strictly Necessary Technologies',
    description: 'Is used to proper request routing.',
  },
];

function PrivacyPage(): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef?.current != null) {
      window.scrollTo({
        top: topRef.current,
        behavior: 'smooth',
      });
    }
  }, [topRef]);

  return (
    <PageWrapper theme={theme} ref={topRef}>
      <TopContent />

      <TopWrapper theme={theme}>
        <DhlBreadCrumbs list={myBreadCrumbList} />
      </TopWrapper>

      <PageBlock theme={theme}>
        <HeaderText size="xs" weight={600} title="1.    Introduction" />
        <NormalText
          size="xs"
          title="DHL Supply Chain Management GmbH (hereinafter called DHL Supply Chain), appreciates your visit to our website and your interest in our company, products and services. The protection of your personal data is an important concern for us. Below we explain what information DHL Supply Chain collects when you visit our website and how this information is used."
        />
        <NormalText
          size="xs"
          title="This privacy notice applies to all users of the MySupplyChain portal, whose personal data, meaning any information relating to an identified or identifiable natural person ('data subject') is being processed by DHL Supply Chain :"
        />

        <ExtraSpace />

        <NormalText2
          size="xs"
          title="-  Shipper: Shippers, including their employees, or individuals sending a shipment"
        />
        <NormalText2
          size="xs"
          title="-  Consignee: Any person who receives a Consignment"
        />
        <NormalText2
          size="xs"
          title="-  People who show an interest in us and our services"
        />
        <NormalText2
          size="xs"
          title="- Business partners: business partners including their employees"
        />

        <ExtraSpace />

        <NormalText
          size="xs"
          title="in respect to the below products and services:"
        />

        <ExtraSpace />

        <NormalText2
          size="xs"
          title="- Track & Trace: Customer, Warehouse and Transport order visibility solution"
        />
        <NormalText2
          size="xs"
          title="- Inventory: Visibility of your warehouse inventory and product stock level. Daily or near real time."
        />
        <NormalText2
          size="xs"
          title="- Operational Performance: KPI and exception operational visibility."
        />
        <NormalText2
          size="xs"
          title="- Business Analytics: Reporting, data analysis and insights."
        />
        <NormalText2
          size="xs"
          title="- Customer Services: Customer Queries and Reporting."
        />
        <NormalText2
          size="xs"
          title="- Documents: Document management and storage solution."
        />
        <NormalText2
          size="xs"
          title="- Claims Management: Transport claims, create new and view existing."
        />
        <NormalText2
          size="xs"
          title="- Transport Management: Monitor your order entry, release and rate lookup. Delivery status."
        />
        <NormalText2
          size="xs"
          title="- Risk Management: Supply chain risk management, with dynamic view of sourcing, procurement and logistical risks."
        />

        <ExtraSpace />

        <NormalText
          size="xs"
          title="When you are using our website, you acknowledge and agree to the collection and processing of your personal data by DHL Supply Chain as explained in this notice."
        />

        <HeaderText
          size="xs"
          weight={600}
          title="2.   Who is responsible (identity and contact details of the controller and the data protection officer / contact)?"
        />

        <NormalText
          size="xs"
          title="This Privacy Notice applies for the data processing carried out by:"
        />

        <NormalText2 size="xs" title="DHL Supply Chain Management GmbH" />
        <NormalText2 size="xs" title="Charles-de-Gaulle-Str.20" />
        <NormalText2 size="xs" title="53113 Bonn Germany" />

        <ExtraSpace />

        <NormalText
          size="xs"
          title="If you have queries with regard to the processing of your personal data, please contact the Data Protection Contact Person:"
        />

        <NormalText2 size="xs" title="Global Head of Data Protection" />
        <NormalText2 size="xs" title="DHL Supply Chain Management GmbH" />
        <NormalText2 size="xs" title="Charles de Gaulle Str. 20" />
        <NormalText2 size="xs" title="53113 Bonn Germany" />
        <NormalText2 size="xs" title="Dsc.dataprotection@dhl.com" />

        <HeaderText
          size="xs"
          weight={600}
          title="3. What are the purposes of and the legal basis for the processing of personal data?"
        />

        <ExtraSpace />

        <NormalText
          size="xs"
          weight={600}
          title="3.1. Customer account creation"
        />
        <NormalText
          size="xs"
          title="The following mandatory information is required when creating user accounts on mysupplychain.dhl.com based on the performance of your contract (Art. 6 (1) b) GDPR): username, first and last name, email address, country and language preference, salutation, phone number, company name, job role and job function are optional and may be stored in the personal account record, if provided."
        />

        <ExtraSpace />

        <NormalText size="xs" weight={600} title="3.2. Shipping information" />
        <NormalText
          size="xs"
          title="For contractual reasons, we also need personal data to provide our services and comply with the obligations arising from contractual agreements concluded with you. This data is used e. g. for performing a (shipment) contract, managing customer data, handling payments and, as the case may be, assessing creditworthiness. Certain shipment data will also be provided to the authorities of the country of transit or destination for customs and tax clearance or for security screening, as required by the laws of such country. The information provided would usually include: shipper name and address, receiver name and address, description of the goods, number of pieces, weight and value of shipment. The legal basis is usually Art. 6 (1) b) and c) GDPR since the processing is required to comply with legal obligations in connection with the performance of a contract to which you are a party."
        />

        <ExtraSpace />

        <NormalText
          size="xs"
          weight={600}
          title="3.3. Processing for advertising purposes"
        />
        <NormalText
          size="xs"
          title="When you visit our web page we offer you a broad variety of information about DHL Supply chain, about our products and services and events. In particular you can get information about our:"
        />

        <NormalText2 size="xs" title="1. Events" />
        <NormalText2 size="xs" title="2. Newsletters" />

        <ExtraSpace />

        <NormalText
          size="xs"
          title="If you are an existing customer of DHL Supply Chain your contact data (e.g. name, email address) will be processed to contact you in order to provide you with the latest information about our offers, news, related only to the products and services we are offering to you. Legal basis for the aforementioned processing is carried out pursuant to our legitimate interest (Art. 6 (1) f) GDPR)."
        />

        <NormalText
          size="xs"
          title="You have right to object at any time to the said processing. To exercise your right, simply get in touch with us by using the contact details mentioned above under section 2."
        />

        <NormalText
          size="xs"
          title="If you register for one of our newsletters, we are entitled to use your e-mail address for this purpose. You may unsubscribe from the newsletter at any time by clicking the relevant link at the bottom of the newsletter. In case you file an objection or unsubscribe from our newsletter services the respective data will be removed from the mailing list or blocked, respectively deleted and no longer be processed for such purposes."
        />

        <NormalText
          size="xs"
          title="Otherwise, if we wish to contact you for our new products and services we may do so only if you have prior consented to said communication."
        />

        <HeaderText
          size="xs"
          weight={600}
          title="4. Processing for internal Analytics purposes"
        />

        <ExtraSpace />

        <NormalText
          size="xs"
          title="DHL Supply Chain may (i) compile statistical and other information related to the performance, operation and use of the products and services, and (ii) use data from MySupplyChain Track & Trace environment, to create statistical analyses, and for research and development purposes to pursue its legitimate interests based on the Article 6 par. 1 f GDPR. Any commercial information obtained on the course of the provision of the services is considered confidential information of DHL Supply Chain and its customers and is intended for internal use only. Third parties in the meaning of this section do not include DHL’s Group companies or external agents, in particular consultants, used by the DHL Supply Chain in connection with the relevant contractual service. Dissemination to third parties or any other form of disclosure shall require the written approval of DHL Supply Chain’s customers."
        />

        <HeaderText
          size="xs"
          weight={600}
          title="5. Use of Cookies and your preferences"
        />

        <ExtraSpace />

        <NormalText
          size="xs"
          title="This website uses cookies and similar technologies, (hereafter “technologies”), which enable us, for example, to determine how frequently our internet pages are visited, the number of visitors, to configure our offers for maximum convenience and efficiency and to support our marketing efforts. These technologies incorporate data transfers to third-party providers based in countries without an adequate level of data protection (e. g. United States)."
        />

        <NormalText
          size="xs"
          title="We employ third-party technologies together with your IP addresses to monitor and analyze the use of our websites, and to automate certain processes related to the development and operation of our websites. Our website uses the following categories of cookies:"
        />

        <ExtraSpace />

        <NormalText2
          size="xs"
          title="1. Strictly Necessary: : These cookies are essential in order to enable you to move around the website and use its features. Without these cookies, services you have asked for cannot be provided. As already stated above, the use of these cookies are based on our legitimate interests (Art. 6 (1) (f) GDPR)."
        />
        <NormalText2
          size="xs"
          title="2. Performance: These cookies collect, subject to your consent (Art. 6 (1) (a) GDPR) information about how visitors use our website, for instance which pages visitors go to most often. It is only used to improve how a website works."
        />
        <NormalText2
          size="xs"
          title="3. Analytics: These cookies are used subject to your consent (Art. 6 (1) (a) GDPR) to collect information about how people access our online services, to improve the quality of our website and its content – for example, the number of users on this website, how long you stay on the site for, and what parts of the site you liked."
        />

        <NormalText size="xs" title="Cookies can either be: " />
        <NormalText2
          size="xs"
          title="- Session cookies: these are only stored on your computer during your web session and are automatically deleted when you close your browser – they usually store an anonymous session ID allowing you to browse a website without having to log in to each page but they do not collect any personal data from your computer; or"
        />
        <NormalText2
          size="xs"
          title="- Persistent cookies: a persistent cookie is stored as a file on your computer and it remains there when you close your web browser. The cookie can be read by the website that created it when you visit that website again."
        />

        <NormalText
          size="xs"
          title="Please find our detailed list of cookies used on mysupplychain.dhl.com"
        />

        <CookieTable>
          <thead>
            <tr>
              <CookieTableCell>
                <DhlText size="xs" weight={600} title={'Cookie Name'} />
              </CookieTableCell>
              <CookieTableCell>
                <DhlText size="xs" weight={600} title={'Lifespan'} />
              </CookieTableCell>
              <CookieTableCell>
                <DhlText size="xs" weight={600} title={'Hostname'} />
              </CookieTableCell>
              <CookieTableCell>
                <DhlText size="xs" weight={600} title={'Default Category'} />
              </CookieTableCell>
              <CookieTableCell>
                <DhlText size="xs" weight={600} title={'Default Description'} />
              </CookieTableCell>
            </tr>
          </thead>

          <CookieTableBody>
            {tableData.map((tData) => (
              <tr key={`table_cookie_${tData.name}`}>
                <CookieTableCell>
                  <DhlText size="xs" title={tData.name} />
                </CookieTableCell>
                <CookieTableCell>
                  <DhlText size="xs" title={tData.lifeSpan} />
                </CookieTableCell>
                <CookieTableCell>
                  <DhlText size="xs" title={tData.hostName} />
                </CookieTableCell>
                <CookieTableCell>
                  <DhlText size="xs" title={tData.category} />
                </CookieTableCell>
                <CookieTableCell>
                  <DhlText size="xs" title={tData.description} />
                </CookieTableCell>
              </tr>
            ))}
          </CookieTableBody>
        </CookieTable>

        <NormalText
          size="xs"
          title="You can always withdraw you consent by clicking on the Cookie Settings of our website."
        />

        <HeaderText
          size="xs"
          weight={600}
          title="6. Will my data be passed on to third parties?"
        />

        <NormalText
          size="xs"
          title="However, in the context of the data processing as described in this Privacy Notice and the respective legal basis given, DHL Supply Chain will transfer your data to the following categories of recipients:"
        />

        <NormalText2
          size="xs"
          title="- DHL Group companies: transfer is required within the DHL Group, its legal entities and shared service centers to provide and improve our products and services. Transfers can also occur to manage our relationship with you, to identify you, to answer your requests and to comply with your privacy rights."
        />
        <NormalText2
          size="xs"
          title="- Third party business partners: The transfer to third party business partners is limited to what is required by applicable law and / or in order to fulfill our contractual obligations."
        />
        <NormalText2
          size="xs"
          title="- Third party processors: which process data are contractually obliged to maintain strict confidentiality as per Art. 28 GDPR. DHL retains responsibility for safeguarding your personal data in such circumstances. The business partners follow the instructions of DHL, and this is guaranteed by technical and organizational measures, as well as by means of checks and controls."
        />
        <NormalText2
          size="xs"
          title="- Public authorities: transfer is required by applicable law (e.g. to fulfil a legal obligation during shipment processing)."
        />
        <NormalText
          size="xs"
          title="Your data is only transferred outside the European Economic Area (EEA) to other DHL Group companies, third party business partners or public authorities when permitted by applicable data protection law. In such cases, we will make sure that appropriate safeguards are in place to ensure the transfer of your data (e.g. our binding corporate rules, standard contractual clauses)."
        />

        <HeaderText
          size="xs"
          weight={600}
          title="7. What is the DHL Data Privacy Policy?"
        />

        <NormalText
          size="xs"
          title="The DHL Data Privacy Policy regulates the Group-wide standards for data processing with a special focus on so-called third country transfers, meaning transfers of personal data to countries outside the EU, which do not have an adequate level of data protection. If you are interested in learning more about the DHL Data Privacy Policy, please use the following link: 
          DHL Privacy Policy (Summary)"
        />

        <HeaderText
          size="xs"
          weight={600}
          title="8. What are my data subject’s rights?"
        />

        <NormalText size="xs" weight={600} title="8.1. Overview" />

        <NormalText
          size="xs"
          title="Apart from the right to obtain you have the following rights:"
        />
        <NormalText2
          size="xs"
          title="- You can request information as to what personal data is stored"
        />
        <NormalText2
          size="xs"
          title="- You can request that we correct, delete or block your personal data provided these actions are permitted by law and in compliance with existing contractual conditions."
        />
        <NormalText2
          size="xs"
          title="- You can request to receive personal data you have provided in a structured, commonly used and machine-readable format."
        />
        <NormalText2
          size="xs"
          title="- You may lodge a complaint with the supervisory authority. The competent data protection authority is:"
        />

        <ExtraSpace />

        <NormalText2
          size="xs"
          title="Die Landesbeauftragte für den Datenschutz Nordrhein-Westfalen"
        />
        <NormalText2 size="xs" title="Kavalleriestraße 2-4" />
        <NormalText2 size="xs" title="40213 Düsseldorf" />

        <ExtraSpace />

        <NormalText size="xs" weight={600} title="8.2. Right to object" />

        <NormalText
          size="xs"
          title="The right to object applies for all processing of personal data which is based on Art. 6 (1) f) GDPR. "
        />

        <NormalText
          size="xs"
          title="To exercise your right, simply get in touch with us by using the contact details mentioned above under sec. 3."
        />

        <HeaderText size="xs" weight={600} title="9. How about data security" />

        <NormalText
          size="xs"
          title="DHL understands that privacy is important to you. We will take all reasonable precautions to ensure that the Customer Information that we hold about you remains confidential. Information security techniques and controls, such as firewalls, anti-virus software and access control procedures are deployed to protect against:"
        />

        <NormalText2 size="xs" title="- Unauthorized access" />
        <NormalText2 size="xs" title="- Improper use or disclosure" />
        <NormalText2 size="xs" title="- Unauthorized modification, and" />
        <NormalText2
          size="xs"
          title="- Unlawful destruction or accidental loss"
        />

        <NormalText
          size="xs"
          title="Due to the fact that the Internet is not regarded as a completely secure system even if you implement the highest security measures, DHL cannot be responsible or liable for the security of your Data whilst in transit through the Internet."
        />

        <NormalText
          size="xs"
          title="Customer Information is handled on a Need to know basis and access is only provided to those employees who have a legitimate need for that information for the purposes of our business."
        />

        <HeaderText
          size="xs"
          weight={600}
          title="10. Changes to Privacy Notice"
        />

        <NormalText
          size="xs"
          title="We keep our Privacy Notice under regular review."
        />

        <NormalText
          size="xs"
          title="DHL Supply Chain reserves the right to change its Privacy Notice at any time with or without prior notice. Please check back frequently to be informed of any changes. By using this website you agree to this Privacy Notice."
        />

        <ExtraSpace />

        <NormalText
          size="xs"
          title="This statement was last updated on 19 May 2022"
        />
      </PageBlock>
    </PageWrapper>
  );
}

export default PrivacyPage;
