import {headerTemplate} from './headerTemplates';

const onModelUpdated = (resizeColumn) => (params) => {
    if (resizeColumn) params.columnApi.autoSizeAllColumns();
};

export const ConfigurationInfinite = {
    // Sorting
    sortable: true,

    // Filter config
    filter: false,
    floatingFilter: false,
    rowHeight: 70,
    suppressMenuHide: true,
    // Filter bu default
    defaultColDef: {
        // width: 150,
        headerClass: 'ag_grid_table__column_header',
        toolPanelClass: 'ag_grid_table__column_tool_panel',
        filter: 'agTextColumnFilter',
        floatingFilterComponent: 'textFloatingFilter',
        // always should be 'keep' for Server Side Model
        // it let's you to see the filter afther the new rows upload
        // otherwise doesn't work
        filterParams: {
            // filterOptions: ['equals', 'contains', 'startsWith', 'endsWith'],
            newRowsAction: 'keep',
            debounceMs: 1000,
            maxNumConditions: 1,
        },
        resizable: true,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        unSortIcon: true,
        headerComponentParams: {
            template: headerTemplate,
        },
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
    },

    // Pagination
    pagination: true,
    // calculating rows automatically
    paginationPageSize: 10,
    // viewportRowModelBufferSize: 0,

    // Resizing by drag and drop
    resizable: true,

    // Do something after data has been changed
    onModelUpdated,

    // ----- Server Side Modelin Properties -----
    // getting data useing server side model
    // 'clientSide' by default
    // rowModelType: 'serverSide',
    rowModelType: 'infinite',
    // rowModelType: 'clientSide',

    // fetch 10 rows per at a time
    cacheBlockSize: 10,

    // wait when scrolling will be finished
    // blockLoadDebounceMillis: 1000,

    // sideBar
    // sideBar: false,

    // selection
    rowSelection: 'multiple',
    suppressRowClickSelection: true, // if true, clicking rows doesn't select (useful for checkbox selection)

    // Styling
    /*
        rowClassRules: {
          'rag-green-outer': function(params) { return params.data.year === 2008},
        }
        getRowClass: function(params) ....
      */
    rowClass: 'ag_grid_table__row',

    // suppressColumnVirtualisation let to not destroy column that will get a checkbox
    suppressColumnVirtualisation: true,
    enableRangeSelection: false,

    // These 2 properties are for to prevent loading data of ag grid for next pages
    suppressNextRowModelLoad: true,
    paginationAutoPageSize: false,
};

export const ConfigurationStatic = {
    sortable: true,
    // Calculate table height dynamically
    domLayout: 'autoHeight',
    suppressDragLeaveHidesColumns: true,
    enableCellTextSelection: true,
    // Filter config
    filter: false,
    floatingFilter: false,
    rowHeight: 70,
    suppressMenuHide: true,
    loadingCellRendererParams: {
        loadingMessage: 'One moment please...',
    },
    // Filter bu default
    defaultColDef: {
        // width: 150,
        headerClass: 'ag_grid_table__column_header',
        toolPanelClass: 'ag_grid_table__column_tool_panel',
        filter: 'agTextColumnFilter',
        floatingFilterComponent: 'textFloatingFilter',
        // always should be 'keep' for Server Side Model
        // it let's you to see the filter afther the new rows upload
        // otherwise doesn't work
        filterParams: {
            // filterOptions: ['equals', 'contains', 'startsWith', 'endsWith'],
            newRowsAction: 'keep',
            debounceMs: 1000,
            maxNumConditions: 1,
        },
        resizable: true,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        unSortIcon: true,
        headerComponentParams: {
            template: headerTemplate,
        },
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
    },
    // Resizing by drag and drop
    resizable: true,
    // fetch 10 rows per at a time
    cacheBlockSize: 40,

    // selection
    rowSelection: 'multiple',
    suppressRowClickSelection: true, // if true, clicking rows doesn't select (useful for checkbox selection)
    rowClass: 'ag_grid_table__row',
    enableRangeSelection: false,
};
