export const minYear = 2000;
export const maxYear = 3000;
export const dateFormat = 'YYYY-MM-DD';
export const filterType = 'date';

export const defaultModel = {
  type: 'equals',
  filterType: 'date',
  dateFrom: null,
};

export const defaultTextModel = {
  type: 'contains',
  filterType: 'text',
  filter: null,
};

export const rangeOption = 'range';
