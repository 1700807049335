import React, { useContext } from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import { ThemeContext } from '../../../styles/ThemeContext';
import { DhlImage } from '@dhl-official/ui-libraries/react-library';
import { IconContext } from '../../../assets/IconContext';
// import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../../utils';
import ApplicationType from '../../../contexts/types/ApplicationType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ACTION_BUSINESS_SERVICE } from '../../App/constants';
import { logAuditEvent } from '../../../service/ServiceRequest';

const BoxWrapper = styled.div`
  width: 240px;
  height: 90px;
  margin: 16px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-shadow: 1px 1px 2px 1px #e6e6e6;
  background-color: ${(props) => props.theme.background};
  border-radius: 4px;
  transition: 0.2s;
  :hover {
    transform: scale(1.02);
    z-index: 2;
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  width: 200px;
  height: 100%;
  margin-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const ImageWrapper = styled(FontAwesomeIcon)`
  width: 30px;
  height: 30px;
  color: ${(props) => props.theme.color.red[400]};
`;

const DhlImageWrapper = styled(DhlImage)`
  width: 40px;
  height: 40px;
`;

const MyText = styled(DhlText)`
  margin-top: 10px;
`;

export interface AppItemProperties {
  app: ApplicationType;
}

function AppItem({ app }: AppItemProperties): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { getIcon } = useContext(IconContext);
  // const { t } = useTranslation();
  const {
    // appIconNameLabelId,
    // shortDescLabelId,
    name,
    shortDesc,
    childApps,
    link,
    icon,
    // integrated,
    // list
    id,
  } = app;


  const onClickBox = (): void => {
    if ((childApps === undefined || childApps === null || childApps?.length === 0) && !isEmpty(link)) {
      void logAuditEvent(ACTION_BUSINESS_SERVICE, id);
      window.open(link, '_blank', 'noopener,noreferrer');
      // if (integrated != null && integrated) {
      //   window.open(link, '_self');
      // } else {
      //   window.open(link, '_blank', 'noopener,noreferrer');
      // }
    }
  };

  return (
    <BoxWrapper theme={theme} onClick={onClickBox} data-tracking={name}>
      {isEmpty(icon) ? (
        <DhlImageWrapper src={getIcon('applicationIcon')} />
      ) : (
        <ImageWrapper theme={theme} icon={icon} size="lg" />
      )}
      <TextWrapper>
        <DhlText size="sm" weight={600} title={name} />
        <MyText size="sm" color="secondary" title={shortDesc} />
      </TextWrapper>
    </BoxWrapper>
  );
}

export default AppItem;
