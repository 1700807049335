/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, {
    ReactElement,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {ThemeContext} from '../../styles/ThemeContext';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';
import {BreadCrumbsWrapper, PageWrapper} from '../common';
import {PATHS} from '../App/constants';
import DhlText from '../../components/DhlText';
import {useTranslation} from 'react-i18next';
import Table from '../../components/Table';
import {getHeight} from '../../components/Table/utils';
import {UserContext} from '../../contexts/UserContext';
import DhlButton from '../../components/DhlButton';
import ServiceRequest from '../../service/ServiceRequest';
import {
    DhlDropdown,
    DhlIcon,
    DhlIconWrapper, DhlLoader,
    DhlSwitch,
} from '@dhl-official/ui-libraries/react-library';
import {IconContext} from '../../assets/IconContext';
import {isEmpty} from 'lodash';
import ImageCell from '../../components/Table/cell/Image';
import LinkCell from '../../components/Table/cell/RenderLink';
import SeqNoColumn from '../../components/Table/cell/SeqNoColumn';
import {device} from '../../styles/Device';
import {
    NOTIFICATION_TYPES,
    NotificationContext,
} from '../../contexts/NotificationContext';
import {LoadingContext} from '../../contexts/LoadingContext';
import {createPortal} from 'react-dom';
import DateColumn from "../../components/Table/cell/DateColumn";
import {CustomerObjectContext} from "../../contexts/CustomerObjectContext";
import DhlMultiSelect from "../../components/DhlMultiSelect";

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    flex-wrap: nowrap;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
  gap: 10px;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;

const Button = styled(DhlButton)`
  margin: 0px;
`;

const IconWrapper = styled(DhlIconWrapper)`
  padding: 4px;
  margin-left: 10px;
  cursor: pointer;
`;

const DropDownWrapper = styled.div`
width: 300px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${device.mobile} {
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: center;
  }
  @media ${device.tablet} {
    width: 50%;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
`;

const FormDropDown = styled(DhlMultiSelect)`
  @media ${device.mobile} {
    width: 100%;
    margin-bottom: 10px;
  }
  @media ${device.tablet} {
    width: 50%;
  }
  min-width: 300px;
  div.options-container.show {
    z-index: 2;
  }
`;

const myBreadCrumbList = [
    {label: 'App.breadcrumb.home', path: PATHS.HOME},
    {label: 'App.breadcrumb.news', path: ''},
];

const DEFAULT_FILTER_PARAMS = {
    buttons: ['apply', 'reset'],
    filterOptions: [
        'contains',
        'notContains',
        'startsWith',
        'endsWith',
        'equals',
        'notEqual',
    ],
};

function NewsListPage(): ReactElement {
    const {theme} = useContext(ThemeContext);
    const {refreshUser} = useContext(UserContext);
    const {getIcon} = useContext(IconContext);
    const {setType, setMessage} = useContext(NotificationContext);
    const {customerObjectList, isCoLoading} = useContext(CustomerObjectContext);
    const {setLoading, addLoading, removeLoading} = useContext(LoadingContext);
    const [selectedCo, setSelectedCo] = useState<any>(null);
    const [updatedList, setUpdatedList] = useState<any[]>([]);
    const [customerObjectData, setCustomerObject] = useState<any>([]);
    const [isEditable, setIsEditable] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dropDownRef = useRef<HTMLElement>();
    const isMobile = window.innerWidth < 600;
    const [customerObjectIconRef, setCustomerObjectIconRef] = useState<HTMLElement | undefined>();

    const onClickAdd = (): void => {
        navigate(PATHS.NEWS_ADD);
    };

    const onClickEdit = (data: any): void => {
        navigate(PATHS.NEWS_EDIT, {state: data});
    };

    const onClickEditSeqNo = (): void => {
        setIsEditable(true);
    };

    const onClickSave = (): void => {
        let isNegativeNo = false;
        const myRequestObject: any[] = [];
        updatedList.forEach((listObj: any) => {
            if (Number(listObj.sequenceNo) < 1)
                isNegativeNo = true;

            myRequestObject.push({
                newsId: listObj.id,
                sequenceNo: listObj.sequenceNo,
            });
        });

        if (isNegativeNo) {
            setType(NOTIFICATION_TYPES.FAILED);
            setMessage("Sequence No always be greater then 0.");
        } else {
            setIsEditable(false);
            setLoading(true);
            ServiceRequest.editBulkNewsSequence(myRequestObject, selectedCo?.value)
                .then((_) => {
                    setIsEditable(false);
                    triggerApi();
                })
                .catch((error) => {
                    setType(NOTIFICATION_TYPES.FAILED);
                    setMessage(error?.response?.data?.message);
                })
                .finally(() => {
                    setLoading(false);
                    setUpdatedList([]);
                });
        }
    };

    const triggerApi = (): void => {
        setTimeout(() => {
            setTrigger(true);
        }, 500);
    };

    const onStatusChange = (data: any): void => {
        const objectRequest = {
            ...data,
            active: !data.active,
        };
        if (selectedCo !== undefined && !isEmpty(selectedCo)) {
            ServiceRequest.editNewsStatus(objectRequest, selectedCo?.value)
                .then((_) => {
                    setType(NOTIFICATION_TYPES.SUCCESS);
                    setMessage(t('App.page.news.success'));
                    triggerApi();
                })
                .catch((error) => {
                    setType(NOTIFICATION_TYPES.FAILED);
                    setMessage(error?.response?.data?.message);
                });
        }
    };

    const setLocation = (): void => {
        if (!isEmpty(location) && !isEmpty(location?.search)) {
            const customerId = location.search.split("=")[1];
            if (!isEmpty(customerId) && customerId !== undefined) {
                setSelectedCo(customerId.trim());
            }
        }
    }

    useEffect(() => {
        setLocation();
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => {
            refreshUser(true);
        };
    }, []);

    useEffect(() => {
        if (customerObjectList.length > 0) {
            setCustomerObject(customerObjectList);
            setLocation();
        }
    }, [customerObjectList]);

    useEffect(() => {
        triggerApi();
        if (selectedCo !== null && !isEmpty(selectedCo)) {
            navigate(`${PATHS.NEWS_LIST}?customerId=${selectedCo?.value}`);
        } else {
            navigate(PATHS.NEWS_LIST);
        }
    }, [selectedCo]);

    useEffect(() => {
        const icon = dropDownRef.current?.querySelector('div.iconWrapper');
        const parentNode = icon?.parentNode;
        const clonedNode = icon?.cloneNode();

        if (clonedNode != null && icon != null && parentNode != null) {
            const newNode = parentNode.insertBefore(clonedNode, icon);
            if (newNode != null) {
                setCustomerObjectIconRef(newNode as HTMLElement);
                return () => {
                    setCustomerObjectIconRef(undefined);
                    parentNode.removeChild(newNode);
                };
            }
        }
        return () => {
        };
    }, [
        dropDownRef.current,
        dropDownRef.current?.querySelector('div.iconWrapper') != null,
    ]);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    const onChangeValue = (data: any): void => {
        const myUpdatedList = updatedList;
        myUpdatedList.push(data);
        setUpdatedList(myUpdatedList);
    };

    const getColumnDefs = (): any[] => !isEmpty(selectedCo) ? [
        {
            headerName: t('App.page.news.action'),
            field: '',
            sortable: false,
            floatingFilter: false,
            minWidth: 140,
            cellRenderer: (params: any) => {
                const {data} = params;
                return (
                    <ActionWrapper>
                        {!isEmpty(selectedCo) && (
                            <div title={data?.active ? 'Disable News' : 'Enable News'}>
                                <DhlSwitch
                                    dataId="publish-switch-inputField"
                                    isChecked={data?.active}
                                    name="Enable/Disable News"
                                    changeEvent={() => onStatusChange(data)}
                                />
                            </div>
                        )}

                        <IconWrapper size="md" onClick={() => onClickEdit(data)}>
                            <DhlIcon src={getIcon('edit')} size="1rem"/>
                        </IconWrapper>
                    </ActionWrapper>
                );
            },
        },
        {
            headerName: t('App.page.news.seqNo'),
            field: 'sequenceNo',
            sortable: true,
            floatingFilter: false,
            minWidth: 140,
            filterParams: DEFAULT_FILTER_PARAMS,
            cellRenderer: (params: any) => {
                const updatedParams = {...params, isEditable, onChangeValue};
                return !isEmpty(selectedCo) ? (
                    <SeqNoColumn {...updatedParams} />
                ) : (
                    <></>
                );
            },
        },
        {
            headerName: t('App.page.news.newsHeader'),
            field: 'header',
            sortable: true,
            floatingFilter: true,
            minWidth: 170,
            filterParams: DEFAULT_FILTER_PARAMS,
        },
        {
            headerName: t('App.page.news.url'),
            field: 'url',
            sortable: false,
            floatingFilter: true,
            minWidth: 200,
            cellRenderer: LinkCell,
        },
        {
            headerName: t('App.page.news.image'),
            field: 'image',
            sortable: false,
            floatingFilter: false,
            minWidth: 200,
            cellRenderer: ImageCell,
        },
        {
            headerName: t('App.page.Brandings.updatedOn'),
            field: 'updated',
            sortable: false,
            floatingFilter: false,
            minWidth: 170,
            // cellRenderer: DateColumn,
            // eslint-disable-next-line no-dupe-keys
            cellRenderer: (params: any) => {
                const updatedParams = {...params, size: '13px'};
                return <DateColumn {...updatedParams} />
            },
        },
        {
            headerName: t('App.page.Brandings.updatedBy'),
            field: 'updatedBy',
            sortable: false,
            minWidth: 200,
            floatingFilter: false,
        }
    ] : [
        {
            headerName: t('App.page.news.action'),
            field: '',
            sortable: false,
            floatingFilter: false,
            minWidth: 140,
            cellRenderer: (params: any) => {
                const {data} = params;
                return (
                    <ActionWrapper>
                        {!isEmpty(selectedCo) && (
                            <div title={data?.active ? 'Disable News' : 'Enable News'}>
                                <DhlSwitch
                                    dataId="publish-switch-inputField"
                                    isChecked={data?.active}
                                    name="Enable/Disable News"
                                    changeEvent={() => onStatusChange(data)}
                                />
                            </div>
                        )}

                        <IconWrapper size="md" onClick={() => onClickEdit(data)}>
                            <DhlIcon src={getIcon('edit')} size="1rem"/>
                        </IconWrapper>
                    </ActionWrapper>
                );
            },
        },
        {
            headerName: t('App.page.news.newsHeader'),
            field: 'header',
            sortable: true,
            floatingFilter: true,
            minWidth: 170,
            filterParams: DEFAULT_FILTER_PARAMS,
        },
        {
            headerName: t('App.page.news.url'),
            field: 'url',
            sortable: false,
            floatingFilter: true,
            minWidth: 200,
            cellRenderer: LinkCell,
        },
        {
            headerName: t('App.page.news.image'),
            field: 'image',
            sortable: false,
            floatingFilter: false,
            minWidth: 200,
            cellRenderer: ImageCell,
        },
        {
            headerName: t('App.page.Brandings.updatedOn'),
            field: 'updated',
            sortable: false,
            floatingFilter: false,
            minWidth: 170,
            // cellRenderer: DateColumn,
            // eslint-disable-next-line no-dupe-keys
            cellRenderer: (params: any) => {
                const updatedParams = {...params, size: '13px'};
                return <DateColumn {...updatedParams} />
            },
        },
        {
            headerName: t('App.page.Brandings.updatedBy'),
            field: 'updatedBy',
            minWidth: 200,
            sortable: false,
            floatingFilter: false,
        }
    ]

    return (
        <PageWrapper theme={theme} id="page_wrapper">
            <BreadCrumbsWrapper>
                <DhlBreadCrumbs list={myBreadCrumbList}/>
            </BreadCrumbsWrapper>

            {!isLoading && <HeaderWrapper id="header_wrapper">
                <DhlText size="md" weight={800} title={t('App.breadcrumb.news')}/>

                <RightWrapper>
                    <DropDownWrapper>
                        <FormDropDown
                            dataId="customerObject-inputField"
                            // ref={dropDownRef}
                            // data={filterCoData}
                            // value={selectedCo}
                            // changeEvent={(event: any) => setSelectedCo(event.target.value)}
                            // filterChangeEvent={(filter: string) => {
                            //     const mySplitArray = filter.split(" ");
                            //     return setTimeout(() => {
                            //         if (!isEmpty(mySplitArray[mySplitArray.length - 1])) {
                            //             return setFilterCoData(
                            //                 customerObjectData.filter((item: any) =>
                            //                     item.label.toLowerCase().includes(filter.toLowerCase())
                            //                 )
                            //             )
                            //         }
                            //     }, 1000);
                            // }
                            // }
                            // filterPlaceholder={t(
                            //     'App.page.ExternalUser.searchCustomerObject'
                            // )}
                            // placeholder={`${t('App.page.ExternalUser.customerObject')}`}
                            // showFilter
                            loading={isCoLoading}
                            value={selectedCo}
                            label={`${t('App.page.ExternalUser.customerObject')} *`}
                            options={customerObjectData}
                            onChange={(_: any, newVal: any) => {
                                setSelectedCo(newVal);
                            }}
                        />
                        {!isEmpty(selectedCo) &&
                            selectedCo !== null &&
                            customerObjectIconRef !== null &&
                            customerObjectIconRef !== undefined &&
                            createPortal(
                                <img
                                    alt="close"
                                    src={getIcon('cross')}
                                    style={{
                                        width: '60%',
                                        height: '60%',
                                        userSelect: 'none',
                                        pointerEvents: 'auto',
                                    }}
                                    onClick={() => setSelectedCo(null)}
                                />,
                                customerObjectIconRef
                            )}
                    </DropDownWrapper>

                    <ButtonWrapper>
                        {!isEmpty(selectedCo) && !isEditable && (
                            <Button
                                size={isMobile ? "xs" : "sm"}
                                variant="primary"
                                title={t('App.common.edit')}
                                dataAriaLabel={t('App.common.edit')}
                                onClick={() => onClickEditSeqNo()}
                            />
                        )}
                        {!isEmpty(selectedCo) && isEditable && (
                            <Button
                                size={isMobile ? "xs" : "sm"}
                                variant="primary"
                                title={t('App.common.save')}
                                dataAriaLabel={t('App.common.save')}
                                onClick={() => onClickSave()}
                            />
                        )}
                        <Button
                            size="sm"
                            variant="primary"
                            title={t('App.page.customer.add')}
                            dataAriaLabel={t('App.page.customer.add')}
                            onClick={() => onClickAdd()}
                        />
                    </ButtonWrapper>
                </RightWrapper>
            </HeaderWrapper>}

            {!isLoading && <Table
                columnDefs={getColumnDefs()}
                dataKey="data"
                totalKey="total"
                id="NewsListPage"
                resizeColumn={false}
                autoSizeColumns={false}
                sizeColumnsToFit
                showActionPanel={false}
                triggerValue={selectedCo === null ? "" : selectedCo?.value}
                trigger={trigger}
                resetTrigger={() => {
                    setTrigger(false);
                }}
                getData={async (request: any) => {
                    addLoading();
                    return await ServiceRequest.getNewsList(request, request.trigger.toString()).finally(() => removeLoading());
                }
                }
                floatingFilter
                rowHeight={getHeight(1)}
            />}
        </PageWrapper>
    );
}

export default NewsListPage;