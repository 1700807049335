/* eslint-disable  */
import React, {useContext, useState} from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import {ThemeContext} from "../../../styles/ThemeContext";
import styled from "styled-components";
import {device} from "../../../styles/Device";
import DhlText from "../../../components/DhlText";
import {IconContext} from "../../../assets/IconContext";
import {DhlIcon, DhlInputField} from "@dhl-official/ui-libraries/react-library";
import {useTranslation} from "react-i18next";
import RoleComponents from "./RolesComponent";
import CustomerObjectProfileComponent from "./CustomerObjectProfileComponent";
import {DATE_FORMATTERS} from "../../../utils";
import moment from "moment";
import {isEmpty} from "lodash";

const DrawerWrapper = styled(Drawer)`
  @media ${device.mobile} {
        min-width: 400px;
  }
  @media ${device.tablet} {
        min-width: 800px;
  }
  @media ${device.laptop} {
        min-width: 1000px;
  }
  @media ${device.desktop} {
        min-width: 1200px;
  }
  @media ${device.largeDesktop} {
        min-width: 1400px;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  overflow: auto;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px; 
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const FieldWrapper = styled.div`
    width: 100%;
    margin-top: 16px;
    margin-bottom: 12px;
    padding: 10px 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
`;

const ItemWrapper = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  margin: 6px;
`;

const TabWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 12px; 
  padding: 4px; 
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const TabItem = styled.div<{ selected: boolean }>`
    padding: 4px 20px 16px;
    font-size: 16px;
    font-weight: ${(props) => props.selected ? 'bold' : 'null'};
    border-bottom: ${(props) => props.selected ? '4px solid #d40511' : '2px solid #666666'};

    :hover {
        cursor: pointer;
    }
`;

const ContentWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 12px; 
  padding: 4px;
`;

const DisableFieldWrapper = styled.div`
    width: 100%;
    height: 50px;
    margin: 8px;
    padding: 4px 4px 4px 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #666666;
    background-color: #e7e7e7;
    border-radius: 4px;
`;

const CloseIcon = styled(DhlIcon)`
  width: 32px;
  height: 32px;
  padding: 4px;
  :hover {
    cursor: pointer;
  }
`;

function UserDetails(props: any): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const {t} = useTranslation();
    const {isMenuOpen, closeMenu, userData} = props;

    const [selectedTab, setSelectedTab] = useState('Role');

    let valueCreatedBy = "";
    let valueUpdatedBy = "";
    if(userData?.created_by !== null && userData?.created_date !== null)
        valueCreatedBy = `${userData?.created_by} - ${moment(new Date(userData?.created_date)).format(DATE_FORMATTERS.FORMATTER2)}`;

    if(userData?.updated_by !== null && userData?.updated_date !== null)
        valueUpdatedBy = `${userData?.updated_by} - ${moment(new Date(userData?.updated_date)).format(DATE_FORMATTERS.FORMATTER2)}`;

    return (
        <DrawerWrapper open={isMenuOpen} onClose={closeMenu} direction="right">

            <Wrapper theme={theme}>

                <HeaderWrapper>
                    <DhlText title={`${userData?.first_name} ${userData?.last_name}`} size='xxl' weight={900}/>
                    <CloseIcon src={getIcon('RedClose')} onClick={closeMenu}/>
                </HeaderWrapper>

                <FieldWrapper>
                    <ItemWrapper width={'31%'}>
                        <DisableFieldWrapper>
                            <DhlText title={t('App.page.customer.email')} size={"xs"} />
                            <DhlText title={userData?.email} size={"sm"} />
                        </DisableFieldWrapper>
                    </ItemWrapper>

                    <ItemWrapper width={'31%'}>
                        <DisableFieldWrapper>
                            <DhlText title={t('App.page.customer.firstName')} size={"xs"} />
                            <DhlText title={userData?.first_name} size={"sm"} />
                        </DisableFieldWrapper>
                    </ItemWrapper>

                    <ItemWrapper width={'31%'}>
                        <DisableFieldWrapper>
                            <DhlText title={t('App.page.customer.lastName')} size={"xs"} />
                            <DhlText title={userData?.last_name} size={"sm"} />
                        </DisableFieldWrapper>
                    </ItemWrapper>

                    <ItemWrapper width={'47%'}>
                        <DisableFieldWrapper>
                            <DhlText title={t('App.page.upload.createdBy')} size={"xs"} />
                            <DhlText title={valueCreatedBy} size={"sm"} />
                        </DisableFieldWrapper>
                    </ItemWrapper>

                    <ItemWrapper width={'47%'}>
                        <DisableFieldWrapper>
                            <DhlText title={t('App.page.Users.updatedBy')} size={"xs"} />
                            <DhlText title={valueUpdatedBy} size={"sm"} />
                        </DisableFieldWrapper>
                    </ItemWrapper>
                </FieldWrapper>

                <TabWrapper>
                    <TabItem selected={selectedTab === 'Role'} onClick={() => setSelectedTab('Role')}> Roles </TabItem>
                    <TabItem
                        selected={selectedTab === 'Cop'}
                        onClick={() => setSelectedTab('Cop')}>
                        Customer Object Profile
                    </TabItem>
                </TabWrapper>

                <ContentWrapper>
                    {selectedTab === 'Role' && !isEmpty(userData) && <RoleComponents userData={userData}/>}
                    {selectedTab === 'Cop' && !isEmpty(userData) && <CustomerObjectProfileComponent userData={userData}/>}
                </ContentWrapper>
            </Wrapper>

        </DrawerWrapper>

    );
}

export default UserDetails;
