/* eslint-disable */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ThemeContext} from '../../styles/ThemeContext';
import {device} from '../../styles/Device';
import ServiceRequest from '../../service/ServiceRequest';
import {LoadingContext} from '../../contexts/LoadingContext';
import {
    NOTIFICATION_TYPES,
    NotificationContext,
} from '../../contexts/NotificationContext';
import {PATHS, ROLES} from '../App/constants';
import StatusView from '../../components/Table/cell/StatusView';
import FileSaver from 'file-saver';
import {
    BreadCrumbsWrapper, ButtonWrapper,
    HeaderWrapper,
    PageWrapper2,
    TitleData,
    TopImageWrapper,
    Button
} from "../common";
import BannerImage from '../../assets/images/CoHeader.png';
import DhlBreadCrumbs from "../../components/DhlBreadCrumbs";
import DhlText from "../../components/DhlText";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../contexts/UserContext";
import {useNavigate} from "react-router";
import {DhlIcon, DhlInputField} from "@dhl-official/ui-libraries/react-library";
import {getHeight} from "../../components/Table/utils";
import DhlButton from "../../components/DhlButton";
import {isEmpty} from "lodash";
import {IconContext} from "../../assets/IconContext";
import StaticTable from "../../components/Table/StaticTable";
import {TableCustomerObjectProfile} from "./components/TableCustomerObjectProfile";
import {GridApi, ModelUpdatedEvent} from "ag-grid-community";
import {useLocation} from "react-router-dom";
import {Grid} from "@mui/material";
import {useDebounce} from "../../utils/debounce-hooks";

interface RegionType {
    label: number;
    value: string;
}

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 60px;
  padding: 10px;
  margin-left: 10px;
  margin-top: 8px;
  align-items: center;
  @media ${device.mobile} {
  width: 93%;
  }
  @media ${device.tablet} {
  width: 99%;
  }
  background-color: ${(props) => props.theme.background};
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 4px;
  margin-left: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  @media ${device.mobile} {
  width: 93%;
  }
  @media ${device.tablet} {
  width: 99%;
  }
  background-color: ${(props) => props.theme.color.green[500]};
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 4px;
  margin-left: 10px;
  align-items: center;
  @media ${device.mobile} {
  width: 93%;
  }
  @media ${device.tablet} {
  width: 99%;
  }
  background-color: ${(props) => props.theme.background};
`;

const DhlInput = styled(DhlInputField)`
  width: 100%;
`;

const TopButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
  min-width: 100%;
  background-color: ${(props) => props.theme.background};
`;

const NameButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 50px;
  padding: 4px;
  overflow-wrap: break-word;
  text-decoration: underline;
  line-height: 16px;
  :hover {
  cursor: pointer;
  }
`;

const MyIcon = styled(DhlIcon)`
    padding: 4px;
    padding-right: 8px;
`;

const SuccessText = styled(DhlText)`
   margin-left: 4px;
   text-wrap: nowrap;
`;

const SuccessText2 = styled(DhlText)`
   margin-left: 2px;
   text-wrap: nowrap;
   text-decoration: underline;
   :hover {
     cursor: pointer;
   }
`;

const MultilineView = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 50px;
  padding: 4px;
  overflow-wrap: break-word;
  line-height: 16px;
`;

function NameView(row: any): any {
    const {data, path, changeVisibleRow, visibleDetailsRowData, setGridApi} = row;
    const theme = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const navigate = useNavigate();
    setGridApi(row?.api);

    if (data !== undefined)
        return (
            <NameButton theme={theme}>
                {Number(data.profiles_count) > 0 ? <MyIcon
                    src={getIcon(data.id === visibleDetailsRowData.id ? 'upArrow' : 'downArrow')}
                    size={"1rem"}
                    onClick={() => changeVisibleRow(data.id === visibleDetailsRowData.id ? '' : data)}/> : <MyIcon
                    src={''}
                    size={"1rem"}/>
                }
                <div onClick={() => navigate(path.replace(':id', data.id), {state: data})}>{data.name}</div>
            </NameButton>
        );
}

function MultiLineCell(props: any): any {
    const {data, value, path} = props;
    const theme = useContext(ThemeContext);
    const navigate = useNavigate();

    if (data !== undefined)
        return (
            <MultilineView theme={theme} onClick={() => navigate(path.replace(':id', data.id))}>
                {value}
            </MultilineView>
        );
}

const statusList = [
    {label: 'None', value: ''},
    {label: 'Active', value: 'ACTIVE'},
    {label: 'Passive', value: 'Passive'},
    // {label: 'New', value: 'NEW'},
    // {label: 'In Progress', value: 'IN PROGRESS'},
];

function CoList(): JSX.Element {
    const theme = useContext(ThemeContext);
    const {user} = useContext(UserContext);
    const {setLoading} = useContext(LoadingContext);
    const {setType, setMessage} = useContext(NotificationContext);
    const {getIcon} = useContext(IconContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [gridApi, setGridApi] = useState<GridApi>();
    const [visibleDetailsRowData, setVisibleDetailsRowData] = useState<any>('');
    const [rowData, setRowData] = useState<any[]>([]);
    const [filterModel, setFilterModel] = useState({});
    const [sortModel, setSortModel] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState<any>({});
    const [coListResponse, setCoListResponse] = useState<any>({});
    const [topFilter, setTopFilter] = useState('');
    const [regionData, setRegionData] = useState<RegionType[]>([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isMessageShown, setIsMessageShown] = useState(false);
    const [isDataDirty, setIsDirty] = useState(false);
    const [messageData, setMessageData] = useState<any>('');
    const [coData, setCoData] = useState<any>(null);
    const [isRowLoading, setIsRowLoading] = useState(true);
    const [isRegionLoading, setIsRegionLoading] = useState(true);
    const isMobile = window.innerWidth < 600;

    const getRegionData = (): void => {
        setIsRegionLoading(true);
        setLoading(true);
        ServiceRequest.getRegion()
            .then((response) => {
                const myRegionList = [{label: 'None', value: ''}]
                response?.data?.forEach((region: any) => {
                    myRegionList.push({
                        label: region.name,
                        value: region.name
                    })
                });
                // @ts-ignore
                setRegionData(myRegionList);
            })
            .catch((error) => {
                console.error(error);
                // This is commented to fix the MSCTT-2108
                // setType(NOTIFICATION_TYPES.FAILED);
                // setMessage(error.message);
            })
            .finally(() => {
                setIsRegionLoading(false);
            });
    };

    const myBreadCrumbList = [
        {label: 'App.breadcrumb.home', path: PATHS.HOME},
        {label: 'App.page.Customers.title', path: ''},
    ];

    useEffect(() => {
        getRegionData();
        getRowDataDebounce();
    }, []);

    useEffect(() => {
        if (isDataDirty) {
            getRowDataDebounce();
        }
    }, [topFilter]);

    useEffect(() => {
        if (isDataDirty) {
            getRowDataDebounce();
        }
    }, [filterModel]);

    useEffect(() => {
        if (!isEmpty(rowData)) {
            getRowDataDebounce();
        }
    }, [sortModel]);

    useEffect(() => {
        if (!isEmpty(rowData)) {
            getRowDataDebounce();
        }
    }, [paginationModel]);

    useEffect(() => {
        if (rowData?.length > 0) {
            gridApi?.redrawRows();
            gridApi?.refreshClientSideRowModel();
        }
    }, [rowData]);

    useEffect(() => {
        if (location.state !== null && !isMessageShown) {
            setIsSuccess(location.state.success);
            setIsMessageShown(true);
            setCoData(location.state?.data);
            setMessageData(location.state?.isUpdate ? t("App.page.customer.successUpdateMessage") : t("App.page.customer.successMessage"));
            location.state = null;
            delete location.state;
            window.history.replaceState({}, '');
        }
    }, [location]);

    useEffect(() => {
        if (!isRegionLoading && !isRowLoading)
            setLoading(false);
    }, [isRowLoading, isRegionLoading]);

    const getCoParams = (isExport: boolean) => {
        const request: any = {
            filter: filterModel,
            page: isExport ? 0 : paginationModel?.pageNo === undefined ? 0 : paginationModel?.pageNo - 1,
            size: isExport ? 10000 : paginationModel?.pageSize === undefined ? 10 : paginationModel?.pageSize,
            trigger: topFilter,
            sort: sortModel
        }

        let myRequestParam = ``;

        // PAGE CONFIG OFFSET AND SIZE
        if (!isEmpty(request) && 'page' in request && 'size' in request) {
            myRequestParam = `offset=${request.page}&limit=${request.size}`;
        }

        // SORTING
        if ('sort' in request && sortModel.length > 0) {
            let sortingType = '';
            let sortingField = ''
            sortModel.forEach((columnData: any) => {
                if (columnData.sort !== null && !isEmpty(columnData.sort)) {
                    sortingType = columnData.sort.toUpperCase();
                    sortingField = columnData.colId.toUpperCase();
                }
            })
            myRequestParam = `${myRequestParam}&sortingType=${sortingType}&sortingCustomerObjects=${sortingField}`;
        }

        // TOP FILTER SEARCH
        if (!isEmpty(request.trigger)) {
            myRequestParam = `${myRequestParam}&topFilter=${request.trigger}`;
        }

        // COLUMNS FILTER
        if (!isEmpty(request.filter) && 'name' in request.filter) {
            myRequestParam = `${myRequestParam}&nameFilter=${request.filter.name?.filter}`;
        }

        if (!isEmpty(request.filter) && 'region' in request.filter) {
            myRequestParam = `${myRequestParam}&regionFilter=${request.filter.region?.filter}`;
        }

        if (!isEmpty(request.filter) && 'brandingObject' in request.filter) {
            myRequestParam = `${myRequestParam}&brandingObjectFilter=${request.filter.brandingObject?.filter}`;
        }

        if (!isEmpty(request.filter) && 'customer' in request.filter) {
            myRequestParam = `${myRequestParam}&customerFilter=${request.filter.customer?.filter}`;
        }

        if (!isEmpty(request.filter) && 'sector' in request.filter) {
            myRequestParam = `${myRequestParam}&sectorFilter=${request.filter.sector?.filter}`;
        }

        if (!isEmpty(request.filter) && 'profiles_count' in request.filter) {
            myRequestParam = `${myRequestParam}&profileCountFilter=${request.filter.profiles_count?.filter}`;
        }

        if (!isEmpty(request.filter) && 'status' in request.filter) {
            myRequestParam = `${myRequestParam}&statusFilter=${request.filter.status?.filter.toUpperCase()}`;
        }

        return myRequestParam;
    }

    const getRowDataDebounce = useDebounce(() => {
        if (location.pathname === PATHS.CUSTOMERS) {
            const myQueryParams = getCoParams(false);
            setIsRowLoading(true);
            setLoading(true);
            ServiceRequest.getCoList(myQueryParams)
                .then((response) => {
                    setCoListResponse(response.data);
                    setRowData(response.data?.list);
                })
                .catch((error) => {
                    setType(NOTIFICATION_TYPES.FAILED);
                    setMessage(error?.response?.data?.message);
                })
                .finally(() => {
                    setIsRowLoading(false);
                    setIsDirty(true);
                });
        }
    }, 1000);

    const download = (): void => {
        const myQueryParams = getCoParams(true);
        setLoading(true);
        ServiceRequest.exportCustomers2(myQueryParams)
            .then((response) => {
                if (response.status === 200) {
                    let fileName = 'export.xlsx';
                    if (response.headers?.['content-disposition']) {
                        const val = response.headers['content-disposition'].split(';');
                        if (val.length === 2 && val[1].trim().startsWith('filename='))
                            fileName = val[1].trim().substring('filename='.length);
                    }
                    // @ts-ignore
                    FileSaver.saveAs(response.data, fileName);
                } else {
                    setType(NOTIFICATION_TYPES.FAILED);
                    setMessage(String(response));
                }
            })
            .catch((error) => {
                setType(NOTIFICATION_TYPES.FAILED);
                if(error?.response?.data?.message !== undefined){
                    setMessage(error.response.data.message);
                } else {
                    setMessage(error.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // ====== START AG GRID TABLE METHOD ========== //

    const getColumnDefs = (): any => {
        const columnDefs = [
            {
                headerName: t('App.page.Customers.name'),
                field: 'name',
                // pinned: true,
                cellRendererParams: {
                    path: PATHS.CUSTOMER_DETAIL,
                    changeVisibleRow,
                    visibleDetailsRowData,
                    setGridApi
                },
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                cellRenderer: NameView,
                minWidth: 150,
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            },
            {
                headerName: t('App.page.Customers.region'),
                field: 'region',
                sortable: true,
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                minWidth: 150,
                cellRenderer: MultiLineCell,
                cellRendererParams: {
                    path: PATHS.CUSTOMER_DETAIL,
                },
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    dataList: regionData
                }
            },
            {
                headerName: t('App.page.Customers.branding'),
                field: 'branding_object',
                sortable: true,
                cellRendererParams: {
                    path: PATHS.CUSTOMER_DETAIL,
                },
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                cellRenderer: MultiLineCell,
                minWidth: 150,
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            },
            {
                headerName: t('App.page.Customers.customerName'),
                field: 'customer',
                sortable: true,
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                cellRenderer: MultiLineCell,
                minWidth: 150,
                cellRendererParams: {
                    path: PATHS.CUSTOMER_DETAIL,
                },
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            },
            {
                headerName: t('App.page.Customers.sector'),
                field: 'sector',
                sortable: true,
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                cellRenderer: MultiLineCell,
                minWidth: 150,
                cellRendererParams: {
                    path: PATHS.CUSTOMER_DETAIL,
                },
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            },
            {
                headerName: t('App.page.customer.noCustObjProfile'),
                field: 'profiles_count',
                sortable: true,
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                minWidth: 150,
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            },
            {
                headerName: t('App.page.customer.status'),
                field: 'status',
                sortable: true,
                floatingFilter: true,
                suppressMenu: true,
                minWidth: 150,
                cellRenderer: StatusView,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    dataList: statusList
                }
            }
        ];
        return columnDefs;
    };

    const renderDetailCells = (): any => {
        return TableCustomerObjectProfile;
    };

    const fullWidthCellRenderer = renderDetailCells();

    const isFullWidth = (data: any): boolean => {
        return data.isDetailRow === undefined ? false : data.isDetailRow;
    };

    const isFullWidthRow = useCallback(
        (params: any): any => isFullWidth(params?.rowNode?.data),
        []
    );

    const changeVisibleRow = (data: any) => {
        let detailRow = '';
        let newData: any[] = [];
        // This is the case same row double-clicked so need to hide the row
        if (isEmpty(data) || data === undefined) {
            // Hide extra row added
            newData = rowData?.filter((coData: any) => coData.isDetailRow === undefined || !coData.isDetailRow);
        } else {
            detailRow = data;
            // Update total rows by +1, so it can be added extra row
            rowData?.forEach((coData: any) => {
                newData.push(coData);
                if (coData.id === data.id) {
                    newData.push({...coData, isDetailRow: true});
                }
            });
        }
        setVisibleDetailsRowData(detailRow);
        setRowData(newData);
    }

    const onModelUpdated = (event: ModelUpdatedEvent<any>): void => {
        setGridApi(event.api);
    }

    const getRowHeight = (params: any): any => {
        if (params.data.isDetailRow !== undefined && params.data.isDetailRow) {
            return getHeight(params.data?.profiles_count * 2 + 8);
        } else {
            return getHeight(2.5);
        }
    };

    // ====== END AG GRID METHOD ======= //

    return (
        <PageWrapper2 theme={theme.theme}>
            <TopImageWrapper theme={theme.theme} BannerImage={BannerImage}>
                <TitleData
                    title={t('App.page.Customers.title')}
                    size={'heading1'}
                    weight={600}
                    color={'#ffffff'}/>
            </TopImageWrapper>

            <BreadCrumbsWrapper theme={theme.theme}>
                <DhlBreadCrumbs list={myBreadCrumbList}/>
            </BreadCrumbsWrapper>

            <HeaderWrapper>
                <DhlText size="md" weight={800} title={t('App.page.Customers.title')}/>

                <ButtonWrapper>
                    {(user.roles?.includes(ROLES.MSCADMIN) ||
                        user.roles?.includes(ROLES.COADMIN)) && (
                        <Button
                            size={isMobile ? "xs" : "sm"}
                            title={t('App.page.Customers.create')}
                            variant="primary"
                            dataAriaLabel={t('App.page.Customers.create')}
                            onClick={() => navigate(PATHS.CUSTOMER_ADD)}
                        />
                    )}
                </ButtonWrapper>
            </HeaderWrapper>

            {isSuccess && <MessageWrapper theme={theme.theme}>
                <Grid container alignItems={"center"}>
                    <DhlButton title={""} variant={"text"} icon={getIcon("check")} size={"sm"}/>
                    <SuccessText
                        title={t("App.page.customer.success")}
                        size={'sm'}
                        color={"white"}
                        weight={600}/>
                    <SuccessText
                        title={messageData}
                        size={'sm'}
                        color={"white"}
                        weight={400}/>
                    {coData !== null && <SuccessText2
                        title={coData?.name}
                        size={'sm'}
                        color={"white"}
                        weight={600}
                        // @ts-ignore
                        onClick={() => navigate(PATHS.CUSTOMER_DETAIL.replace(':id', coData.id), {state: coData})}
                    />}
                    <SuccessText
                        title={t('App.page.customer.successUpdateMessage2')}
                        size={'sm'}
                        color={"white"}
                        weight={400}/>
                </Grid>

                <DhlButton
                    title={""}
                    variant={"text"}
                    size={isMobile ? "xs" : "sm"}
                    icon={getIcon("cross", "dark")}
                    onClick={() => {
                        setMessage("");
                        setCoData(null);
                        setIsSuccess(false);
                    }}/>
            </MessageWrapper>}

            <SearchWrapper theme={theme.theme}>
                <DhlInput
                    searchIcon
                    value={topFilter}
                    inputEvent={(e: any) => setTopFilter(e.target.value)}
                    variant={{
                        label: "",
                        placeholder: t('App.page.Customers.searchPlaceholder'),
                        type: "animated"
                    }}/>
            </SearchWrapper>

            <TableWrapper>
                <TopButtons>
                    <DhlButton
                        size="sm"
                        title={t('App.page.Customers.export')}
                        variant="text"
                        icon={getIcon('download')}
                        iconOrientation={'left'}
                        dataAriaLabel={t('App.page.Customers.export')}
                        onClick={() => download()}
                    />
                </TopButtons>

                {regionData.length > 0 && <StaticTable
                    columnDefs={getColumnDefs()}
                    id="CoListPage"
                    rowData={rowData}
                    totalResult={coListResponse?.total_results}
                    resizeColumn={false}
                    autoSizeColumns={false}
                    sizeColumnsToFit
                    showActionPanel={false}
                    suppressPaginationPanel={true}
                    suppressScrollOnNewData={true}
                    showCustomPagination={true}
                    getRowHeight={getRowHeight}
                    onModelUpdated={onModelUpdated}
                    onFilterChanged={(e: any) => setFilterModel(e.api.getFilterModel())}
                    onSortChanged={(e: any) => setSortModel(e.columnApi.getColumnState())}
                    onPaginationChanged={(e: any) => setPaginationModel(e)}
                    isFullWidthRow={isFullWidthRow}
                    fullWidthCellRenderer={fullWidthCellRenderer}
                    rowClass={'static-content'}
                />}

            </TableWrapper>

        </PageWrapper2>
    );
}

export default CoList;
