import React, { useContext } from 'react';
import styled from 'styled-components';
// import { DhlText as TextComponent } from '@dhl-official/ui-libraries/react-library';
import { TypeText } from './TypeText';
import { ThemeContext } from '../../styles/ThemeContext';

const getSize = (props: any): any => props.theme.fontSize[props.size];

const getWeight = (props: any): any => props.weight;

const TextWrapper = styled.div`
  color: ${(props) =>
    props.color !== null && props.color !== undefined
      ? props.color === 'secondary'
        ? props.theme.secondaryText
        : props.color
      : props.theme.primaryText};
  font-size: ${(props) => getSize(props)};
  font-weight: ${(props) => getWeight(props)};
`;

const DhlText: React.FC<TypeText> = (props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <TextWrapper id={props.id} {...props} theme={theme}>
      {' '}
      {props.title}{' '}
    </TextWrapper>
  );
};

export default DhlText;
