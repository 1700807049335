/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  BreadCrumbsWrapper,
  ButtonItem,
  ButtonWrapper,
  HeaderWrapper,
  PageWrapper,
} from '../common';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';
import DhlText from '../../components/DhlText';
import { PATHS } from '../App/constants';
import { ThemeContext } from '../../styles/ThemeContext';
import { useTranslation } from 'react-i18next';
import ServiceRequest from '../../service/ServiceRequest';
import { LoadingContext } from '../../contexts/LoadingContext';
import {
  NOTIFICATION_TYPES,
  NotificationContext,
} from '../../contexts/NotificationContext';
import CategoryForm from './Category';

interface IFormInputs {
  name: string;
  description: string;
}

const myBreadCrumbList = [
  { label: 'App.breadcrumb.home', path: PATHS.HOME },
  { label: 'App.page.Categories.title', path: PATHS.CATEGORIES },
  { label: 'App.breadcrumb.categories', path: '' },
];

function Category(): ReactElement {
  const { id: categoryId } = useParams();
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const { setType, setMessage } = useContext(NotificationContext);

  const [categoryData, setCategoryData] = useState<any>(null);

  useEffect(() => {
    if (categoryId != null) {
      ServiceRequest.processRequest({
        setLoading,
        setType,
        setMessage,
        inputData: categoryId,
        request: ServiceRequest.getCategory,
        responseHandler: (response) => {
          setCategoryData(response.data);
        },
      });
    }
  }, [categoryId]);

  const { handleSubmit, control, reset, getValues } = useForm<IFormInputs>({
    defaultValues: {
      name: undefined,
      description: undefined,
    },
  });

  useEffect(() => {
    reset(categoryData);
  }, [categoryData]);
  const onSubmitData: SubmitHandler<IFormInputs> = (data) => {
    const objectRequest = {
      ...data,
    };

    ServiceRequest.processRequest({
      setLoading,
      setType,
      setMessage,
      inputData: { id: categoryId, data: objectRequest },
      request:
        categoryId != null
          ? ServiceRequest.saveCategory
          : ServiceRequest.createCategory,
      responseHandler: (response) => {
        setType(NOTIFICATION_TYPES.SUCCESS);
        setMessage(t('App.page.Categories.categorySaved'));
        if (categoryId == null) {
          navigate(
            PATHS.CATEGORIES_DETAIL_DETAIL.replace(
              ':id',
              response?.data?.id
            )
          );
        } else {
          setCategoryData(response.data);
        }
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <PageWrapper theme={theme} id="page_wrapper">
        <BreadCrumbsWrapper>
          <DhlBreadCrumbs list={myBreadCrumbList} />
        </BreadCrumbsWrapper>

        <HeaderWrapper id="header_wrapper">
          <DhlText
            size="md"
            weight={800}
            title={t('App.page.Categories.title')}
          />

          <ButtonWrapper>
            <ButtonItem
              title={t('App.common.exit')}
              variant="outline"
              size="sm"
              onClick={() => navigate(PATHS.CATEGORIES)}
            />
            <ButtonItem
              title={t('App.common.save')}
              variant="primary"
              size="sm"
              type="submit"
            />
          </ButtonWrapper>
        </HeaderWrapper>

        <CategoryForm
          control={control}
          categoryData={categoryData}
          getValues={getValues}
        />
      </PageWrapper>
    </form>
  );
}

export default Category;
