/* eslint-disable */
import React, {useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {ThemeContext} from '../../styles/ThemeContext';
import ServiceRequest from '../../service/ServiceRequest';
import {LoadingContext} from '../../contexts/LoadingContext';
import {PATHS} from '../App/constants';
import {Button, ButtonWrapper, PageWrapper2, TitleData, TopImageWrapper,} from "../common";
import BannerImage from '../../assets/images/CoHeader.png';
import DhlBreadCrumbs from "../../components/DhlBreadCrumbs";
import DhlText from "../../components/DhlText";
import {useTranslation} from "react-i18next";
import {Controller, useForm, useWatch} from 'react-hook-form';
import {DhlDropdown, DhlIcon, DhlInputField,} from "@dhl-official/ui-libraries/react-library";
import {Grid, TextareaAutosize, TextField} from "@mui/material";
import {device} from "../../styles/Device";
import {isEmpty} from "lodash";
import {NOTIFICATION_TYPES, NotificationContext} from "../../contexts/NotificationContext";
import {useLocation, useNavigate} from "react-router-dom";
import Drawer from "react-modern-drawer";
import ProfilePage from "./components/ProfilePage";
import DhlButton from "../../components/DhlButton";
import {useDebounce} from "../../utils/debounce-hooks";
import {getHeight} from "../../components/Table/utils";
import StaticTable from "../../components/Table/StaticTable";
import {IconContext} from "../../assets/IconContext";
import {CountryContext} from "../../contexts/CountryContext";
import {UserContext} from "../../contexts/UserContext";
import AutoCompleteView from "../../components/DhlAutoCompleteView";
import DhlMultiSelect from "../../components/DhlMultiSelect";

const HeaderWrapper = styled.div`
  width: 100%;
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${(props) => props.theme.background};
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.19);
`;

const BreadCrumbsWrapper = styled.div`
  width: 100%;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: ${(props) => props.theme.background};
`;

const DisplayNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 4px;
  margin-top: 12px;
  align-items: flex-start;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const StatusWrapper = styled.div<{ status: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #ededed;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: ${(props) => props.status === 'New' ? "#3884ff" : "#d9b72e"};
  color: #ffffff;
  margin-left: 8px;
  border-radius: 4px;
  font-size: 12px;
  height: 25px;
`;

const SectionWrapper = styled.div`
   @media ${device.mobile} {
    width: 96%;
    margin-left: 8px;
  }
  @media ${device.tablet} {
    width: 98%;
    margin-left: 16px;
  }
  min-height: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  
  margin-top: 16px;
  margin-bottom: 16px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 8px;
  background-color: ${(props) => props.theme.theme.background};
`;

const ObjectDetailsFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
    width : 100%;
    height: 2px;
    background-color: ${(props) => props.theme.theme.divider};
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const CardTitle = styled(DhlText)`
    margin: 10px;
`;

export const Dropdown = styled(DhlDropdown)`
  width: 100%;
  display: block;
  @media ${device.mobile} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    margin-top: 6px;
    margin-bottom: 6px;
  }
`;

export const FormField = styled(DhlInputField)`
  width: 100%;
  display: block;
  @media ${device.mobile} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    margin-top: 6px;
    margin-bottom: 6px;
  }
`;

export const FormTextArea = styled(TextareaAutosize)`
  width: 100%;
  display: block;
  @media ${device.mobile} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    margin-top: 16px;
    margin-bottom: 6px;
  }
`;

const BpDrawer = styled(Drawer)`
  width: 70%;
`;

export const HeaderTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 45px;
  background-color: grey;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 12px;
`;

const MyStaticTable = styled(StaticTable)`
    margin-top: 20px;
`;

const ActionDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 6px;
`;

const VerticalActionDiv = styled.div`
    width: 1px;
    height: 20px;
    background-color: #e6e6e6;
    margin-top: 12px; 
    margin-left: 10px; 
    margin-right: 10px;
`;

const ActionSectionDiv = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 4px;
    align-items: center;
    :hover {
    cursor: pointer;
    }
`;

const ActionText = styled(DhlText)`
    margin-left: 6px;
    text-decoration: underline;
`;

function CountryView(row: any): any {
    const {data} = row;
    const theme = useContext(ThemeContext);
    const {countries} = useContext(CountryContext);
    const mySelectedCountry: any = countries.find((country: any) => Number(country.id) === Number(data.country));

    if (data !== undefined)
        return (
            <ActionDiv theme={theme}>
                <DhlText title={mySelectedCountry?.name} size='sm'/>
            </ActionDiv>
        );
}

function OtherTextView(row: any): any {
    const {value} = row;
    const theme = useContext(ThemeContext);

    if (value !== undefined)
        return (
            <ActionDiv theme={theme}>
                <DhlText title={value} size='sm'/>
            </ActionDiv>
        );
}

function ActionView(row: any): any {
    const {data, onClickDelete, onClickEdit, bpList} = row;
    const theme = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const {t} = useTranslation();

    if (data !== undefined)
        return (
            <ActionDiv theme={theme}>
                <ActionSectionDiv onClick={() => onClickEdit(data)}>
                    <DhlIcon src={getIcon('edit')} size={'1rem'}/>
                    <ActionText title={t('App.common.edit')} size='sm'/>
                </ActionSectionDiv>
                <VerticalActionDiv/>
                <ActionSectionDiv onClick={() => onClickDelete(data, bpList)}>
                    <DhlIcon src={getIcon('delete')} size={'1rem'}/>
                    <ActionText title={t('App.common.delete')} size='sm'/>
                </ActionSectionDiv>
            </ActionDiv>
        );
}

function isInArray(value: any, dataArray: any[]): boolean {
    const filterList = dataArray.filter((data: any) => data.value.trim() === value.value.trim());
    return filterList.length > 0;
}

function CoPage(): JSX.Element {
    const theme = useContext(ThemeContext);
    const {setLoading} = useContext(LoadingContext);
    const {setMessage, setType} = useContext(NotificationContext);
    const {user} = useContext(UserContext);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [customerObjectData, setCustomerObjectData] = useState<any>(null);
    //Region Data
    const [regionData, setRegionData] = useState<any[]>([]);
    // BO Data
    const [brandingData, setBrandingData] = useState<any[]>([]);
    const [boFilter, setBoFilter] = useState('');
    //Customer Data
    const [customers, setCustomers] = useState<any[]>([]);
    const [customerFilter, setCustomerFilter] = useState("");
    // Users Data
    const [usersList, setUsersList] = useState<any[]>([]);
    const [ownerFilter, setOwnerFilter] = useState('');
    const [keySupportContactFilter, setKeySupportContactFilter] = useState('');
    const [isBpOpen, setIsBpOpen] = useState(false);
    const [bpList, setBpList] = useState<any[]>([]);
    const [deletedBpId, setDeletedBpId] = useState<any>(null);
    const [bpDetailsForEdit, setBpDetailsForEdit] = useState<any>(null);
    const location = useLocation();
    const isUpdateCustomerData = location.pathname !== PATHS.CUSTOMER_ADD;
    const [isRegionLoading, setIsRegionLoading] = useState(true);
    const [isCustomerLoading, setIsCustomerLoading] = useState(true);
    const [isBoLoading, setIsBoLoading] = useState(true);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [isFirstTimeFormUpdate, setIsFirstTimeFormUpdate] = useState(true);
    const [isOwnerLoading, setIsOwnerLoading] = useState(false);
    const [isKspLoading, setIsKspLoading] = useState(false);
    const [isCustomerObjectLoading, setIsCustomerObjectLoading] = useState(false);

    const onClickDeleteBp = (params: any) => {
        setDeletedBpId(params.id);
    }

    const onClickEditBp = (params: any) => {
        setBpDetailsForEdit(params);
        setIsBpOpen(true);
    }

    const [columnDefs] = useState([
        {
            field: 'name',
            headerName: t('App.page.Customers.name'),
            hide: false,
            filter: false,
            sortable: false,
            minWidth: 200,
            cellRenderer: OtherTextView,
        },
        {
            field: 'country',
            headerName: t('App.page.ExternalUser.country'),
            hide: false,
            filter: false,
            sortable: false,
            minWidth: 200,
            cellRenderer: CountryView,
        },
        {
            field: 'notes',
            headerName: t('App.page.Customer.notes'),
            hide: false,
            filter: false,
            sortable: false,
            minWidth: 200,
            cellRenderer: OtherTextView,
        },
        {
            field: 'status',
            headerName: t('App.page.customer.status'),
            hide: false,
            filter: false,
            sortable: false,
            minWidth: 200,
            cellRenderer: OtherTextView,
        },
        {
            field: 'action',
            headerName: t('App.page.news.action'),
            hide: false,
            filter: false,
            sortable: false,
            minWidth: 250,
            cellRenderer: ActionView,
            cellRendererParams: {
                onClickEdit: onClickEditBp,
                onClickDelete: onClickDeleteBp,
            },
        }
    ]);

    const defaultFormValues = {
        region: '',
        customer: '',
        branding_object: '',
        domain: '',
        owners: [],
        key_support_person: [],
        notes: '',
    }

    const {
        handleSubmit,
        control,
        setValue,
        setError,
        formState: {errors, isDirty}
    } =
        useForm<any>({
            defaultValues: defaultFormValues,
        });

    const results = useWatch({control});

    const getRegionData = (): void => {
        ServiceRequest.getRegion()
            .then((response) => {
                setRegionData(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsRegionLoading(false);
            });
    };

    const getBrandingDataDebounce = useDebounce(() => {
        setIsBoLoading(true);
        const queryParams = `nameFilter=${boFilter}&offset=0&limit=500`;
        ServiceRequest.getBrandingObjects2(queryParams)
            .then((response) => {
                setBrandingData(response.data);
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
            setIsBoLoading(false);
        });
        ;
    }, 700);

    const getCustomerDataDebounce = useDebounce(() => {
        setIsCustomerLoading(true);
        const queryParams = `nameFilter=${customerFilter}&offset=0&limit=200`;
        ServiceRequest.getCustomers(queryParams)
            .then((response) => {
                setCustomers(response.data);
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
            setIsCustomerLoading(false);
        });
        ;
    }, 700);

    const merge = (array1: any[], array2: any[]) => {
        const mergedArray = array2
            .reduce((accumulator, item2) => {
                if (!accumulator.some((item1: any) =>
                    item1.username === item2.username)) {
                    accumulator.push(item2);
                }
                return accumulator;
            }, array1);
        // Remove duplicates using Set
        return mergedArray;
        ;
    }

    const getUsersData = useDebounce((search: string) => {
        setIsUserLoading(true);
        ServiceRequest.getUsers2(search)
            .then((response) => {
                let myUpdatedList = [];
                const myResponse = response.data.list;
                if (usersList.length < 1 && !isEmpty(user.email))
                    myResponse.push({username: user.email, email: user.email});

                myUpdatedList = merge(myResponse, usersList);
                setUsersList(myUpdatedList);
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
            setIsUserLoading(false);
            setIsOwnerLoading(false);
            setIsKspLoading(false);
        });
    }, 700);

    const getCoData = (): void => {
        const custObjectId = location.pathname.split("/")[2];
        if (custObjectId !== undefined) {
            setIsCustomerObjectLoading(true);
            setLoading(true);
            setCustomerObjectData(location.state);
            ServiceRequest.getCustomerObjectData(custObjectId)
                .then((response) => {
                    setCustomerObjectData(response.data);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                    setIsCustomerObjectLoading(false);
                });
        }
    };

    const updateForm = () => {
        const kspData: any[] = [];
        const ownersData: any[] = [];
        if (customerObjectData !== null) {
            console.log("UPDATE FORM ====> ", customerObjectData);
            setIsFirstTimeFormUpdate(false);
            if (customerObjectData?.key_support_person !== undefined && customerObjectData?.key_support_person.length > 0) {
                customerObjectData?.key_support_person.forEach((userData: any) => {
                    kspData.push({label: userData.email, value: userData.email});
                })
            }

            if (customerObjectData?.owners !== undefined && customerObjectData?.owners.length > 0) {
                customerObjectData?.owners.forEach((userData: any) => {
                    ownersData.push({label: userData.email, value: userData.email});
                })
            }

            setCustomers([...customers, customerObjectData?.customer]);
            setBrandingData([...brandingData, customerObjectData?.branding_object]);

            // const selectedRegion = regionData.find((coData: any) => Number(coData.regionId) === Number(customerObjectData?.region.id));

            setValue("notes", customerObjectData?.notes === undefined ? '' : customerObjectData?.notes);
            setValue("domain", customerObjectData?.domain === undefined ? '' : customerObjectData?.domain);
            setValue("region", {label: customerObjectData?.region?.name, value: customerObjectData?.region?.id});
            setValue("customer", {label: `${customerObjectData?.customer?.id}-${customerObjectData?.customer?.name}`, value: customerObjectData?.customer?.id});
            setValue("branding_object", {
                label: customerObjectData?.branding_object?.name,
                value: customerObjectData?.branding_object?.id
            });
            setValue("owners", ownersData);
            setValue("key_support_person", kspData);

            if (customerObjectData?.customer_object_profiles !== undefined && customerObjectData?.customer_object_profiles.length > 0) {
                const custObjProfiles = customerObjectData?.customer_object_profiles.map((coData: any) => {
                    return {
                        ...coData,
                        country: coData.country?.id,
                        bfList: coData.business_functions?.filter((bfData: any) => bfData.selected)
                    }
                })

                setBpList(custObjProfiles);
            }
        }
    }

    const onSubmitData = (data: any): void => {

        console.log("SUBMIT FORM-1 ====> ", data);

        let coAddRequest = data;
        let kspOutput = [];
        let ownersOutput = [];
        let businessProfiles = [];
        let valid = "";
        let isBpChanged = false;

        if (isEmpty(data.branding_object) || data.branding_object === undefined)
            valid: 'branding_object';

        if (isEmpty(data.customer) || data.customer === undefined)
            valid: 'customer';

        if (isEmpty(data.region) || data.region === undefined)
            valid: 'region';

        if (isEmpty(valid)) {
            // const selectedCustomer = customers.find((coData: any) => String(coData.id) === String(data.customer));
            let regionName = data.region?.label?.split("(")[1]?.split(")")[0];

            if (regionName === undefined)
                regionName = data.region?.label;

            if (!isEmpty(data.key_support_person)) {
                kspOutput = data.key_support_person.map((ksp: any) => {
                    return {
                        email: ksp.label
                    }
                })
            }

            if (!isEmpty(data.owners)) {
                ownersOutput = data.owners.map((ksp: any) => {
                    return {
                        email: ksp.label
                    }
                })
            }

            //Validate Business Profile changed for Update only
            if (isUpdateCustomerData) {
                // Check the length of both list
                if (customerObjectData?.customer_object_profiles?.length !== bpList.length)
                    isBpChanged = true;

                if (!isBpChanged) {
                    bpList.forEach((bpObject: any) => {
                        if (bpObject.isNewBp)
                            isBpChanged = true;
                        else {
                            // Check If already existing profile had changed any data or not
                            let alreadyAddedProfile = customerObjectData?.customer_object_profiles?.find((copData: any) => copData.id === bpObject.id);
                            if (alreadyAddedProfile === undefined && alreadyAddedProfile === null)
                                isBpChanged = true;
                            else {
                                alreadyAddedProfile.bfList = alreadyAddedProfile.business_functions?.filter((bfData: any) => bfData.selected);

                                if (bpObject.name !== alreadyAddedProfile?.name)
                                    isBpChanged = true;

                                if (bpObject.notes !== alreadyAddedProfile?.notes)
                                    isBpChanged = true;

                                if (bpObject.country !== alreadyAddedProfile?.country?.id)
                                    isBpChanged = true;

                                if (bpObject.bfList.length !== alreadyAddedProfile?.bfList?.length)
                                    isBpChanged = true;
                            }
                        }

                    });
                }
            }
            //Validation End Business Profile changed for Update only

            if (!isEmpty(bpList)) {
                businessProfiles = bpList.map((bpObject: any) => {
                    const myUpdatedObj = {
                        ...bpObject,
                        business_functions: bpObject?.bfList.map((businessFunctionObj: any) => {
                            return {
                                id: businessFunctionObj.id
                            }
                        })
                    }
                    delete myUpdatedObj.bfList;
                    if (bpObject.isNewBp)
                        delete myUpdatedObj.id;
                    return myUpdatedObj;
                })
            }

            coAddRequest = {
                ...coAddRequest,
                key_support_person: kspOutput,
                customer: data?.customer?.value,
                owners: ownersOutput,
                customer_object_profiles: businessProfiles,
                name: isUpdateCustomerData ? customerObjectData?.name : `${regionName.toUpperCase()}_${data.customer?.name}`,
                region: Number(data.region.value),
                branding_object: Number(data.branding_object.value)
            }

            console.log("SUBMIT FORM-2 ====> ", coAddRequest);

            if (isUpdateCustomerData)
                coAddRequest['status'] = customerObjectData?.status;

            //Update Request
            if (isUpdateCustomerData) {
                if (isDirty || isBpChanged) {
                    updateCoData(coAddRequest);
                } else {
                    setMessage("Please update the form.");
                    setType(NOTIFICATION_TYPES.FAILED);
                }
            } else {
                if (isDirty) {
                    saveCoData(coAddRequest);
                } else {
                    setMessage("Please update the form.");
                    setType(NOTIFICATION_TYPES.FAILED);
                }
            }

        } else {
            setError(valid, {type: "Required"});
        }
    }

    const saveCoData = (requestObj: any): void => {
        setLoading(true);
        ServiceRequest.saveCustomerObject2(requestObj)
            .then((response) => {
                if (Number(response.status) === 200) {
                    navigate(PATHS.CUSTOMERS, {state: {success: true, isUpdate: false, data: response.data}});
                }
            })
            .catch((error) => {
                console.error(error);
                setType(NOTIFICATION_TYPES.FAILED);
                setMessage(error?.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const updateCoData = (requestObj: any): void => {
        setLoading(true);
        ServiceRequest.updateCustomerObject(customerObjectData?.id, requestObj)
            .then((response) => {
                if (Number(response.status) === 200) {
                    navigate(PATHS.CUSTOMERS, {state: {success: true, isUpdate: true, data: response.data}});
                }
            })
            .catch((error) => {
                console.error(error);
                setType(NOTIFICATION_TYPES.FAILED);
                if(error?.response?.data?.message !== undefined){
                    setMessage(error.response.data.message);
                } else {
                    setMessage(error.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validateObjectProfile = (data: any): boolean => {
        let isValid = true;
        bpList.forEach((bpDetails: any) => {
            if (bpDetails?.name?.toLowerCase() === data?.name?.toLowerCase()) {
                // if (bpDetails?.status?.toLowerCase() === data?.status?.toLowerCase()) {
                //     if (bpDetails.country === data.country) {
                //         if (bpDetails?.notes?.toLowerCase() === data?.notes?.toLowerCase()) {
                //             if (bpDetails.bfList.length === data.bfList.length) {
                if (data?.isNewBp) {
                    setMessage(t('App.page.customer.errorDuplicateCoProfile'));
                    setType(NOTIFICATION_TYPES.FAILED);
                    isValid = false;
                }
                //             }
                //         }
                //     }
                // }
            }
        })
        return isValid;
    }

    const updateBp = (data: any) => {
        let myUpdatedList: any[] = [];
        if (validateObjectProfile(data)) {
            if (data.isNewBp) {
                myUpdatedList = [...bpList, data];
            } else {
                bpList.forEach((bpData: any) => {
                    if (bpData.id === data.id)
                        myUpdatedList.push(data);
                    else
                        myUpdatedList.push(bpData);
                });
            }
            setBpList(myUpdatedList);
        }
        setBpDetailsForEdit(null);
    }

    const deleteCo = () => {
        let coAddRequest = results;
        let businessProfiles = [];

        if (!isEmpty(bpList)) {
            businessProfiles = bpList.map((bpObject: any) => {
                const myUpdatedObj = {
                    ...bpObject,
                    business_functions: bpObject?.bfList.map((businessFunctionObj: any) => {
                        return {
                            id: businessFunctionObj.id
                        }
                    })
                }
                delete myUpdatedObj.bfList;
                if (!isUpdateCustomerData)
                    delete myUpdatedObj.id;
                return myUpdatedObj;
            })
        }

        coAddRequest = {
            ...coAddRequest,
            key_support_person: customerObjectData?.key_support_person,
            owners: customerObjectData?.owners,
            customer_object_profiles: businessProfiles,
            name: customerObjectData?.name,
            region: Number(customerObjectData?.region?.id),
            branding_object: Number(customerObjectData?.branding_object?.id),
            status: "DELETED"
        }

        if (isUpdateCustomerData)
            updateCoData(coAddRequest);
    }

    const clickHistory = () => {

    }

    const myBreadCrumbList = [
        {label: 'App.breadcrumb.home', path: PATHS.HOME},
        {label: 'App.page.Customers.title', path: PATHS.CUSTOMERS},
        {label: 'App.breadcrumb.customer', path: ''},
    ];

    useEffect(() => {
        getRegionData();
        getUsersData("");
        if (isUpdateCustomerData)
            getCoData();
    }, []);

    useEffect(() => {
        if (deletedBpId !== null) {
            const updatedBpList = bpList.filter((bpDetails: any) => bpDetails.id !== deletedBpId);
            setBpList(updatedBpList);
            setDeletedBpId(null);
        }
    }, [deletedBpId])

    useEffect(() => {
        if (!isRegionLoading && !isCustomerLoading && !isBoLoading && !isUserLoading && !isCustomerObjectLoading) {
            if (isUpdateCustomerData && isFirstTimeFormUpdate) {
                updateForm();
            }
        }
    }, [isRegionLoading, isCustomerLoading, isBoLoading, isUserLoading, isCustomerObjectLoading, customerObjectData])

    useEffect(() => {
        if (!isEmpty(ownerFilter) && ownerFilter.length > 2) {
            setIsOwnerLoading(true);
            getUsersData(ownerFilter);
        } else {
            setIsOwnerLoading(true);
            getUsersData("");
        }
    }, [ownerFilter])

    useEffect(() => {
        if (isEmpty(customerFilter)) {
            getCustomerDataDebounce();
        } else if (customerFilter.length > 2)
            getCustomerDataDebounce();
    }, [customerFilter]);

    useEffect(() => {
        if (isEmpty(boFilter)) {
            getBrandingDataDebounce();
        } else if (boFilter.length > 2)
            getBrandingDataDebounce();
    }, [boFilter]);

    useEffect(() => {
        if (!isEmpty(keySupportContactFilter) && keySupportContactFilter.length > 2) {
            setIsKspLoading(true);
            getUsersData(keySupportContactFilter);
        } else {
            setIsKspLoading(true);
            getUsersData("");
        }
    }, [keySupportContactFilter]);

    const regionSelectData = useMemo(
        () =>
            regionData.map((region: any) => ({
                label: region.name,
                value: String(region.id),
               // countryApplicable: region.countryApplicable,
            })),
        [regionData]
    );

    const brandingSelectData = useMemo(
        () => {
            const myFilterList = brandingData.map((branding: any) => ({
                value: String(branding.id),
                label: branding.name,
            }));

            if (isUpdateCustomerData) {
                const myBoObject = {
                    value: String(customerObjectData?.branding_object?.id),
                    label: customerObjectData?.branding_object?.name
                }
                if (!isInArray(myBoObject, myFilterList))
                    myFilterList.push(myBoObject);
            }
            return myFilterList;
        },
        [brandingData, customerObjectData]
    );

    const customerSelectData = useMemo(
        () => {
            const myFilterList = customers.map((cust: any) => ({
                value: String(cust.id),
                label: `${String(cust.id)}-${cust.name}`,
                name: cust?.name
            }));
            if (isUpdateCustomerData) {
                const myCustomerObject = {
                    value: String(customerObjectData?.customer?.id),
                    label: `${String(customerObjectData?.customer?.id)}-${customerObjectData?.customer?.name}`,
                    name: customerObjectData?.customer?.name,
                }
                if (!isInArray(myCustomerObject, myFilterList))
                    myFilterList.push(myCustomerObject);
            }
            return myFilterList;
        },
        [customers, customerObjectData]
    );

    const ownerSelectData = useMemo(
        () => {
            const myUpdatedList = usersList.map((userData: any) => ({
                value: String(userData.email),
                label: String(userData.email),
            }));

            // if (!isEmpty(user.email)) {
            //     myUpdatedList.push({label: user.email, value: user.email})
            // }

            if (isUpdateCustomerData && customerObjectData?.owners != undefined && customerObjectData?.owners.length > 0) {
                customerObjectData?.owners.forEach((ownerData: any) => {
                    const myOwnerObject = {
                        value: ownerData?.email,
                        label: ownerData?.email
                    }
                    if (!isInArray(myOwnerObject, myUpdatedList))
                        myUpdatedList.push(myOwnerObject);
                })
            }

            const myFilterUpdatedList = myUpdatedList.filter((a, i) => myUpdatedList.findIndex((s) => a.value === s.value) === i);

            return myFilterUpdatedList;
        },
        [usersList, customerObjectData]
    );

    const kspSelectData = useMemo(
        () => {
            const myUpdatedList = usersList.map((userData: any) => ({
                value: String(userData.email),
                label: String(userData.email),
            }));

            // if (!isEmpty(user.email)) {
            //     myUpdatedList.push({label: user.email, value: user.email})
            // }

            if (isUpdateCustomerData && customerObjectData?.key_support_person != undefined && customerObjectData?.key_support_person.length > 0) {
                customerObjectData?.key_support_person.forEach((kspData: any) => {
                    const myKspObject = {
                        value: kspData?.email,
                        label: kspData?.email
                    }
                    if (!isInArray(myKspObject, myUpdatedList))
                        myUpdatedList.push(myKspObject);
                })
            }

            const myFilterUpdatedList = myUpdatedList.filter((a, i) => myUpdatedList.findIndex((s) => a.value === s.value) === i);

            return myFilterUpdatedList;
        },
        [usersList, customerObjectData]
    );

    const businessProfilePageWidth = window.innerWidth > 1600 ? 1562 : window.innerWidth * 0.8;

    return (
        <form onSubmit={handleSubmit(onSubmitData)}>
            <PageWrapper2 theme={theme.theme}>
                <TopImageWrapper theme={theme.theme} BannerImage={BannerImage}>
                    <TitleData
                        title={t('App.page.Customers.title')}
                        size={'heading1'}
                        weight={600}
                        color={'#ffffff'}/>
                </TopImageWrapper>

                <BreadCrumbsWrapper theme={theme.theme}>
                    <DhlBreadCrumbs list={myBreadCrumbList}/>
                </BreadCrumbsWrapper>

                <HeaderWrapper theme={theme.theme}>
                    <DisplayNameWrapper>
                        {isUpdateCustomerData &&
                            <DhlText size="xs" weight={300} title={t('App.page.customer.displayName')}/>}
                        <NameWrapper>
                            <DhlText size="xl" weight={900}
                                     title={!isUpdateCustomerData ? t('App.page.customer.newCustObj') : customerObjectData?.name}/>
                            {isUpdateCustomerData &&
                                <StatusWrapper status={'New'}>{customerObjectData?.status}</StatusWrapper>}
                        </NameWrapper>
                    </DisplayNameWrapper>

                    <ButtonWrapper>

                        {isUpdateCustomerData && customerObjectData?.changeHistory && <Button
                            size="md"
                            title={t('App.page.customer.history')}
                            variant="outline"
                            dataAriaLabel={t('App.page.customer.history')}
                            onClick={clickHistory}
                        />}

                        {isUpdateCustomerData && customerObjectData?.changeHistory && <VerticalDivider/>}

                        {isUpdateCustomerData && <Button
                            size="md"
                            title={t('App.common.delete')}
                            variant="outline"
                            dataAriaLabel={t('App.common.delete')}
                            onClick={deleteCo}
                        />}

                        <Button
                            size="md"
                            title={t('App.common.cancel')}
                            variant="outline"
                            dataAriaLabel={t('App.common.cancel')}
                            onClick={() => navigate(-1)}
                        />

                        <Button
                            size="md"
                            title={isUpdateCustomerData ? t('App.common.updateRequest') : t('App.common.submitRequest')}
                            variant="primary"
                            dataAriaLabel={isUpdateCustomerData ? t('App.common.updateRequest') : t('App.common.submitRequest')}
                            type="submit"
                        />
                    </ButtonWrapper>
                </HeaderWrapper>

                {/* ===== Object Details =========*/}
                <SectionWrapper theme={theme}>
                    <CardTitle size="md" weight={800} title={t('App.page.customer.objectDetails')}/>

                    <ObjectDetailsFieldWrapper>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="region"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, ...propsField}}) => (
                                        <AutoCompleteView
                                            {...propsField}
                                            loading={isRegionLoading}
                                            disabled={isUpdateCustomerData}
                                            label={`${t('App.page.Customer.regionalOperation')} *`}
                                            error={errors?.region}
                                            options={regionSelectData}
                                            onChange={(_: any, newVal: string) => {
                                                onChange(newVal);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="customer"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, ...propsField}}) => (
                                        <AutoCompleteView
                                            {...propsField}
                                            loading={isCustomerLoading}
                                            disabled={isUpdateCustomerData}
                                            label={`${t('App.page.customer.customer')} *`}
                                            error={errors?.customer}
                                            options={customerSelectData}
                                            onChange={(_: any, newVal: string) => {
                                                onChange(newVal);
                                            }}
                                            inputValue={customerFilter}
                                            onInputChange={(_: any, newVal: string) => {
                                                setCustomerFilter(newVal)
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="branding_object"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, ...propsField}}) => (
                                        <AutoCompleteView
                                            {...propsField}
                                            loading={isBoLoading}
                                            label={`${t('App.page.Customer.brandingObject')} *`}
                                            error={errors?.branding_object}
                                            options={brandingSelectData}
                                            onChange={(_: any, newVal: string) => {
                                                onChange(newVal);
                                            }}
                                            inputValue={boFilter}
                                            onInputChange={(_: any, newVal: string) => {
                                                setBoFilter(newVal)
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="domain"
                                    control={control}
                                    defaultValue=""
                                    render={({field: {onChange, ...propsField}}) => (
                                        <TextField
                                            {...propsField}
                                            sx={{width: "100%", marginTop: "14px"}}
                                            label={`${t('App.page.customer.domain')}`}
                                            error={Boolean(errors?.domain)}
                                            InputLabelProps={{shrink: false}}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                onChange(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>

                        <Divider theme={theme}/>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="owners"
                                    control={control}
                                    render={({field: {onChange, ...propsField}}) => (
                                        <DhlMultiSelect
                                            {...propsField}
                                            multiple
                                            loading={isUserLoading && isOwnerLoading}
                                            label={t('App.page.customer.owner')}
                                            error={false}
                                            options={ownerSelectData}
                                            onChange={(_: any, newVal: string) => {
                                                onChange(newVal);
                                            }}
                                            onInputChange={(_: any, newVal: string) => {
                                                setOwnerFilter(newVal);
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="key_support_person"
                                    control={control}
                                    render={({field: {onChange, ...propsField}}) => (
                                        <DhlMultiSelect
                                            {...propsField}
                                            multiple
                                            loading={isUserLoading && isKspLoading}
                                            label={t('App.page.Customer.keySupportContact')}
                                            error={false}
                                            options={kspSelectData}
                                            onChange={(_: any, newVal: string) => {
                                                onChange(newVal);
                                            }}
                                            onInputChange={(_: any, newVal: string) => {
                                                setKeySupportContactFilter(newVal)
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="notes"
                                    control={control}
                                    render={({field: {...propsField}}) => {
                                        // @ts-ignore
                                        return <FormTextArea
                                            {...propsField}
                                            minRows={8}
                                            maxRows={8}
                                            //@ts-ignore
                                            placeholder={t('App.page.Customer.notes')}
                                        />
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </ObjectDetailsFieldWrapper>

                </SectionWrapper>

                {/* ====== Customer Object Business Profile ====== */}
                <SectionWrapper theme={theme}>

                    <HeaderTitleWrapper>
                        <CardTitle size="md" weight={800} title={t('App.page.customer.custObjProfile')}/>

                        <DhlButton
                            title={t("App.page.customer.addNewProfile")}
                            size={"md"}
                            isDisabled={isEmpty(results.customer)}
                            onClick={() => setIsBpOpen(true)}/>
                    </HeaderTitleWrapper>

                    <MyStaticTable
                        columnDefs={columnDefs}
                        dataKey="data"
                        totalKey="total"
                        id="bpData"
                        resizeColumn={true}
                        autoSizeColumns={false}
                        rowData={bpList}
                        rowHeight={getHeight(1.5)}
                        isDetail={false}
                        sizeColumnsToFit={'forced'}
                        rowClass={'static-content'}
                    />

                </SectionWrapper>

                <BpDrawer
                    open={isBpOpen}
                    direction={'right'}
                    size={businessProfilePageWidth}>
                    <ProfilePage
                        isOpen={isBpOpen}
                        onClose={() => {
                            setIsBpOpen(false);
                            setBpDetailsForEdit(null);
                        }}
                        customer={results.customer}
                        originalWidth={businessProfilePageWidth}
                        bpDetailsForEdit={bpDetailsForEdit}
                        updateBp={updateBp}/>
                </BpDrawer>

            </PageWrapper2>
        </form>
    );
}

export default CoPage;
