/* eslint-disable */
import React, {useCallback, useContext, useEffect, useReducer, useState} from 'react';
import styled from 'styled-components';
import {ThemeContext} from '../../styles/ThemeContext';
import {device} from '../../styles/Device';
import ServiceRequest from '../../service/ServiceRequest';
import {LoadingContext} from '../../contexts/LoadingContext';
import {NOTIFICATION_TYPES, NotificationContext,} from '../../contexts/NotificationContext';
import {PATHS} from '../App/constants';
import FileSaver from 'file-saver';
import {BreadCrumbsWrapper, HeaderWrapper, PageWrapper2, TitleData, TopImageWrapper,} from "../common";
import BannerImage from '../../assets/images/CoHeader.png';
import DhlBreadCrumbs from "../../components/DhlBreadCrumbs";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {DhlInputField} from "@dhl-official/ui-libraries/react-library";
import {getHeight} from "../../components/Table/utils";
import DhlButton from "../../components/DhlButton";
import {isEmpty} from "lodash";
import {IconContext} from "../../assets/IconContext";
import StaticTable from "../../components/Table/StaticTable";
import {GridApi, ModelUpdatedEvent} from "ag-grid-community";
import {useDebounce} from "../../utils/debounce-hooks";
import DhlText from "../../components/DhlText";
import UserDetails from "./components/UserDetails";
import DateTimeLabel from "../../components/DateTimeLabel";
import {TableCustomerObjectProfile} from "../CoList/components/TableCustomerObjectProfile";
// import response from "./response.json"

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 60px;
  padding: 10px;
  margin-left: 10px;
  margin-top: 14px;
  align-items: center;
  @media ${device.mobile} {
  width: 93%;
  }
  @media ${device.tablet} {
  width: 99%;
  }
  background-color: ${(props) => props.theme.background};
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 4px;
  margin-left: 10px;
  align-items: center;
  @media ${device.mobile} {
  width: 93%;
  }
  @media ${device.tablet} {
  width: 99%;
  }
  background-color: ${(props) => props.theme.background};
`;

const DhlInput = styled(DhlInputField)`
  width: 100%;
`;

const TopButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
  min-width: 100%;
  background-color: ${(props) => props.theme.background};
`;

const MultilineView = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 50px;
  padding: 4px;
  overflow-wrap: break-word;
  line-height: 16px;
  font-size: 14px;
`;

const MultilineDateView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 50px;
  padding: 4px;
  margin-left: 4px;
  line-height: 16px;
  font-size: 14px;
`;

const MultilineDateLabel = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  font-size: 14px;
`;

function UsersPage(): JSX.Element {
    const theme = useContext(ThemeContext);
    const {setLoading} = useContext(LoadingContext);
    const {setType, setMessage} = useContext(NotificationContext);
    const {getIcon} = useContext(IconContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState<GridApi>();
    const [visibleDetailsRowData, setVisibleDetailsRowData] = useState<any>({});
    const [rowData, setRowData] = useState<any[]>([]);
    const [filterModel, setFilterModel] = useState({});
    const [sortModel, setSortModel] = useState<any[]>([]);
    const [paginationModel, setPaginationModel] = useState<any>({});
    const [totalResults, setTotalResults] = useState(0);
    const [topFilter, setTopFilter] = useState('');
    const [isDataDirty, setIsDirty] = useState(false);
    const [isRowLoading, setIsRowLoading] = useState(true);
    const [isDetailsOpening, setIsDetailsOpening] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const myBreadCrumbList = [
        {label: 'App.breadcrumb.home', path: PATHS.HOME},
        {label: 'App.page.customer.users', path: ''},
    ];

    useEffect(() => {
        getRowDataDebounce();
    }, []);

    useEffect(() => {
        if (isDataDirty) {
            getRowDataDebounce();
        }
    }, [topFilter]);

    useEffect(() => {
        if (isDataDirty) {
            getRowDataDebounce();
        }
    }, [filterModel]);

    useEffect(() => {
        if (!isEmpty(rowData)) {
            getRowDataDebounce();
        }
    }, [sortModel]);

    useEffect(() => {
        if (!isEmpty(rowData)) {
            getRowDataDebounce();
        }
    }, [paginationModel]);

    useEffect(() => {
        console.log("ROW DATA UPDATE => ", rowData);
        setTimeout(() => {
            if (rowData?.length > 0 && totalResults > 0) {
                gridApi?.redrawRows();
                gridApi?.refreshClientSideRowModel();
                forceUpdate();
            }
        },400);
    }, [rowData, totalResults]);

    useEffect(() => {
        if (!isRowLoading)
            setLoading(false);
    }, [isRowLoading]);

    const getCoParams = (isExport: boolean): any => {
        const request: any = {
            filter: filterModel,
            page: isExport ? 0 : paginationModel?.pageNo === undefined ? 0 : paginationModel?.pageNo - 1,
            size: isExport ? 10000 : paginationModel?.pageSize === undefined ? 10 : paginationModel?.pageSize,
            trigger: topFilter,
            sort: sortModel
        }

        let myRequestParam = ``;

        console.log("REQUEST MODEL ==> ", request);

        // PAGE CONFIG OFFSET AND SIZE
        if (!isEmpty(request) && 'page' in request && 'size' in request) {
            myRequestParam = `offset=${request.page}&limit=${request.size}`;
        }

        // SORTING
        if ('sort' in request && sortModel.length > 0) {
            let sortingType = '';
            let sortingField = ''
            sortModel.forEach((columnData: any) => {
                if (columnData.sort !== null && !isEmpty(columnData.sort)) {
                    sortingType = columnData.sort.toUpperCase();
                    sortingField = columnData.colId.toUpperCase();
                }
            })
            myRequestParam = `${myRequestParam}&sortingType=${sortingType}&sortingDscumMyscUsersEnum=${sortingField}`;
        }

        // TOP FILTER SEARCH
        if (!isEmpty(request.trigger)) {
            myRequestParam = `${myRequestParam}&topFilter=${request.trigger}`;
        }

        // COLUMNS FILTER
        if (!isEmpty(request.filter) && 'email' in request.filter) {
            myRequestParam = `${myRequestParam}&nameFilter=${request.filter.email?.filter}`;
        }

        if (!isEmpty(request.filter) && 'first_name' in request.filter) {
            myRequestParam = `${myRequestParam}&firstNameFilter=${request.filter.first_name?.filter}`;
        }

        if (!isEmpty(request.filter) && 'last_name' in request.filter) {
            myRequestParam = `${myRequestParam}&lastNameFilter=${request.filter.last_name?.filter}`;
        }

        if (!isEmpty(request.filter) && 'created_by' in request.filter) {
            myRequestParam = `${myRequestParam}&createdFilter=${request.filter.created_by?.filter}`;
        }

        if (!isEmpty(request.filter) && 'updated_by' in request.filter) {
            myRequestParam = `${myRequestParam}&updatedFilter=${request.filter.updated_by?.filter}`;
        }

        return myRequestParam;
    }

    const getRowDataDebounce = useDebounce(() => {
        if (location.pathname === PATHS.USERS) {
            const myQueryParams = getCoParams(false);
            setIsRowLoading(true);
            setLoading(true);
            ServiceRequest.getDscUmUsersList(myQueryParams)
                .then((response) => {
                    setTotalResults(response?.data?.total_results);
                    setRowData(response.data?.list);
                })
                .catch((error) => {
                    setType(NOTIFICATION_TYPES.FAILED);
                    setMessage(error?.response?.data?.message);
                })
                .finally(() => {
                    setIsRowLoading(false);
                    setIsDirty(true);
                });
        }
    }, 1000);

    const exportUsers = (): void => {
        const myQueryParams = getCoParams(true);
        setLoading(true);
        ServiceRequest.exportDscUmUsers(myQueryParams)
            .then((response) => {
                if (response.status === 200) {
                    let fileName = 'export.xlsx';
                    if (response?.headers?.['content-disposition']) {
                        const val = response.headers['content-disposition'].split(';');
                        if (val.length === 2 && val[1].trim().startsWith('filename='))
                            fileName = val[1].trim().substring('filename='.length);
                    }
                    // @ts-ignore
                    FileSaver.saveAs(response.data, fileName);
                } else {
                    setType(NOTIFICATION_TYPES.FAILED);
                    setMessage(String(response));
                }
            })
            .catch((error) => {
                setType(NOTIFICATION_TYPES.FAILED);
                setMessage(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // ====== START AG GRID TABLE METHOD ========== //

    const RenderView = (row: any): any => {
        const {data, value, path, setGridApi} = row;
        setGridApi(row?.api);

        if (data !== undefined)
            return (
                <MultilineView theme={theme} onClick={() => {
                    setVisibleDetailsRowData(data)
                    navigate(path.replace(':id', data.email));
                    setIsDetailsOpening(true);
                }}>
                    {value}
                </MultilineView>
            );
        else return null;
    }

    const RenderDateNameView = (row: any): any => {
        const {data, value, path, field, setGridApi} = row;
        setGridApi(row?.api);

        if (data !== undefined) {
            let valueByToShow = field === 'created_by' ? data?.created_by === null ? "-" : data?.created_by : data?.updated_by === null ? "-" : data?.updated_by;
            let valueDateToShow = field === 'created_by' ? data?.created_date  : data?.updated_date;
            return (
                <MultilineDateView theme={theme} onClick={() => {
                    setVisibleDetailsRowData(data)
                    navigate(path.replace(':id', data.email));
                    setIsDetailsOpening(true);
                }}>
                    <MultilineDateLabel>{value}</MultilineDateLabel>
                    {valueDateToShow === null ? <MultilineDateLabel>{""}</MultilineDateLabel> : <DateTimeLabel datetime={valueDateToShow} textSize={"14px"}/>}
                </MultilineDateView>
            );
        } else return null;
    }

    const getColumnDefs = (): any => {
        return [
            {
                headerName: t('App.page.customer.email'),
                field: 'email',
                // pinned: true,
                cellRendererParams: {
                    path: PATHS.USERS,
                    setGridApi,
                    field: "email"
                },
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                cellRenderer: RenderView,
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            },
            {
                headerName: t('App.page.customer.firstName'),
                field: 'first_name',
                sortable: true,
                cellRendererParams: {
                    path: PATHS.USERS,
                    setGridApi,
                    field: "firstName"
                },
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                cellRenderer: RenderView,
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            },
            {
                headerName: t('App.page.customer.lastName'),
                field: 'last_name',
                sortable: true,
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                cellRenderer: RenderView,
                cellRendererParams: {
                    path: PATHS.USERS,
                    setGridApi,
                    field: "lastName"
                },
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            },
            {
                headerName: t('App.page.upload.createdBy'),
                field: 'created_by',
                sortable: true,
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                cellRenderer: RenderDateNameView,
                cellRendererParams: {
                    path: PATHS.USERS,
                    setGridApi,
                    field: "created_by"
                },
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            },
            {
                headerName: t('App.page.Users.updatedBy'),
                field: 'updated_by',
                sortable: true,
                floatingFilter: true,
                suppressMenu: true,
                wrapText: true,
                autoHeight: true,
                cellRenderer: RenderDateNameView,
                cellRendererParams: {
                    path: PATHS.USERS,
                    setGridApi,
                    field: "updated_by"
                },
                floatingFilterComponent: 'textFloatingFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                    debounceMs: 600
                }
            }
        ];
    };

    const onModelUpdated = (event: ModelUpdatedEvent<any>): void => {
        setGridApi(event.api);
    }

    const getRowHeight = (params: any): any => {
            return getHeight(2.5);
    };

    const fullWidthCellRenderer = (): any => {
        return TableCustomerObjectProfile;
    };

    const isFullWidth = (data: any): boolean => {
        return data.isDetailRow === undefined ? false : data.isDetailRow;
    };

    const isFullWidthRow = useCallback(
        (params: any): any => isFullWidth(params?.rowNode?.data),
        []
    );

    // ====== END AG GRID METHOD ======= //

    return (
        <PageWrapper2 theme={theme.theme}>
            <TopImageWrapper theme={theme.theme} BannerImage={BannerImage}>
                <TitleData
                    title={t('App.page.customer.users')}
                    size={'heading1'}
                    weight={600}
                    color={'#ffffff'}/>
            </TopImageWrapper>

            <BreadCrumbsWrapper theme={theme.theme}>
                <DhlBreadCrumbs list={myBreadCrumbList}/>
            </BreadCrumbsWrapper>

            <HeaderWrapper>
                <DhlText size="md" weight={800} title={t('App.page.customer.users')}/>
            </HeaderWrapper>

            <SearchWrapper theme={theme.theme}>
                <DhlInput
                    searchIcon
                    value={topFilter}
                    inputEvent={(e: any) => setTopFilter(e.target.value)}
                    variant={{
                        label: "",
                        placeholder: t('App.page.Users.search'),
                        type: "animated"
                    }}/>
            </SearchWrapper>

            <TableWrapper>
                <TopButtons>
                    <DhlButton
                        size="sm"
                        title={t('App.page.Customers.export')}
                        variant="text"
                        icon={getIcon('download')}
                        iconOrientation={'left'}
                        dataAriaLabel={t('App.page.Customers.export')}
                        onClick={() => exportUsers()}
                    />
                </TopButtons>

                <StaticTable
                    columnDefs={getColumnDefs()}
                    id="UsersListPage"
                    rowData={rowData}
                    totalResult={totalResults}
                    resizeColumn={false}
                    autoSizeColumns={false}
                    sizeColumnsToFit
                    showActionPanel={false}
                    suppressPaginationPanel={true}
                    suppressScrollOnNewData={true}
                    showCustomPagination={true}
                    getRowHeight={getRowHeight}
                    onModelUpdated={onModelUpdated}
                    onFilterChanged={(e: any) => setFilterModel(e.api.getFilterModel())}
                    onSortChanged={(e: any) => setSortModel(e.columnApi.getColumnState())}
                    onPaginationChanged={(e: any) => setPaginationModel(e)}
                    isFullWidthRow={isFullWidthRow}
                    fullWidthCellRenderer={fullWidthCellRenderer}
                    rowClass={'static-content'}
                />

            </TableWrapper>

            <UserDetails
                isMenuOpen={isDetailsOpening}
                closeMenu={() => setIsDetailsOpening(false)}
                userData={visibleDetailsRowData}/>

        </PageWrapper2>
    );
}

export default UsersPage;
