import React, { useContext } from 'react';
import styled from 'styled-components';
import { DhlButton as ButtonComponent } from '@dhl-official/ui-libraries/react-library';
import { ThemeContext } from '../../styles/ThemeContext';
import { TypeButton } from './TypeButton';

const ButtonWrapper = styled(ButtonComponent)`
  font-size: ${(props) => props.theme.fontSize[props.textSize]};
`;

const DhlButton: React.FC<TypeButton> = (props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <ButtonWrapper {...props} theme={theme}>
      {' '}
      {props.title}{' '}
    </ButtonWrapper>
  );
};

export default DhlButton;
