/* eslint-disable react/prop-types */
import React from 'react';
import DateTimeLabel from '../../DateTimeLabel';

class DateColumn extends React.PureComponent {
    render() {
        const {value, size = '13px'} = this.props;
        return <DateTimeLabel datetime={value} textSize={size}/>;
    }
}

export default DateColumn;
