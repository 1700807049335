/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import DhlText from '../../components/DhlText';
import { useTranslation } from 'react-i18next';
import { device } from '../../styles/Device';
import DhlButton from '../../components/DhlButton';
import ServiceRequest from '../../service/ServiceRequest';
import { LoadingContext } from '../../contexts/LoadingContext';
import {
  NOTIFICATION_TYPES,
  NotificationContext,
} from '../../contexts/NotificationContext';

import { PATHS, ROLES } from '../App/constants';
import CellLink from '../../components/Table/cell/Link';

import Table from '../../components/Table';
import { getHeight } from '../../components/Table/utils';
import FileSaver from 'file-saver';
import UpdatedCell from '../../components/Table/cell/Updated';
import { useNavigate } from 'react-router';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';
import { UserContext } from '../../contexts/UserContext';
import CreatedCell from '../../components/Table/cell/Created';

const PageWrapper = styled.div`
  @media ${device.mobile} {
    min-height: 50vh;
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    min-height: 65vh;
    min-width: 70vh;
    flex-wrap: nowrap;
  }
  @media ${device.laptop} {
    min-height: 65vh;
    min-width: 70vh;
    flex-wrap: nowrap;
  }
  @media ${device.desktop} {
    min-height: 78vh;
    min-width: 70vh;
    flex-wrap: nowrap;
  }
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Button = styled(DhlButton)`
  margin: 10px;
`;

const DEFAULT_FILTER_PARAMS = {
  buttons: ['apply', 'reset'],
  filterOptions: [
    'contains',
    'notContains',
    'startsWith',
    'endsWith',
    'equals',
    'notEqual',
  ],
};

export function getColumnDefs(t: any): any {
  const columnDefs = [
    {
      headerName: t('App.page.Categories.name'),
      field: 'name',
      cellRenderer: CellLink,
      floatingFilter: true,
      minWidth: 150,
      cellRendererParams: {
        path: PATHS.CATEGORIES_DETAIL_DETAIL,
      },
      filterParams: DEFAULT_FILTER_PARAMS,
    },{
      headerName: t('App.page.Categories.description'),
      field: 'description',
      cellRenderer: CellLink,
      floatingFilter: true,
      minWidth: 150,
      cellRendererParams: {
        path: PATHS.CATEGORIES_DETAIL_DETAIL,
      },
      filterParams: DEFAULT_FILTER_PARAMS,
    },
    {
      headerName: t('App.page.Categories.createdBy'),
      field: 'createdBy',
      floatingFilter: true,
      sortable: true,
      minWidth: 150,
      filterParams: DEFAULT_FILTER_PARAMS,
    },
    {
      headerName: t('App.page.Categories.createdOn'),
      field: 'created',
      sortable: true,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      floatingFilterComponent: 'dateFloatingFilter',
      cellRenderer: CreatedCell,
      minWidth: 150,
      filterParams: {
        buttons: ['apply', 'reset'],
        filterOptions: ['equals', 'notEqual'],
      },
    },
    {
      headerName: t('App.page.Categories.updatedBy'),
      field: 'updatedBy',
      floatingFilter: true,
      sortable: true,
      minWidth: 150,
      filterParams: DEFAULT_FILTER_PARAMS,
    },
    {
      headerName: t('App.page.Categories.updatedOn'),
      field: 'updated',
      sortable: true,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      minWidth: 150,
      floatingFilterComponent: 'dateFloatingFilter',
      cellRenderer: UpdatedCell,
      filterParams: {
        buttons: ['apply', 'reset'],
        filterOptions: ['equals', 'notEqual'],
      },
    },
  ];
  return columnDefs;
}

function Categories(): JSX.Element {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const [filterModel, setFilterModel] = useState(null);
  const { setLoading } = useContext(LoadingContext);
  const { setType, setMessage } = useContext(NotificationContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const download = (): void => {
    setLoading(true);
    ServiceRequest.  exportCategory({ filter: filterModel })
      .then((response) => {
        if (response.status === 200) {
          let fileName = 'export.xlsx';
          if (response.headers?.['content-disposition']) {
            const val = response.headers['content-disposition'].split(';');
            if (val.length === 2 && val[1].trim().startsWith('filename='))
              fileName = val[1].trim().substring('filename='.length);
          }
          FileSaver.saveAs(response.data, fileName);
        } else {
          setType(NOTIFICATION_TYPES.FAILED);
          setMessage(String(response));
        }
      })
      .catch((error) => {
        setType(NOTIFICATION_TYPES.FAILED);
        setMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const myBreadCrumbList = [
    { label: 'App.breadcrumb.home', path: PATHS.HOME },
    { label: 'App.page.Categories.title', path: '' },
  ];

  return (
    <PageWrapper theme={theme}>
      <TopWrapper theme={theme}>
        <DhlBreadCrumbs list={myBreadCrumbList} />
      </TopWrapper>

      <HeaderWrapper>
        <DhlText size="md" weight={800} title={t('App.page.Categories.title')} />

        <ButtonWrapper>
          {user.roles?.includes(ROLES.MSCADMIN) && (
            <Button
              variant="outline"
              size="sm"
              title={t('App.page.Categories.create')}
              dataAriaLabel={t('App.page.Categories.create')}
              onClick={() => navigate(PATHS.CATEGORIES_ADD)}
            />
          )}
          <Button
            size="sm"
            variant="primary"
            title={t('App.page.Categories.export')}
            dataAriaLabel={t('App.page.Categories.export')}
            onClick={() => download()}
          />
        </ButtonWrapper>
      </HeaderWrapper>

      <Table
        columnDefs={getColumnDefs(t)}
        dataKey="data"
        totalKey="total"
        id="categoriesPage"
        resizeColumn={false}
        autoSizeColumns={false}
        sizeColumnsToFit
        showActionPanel={false}
        getData={async (request: any) =>
          await ServiceRequest.getCategories(request)
        }
        floatingFilter
        rowHeight={getHeight(1)}
        onFilterChanged={(e: any) => setFilterModel(e.api.getFilterModel())}
      />
    </PageWrapper>
  );
}

export default Categories;
