/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import { device } from '../../styles/Device';
import { UploadType } from './UploadType';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import DhlText from '../DhlText';

const PageBlock = styled.div`
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    flex-wrap: nowrap;
  }
  width: 100%;
  padding: 8px;
  display: flex;
  z-index: 1;
  border: 1px solid ${(props) => props.theme.divider};
  flex-direction: row;
  border-radius: 4px;
  background-color: ${(props) => props.theme.secondaryBg};
`;

const DropBox = styled.div`
  @media ${device.mobile} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 20%;
  }
  height: 100%;
  min-width: 150px;
  min-height: 70px;
  padding: 18px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-style: dashed;
  border-width: 1px;
  text-align: center;
`;

const FilesBox = styled.div`
  @media ${device.mobile} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 80%;
  }
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const FileBox = styled.div`
  display: block;
  position: relative;
  border: 1px solid ${(props) => props.theme.color.grey[300]};
  margin: 10px;
  padding: 10px;
`;

const FileCross = styled(FontAwesomeIcon)`
  position: absolute;
  padding: 10px;
  right: 0;
  top: 0;
`;

const Upload: React.FC<UploadType> = (props) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { isUploading, onFileAdded, files, setFiles } = props;

  const removeFile = (file: any) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };
  return (
    <PageBlock theme={theme}>
      <Dropzone
        accept={{
          '': ['.xlsx', '.xls'],
        }}
        disabled={isUploading}
        onDrop={(file: any) => onFileAdded(file)}
        maxFiles={4}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
              <DropBox theme={theme}>
                <FontAwesomeIcon icon="paperclip" size="2x" />
                <DhlText size="xs" title={t('App.page.upload.uploadText')} />
              </DropBox>
            }
          </div>
        )}
      </Dropzone>

      <FilesBox>
        {files.map((fileData) => {
          const { name } = fileData[0];
          return (
            <FileBox key={`${name}_file`} theme={theme}>
              <FontAwesomeIcon icon={faFile} />
              <DhlText size="xs" title={name} />
              <FileCross icon={faXmark} onClick={removeFile(fileData)} />
            </FileBox>
          );
        })}
      </FilesBox>
    </PageBlock>
  );
};

export default Upload;
