/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import TopContent from './components/TopContent';
import BottomContent from './components/BottomContent';
import { device } from '../../styles/Device';
import { UserContext } from '../../contexts/UserContext';
import { logAuditEvent } from '../../service/ServiceRequest';
import { ACTION_BUSINESS_SERVICE } from '../App/constants';
import ProfilePopup from './components/ProfilePopup';

const Wrapper = styled.div`
  width: 100%;
  @media ${device.mobile} {
    min-height: 50vh;
  }
  @media ${device.tablet} {
    min-height: 70vh;
  }
  @media ${device.laptop} {
    min-height: 68vh;
  }
  @media ${device.desktop} {
    min-height: 80vh;
  }
  background-color: ${(props) => props.theme.secondaryBg};
  align-items: baseline;
  display: flex;
  flex-direction: column;
`;

function PrivateHome(): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user?.isRedirected) {
      const { apps = [] } = user;
      if (apps?.length === 1) {
        const app = apps[0];
        if (
          app.childApps === null &&
          app.link !== null /*  || apps[0].childApps.length === 1 */
        ) {
          void logAuditEvent(ACTION_BUSINESS_SERVICE, app.id);
          if (app.integrated !== null && Boolean(app.integrated)) {
            window.open(app.link, '_self');
          } else {
            window.open(app.link, '_blank', 'noopener,noreferrer');
          }
          /* const childItem = apps[0].childApps[0];
          if (childItem?.link !== undefined && childItem?.link !== null) {
            void logAuditEvent(ACTION_BUSINESS_SERVICE, childItem.id);
            if (childItem.integrated != null && Boolean(childItem.integrated)) {
              window.open(childItem.link, '_self');
            } else {
              window.open(childItem.link, '_blank', 'noopener,noreferrer');
            }
          } */
        }
      }
    }
  }, []);

  return (
    <Wrapper theme={theme}>
      {Boolean(user.brandingObject?.showBannerOnMainPage || user.brandingObject === undefined || user.brandingObject.showBannerOnMainPage === undefined) && <TopContent />}
      {!user.profileUpdated && !user?.impersonated && <ProfilePopup />}
      <BottomContent />
    </Wrapper>
  );
}

export default PrivateHome;
