import {ReactNode} from 'react';
import MoonDark from './images/moonLight.svg';
import SunLight from './images/sun.svg';
import SaturationLight from './images/saturationLight.svg';
import SaturationDark from './images/saturationDark.svg';
import ContrastLight from './images/contrastLight.svg';
import ContrastDark from './images/contrastDark.svg';
import upArrowLight from './images/upArrowLight.svg';
import upArrowDark from './images/upArrowDark.svg';
import downArrowLight from './images/downArrowLight.svg';
import checkLight from './images/checkmark_light.svg';
import checkDark from './images/checkmark_dark.svg';
import cancelLight from './images/cancel_light.svg';
import cancelDark from './images/cancel_dark.svg';
import infoLight from './images/info_light.svg';
import infoDark from './images/info_dark.svg';
import MyScLogo from './images/mysc_logo.png';
import Deactivated from './images/deactivated.svg';
import Activated from './images/activated.svg';
import Activated2 from './images/activated2.svg';
import ApplicationIcon from './images/appIcon.svg';
import MoreVerticalDark from './images/moreVerticalDark.svg';
import MoreVerticalLight from './images/moreVerticalLight.svg';
import UserProfileLight from './images/userCircleLight.svg';
import UserProfileDark from './images/userCircleDark.svg';
import ProfileLight from './images/user_light.svg';
import ProfileDark from './images/user_dark.svg';
import HelpLight from './images/help_light.svg';
import HelpDark from './images/help_dark.svg';
import LogoutLight from './images/logout_light.svg';
import LogoutDark from './images/logout_dark.svg';
import GroupLight from './images/groupLight.svg';
import GroupDark from './images/groupDark.svg';
import SearchLight from './images/searchLight.svg';
import SearchDark from './images/searchDark.svg';
import cartLight from './images/shoppingCartLight.svg';
import cartDark from './images/shoppingCartDark.svg';
import homeLight from './images/homeLight.svg';
import homeDark from './images/homeDark.svg';
import UserGroupLight from './images/userGroupLight.svg';
import UserGroupDark from './images/userGroupDark.svg';
import TemplateLight from './images/TemplateLight.svg';
import TemplateDark from './images/TemplateDark.svg';
import BriefcaseLight from './images/BriefcaseLight.svg';
import BriefcaseDark from './images/BriefcaseDark.svg';
import GlobeLight from './images/GlobeLight.svg';
import GlobeDark from './images/GlobeDark.svg';
import TagLight from './images/TagLight.svg';
import TagDark from './images/TagDark.svg';
import DHLResource from './images/dhlResource.svg';
import MenuDark from './images/menuDark.svg';
import DownloadRed from './images/download_arrow_red.svg';

import DoubleArrowLeft from './images/da_left_icon.svg';
import DoubleArrowRight from './images/da_right_icon.svg';
import SingleArrowLeft from './images/a_left_icon.svg';
import SingleArrowRight from './images/a_right_icon.svg';
import crossDark from './images/crossDark.svg';
import crossLight from './images/crossLight.svg';
import VideoPlay from './images/videoPlay.svg';
import Rocket from './images/rocket.svg';
import EditLight from './images/editLight.svg';
import EditDark from './images/editDark.svg';
import ChevronDownDark from './images/chevron_down_dark.svg';
import ChevronDownLight from './images/chevron_down_light.svg';
import MyScPlaceholder from './images/mysc_placeholder.png';
import Feedback from './images/feedback.svg';
import Delete from './images/delete.svg';
import Check from './images/checkIcon.svg';
import RedPencil from './images/red_pencil.svg';
import RedClose from './images/ic_close.png';
import errorCircle from './images/errorCircle.svg';

export function fetchIcon(iconName: string, isDarkTheme: boolean): ReactNode {
    switch (iconName) {
        case 'moon':
            if (isDarkTheme) return SunLight;
            else return MoonDark;

        case 'saturation':
            if (isDarkTheme) return SaturationLight;
            else return SaturationDark;

        case 'contrast':
            if (isDarkTheme) return ContrastLight;
            else return ContrastDark;

        case 'upArrow':
            if (isDarkTheme) return upArrowLight;
            else return upArrowDark;

        case 'downArrow':
            if (isDarkTheme) return downArrowLight;
            else return downArrowLight;

        case 'check':
            if (isDarkTheme) return checkLight;
            else return checkDark;

        case 'cancel':
            if (isDarkTheme) return cancelLight;
            else return cancelDark;

        case 'info':
            if (isDarkTheme) return infoLight;
            else return infoDark;

        case 'moreVertical':
            if (isDarkTheme) return MoreVerticalLight;
            else return MoreVerticalDark;

        case 'myScLogo':
            return MyScLogo;

        case 'deactivated':
            return Deactivated;

        case 'activated':
            return Activated;

        case 'activated2':
            return Activated2;

        case 'applicationIcon':
            return ApplicationIcon;

        case 'userProfile':
            if (isDarkTheme) return UserProfileLight;
            else return UserProfileDark;

        case 'profile':
            if (isDarkTheme) return ProfileLight;
            else return ProfileDark;

        case 'help':
            if (isDarkTheme) return HelpLight;
            else return HelpDark;

        case 'logout':
            if (isDarkTheme) return LogoutLight;
            else return LogoutDark;

        case 'fas fa-user':
            if (isDarkTheme) return UserGroupLight;
            else return UserGroupDark;

        case 'fas fa-users':
            if (isDarkTheme) return GroupLight;
            else return GroupDark;

        case 'fas fa-search':
            if (isDarkTheme) return SearchLight;
            else return SearchDark;

        case 'fas fa-shopping-cart':
            if (isDarkTheme) return cartLight;
            else return cartDark;

        case 'fas fa-list-alt':
            if (isDarkTheme) return TemplateLight;
            else return TemplateDark;

        case 'fas fa-crosshairs':
            if (isDarkTheme) return BriefcaseLight;
            else return BriefcaseDark;

        case 'fas fa-user-plus':
            if (isDarkTheme) return ProfileLight;
            else return ProfileDark;

        case 'fas fa-globe':
            if (isDarkTheme) return GlobeLight;
            else return GlobeDark;

        case 'fas fa-sticky-note':
            if (isDarkTheme) return TagLight;
            else return TagDark;

        case 'home':
            if (isDarkTheme) return homeLight;
            else return homeDark;

        case 'edit':
            if (isDarkTheme) return EditLight;
            else return EditDark;

        case 'ChevronDown':
            if (isDarkTheme) return ChevronDownDark;
            else return ChevronDownLight;

        case 'resource':
            return DHLResource;

        case 'sideMenu':
            return MenuDark;

        case 'cross':
            if (isDarkTheme) return crossDark;
            else return crossLight;

        case 'videoPlay':
            return VideoPlay;

        case 'rocket':
            return Rocket;

        case 'MyScPlaceholder':
            return MyScPlaceholder;

        case 'Feedback':
            return Feedback;

        case 'download':
            return DownloadRed;

        case 'doubleArrowLeft':
            return DoubleArrowLeft;

        case 'doubleArrowRight':
            return DoubleArrowRight;

        case 'singleArrowLeft':
            return SingleArrowLeft;

        case 'singleArrowRight':
            return SingleArrowRight;

        case 'delete':
            return Delete;

        case 'checkIcon':
            return Check;

        case 'RedPencil':
            return RedPencil;

        case 'RedClose':
            return RedClose;            

        case 'crossLight':
            return crossLight;         

        case 'crossDark':
            return crossDark;

        case 'errorCircle':
            return errorCircle;     

        default:
            return null;
    }
}
