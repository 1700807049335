export const toBase64 = async (file: Blob): Promise<string> =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const resizeImage = async (base64img: string, h: number, w: number, enabled: boolean): Promise<string> =>
  await new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64img;
    img.onload = () => {
      let height = h;
      let width = w;

      if (img.height <= height && img.width <= width) {
        return resolve(base64img);
      }
      if (!enabled) {
        return reject(new Error('Image constraints violated'));
      }

      // Make sure the width and height preserve the original aspect ratio and adjust if needed
      if (img.height > img.width) {
        width = Math.floor(height * (img.width / img.height));
        if (width > w) {
          width = w;
          height = Math.floor(width * (img.height / img.width));
        }
      } else {
        height = Math.floor(width * (img.height / img.width));
        if (height > h) {
          height = h;
          width = Math.floor(height * (img.width / img.height));
        }
      }

      const outputCanvas: HTMLCanvasElement = document.createElement('canvas');
      const outputCanvasContext = outputCanvas.getContext('2d');

      outputCanvas.width = width;
      outputCanvas.height = height;

      outputCanvasContext?.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        width,
        height,
      );

      // output the canvas pixels as an image. params: format, quality
      const base64ResizedImage = outputCanvas.toDataURL('image/png', 1);
      return resolve(base64ResizedImage);
    };
    img.onerror = error => reject(error);
  });
