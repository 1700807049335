import React, {useContext} from 'react';
import {UserContext} from '../../contexts/UserContext';
import PrivateHome from '../PrivateHome';
import PublicHome from '../PublicHome';
import {LoadingContext} from '../../contexts/LoadingContext';

function Home(): JSX.Element {
    const {isLogin} = useContext(UserContext);
    const {isUserLoading} = useContext(LoadingContext);

    if (isUserLoading) return <></>;
    else {
        if (isLogin) {
            return <PrivateHome/>;
        } else {
            return <PublicHome/>;
        }
    }
}

export default Home;
