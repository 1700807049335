/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import DhlText from '../../../components/DhlText';
import {DhlModal,} from '@dhl-official/ui-libraries/react-library';
import DhlButton from '../../../components/DhlButton';
import {device} from '../../../styles/Device';

const RatingModel = styled(DhlModal)`
  margin-bottom: 16px;
  overflow: auto;
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  height: 100%;
  margin: 0px;
  overflow: auto;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const RowWrapperTopMargin = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${device.mobile} {
    margin-top: 10px;
  }
  @media ${device.tablet} {
    margin-top: 16px;
  }
`;

const RowCenterWrapperTopMargin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  @media ${device.mobile} {
    margin-top: 10px;
  }
  @media ${device.tablet} {
    margin-top: 16px;
  }
`;

const MyButtons = styled(DhlButton)`
  margin-left: 10px;
`;


export const TermsModel: React.FC<any> = (props) => {
    const {t} = useTranslation();
    const {isOpen, handleClose, submitAccept} = props;

    return (
        <RatingModel
            isOpen={isOpen}
            handleOnClose={handleClose}
            dataId="rating-dialog"
            closeButtonAriaLabel="Close"
            shouldShowCloseButton={true}
        >
            <RatingWrapper>
                <RowWrapper slot="header">
                    <DhlText
                        size={'lg'}
                        title={t('App.page.profile.savingsProfile')}
                        weight={600}
                    />
                </RowWrapper>

                <RowWrapperTopMargin>
                    <DhlText
                        size="md"
                        title={t('App.page.profile.savingsProfileTerms')}
                    />
                </RowWrapperTopMargin>

                <RowCenterWrapperTopMargin>
                    <MyButtons
                        title={t('App.common.cancel')}
                        variant={'outline'}
                        onClick={handleClose}
                    />

                    <MyButtons
                        title={t('App.page.profile.accept')}
                        onClick={submitAccept}
                    />
                </RowCenterWrapperTopMargin>
            </RatingWrapper>
        </RatingModel>
    );
};

export default TermsModel;
