import property from 'lodash/property';

class ServerSideDatasource {
    gridOptions = {};
    queryParams = {};
    isUnmount = false;
    getData = false;
    triggerValue = false;
    showCustomPagination = false;

    constructor(api, queryParams, isUnmount, getData, triggerValue, showCustomPagination) {
        this.isUnmount = isUnmount;
        this.gridOptions = api.gridOptionsService.gridOptions;
        this.queryParams = queryParams;
        this.getData = getData;
        this.triggerValue = triggerValue;
        this.showCustomPagination = showCustomPagination;
    }

    getRows(params) {
        const {totalKey, dataKey, updatePagination, failCallback, successCallback} = this.queryParams;
        const {startRow, sortModel, filterModel} = params;

        let pageCount = Math.floor(startRow / this.gridOptions.cacheBlockSize);
        let pageSize = this.gridOptions.cacheBlockSize;

        if (this.showCustomPagination) {
            pageCount = Math.floor(startRow / this.gridOptions.paginationPageSize);
            pageSize = this.gridOptions.paginationPageSize;
            updatePagination(pageCount);
        }

        const currentVariables = {
            page: pageCount,
            size: pageSize,
            filter: filterModel,
            trigger: this.triggerValue,
        };

        if (sortModel.length > 0) {
            const {colId, sort} = sortModel[0];
            currentVariables.sort = {
                field: colId,
                order: sort.toUpperCase(),
            };
        }
        const response = this.getData(currentVariables);
        response
            .then((res) => {
                const payload = res.data;
                const data = property(dataKey)(payload);
                const lastRow = property(totalKey)(payload);
                params.successCallback(data || [], lastRow || 0);
                successCallback(data || [], lastRow || 0);
            })
            .catch((e) => {
                console.error(e);
                params.failCallback();
                failCallback();
            });
    }
}

export default ServerSideDatasource;
