/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import DhlText from '../../../components/DhlText';
// import {ThemeContext} from '../../../styles/ThemeContext';
import {DhlButton, DhlIcon, DhlLoader} from "@dhl-official/ui-libraries/react-library";
import {IconContext} from "../../../assets/IconContext";
// import insightsList from "../../../service/response/insights.json";
import InsightsItem from "./InsightsItem";
import ServiceRequest from "../../../service/ServiceRequest";
import { device } from '../../../styles/Device';

const RatingModel = styled.div<{ isOpen: boolean }>`
  display: ${(props) => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: fixed;
  min-width: 30vw;
  height: 80vh;
  overflow: hidden;
  background-color: white;
  z-index: 2;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
  @media ${device.mobile} {
    top: 12%;
    left: 4%;
    right: 4%;
  }
  @media ${device.laptop} {
    top: 12%;
    left: 10%;
    right: 10%;
    width: fit-content;
  }
`;

const RatingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 10px;
    overflow: auto;
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const RowItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    @media ${device.laptop} {
      flex-direction: row;
    }
`;

const RowLastWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  padding-right: 40px;
  position: relative;
  bottom: 10px;
`;

const CloseIcon = styled(DhlIcon)`
  width: 32px;
  height: 32px;
  :hover {
    cursor: pointer;
  }
`;

const MyInsightsLoader = styled(DhlLoader)`
  align-self: center;
  justify-self: center;
`;

export const InsightsModel: React.FC<any> = (props) => {
    //  const {theme} = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const {t} = useTranslation();
    const [insightsList, setInsightsList] = useState<any[]>([]);
    const [insightsLoading, setInsightsLoading] = useState(false);
    const {isOpen, handleClose, insightsListData, offset} = props;
    const isMobile = window.innerWidth < 600;
    const pivotPointToRenderColumn = isMobile ? 1 : 4;
    const pivotPointToRenderColumn2 = isMobile ? 0 : 3;

    const getInsightsListData = (): void => {
        setInsightsLoading(true);
        void ServiceRequest.getInsights('',0,200)
            .then((response) => {
                if (response.data?.list?.length) {
                    setInsightsList(response.data.list.slice(offset));
                }
            }).finally(() => {
                setInsightsLoading(false);
            });
    }

    useEffect(() => {
        if(isOpen) {
            setInsightsList(insightsListData);
            getInsightsListData();
        }
    }, [isOpen])

    const renderColumns = (index: number): any => {
        if (index <= insightsList.length) {
            let j = 0;
            const results: any[] = [];
            while (j <= pivotPointToRenderColumn2) {
                const itemIndex = index + j;
                if (itemIndex + 1 <= insightsList.length) {
                    const resourceItem = insightsList[itemIndex];
                    results.push(<InsightsItem key={resourceItem?.title} itemDetails={{...resourceItem}}/>);
                }
                j++;
            }
            return results;
        }
        return null;
    }

    const renderRow = (): any => {
        let i = 0;
        const results: any[] = [];
        while (i <= insightsList.length) {
            const index = i === 0 ? 0 : (i) * pivotPointToRenderColumn;
            results.push(<RowItemWrapper>
                {renderColumns(index)}
            </RowItemWrapper>);
            i = i + 1;
        }
        return results;
    }

    return (
        <RatingModel
            isOpen={isOpen}
            // handleOnClose={handleClose}
            // dataId="rating-dialog"
            // closeButtonAriaLabel="Close"
            // shouldShowCloseButton={true}
        >
            <RatingWrapper>

                <RowWrapper>
                    <DhlText
                        size={isMobile ? 'md' : 'lg'}
                        title={t('App.page.insights.moreInsights')}
                        weight={800}
                    />

                    <CloseIcon src={getIcon('RedClose')} onClick={() => handleClose()}/>
                </RowWrapper>

                {insightsList?.length > 0 && renderRow()}
                {insightsLoading && <MyInsightsLoader size={"sm"}/>}

            </RatingWrapper>

            <RowLastWrapper>
                <DhlButton variant={'outline'} onClick={() => handleClose()}>
                    {t("App.common.close")}
                </DhlButton>
            </RowLastWrapper>
        </RatingModel>
    );
};

export default InsightsModel;