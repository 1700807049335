import React from "react";
import DhlText from "../DhlText";

interface ValueFieldProps {
    label: string;
    value?: string;
    children?: any;
}

const ValueField: React.FC<ValueFieldProps> = ({label, value, ...props}) => (
    <div style={{marginTop: 16, marginBottom: 16}}>
        <DhlText
            size="md"
            weight={400}
            title={label}
        />
        {props.children === undefined &&
            <DhlText
                size="md"
                weight={200}
                title={value}
            />}
        {props?.children}
    </div>
);

export default ValueField;
