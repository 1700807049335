/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/prop-types */
import React from 'react';

export const headerTemplate = `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
        <span ref="eSortAsc" class="ag-header-icon icon-sort-asc" ></span>
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
    </div>
</div>`;
export const SelectAllCheckBox = ({ onClick }) => (
  <input onClick={onClick} type="checkbox" />
);
