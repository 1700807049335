/* eslint-disable  */
import React, {useContext, useEffect, useState} from 'react';
import { DhlImage } from '@dhl-official/ui-libraries/react-library';
import DHLLogo from '../../assets/images/dhl-logo.svg';
import './Header.css';
import { ThemeContext } from '../../styles/ThemeContext';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MenuContext, { iconTypeEnum } from '../MenuContext';
import DhlText from '../DhlText';
import DhlButton from '../DhlButton';
import ProfileContext from '../ProfileContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { UserContext } from '../../contexts/UserContext';
import AppContext from '../AppContenxt';
import BrandingHeader from './BrandingHeader';
// import AccessibilityContext from '../AccessibilityContext';
import { device } from '../../styles/Device';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from '../../contexts/LoadingContext';
import { isEmpty } from 'lodash';
import { getAccessToken } from "../../service";

const linearGradientSmaller = `
  90deg,
  #ffcc00,
  #ffcc00 5%,
  #ffe57f 20%,
  #fff0b2
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    ${(props) =>
      props.theme.isBrandingObjectShowing ? linearGradientSmaller : null}
  );
`;

const Ribbon = styled.div`
  width: 150px;
  background-color: RGBA(235, 19, 30, 0.7);
  text-align: center;
  color: ${(props) => props.theme.color.white[400]};
  transform: rotate(-45deg);
  margin-left: -50px;
  padding: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
`;

const LeftWrapper = styled.div`
  min-width: 300px;
  width: 25%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 30px;
  padding-right: 20px;
`;

const RightWrapper = styled.div`
  @media ${device.mobile} {
    width: 68%;
  }
  @media ${device.laptop} {
    width: 110%;
  }
  width: 100%;
  height: 100%;
  display: block;
  background-color: ${(props) =>
    props.theme.isBrandingObjectShowing ? props.theme.brandingColor : null};
  color: ${(props) =>
    props.theme.isBrandingObjectShowing ? props.theme.brandingTextColor : null};
`;

const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  right: 0%;
  bottom: ${(props) => (props.theme.isBrandingObjectShowing ? '100%' : '0%')};
`;

const VerticalDevider = styled.div`
  width: 2px;
  height: 70%;
  margin-left: 20px;
  background-color: #ababab;
`;

const SpaceWrapper = styled.div`
  @media ${device.tablet} {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const MyLogo = styled(DhlImage)`
  cursor: pointer;
`;

const MyScTextLogo = styled.div`
  cursor: pointer;
`;

interface propTypes {
  isBrandingObjectShowing: boolean;
  brandingColor: string;
  selectedLanguage: string;
  setImpersonatorModal?: Function;
}

function NavBar({
  isBrandingObjectShowing,
  brandingColor,
  selectedLanguage,
  setImpersonatorModal
}: propTypes): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { languages } = useContext(LanguageContext);
  const { isLogin, user, refreshUser } = useContext(UserContext);
  const { isUserLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const brandingTextColor =
    user.brandingObject?.textColor !== undefined
      ? user.brandingObject.textColor
      : null;

  const { t, i18n } = useTranslation();
  // const [isAccessibilityMenuOpen, setIsAccessibilityMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isAppMenuOpen, setIsAppMenuOpen] = useState(false);

  useEffect(() => {
      void i18n.changeLanguage(selectedLanguage);
  }, []);

  const onLanguageChange = (item: any): void => {
    localStorage.setItem(`MSC-${process.env.REACT_APP_ENVIRONMENT}-language`, item.code);
    void i18n.changeLanguage(item.code);
    setIsMenuOpen(false);
  };

  const login: () => void = () => {
    // Just obtain the token, the page should be then refreshed by its own.
    getAccessToken()
      .then(
        (accessToken) => {
          refreshUser(false)
        }
      )
      .catch(
        err => console.error(err)
      );
  }

  // const toggleAccessibilityMenu = (val: boolean): void => {
  //   setIsAccessibilityMenuOpen(val);
  // };

  const toggleMenu = (val: boolean): void => {
    setIsMenuOpen(val);
  };

  const toggleProfileMenu = (val: boolean): void => {
    setIsProfileMenuOpen(val);
  };

  const toggleAppMenu = (val: boolean): void => {
    setIsAppMenuOpen(val);
  };

  return (
    <Wrapper
      theme={{
        ...theme,
        isBrandingObjectShowing,
      }}
    >
      {!isEmpty(process.env.REACT_APP_ENVIRONMENT) && (
        <Ribbon theme={theme}>{process.env.REACT_APP_ENVIRONMENT}</Ribbon>
      )}

      <LeftWrapper theme={theme}>
        <MyLogo src={DHLLogo} onClick={() => navigate('/')} width={143} height={20}  />

        <VerticalDevider />

        <MyScTextLogo onClick={() => navigate('/')}>
          <DhlText
            size="sm"
            weight={600}
            color="#d40511"
            title={t('App.applicationName')}
          />
        </MyScTextLogo>
      </LeftWrapper>

      <RightWrapper
        theme={{
          ...theme,
          isBrandingObjectShowing,
          brandingColor,
          brandingTextColor,
        }}
      >
        {isBrandingObjectShowing && <BrandingHeader />}

        <MenuWrapper
          theme={{
            ...theme,
            isBrandingObjectShowing,
          }}
        >
          {/* <SpaceWrapper>
            <AccessibilityContext
              isMenuOpen={isAccessibilityMenuOpen}
              toggleMenu={toggleAccessibilityMenu}
            />
          </SpaceWrapper> */}

          <SpaceWrapper>
            <MenuContext
              items={languages}
              isMenuOpen={isMenuOpen}
              title={selectedLanguage}
              toggleMenu={toggleMenu}
              onSelect={onLanguageChange}
              iconType={iconTypeEnum.BASE64}
              isBrandingObjectShowing={isBrandingObjectShowing}
              brandingTextColor={brandingTextColor}
            />
          </SpaceWrapper>

          {!isUserLoading && (
            <SpaceWrapper>
              {!isLogin ? (
                <DhlButton
                  size="md"
                  textSize="xs"
                  textVariant="primary"
                  dataAriaLabel={t('App.components.Header.login')}
                  title={t('App.components.Header.login')}
                  onClick={() => login()}
                />
              ) : (
                <ProfileContext
                  items={languages}
                  isMenuOpen={isProfileMenuOpen}
                  toggleMenu={toggleProfileMenu}
                  isBrandingObjectShowing={isBrandingObjectShowing}
                  brandingTextColor={brandingTextColor}
                  setImpersonatorModal={setImpersonatorModal}
                />
              )}
            </SpaceWrapper>
          )}

          {isLogin && (
            <AppContext isMenuOpen={isAppMenuOpen} toggleMenu={toggleAppMenu} />
          )}
        </MenuWrapper>
      </RightWrapper>
    </Wrapper>
  );
}

export default NavBar;
