/* eslint-disable react/prop-types */
// @flow
import React from 'react';
import { rangeOption } from './constants';
import DatePicker from './DatePicker';

const Option = ({ opt }) => {
  const label = opt;
  return <option value={opt}>{label}</option>;
};

class DatePickers extends React.PureComponent {
  componentDidUpdate(PrevProps) {
    if (PrevProps.model !== this.props.model) {
      const { onModelModified, model } = this.props;
      onModelModified && onModelModified(model);
    }
  }

  render() {
    const {
      filterOptions,
      startDate,
      endDate,
      type,
      error,
      onTypeChange,
      onDateFromChange,
      onDateToChange,
      setModel,
      isFloating = false,
    } = this.props;

    if (isFloating) {
      if (type !== rangeOption) {
        return <DatePicker selected={startDate} onChange={onDateFromChange} />;
      }
      return (
        <div className="flex-row-box">
          <DatePicker
            selected={startDate}
            onChange={onDateFromChange}
            dpProps={{
              selectsEnd: true,
              startDate,
              endDate,
            }}
          />
          -
          <DatePicker
            selected={endDate}
            onChange={onDateToChange}
            dpProps={{
              selectsEnd: true,
              startDate,
              endDate,
            }}
          />
        </div>
      );
    }

    return (
      <div className="ag-filter-body-wrapper">
        <select
          className="ag-filter-select"
          id="filterType"
          onChange={onTypeChange(setModel)}
          value={type}
        >
          {filterOptions &&
            filterOptions.map((opt) => <Option key={opt} opt={opt} />)}
        </select>
        <div className="ag-filter-body">
          {type === rangeOption ? (
            <div className="date-range-filter">
              <div className="flex-row-box">
                <div className="date-range-filter__label">From</div>
                <div className="date-range-filter__label">To</div>
              </div>
              <div className="flex-row-box">
                <DatePicker
                  error={error}
                  selected={startDate}
                  className="date-range-filter__input"
                  onChange={onDateFromChange(setModel)}
                  dpProps={{
                    selectsStart: true,
                    startDate,
                    endDate,
                  }}
                />
                <DatePicker
                  error={error}
                  selected={endDate}
                  className="date-range-filter__input"
                  onChange={onDateToChange(setModel)}
                  dpProps={{
                    selectsEnd: true,
                    startDate,
                    endDate,
                  }}
                />
              </div>
            </div>
          ) : (
            <DatePicker
              error={error}
              selected={startDate}
              onChange={onDateFromChange(setModel)}
            />
          )}
        </div>
      </div>
    );
  }
}

// export default connectDateFilter(DatePickers);
export default DatePickers;
