/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
// @flow
import React from 'react';
import keyBy from 'lodash/keyBy';
import TextInput from './TextInput';

const zeroEntries = 'zeroEntries';
export default class SetFloatingFilter extends React.PureComponent {
  valuesDict = {};

  state = {
    value: '',
  };

  constructor(props) {
    super(props);
    const { column } = this.props;
    if (column) {
      const {
        colDef: { filterFramework, filterParams = {} },
      } = column;
      if (!filterFramework || filterFramework.name !== 'GroupFilter')
        this.valuesDict = keyBy(filterParams.values, 'id') || {};
      else {
        this.valuesDict = this.getGroupFilterVals(filterParams);
      }
    }
  }

  getGroupFilterVals(filterParams) {
    const { subGroupKey, values } = filterParams;
    if (!values || values.length === 0) return {};
    let valuesDict = [];
    values.map(value => {
      if (subGroupKey in value)
        valuesDict = valuesDict.concat(value[subGroupKey]);
    });
    return keyBy(valuesDict, 'id');
  }

  getModelAsString = (model) => {
    if (!model || !model.values) {
      this.setState({ value: '' });
      return;
    }
    if (model.values.length === 0) {
      this.setState({ value: zeroEntries });
      return;
    }
    const names = model.values.map(val => {
      if (val in this.valuesDict) {
        return this.valuesDict[val].name;
      }
      return val;
    });
    this.setState({ value: `(${names.length}) ${names.join(', ')}` });
  };

  onParentModelChanged(parentModel) {
    this.getModelAsString(parentModel);
  }

  clearSetFilter = () => {
    const { onFloatingFilterChanged } = this.props;
    onFloatingFilterChanged({ model: null });
  };

  render() {
    const { value } = this.state;
    const translate = value === zeroEntries;
    return (
      <div className="set-floating-filter">
        <TextInput
          value={value}
          inputProps={{ disabled: true }}
          onClear={this.clearSetFilter}
          translate={translate}
          placeholder=" "
        />
      </div>
    );
  }
}
