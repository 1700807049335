/* eslint-disable */
import React, { useContext, useState } from 'react';
import TopHeader from './components/TopHeader';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import BottomContent from './components/BottomContent';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};
`;

function PublicHome(): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const [selectedCountry, setSelectedCountry] = useState('');
  const navigate = useNavigate();

  return (
    <>
        <Wrapper theme={theme}>
          <TopHeader
            selectedCountry={selectedCountry}
            changeCountry={(countryData: any) =>
              setSelectedCountry(countryData)
            }
          />
          <BottomContent />
        </Wrapper>
    </>
  );
}

export default PublicHome;
