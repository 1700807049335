/* eslint-disable react/prop-types */
// @flow
import React from 'react';
import debounce from 'lodash/debounce';
import {FormControl, Select, MenuItem, InputLabel} from "@mui/material";
import {isEmpty} from "lodash";

export default class SelectFloatingFilter extends React.PureComponent {
    updateAndNotifyAgGrid = (dict) => {
    };

    defaultModel = null;

    constructor(props) {
        super(props);
        const {debounceMs, parentFilterInstance} = this.props;
        const onFloatingFilterChanged = (input) => parentFilterInstance(instance => instance.onFloatingFilterChanged(input?.model?.type, input?.model?.filter));
        const model = this.getModel();
        this.state = {
            value: (model && model.filter) || '',
        };
        this.updateAndNotifyAgGrid = onFloatingFilterChanged;
        if (debounceMs) this.updateAndNotifyAgGrid = debounce(onFloatingFilterChanged, debounceMs,);
    }

    getModel() {
        const {
            api, column: {colId},
        } = this.props;
        const filtersModel = api.getFilterModel();
        const model = (filtersModel && filtersModel[colId]) || '';
        return model;
    }

    onParentModelChanged(parentModel) {
        this.setState({value: (parentModel && parentModel.filter) || ''});
    }

    valueChanged = (event) => {
        event.preventDefault();
        this.setState({value: event.target.value}, () => {
            this.updateAndNotifyAgGrid({model: this.buildModel()});
        });
    };

    getModelKey(model = {}) {
        const {filterType} = model;
        switch (filterType) {
            case 'number':
                return 'filter';
            case 'text':
                return 'filter';
            case 'select':
                return 'filter';
        }
        return 'filter';
    }

    getDefaultModel() {
        if (this.defaultModel) return this.defaultModel;
        const {
            column: {colDef},
        } = this.props;
        this.defaultModel = {
            type: 'contains', filterType: 'select', filter: '',
        };
        if (!colDef) {
            return this.defaultModel;
        }
        const {filter, filterParams} = colDef;
        const filterOptions = (filterParams && filterParams.filterOptions) || null;
        if (filter === 'agNumberColumnFilter') {
            this.defaultModel.filterType = 'number';
            this.defaultModel.type = (filterOptions && filterOptions.length > 0 && filterOptions[0]) || 'equals';
        }

        return this.defaultModel;
    }

    buildModel() {
        // ag-grid will call us here when in need to check what the current date value is hold by this
        // component.
        const {value} = this.state;
        const model = this.getModel() || this.getDefaultModel();
        const newModel = {...model, filter: value};
        return newModel;
    }

    clearValue = (event) => {
        event.preventDefault();
        this.setState({value: ''}, () => this.updateAndNotifyAgGrid({model: this.buildModel()}),);
    };

    render() {
        const {value} = this.state;
        const {dataList} = this.props;
        return (
            <FormControl sx={{m: 1, minWidth: 120}} size="small">
                {isEmpty(value) && <InputLabel style={{marginTop: 10}}>Select</InputLabel>}
                <Select
                    value={value}
                    placeholder={'Select'}
                    onChange={this.valueChanged}
                    style={{height: 40, paddingBottom: 10}}
                >
                    {dataList.map((selectItem) => <MenuItem key={selectItem.value} value={selectItem.value}>
                        <em>{selectItem.label}</em>
                    </MenuItem>)}
                </Select>
            </FormControl>
        )
    }
}
