/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import StaticTable from "../../../components/Table/StaticTable";
import {getHeight} from "../../../components/Table/utils";
import DhlText from "../../../components/DhlText";

const DetailTableWrapper = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  background-color: ${(props) => props.theme.secondaryBg};
`;

const MyStaticTable = styled(StaticTable)`
    margin-top: 20px;
`;


export const TableCustomerObjectProfile = (node: any): JSX.Element => {
    const {t} = useTranslation();
    const rowData = node?.data;
    const [columnDefs] = useState([
        {
            field: 'name',
            headerName: t('App.page.Customers.name'),
            hide: false,
            filter: false,
            sortable: false,
        },
        {
            field: 'country',
            headerName: t('App.page.ExternalUser.country'),
            hide: false,
            filter: false,
            sortable: false,
            minWidth: 150,
        },
        {
            field: 'notes',
            headerName: t('App.page.Customer.notes'),
            hide: false,
            filter: false,
            sortable: false,
            minWidth: 150,
        },
        {
            field: 'status',
            headerName: t('App.page.customer.status'),
            hide: false,
            filter: false,
            sortable: false,
            minWidth: 150,
        }
    ]);

    return (
        <DetailTableWrapper className="full-width-panel">
            <DhlText title={"Custom Object Profile"} size={"md"} weight={600}/>
            <MyStaticTable
                columnDefs={columnDefs}
                dataKey="data"
                totalKey="total"
                id="units"
                resizeColumn={true}
                autoSizeColumns={false}
                rowData={rowData !== undefined ? rowData?.customer_object_profiles : []}
                rowHeight={getHeight(1.5)}
                isDetail={true}
                sizeColumnsToFit={'forced'}
                rowClass={'static-content'}
            />
        </DetailTableWrapper>
    );
};
