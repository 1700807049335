// @flow
import React, { ReactElement } from 'react';
import './upload.scss';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

interface Props {
  valueFormatted: string;
  value: string;
};

function UploadStatus({ value}: Props): ReactElement {
  const { t } = useTranslation();
  
  if (isEmpty(value)) {
    return <span></span>;
  } else {
    return (
      <div className="upload-status">
        <div
          className={`upload-status-item upload-status-item_${value.toLowerCase()}`}
        >
          {t(`App.page.upload.${value}`)}
        </div>
      </div>
    );
  }
}

export default UploadStatus;
