import Grid from "@mui/material/Grid";
import React from "react";
import { Box } from "@mui/material";
import ValueField from "../ValueField";
import DhlText from "../DhlText";
import { useTranslation } from "react-i18next";
import DateTimeLabel from "../DateTimeLabel";

const FormAudit: React.FC<any> = ({idLabel, auditData}) => {
  const {t} = useTranslation();

  return (
    <>
      <DhlText
        size="md"
        weight={800}
        title={t('App.page.customer.audit')}
      />
      <Box py={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ValueField label={t(idLabel)} value={auditData?.id ?? '-'}/>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ValueField
              label={t('App.page.customer.createdBy')}
              value={auditData?.createdBy ?? '-'}
            />
          </Grid>
          <Grid item xs={6}>
            <ValueField
              label={t('App.page.customer.updatedBy')}
              value={auditData?.updatedBy ?? '-'}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ValueField label={t('App.page.customer.createdOn')}>
              <DateTimeLabel datetime={auditData?.created}/>
            </ValueField>
          </Grid>
          <Grid item xs={6}>
            <ValueField label={t('App.page.customer.updatedOn')}>
              <DateTimeLabel datetime={auditData?.updated}/>
            </ValueField>
          </Grid>
        </Grid>
      </Box>
    </>
  )
};

export default FormAudit;
