/* eslint-disable  */
import React, { createContext, useContext, useEffect, useState } from 'react';
import ServiceRequest from '../service/ServiceRequest';
import { LoadingContext } from './LoadingContext';
import CommonType from './types/CommonType';
import UserType from './types/UserType';
import { MsalContext } from "@azure/msal-react";
import {PATHS} from "../pages/App/constants";
import { msalInstance } from '..';
import _ from 'lodash';

export const EmptyUser: UserType = {
    adminMenuLinks: [],
    banners: [],
    apps: [],
    mediaApps: [],
    brandingObject: [],
    resources: [],
    news: [],
    insights: [],
    dhl: '',
    email: '',
    firstName: '',
    lastName: '',
    languageCode: '',
    roles: [],
    userName: '',
    lastLogin: '',
    countryIso: '',
    isRedirected: false,
    profileUpdated: false,
    isDhl: false,
};

export const UserContext = createContext({
    user: EmptyUser,
    isLogin: false,
    setUser: (user: UserType): void => {},
    refreshUser: (isDefault: boolean): void => {},
    insightsList: [],
    mediaTypes: []
});

export const UserProvider: React.FC<CommonType> = ({children, ignorePath}) => {
    const [user, setUser] = useState<UserType>(EmptyUser);
    const [isLogin, setIsLogin] = useState(false);
    const [insightsList, setInsightsList]  =  useState([])
    const [mediaTypes, setMediaTypes] = useState<[]>([]);
    const {setIsUserLoading} = useContext(LoadingContext);

    const {inProgress} = useContext(MsalContext);

    const homePageRedirection = (): void => {
        if (window.location.pathname !== '/') {
            const win: Window = window;
            win.location = `${process.env.REACT_APP_BASE_URL}/expired`;
        }
    };

    const refreshUser = (isDefault: boolean) => {
        setIsUserLoading(isDefault);

        if (msalInstance.getActiveAccount()) {
            Promise.allSettled(
                [   ServiceRequest.getUser(),
                    ServiceRequest.getProfilePicture(),
                    ServiceRequest.getInsights("", 0, 10),
                    ServiceRequest.getMediaTypes()])
                .then(([user, profilePicture, insights, mediaTypes]) => {
                    // Only when getUser fails we want to cancel everything, otherwise try to do with defaults.
                    if (user.status === 'rejected') {
                        throw user.reason;
                    }
                    setUser({...user.value.data, avatar: profilePicture.status === 'fulfilled' ? profilePicture.value.data : null})
                    setInsightsList(insights.status === 'fulfilled' ? insights.value.data?.list || [] : []);
                    if (mediaTypes.status === 'fulfilled' && !_.isEmpty(mediaTypes.value.data)) {
                        setMediaTypes(mediaTypes.value.data);
                    }
                    setIsLogin(true);
                }).catch(() => {
                    setUser({} as UserType);
                    setIsLogin(false);
                    homePageRedirection();
                }).finally(() => setIsUserLoading(false))
        } else {
            setIsUserLoading(false);
            homePageRedirection();
        }
    }

    useEffect(() => {
        // prevent infinity loop. for expired or logout page we don't want to load current user
        if (ignorePath != null && window.location.pathname != null) {
            for (let i = 0; i < ignorePath.length; i++) {
                if (window.location.pathname.includes(ignorePath[i])) {
                    return;
                }
            }
        }
        if (inProgress === 'none') {
            refreshUser(true);
        }
    }, [inProgress === 'none']);

    // debugger;
    return (
        <UserContext.Provider value={{user, isLogin, setUser, refreshUser, insightsList, mediaTypes}}>
            {children}
        </UserContext.Provider>
    );
};