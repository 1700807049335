import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../../../styles/ThemeContext';
import DhlText from '../../../../components/DhlText';
import TypeGroupHeaderItem from './TypeHeaderCell';
import { DhlCheckbox } from '@dhl-official/ui-libraries/react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableCellWrapper = styled.div`
  width: auto;
  display: table-cell;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px;
`;

const IconWrapper = styled(FontAwesomeIcon)`
  padding-left: ${(props) => props.theme.spacing.sm};
  padding-right: ${(props) => props.theme.spacing.sm};
  cursor: pointer;
`;

function TableHeaderCell(props: TypeGroupHeaderItem): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const {
    id,
    // field,
    label,
    isSelectable,
    isSortable,
    isFilterable,
    isCheck = false,
    checkChange,
  } = props;

  const sortIcon: any = 'fa-solid fa-sort';
  const filterIcon: any = 'fa-solid fa-filter';

  return (
    <TableCellWrapper key={id} theme={theme}>
      <ComponentWrapper>
        {isSelectable && (
          <DhlCheckbox
            size="sm"
            isChecked={isCheck}
            changeEvent={checkChange}
          />
        )}
        {!isSelectable && <DhlText size="sm" weight={800} title={label} />}
        {isSortable && <IconWrapper theme={theme} icon={sortIcon} size="sm" />}
        {isFilterable && (
          <IconWrapper theme={theme} icon={filterIcon} size="sm" />
        )}
      </ComponentWrapper>
    </TableCellWrapper>
  );
}

export default TableHeaderCell;
