import {Control, Controller} from 'react-hook-form';
import React, {ReactElement, useContext} from 'react';
import {FormField, innerSpacing, isRoleCoAdminOnly, MainFormWrapper} from '../common';
import {ThemeContext} from '../../styles/ThemeContext';
import {Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ImageUpload from '../../components/ImageUpload';
import {DhlCheckbox} from '@dhl-official/ui-libraries/react-library';
import {UserContext} from "../../contexts/UserContext";

interface BrandingImagesInput {
    control: Control<any, any>;
}

function BrandingImages({control}: BrandingImagesInput): ReactElement {
    const {t} = useTranslation();
    const {theme} = useContext(ThemeContext);
    const { user } = useContext(UserContext);

    const isCoAdminRole = isRoleCoAdminOnly(user?.roles);

    return (
        <MainFormWrapper theme={theme}>
            <h2 style={{paddingBottom: '16px'}}>{t('App.page.Branding.images')}</h2>

            <Controller
                name="tinyLogo"
                control={control}
                rules={{required: true}}
                render={({
                             field: {onChange, onBlur, value, ...propsField},
                             formState: {submitCount},
                             fieldState: {error},
                         }) => (
                    <ImageUpload
                        {...propsField}
                        caption={t('App.page.Branding.tinyLogo')}
                        dropzoneText={`${t('App.page.Branding.max')} 70 x 50 ${t(
                            'App.page.Branding.pixels'
                        )}`}
                        additionalDropzoneText={`${t('App.page.Branding.maxSize')} 500kB`}
                        isUploading={false}
                        maxWidth={70}
                        maxHeight={50}
                        maxSize={512000}
                        disabled={isCoAdminRole}
                        data={value}
                        setData={onChange}
                        required
                        error={
                            submitCount > 0 && error != null
                                ? t('App.page.Branding.required')
                                : undefined
                        }
                    />
                )}
            />

            <Controller
                name="mediumLogo"
                control={control}
                rules={{required: true}}
                render={({
                             field: {onChange, onBlur, value, ...propsField},
                             formState: {submitCount},
                             fieldState: {error},
                         }) => (
                    <ImageUpload
                        {...propsField}
                        caption={t('App.page.Branding.mediumLogo')}
                        dropzoneText={`${t('App.page.Branding.max')} 520 x 50 ${t(
                            'App.page.Branding.pixels'
                        )}`}
                        additionalDropzoneText={`${t('App.page.Branding.maxSize')} 500kB`}
                        isUploading={false}
                        maxWidth={520}
                        maxHeight={50}
                        maxSize={512000}
                        disabled={isCoAdminRole}
                        data={value}
                        setData={onChange}
                        required
                        error={
                            submitCount > 0 && error != null
                                ? t('App.page.Branding.required')
                                : undefined
                        }
                    />
                )}
            />

            <Controller
                name="bigLogo"
                control={control}
                render={({field: {onChange, onBlur, value, ...propsField}}) => (
                    <ImageUpload
                        {...propsField}
                        caption={t('App.page.Branding.bigLogo')}
                        dropzoneText={`${t('App.page.Branding.max')} 800 x 50 ${t(
                            'App.page.Branding.pixels'
                        )}`}
                        additionalDropzoneText={`${t('App.page.Branding.maxSize')} 500kB`}
                        isUploading={false}
                        maxWidth={800}
                        maxHeight={50}
                        maxSize={512000}
                        disabled={isCoAdminRole}
                        data={value}
                        setData={onChange}
                    />
                )}
            />

            <Controller
                name="homepageImage"
                control={control}
                render={({field: {onChange, onBlur, value, ...propsField}}) => (
                    <ImageUpload
                        {...propsField}
                        caption={t('App.page.Branding.homepageImage')}
                        dropzoneText={`${t('App.page.Branding.max')} 300 x 250 ${t(
                            'App.page.Branding.pixels'
                        )}`}
                        additionalDropzoneText={`${t('App.page.Branding.maxSize')} 500kB`}
                        isUploading={false}
                        maxWidth={300}
                        maxHeight={250}
                        maxSize={512000}
                        disabled={isCoAdminRole}
                        data={value}
                        setData={onChange}
                    />
                )}
            />

            <Controller
                name="templateHeaderImage"
                control={control}
                render={({field: {onChange, onBlur, value, ...propsField}}) => (
                    <ImageUpload
                        {...propsField}
                        caption={t('App.page.Branding.templateHeaderImage')}
                        dropzoneText={`${t('App.page.Branding.max')} 1920 x 1080 ${t(
                            'App.page.Branding.pixels'
                        )}`}
                        additionalDropzoneText={`${t('App.page.Branding.maxSize')} 500kB`}
                        isUploading={false}
                        maxWidth={1920}
                        maxHeight={1080}
                        maxSize={512000}
                        disabled={isCoAdminRole}
                        data={value}
                        setData={onChange}
                    />
                )}
            />

            <Controller
                name="templateFooterImage"
                control={control}
                render={({field: {onChange, onBlur, value, ...propsField}}) => (
                    <ImageUpload
                        {...propsField}
                        caption={t('App.page.Branding.templateFooterImage')}
                        dropzoneText={`${t('App.page.Branding.max')} 1920 x 1080 ${t(
                            'App.page.Branding.pixels'
                        )}`}
                        additionalDropzoneText={`${t('App.page.Branding.maxSize')} 500kB`}
                        isUploading={false}
                        maxWidth={1920}
                        maxHeight={1080}
                        maxSize={512000}
                        disabled={isCoAdminRole}
                        data={value}
                        setData={onChange}
                    />
                )}
            />

            <Grid container spacing={innerSpacing}>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="backgroundColor"
                        control={control}
                        render={({field: {onChange, onBlur, ...propsField}}) => (
                            <FormField
                                {...propsField}
                                InputLabelProps={{shrink: true}}
                                dataId="name-inputfield"
                                type="text"
                                blurEvent={onBlur}
                                inputEvent={onChange}
                                isDisabled={isCoAdminRole}
                                variant={{
                                    label: t('App.page.Branding.backgroundColor'),
                                    placeholder: t('App.page.Branding.backgroundColor'),
                                    type: 'animated',
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="textColor"
                        control={control}
                        render={({field: {onChange, onBlur, ...propsField}}) => (
                            <FormField
                                {...propsField}
                                InputLabelProps={{shrink: true}}
                                dataId="name-inputfield"
                                type="text"
                                blurEvent={onBlur}
                                inputEvent={onChange}
                                isDisabled={isCoAdminRole}
                                variant={{
                                    label: t('App.page.Branding.textColor'),
                                    placeholder: t('App.page.Branding.textColor'),
                                    type: 'animated',
                                }}
                            />
                        )}
                    />

                    <Controller
                        name="showBannerOnMainPage"
                        control={control}
                        render={({field: {onChange, onBlur, value, ...propsField}}) => (
                            <DhlCheckbox
                                {...propsField}
                                changeEvent={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    onChange(e.target?.checked)
                                }
                                isChecked={value}
                                isDisabled={isCoAdminRole}
                                size="sm"
                            >
                                {t('App.page.Branding.showBannerOnMainPage')}
                            </DhlCheckbox>
                        )}
                    />
                </Grid>
            </Grid>
        </MainFormWrapper>
    );
}

export default BrandingImages;
