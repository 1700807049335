/**
 *
 * AutoCompleteView
 *
 */

import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import {CircularProgress, TextField, Chip} from "@mui/material";
import {getErrorMessage} from "../../utils";
import {useTranslation} from "react-i18next";

// eslint-disable-next-line react/prop-types
function DhlMultiSelect({loading, options, label, error, ...props}) {
    const {t} = useTranslation();

    return <Autocomplete
        {...props}
        id="tags-outlined"
        options={options}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        limitTags={3}
        loading={loading}
        renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
                <Chip
                    key={`Key-${option?.value}`}
                    variant="outlined"
                    label={option?.label}
                    {...getTagProps({index})}
                />
            ))
        }
        renderInput={(params) => (
            <TextField
                {...params}
                placeholder={label}
                variant="outlined"
                fullWidth
                error={error}
                margin="normal"
                helperText={getErrorMessage(error?.type, t)}
                InputLabelProps={{shrink: false}}
                InputProps={{
                    ...params.InputProps, endAdornment: (<React.Fragment>
                        {loading ? (<CircularProgress color="inherit" size={20}/>) : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>),
                }}
            />
        )}
    />
    // // @ts-ignore
    // return (<NoPaddingAutocomplete
    //     {...props}
    //     multiple
    //     options={options}
    //     autoHighlight
    //     blurOnSelect
    //     value={value}
    //     onChange={onChange}
    //     // clearIcon={<div style={{width: 0, height: 0}}/>}
    //     // clearText=""
    //     filterSelectedOptions
    //     getOptionLabel={(opt) => opt && opt.label ? opt.label : ""}
    //     limitTags={3}
    //     loading={loading}
    //     renderTags={(value, getTagProps) =>
    //         value?.map((option, index) => (
    //             <Chip
    //                 key={`Key-${option?.value}`}
    //                 variant="outlined"
    //                 label={option?.label}
    //                 {...getTagProps({index})}
    //             />
    //         ))
    //     }
    //     renderOption={(props, option) => (<MenuItem {...props}>{option.label}</MenuItem>)}
    //     renderInput={(params) => (<TextField
    //         {...params}
    //         variant="outlined"
    //         fullWidth
    //         // label={label}
    //         placeholder={label}
    //         margin="normal"
    //         error={error}
    //         helperText={getErrorMessage(error?.type, t)}
    //         InputLabelProps={{shrink: false}}
    //         InputProps={{
    //             ...params.InputProps, endAdornment: (<React.Fragment>
    //                 {loading ? (<CircularProgress color="inherit" size={20}/>) : null}
    //                 {params.InputProps.endAdornment}
    //             </React.Fragment>),
    //         }}
    //     />)}
    // />);
}

DhlMultiSelect.propTypes = {
    loading: PropTypes.bool,
    options: PropTypes.array,
    error: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default DhlMultiSelect;
