interface GroupType {
  groupId: number | string;
  groupDescr: string;
  groupName: string;
  isProtected: string;
  roleId: number;
  scope: string;
}

interface GroupTypeApiResponse {
  draw: number;
  data: GroupType[];
  error: any;
  recordsFiltered: number;
  recordsTotal: number;
}

const EmptyResponse: GroupTypeApiResponse = {
  draw: 1,
  data: [],
  error: null,
  recordsFiltered: 0,
  recordsTotal: 0,
};

export type { GroupType, GroupTypeApiResponse };
export { EmptyResponse };
