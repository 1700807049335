/* eslint-disable */
import React, {useContext, useEffect, useRef, useState} from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import {ThemeContext} from '../../../styles/ThemeContext';
import {IconContext} from "../../../assets/IconContext";
import {useTranslation} from "react-i18next";
import {DhlIcon} from "@dhl-official/ui-libraries/react-library";
import {Grid} from "@mui/material";

const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const ComponentItemWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const ItemWrapper = styled(Grid)`
  padding 4px;
  margin-left: 6px;
  margin-top: 6px;
  border: 1px solid gray;
  border-radius: 2px;
`;

const ItemText = styled(DhlText)`
 text-wrap: nowrap;
 margin-right: 10px;
`;

const ItemText2 = styled(DhlText)`
    text-wrap: nowrap;
    margin-left: 4px;
    margin-right: 4px;
`;

const ItemCounterText = styled(DhlText)`
    margin-left: 10px;
    :hover {
    cursor: pointer;
    text-decoration: underline;
    }
`;

const ItemTitle = styled(DhlText)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const MyIcon = styled(DhlIcon)`
width: 12px;
height: 100%;
  :hover {
    cursor: pointer;
`;

interface AccordionType {
    selectedList: any[],
    changeSelectedList: Function,
    originalWidth: Number,
}

function HeaderBfSelection(props: AccordionType): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const {t} = useTranslation();
    const [expandView, setExpandView] = useState(false);
    const {selectedList, changeSelectedList, originalWidth} = props;

    const pivotPoint = originalWidth < 500 ? 1 : originalWidth < 1000 ? 2 : 4;

    const updateList = (bfData: any): void => {
        let myUpdatedList = selectedList;
        myUpdatedList = myUpdatedList.filter(x => x.id !== bfData.id);
        changeSelectedList(myUpdatedList);
    }

    return (
        <ComponentWrapper theme={theme}>
            <ItemTitle title={t('App.page.customer.functionsSelected')} size={'md'}/>

            <ComponentItemWrapper>
                {selectedList.map((bfData: any, index) => {
                    return expandView ? <ItemWrapper container
                                                     direction="row"
                                                     wrap="nowrap"
                                                     justifyContent="space-between"
                                                     alignItems="center"
                                                     style={{width: "fit-content"}}
                                                     key={bfData.id}>
                            <ItemText title={`${bfData?.parent_name}:`} size={'sm'} weight={600}/>
                            <ItemText2 title={bfData.name} size={'sm'} weight={300}/>

                            <MyIcon src={getIcon('cross')} size={"0.7rem"} onClick={() => updateList(bfData)}/>
                        </ItemWrapper> :
                        index < pivotPoint ? <ItemWrapper container
                                                           direction="row"
                                                           wrap="nowrap"
                                                           justifyContent="space-between"
                                                           alignItems="center"
                                                           style={{width: "fit-content"}}
                                                           key={bfData.id}>
                            <ItemText title={`${bfData?.parent_name}:`} size={'sm'} weight={600}/>
                            <ItemText2 title={bfData.name} size={'sm'} weight={300}/>

                            <MyIcon src={getIcon('cross')} size={"0.7rem"} onClick={() => updateList(bfData)}/>
                        </ItemWrapper> : null
                })
                }

                {expandView ? <ItemCounterText
                    title={t('App.page.customer.hide')}
                    size={'md'}
                    weight={400}
                    // @ts-ignore
                    onClick={() => setExpandView(false)}
                /> : selectedList.length > pivotPoint && <ItemCounterText
                    title={`+ ${selectedList.length - pivotPoint}`}
                    size={'md'}
                    weight={400}
                    // @ts-ignore
                    onClick={() => setExpandView(true)}
                />
                }
            </ComponentItemWrapper>
        </ComponentWrapper>
    );
}

export default HeaderBfSelection;
