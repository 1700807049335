/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
// @flow
import React from 'react';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import debounce from 'lodash/debounce';
import Checkbox from './Checkbox';
import SearchInput from './SearchInput';

class SetFilter extends React.PureComponent {
  allSelectedLength = 0;

  filterChangedCallback = () => {};

  key = 'id';

  label = 'name';

  constructor(props) {
    super(props);
    const {
      api,
      column: { colId },
      filterChangedCallback,
      debounceMs,
      key = 'id',
      label = 'name',
    } = this.props;
    this.key = key;
    this.label = label;
    const filtersModel = api.getFilterModel();
    const model = (filtersModel && filtersModel[colId]) || null;
    this.state = this.getState(model);
    this.filterChangedCallback = debounceMs
      ? debounce(filterChangedCallback, debounceMs)
      : filterChangedCallback;
  }

  getState = (filterModel) => {
    const origList = this.getList();
    this.allSelectedLength = origList.length;

    if (!filterModel) {
      const selected = this.getSelected(origList);
      return {
        list: origList,
        origList,
        selected,
        search: '',
        selectAll: true,
        isFilterActive: false,
      };
    }

    const { values = [], search = '' } = filterModel;
    const list = search ? this.filterList(search, origList) : origList;
    const selected = {};
    values.map((id) => {
      const value = find(list, { id });
      if (value) selected[id] = value;
    });

    return {
      list,
      origList,
      selected,
      search,
      selectAll: false,
      isFilterActive: true,
    };
  };

  getModel = () => {
    const { selected, isFilterActive, search } = this.state;
    if (!isFilterActive) return;
    const values = Object.keys(selected).filter(
      (key) => !key.startsWith('group-')
    );
    return { filterType: 'set', values, search };
  };

  setModel = (model) => {
    const { filterChangedCallback } = this.props;
    this.setState(this.getState(model), filterChangedCallback);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.values !== this.props.values) {
      this.state = this.getState();
    }
  }

  isFilterActive() {
    return this.state.isFilterActive;
  }

  getList() {
    const { values = [] } = this.props;
    if (!Array.isArray(values)) {
      console.error(`values should be an array of objects getting ${values}`);
      return [];
    }
    return values;
  }

  getSelected = (list) => keyBy(list, 'id');

  getModelAsString(model) {
    if (!model || !model.values || model.values.length === 0) return '';
    const { selected } = this.state;
    const names = model.values.map((val) => selected[val][this.label]);
    return `(${names.length}) ${names.join(', ')}`;
  }

  renderItem = (value) => {
    const { selected } = this.state;
    const checked = value[this.key] in selected;
    return (
      <label
        className="ag-set-filter-item"
        onClick={this.toggleSelectItem(value)}
      >
        <Checkbox id={value[this.key]} checked={checked} />
        <span className="ag-filter-value">{value[this.label]}</span>
      </label>
    );
  };

  onSearchInputChange = (e) => {
    const text = e.target.value;
    const { search, origList } = this.state;
    if (text === search) return;

    const filteredList = this.filterList(text, origList);
    this.setState({ search: text, list: filteredList });
  };

  filterList = (text, list) => {
    const searchText = text.trim().toLowerCase();
    if (searchText === '') {
      return list;
    }
    return list.filter((value) =>
      value[this.label].toLowerCase().includes(searchText)
    );
  };

  toggleSelectAll = () => {
    this.setState((state) => {
      const selectAll = !state.selectAll;
      const isFilterActive = !selectAll || state.search !== '';
      const selected = selectAll ? this.getSelected(state.list) : {};
      return {
        ...state,
        selectAll,
        selected,
        isFilterActive,
      };
    }, this.filterChangedCallback);
  };

  toggleSelectItem = (value) => (e) => {
    const { selected } = this.state;
    const newSelected = { ...selected };
    const id = value[this.key];
    if (id in selected) {
      delete newSelected[id];
    } else {
      newSelected[id] = { ...value };
    }
    const isFilterActive =
      Object.keys(newSelected).length !== this.allSelectedLength;
    this.setState(
      { selected: newSelected, isFilterActive },
      this.filterChangedCallback
    );
  };

  clearFilter = () => {
    this.setState(
      (state) => ({
        list: state.origList,
        search: '',
        selectAll: true,
        selected: this.getSelected(state.origList),
        isFilterActive: false,
      }),
      this.filterChangedCallback
    );
  };

  onFloatingFilterChanged(params) {
    if (params.model === null) this.clearFilter();
  }

  render() {
    const { suppressSearch, clearButton } = this.props;
    const { list, selectAll, search } = this.state;
    return (
      <div className="ag-filter-body-wrapper ag-filter-body-wrapper-custom">
        {!suppressSearch && (
          <div
            className="ag-input-text-wrapper ag-filter-header-container"
            id="ag-mini-filter"
          >
            <SearchInput value={search} onChange={this.onSearchInputChange} />
          </div>
        )}
        <div className="ag-filter-header-container">
          <label id="selectAllContainer" onClick={this.toggleSelectAll}>
            <Checkbox id="selectAll" checked={selectAll} />
            <span className="ag-filter-value">(Select All)</span>
          </label>
        </div>
        <div className="ag-set-filter-list" id="richList">
          <ul className="table-group-filter ag-virtual-list-viewport">
            {list.map((value, index) => (
              <li key={`${value[this.key]}-${Math.random()}`}>
                {this.renderItem(value)}
              </li>
            ))}
          </ul>
        </div>
        {clearButton && (
          <div className="ag-filter-apply-panel" id="applyPanel">
            <button type="button" id="clearButton" onClick={this.clearFilter}>
              Clear Filter
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default SetFilter;
