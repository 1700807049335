/* eslint-disable no-case-declarations */
/* eslint-disable array-callback-return */
// @flow
import property from 'lodash/property';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import moment from 'moment';

export function getInnerHeight() {
  return 18;
}

export function getHeight(listLength) {
  const height = (listLength || 1) * getInnerHeight() + 20; // 10px padding, 20px row height
  return height;
}

export function getRowHeight(keys) {
  return ({ data }) => {
    let length = 3; // min
    keys.map(key => {
      const list = data[key];
      if (Array.isArray(list) && list.length > length) length = list.length;
    });
    return length * 26;
  };
}

// please use injectIntl formatMessage for it
export const formatBoolean = (formatMessage) => (params) => {
  const { value } = params;
  if (typeof value !== 'boolean') return value;

  return formatMessage(value ? 'Yes' : 'No');
};

export const getByKey = (key) => (row) =>
  property(key)(row.data);

export const getFromArray = (arrayKey, key) => (
  row,
) => {
  const arr = property(arrayKey)(row.data) || [];
  return arr.map(item => property(key)(item));
};

export const keyCreator = (key) => (params) =>
  JSON.stringify(params.value);

// type SetFilterDataProps = {
//   vals: Array<string>,
//   ids: Array<string>,
// };

export function createSetFilterData(
  data,
  label,
  id = 'id',
) {
  if (!data) return { vals: [], ids: [] };
  const ids = [];
  const vals = [];
  data.map(item => {
    vals.push(item[label]);
    ids.push(item[id]);
  });
  return { vals, ids };
}

export function getSetFilterVals(
  { vals, ids },
  params,
) {
  if (!params.values || !vals || !ids) return;
  const idList = [];
  params.values.map(val => {
    const index = vals.indexOf(val);
    if (index === -1) return;
    idList.push(ids[index]);
  });
  return idList;
}

export function createFilter(
  filterModel,
  columnDefs,
  constantFilter,
) {
  if (!filterModel) {
    return constantFilter || [];
  }
  let filterInput = [];

  // get object with field as keys
  const cFilter = constantFilter ? keyBy(constantFilter, 'field') : null;
  for (const field in filterModel) {
    // If we have a filter in constants we are not going to use ag-grid filter values
    if (cFilter && field in cFilter) continue;

    // creating a  Graphql FilterInput
    const params = filterModel[field];
    const { filterType, type = 'equals' } = params;
    const filter = {
      field,
      type: filterType.toUpperCase(),
      operator: type.toUpperCase(),
    };

    switch (filterType) {
      case 'text': {
        filter.value1 = params.filter;
        break;
      }
      case 'date': {
        const { dateFrom, dateTo } = params;
        filter.value1 = dateFrom;
        filter.value2 = dateTo;
        break;
      }
      case 'number': {
        filter.value1 = params.filter;
        filter.value2 = params.filterTo;
        break;
      }
      case 'set':
        filter.operator = 'IN';
        const columnParams = find(columnDefs, { field });
        const originValues = property('filterParams.originValues')(
          columnParams,
        );
        if (originValues)
          filter.values = getSetFilterVals(originValues, params);
        else filter.values = params.values;
        break;
    }
    filterInput.push(filter);
  }

  // add constantFilter
  if (constantFilter) filterInput = filterInput.concat(constantFilter);
  return filterInput;
}

export function getSetFilterLables(
  values,
  originValues,
) {
  if (!values) return [];
  const labels = [];
  values.map(value => {
    const index = originValues.ids.indexOf(value);
    if (index === -1) {
      return;
    }
    labels.push(originValues.vals[index]);
  });
  return labels;
}

export function createAgGridFilterModel(
  filters,
  columnDefs,
) {
  if (filters.length === 0) return null;
  const filterModel = {};
  filters.map(filter => {
    const { field, type, value1, value2, values, operator } = filter;
    if (!field || !type) {
      console.error('Invalid Filter', filter);
      return;
    }
    const agFilter = (filterModel[field] = {});
    agFilter.filterType = type.toLowerCase();
    agFilter.type = operator.toLowerCase();
    switch (agFilter.filterType) {
      case 'text': {
        agFilter.filter = value1;
        return;
      }
      case 'date': {
        agFilter.dateFrom = value1;
        agFilter.dateTo = value2;
        return;
      }
      case 'number': {
        agFilter.filter = value1;
        agFilter.filterTo = value2;
        return
      }
      case 'set':
        const columnParams = find(columnDefs, { field });
        const originValues = property('filterParams.originValues')(
          columnParams,
        );
        if (originValues) {
          agFilter.values = getSetFilterLables(values, originValues);
        } else agFilter.values = values;
        delete agFilter.type;
    }
  });
  return filterModel;
}

export function createSortBy(sortArray) {
  if (!sortArray || !Array.isArray(sortArray) || sortArray.length === 0) return;
  const { colId, sort } = sortArray[0];
  return {
    field: colId,
    order: sort.toUpperCase(),
  };
}

export const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));

export function dateFromString(value) {
  let date = null;
  if (!value) return date;
  date = moment(value);
  if (!date.isValid()) return null;
  return date;
}

export const dateFilterParams = {
  minWidth: 179, // to see the floating filter
  filter: 'dateFilter',
  floatingFilterComponent: 'dateFloatingFilter',
  floatingFilterComponentParams: {
    debounceMs: 1500,
    suppressFilterButton: true,
  },
  filterParams: {
    newRowsAction: 'keep',
    maxNumConditions: 1,
    clearButton: true,
    filterOptions: ['equals', 'range', 'lessThanOrEqual', 'greaterThanOrEqual'],
  },
};

export function setFilterParams(
  filterValues,
  valueFormatter,
  suppressSearch,
) {
  const extraProps = {};
  const extraFilterProps = {};
  if (valueFormatter) {
    extraProps.valueFormatter = valueFormatter;
    extraFilterProps.copyToClipboard = valueFormatter;
  }
  if (filterValues) {
    extraFilterProps.values = filterValues || [];
  }

  if (suppressSearch) {
    extraFilterProps.suppressSearch = suppressSearch;
  }

  return {
    filter: 'setFilter',
    ...extraProps,
    filterParams: {
      selectAllOnMiniFilter: true,
      clearButton: true,
      ...extraFilterProps,
    },
    floatingFilterComponent: 'setFloatingFilter',
  };
}
