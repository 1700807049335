/* eslint-disable no-console */
import React, {createContext, useState, useEffect, useContext} from 'react';
import CommonType from './types/CommonType';
import ServiceRequest from '../service/ServiceRequest';
import CustomerObjectType from './types/CustomerObjectType';
import {UserContext} from "./UserContext";
import { LoadingContext } from './LoadingContext';

export const CustomerObjectContext = createContext({
    customerObjectList: [] as CustomerObjectType[],
    isCoLoading: false
});

const CustomerObjectProvider: React.FC<CommonType> = (props) => {
    const [customerObjectList, setCustomerObjectList] = useState<CustomerObjectType[]>([]);
    const [isCoLoading, setIsCoLoading] = useState(false);
    const {isLogin} = useContext(UserContext);
    const { setLoading } = useContext(LoadingContext);

    useEffect(() => {
        if (isLogin) {
            setIsCoLoading(true);
            setLoading(true);
            ServiceRequest.getCustomerObject()
                .then((response) => {
                    const coList: any[] = [];
                    response.data.list.forEach((customerObject: any) => {
                        if (customerObject.name !== '001 DHL Default') {
                            coList.push({
                                label: customerObject.name,
                                value: customerObject.id,
                            });
                        }
                    });
                    setCustomerObjectList(coList);
                })
                .catch((error) => {
                    console.error(error.message);
                }).finally(() => {
                setIsCoLoading(false);
                setLoading(false);
            });
        }
    }, [isLogin]);

    return (
        <CustomerObjectContext.Provider value={{customerObjectList, isCoLoading}}>
            {props.children}
        </CustomerObjectContext.Provider>
    );
};

export default CustomerObjectProvider;
