import React, { useContext } from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import { ThemeContext } from '../../../styles/ThemeContext';
import BannerImage from '../../../assets/images/bg1.png';
import { device } from '../../../styles/Device';
import { useTranslation } from 'react-i18next';
import MyScLogo from '../../../assets/images/mysc_logo.png';
import { DhlImage } from '@dhl-official/ui-libraries/react-library';

const Wrapper = styled.div`
  width: 100%;
  z-index: 1;
  @media ${device.mobile} {
    height: 16%;
  }
  @media ${device.tablet} {
    height: 16%;
  }
  @media ${device.laptop} {
    height: 16vh;
  }
  @media ${device.desktop} {
    height: 24vh;
  }
  min-height: 250px;
  z-index: 0;
  background: url(${BannerImage}) top right;
  background-repeat: no-repeat;
  background-size: auto 100%;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.color.black[500]} 40%,
    ${(props) => props.theme.color.black[500]} 70%,
    ${(props) => props.theme.color.black[60]},
    ${(props) => props.theme.color.black[10]},
    transparent
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.mobile} {
    align-items: center;
    padding-left: ${(props) => props.theme.spacing.xs};
  }
  @media ${device.tablet} {
    align-items: flex-start;
    padding-left: ${(props) => props.theme.spacing.xxxl};
  }
`;

const DetailText = styled(DhlText)`
  text-align: center;
  margin: ${(props) => props.theme.spacing.xs};
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  @media ${device.mobile} {
    justify-content: center;
  }
  @media ${device.tablet} {
    justify-content: flex-start;
  }
  margin-top: ${(props) => props.theme.spacing.xs};
  margin-bottom: ${(props) => props.theme.spacing.xs};
`;

function TopContent(props: any): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InnerWrapper theme={theme}>
        <DhlText
          id="welcome_text"
          size="md"
          color="#ffffff"
          title={t('App.page.PublicHome.welcome')}
        />

        <LogoContainer theme={theme}>
          <DhlImage alt="MySC Logo" src={MyScLogo} height="90" />
        </LogoContainer>

        <DetailText
          size="md"
          weight={100}
          color="#ffffff"
          theme={theme}
          title={t('App.page.PublicHome.oneStopSol')}
        />
      </InnerWrapper>
    </Wrapper>
  );
}

export default TopContent;
