import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@dhl-official/ui-libraries/duil-styles-all.css';
import { defineCustomElements } from '@dhl-official/ui-libraries/stencil-library/loader';
import App from './pages/App';
import ThemeProvider from './styles/ThemeContext';
import IconProvider from './assets/IconContext';
import LoadingProvider from './contexts/LoadingContext';
import NotificationProvider from './contexts/NotificationContext';
import LanguageProvider from './contexts/LanguageContext';
import CountryProvider from './contexts/CountryContext';
import { UserProvider } from './contexts/UserContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { msalConfig } from './authConfig';
import { PATHS } from './pages/App/constants';
import { createTheme, ThemeProvider as MuiThemeProvider, } from '@mui/material/styles';
import CustomerObjectProvider from "./contexts/CustomerObjectContext";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ServiceRequest from "./service/ServiceRequest";


export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((message) => {
    // set active account after login success or redirect
    if (
      message.eventType === EventType.LOGIN_SUCCESS ||
      message.eventType === EventType.HANDLE_REDIRECT_END
    ) {
        const accs = msalInstance.getAllAccounts();
        if (accs.length > 0) {
            msalInstance.setActiveAccount(accs[0]);
        }
        // Notify server about login process completed
        if (message.eventType === EventType.LOGIN_SUCCESS) {
            ServiceRequest.logLogin().catch(err => console.error(err));
        }
    }
});

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

library.add(fas);
library.add(far);
library.add(fab);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    typography: {fontFamily: "'Delivery'", fontSize: 13.5},
    palette: {
        mode: 'light',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#f50057',
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    paddingTop: '20px',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: ({theme}) => ({
                    overflow: 'hidden',
                    borderRadius: 4,
                    backgroundColor: 'transparent',
                    border: '1px solid',
                    borderColor: 'rgb(140, 140, 140)',
                    transition: theme.transitions.create([
                        'border-color',
                        'background-color',
                        'box-shadow',
                    ]),
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-focused': {
                        backgroundColor: 'transparent',
                        borderColor: 'rgb(140, 140, 140)',
                    },
                }),
                input: {
                    paddingTop: '20px',
                    paddingBottom: '3px',
                    '&::placeholder': {
                        fontSize: '13px',
                    },
                },
            },
            defaultProps: {
                disableUnderline: true,
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: ({theme}) => ({
                    margin: 0,
                    color: '#d40511',
                    fontSize: '14px',
                }),
            },
        },
        // MuiCheckbox: {
        //   styleOverrides: {
        //     root: ({ theme }) => ({
        //       border: '1px solid #8c8c8c',
        //       borderRadius: '4px',
        //       height: '24px',
        //       width: '24px',
        //       padding: '4px',
        //       marginRight: '5px',
        //     }),
        //   },
        // },
        MuiFormLabel: {
            styleOverrides: {
                root: ({theme}) => ({
                    fontSize: '14px',
                    color: 'rgb(102, 102, 102)',
                    transform: 'unset !important',
                    paddingLeft: '14px',
                    top: '0 !important',
                }),
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
        },
    },
});

root.render(
    // This is removed because of all the context APIs getting called twice due to this
    // <React.StrictMode>
    <ThemeProvider>
        <MuiThemeProvider theme={theme}>
            <IconProvider>
                <LoadingProvider>
                    <NotificationProvider>
                        <LanguageProvider>
                            <CountryProvider>
                                <MsalProvider instance={msalInstance}>
                                <UserProvider
                                    ignorePath={[
                                        PATHS.ERROR,
                                        PATHS.INACTIVE,
                                        PATHS.EXPIRED,
                                        PATHS.LOGOUT,
                                    ]}
                                >
                                    <CustomerObjectProvider>
                                        <App/>
                                    </CustomerObjectProvider>
                                </UserProvider>
                                </MsalProvider>
                            </CountryProvider>
                        </LanguageProvider>
                    </NotificationProvider>
                </LoadingProvider>
            </IconProvider>
        </MuiThemeProvider>
    </ThemeProvider>
    // </React.StrictMode>
);

// Ensure the defineCustomElements() function is called after ReactDOM.render()
// eslint-disable-next-line @typescript-eslint/no-floating-promises
defineCustomElements();