/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useContext } from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import { ThemeContext } from '../../../styles/ThemeContext';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../contexts/UserContext';
import { isEmpty } from 'lodash';
import DateTimeLabel from '../../../components/DateTimeLabel';
import { IconContext } from '../../../assets/IconContext';

const BoxWrapper = styled.div`
  width: 110%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: 1px 1px 2px 1px #e6e6e6;
  background-color: ${(props) => props.theme.background};
  border-radius: 4px;
  transition: 0.2s;
  :hover {
    transform: scale(1.01);
    z-index: 2;
  }
`;

const MyText = styled(DhlText)`
  margin-top: 10px;
`;

const TextWrapper = styled.div`
  padding-bottom: 10px;
  border-bottom: 2px solid red;
`;

const NewsWrapper = styled.div`
  margin-top: 10px;
`;

const NewsItemWrapper = styled.div`
  width: 100%;
  height: 80px;
  margin-top: 8px;
  box-shadow: 1px 1px 2px 1px #e6e6e6;
  border-radius: 4px;
  background-color: ${(props) => props.theme.background};
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

// const ImageItem = styled.img`
//   height: 100%;
//   width: 70px;
//   border-radius: 4px;
// `;

const MainImage = styled.div<{ src: any }>`
  width: 90px;
  height: auto;
  background-image: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-position: center;
  border-radius: 4px;
  background-repeat: no-repeat;
  `;

const NewsTextWrapper = styled.div`
  margin: 4px;
  display: flex;
  flex-direction: column;
`;

const DateText = styled(DateTimeLabel)`
  margin-top: 4px;
`;

function NewsContent(props: any): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { getIcon } = useContext(IconContext);
  const { t } = useTranslation();

  const onClickItem = (data: any): void => {
    if (data && !isEmpty(data.url)) window.open(data.url, '_blank');
  };

  return user?.news === null || user.news?.length < 1 ? (
    <></>
  ) : (
    <BoxWrapper theme={theme}>
      <TextWrapper>
        <MyText size="md" weight={600} title={t('App.page.Home.WhatsNew')} />
      </TextWrapper>

      <NewsWrapper>
        {user.news?.sort((a, b) => (a.sequenceNo < b.sequenceNo ? -1 : 1)).map((newsContent: any, index: number) => {
          const isImageNotAvailable =
            newsContent.image === null ||
            newsContent.image.mimeType === null ||
            newsContent.image.content === null ||
            isEmpty(newsContent.image) ||
            isEmpty(newsContent.image.mimeType) ||
            isEmpty(newsContent.image.content);
          return (
            <NewsItemWrapper
              key={newsContent.id}
              theme={theme}
              onClick={() => onClickItem(newsContent)}
              data-tracking={newsContent.header}
            >
              <MainImage
                src={
                  isImageNotAvailable
                    ? getIcon('MyScPlaceholder')
                    : `data:${newsContent.image.mimeType};base64,${newsContent.image.content}`
                }
              />

              <NewsTextWrapper>
                <DhlText size="sm" weight={600} title={newsContent.header} />
                <DateText datetime={newsContent.updated} textSize="12px" />
              </NewsTextWrapper>
            </NewsItemWrapper>
          );
        })}
      </NewsWrapper>
    </BoxWrapper>
  );
}

export default NewsContent;
