/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import './Header.css';
import { ThemeContext } from '../../styles/ThemeContext';
import { UserContext } from '../../contexts/UserContext';
import styled from 'styled-components';
import NavBar from './NavBar';
import useMediaQuery from '../../styles/MediaQueryHooks';
import SideDrawer from './SideDrawer';
import { useTranslation } from "react-i18next";
import Impersonator from '../Impersonator';
import { DhlIcon, DhlModal } from '@dhl-official/ui-libraries/react-library';
import { device } from '../../styles/Device';
import DhlText from '../DhlText';
import Avtar from '../../pages/ProfilePage/components/Avtar';
import { IconContext } from '../../assets/IconContext';
import { LoadingContext } from '../../contexts/LoadingContext';
import ServiceRequest from '../../service/ServiceRequest';
import { NOTIFICATION_TYPES, NotificationContext } from '../../contexts/NotificationContext';
import DhlButton from '../DhlButton';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../pages/App/constants';

const linearGradientNormal = `
90deg,
#ffcc00,
#ffcc00 48%,
#ffe57f 70%,
#fff0b2
`;

const Wrapper = styled.div`
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    ${(props) =>
    props.theme.isBrandingObjectShowing && props.theme.matches
      ? props.theme.linearColor
      : linearGradientNormal}
  );
`;

const ImpersonatorModal = styled(DhlModal)`
  @media ${device.mobile} {
    .dialog-container > dhl-card {
      width: 90%;
    }
  }
  @media ${device.tablet} {
    .dialog-container > dhl-card {
      width: 674px;
    }
  }
  .dialog-container {
    max-height: calc(100vh - 10px);
    overflow-y: auto;
  }
`;

const ImpersonatedUserWrapper = styled.div`
  min-height: 45px;
  max-height: 45px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: ${(props) => props.theme.color.red[500]};
`;

const ImpersonatedUserName = styled(DhlText)`
  margin-left: 5px;
`;

const ViewingAs = styled(DhlText)`
  margin-right: 10px;
`;

const RightIcon = styled(DhlIcon)`
  margin-top:2px;
  margin-left: 15px;
  object-fit: contain;
  user-select: none;
  pointer-events: auto;
`;

const TitleModalText = styled(DhlText)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const CloseImpersonatorSessionModal = styled(DhlModal)`
  @media ${device.mobile} {
    .dialog-container > dhl-card {
      width: 90%;
    }
  }
  @media ${device.tablet} {
    .dialog-container > dhl-card {
      width: 674px;
    }
  }
  .dialog-container {
    max-height: calc(100vh - 10px);
    overflow-y: auto;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  @media ${device.mobile} {
      margin-top: 0;
  }
  @media ${device.tablet} {
      margin-top: 30px;
  }
`;

const LeftButton = styled(DhlButton)`
  display: flex;
   @media ${device.mobile} {
      margin-left: 0%;
      margin-right: 5px;
  }
  @media ${device.tablet} {
      margin-left: 56%;
      margin-right: 10px;
  }
  
`;

const RightButton = styled(DhlButton)`
  display: flex;
`;


function Header(): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);
  const { setType, setMessage } = useContext(NotificationContext);
  const [impersonatedSession, setImpersonatedSession] = useState<Boolean>(false);
  const [impersonatorSessionModal, setImpersonatorSessionModal] = useState(false);
  const [impersonatorModal, setImpersonatorModal] = useState(false);
  const { t, i18n } = useTranslation();
  const { getIcon } = useContext(IconContext);


  const isBrandingObjectShowing =
    !!user.brandingObject && user.brandingObject.stylesheetId === 2;

  const brandingColor =
    user?.brandingObject?.backgroundColor !== undefined
      ? user.brandingObject.backgroundColor
      : null;

  const selectedLanguage = localStorage.getItem(`MSC-${process.env.REACT_APP_ENVIRONMENT}-language`) ? localStorage.getItem(`MSC-${process.env.REACT_APP_ENVIRONMENT}-language`) : i18n.language;

  const matches = useMediaQuery('(min-width: 900px)');
  /* eslint-disable @typescript-eslint/restrict-template-expressions */
  const linearColor = `to right, rgb(254, 203, 46) 63%, rgb(255, 229, 127) 63%, rgb(255, 240, 178) 63%, ${brandingColor !== null ? brandingColor : 'rgb(255, 240, 178)'
    } 55%`;

   const handleCloseImpersonationSession = (): void => {
      setLoading(true);
      ServiceRequest.getStopImpersonatedUserSession()
          .then((response) => {
              if (response.status === 200) {
                  ServiceRequest.getProfilePicture()
                  .then(profilePictureResponse => setUser({
                    ...response.data,
                    avatar: profilePictureResponse.data
                }))
                .catch((error) => {
                  setMessage(error.message);
                  setType(NOTIFICATION_TYPES.FAILED);
                })
                setUser(response.data);
                setMessage(t('App.page.Impersonator.impersonation.close.success'));
                setType(NOTIFICATION_TYPES.SUCCESS);
                setImpersonatorSessionModal(false);
                localStorage.clear();
                navigate(PATHS.HOME);
              } else {
                setMessage(t('App.page.Impersonator.impersonation.synchronizationError'));
                setType(NOTIFICATION_TYPES.FAILED);
              }
          })
          .catch((error) => {
              setMessage(error?.response?.data?.message);
              setType(NOTIFICATION_TYPES.FAILED);
          }
          )
          .finally(() => {
              
              setLoading(false);
          });
  }
  useEffect(() => {
    if (user?.impersonated === true) {
      setImpersonatedSession(true);
    } else {
      setImpersonatedSession(false);
    }

  }, [user]);

  return (
    <>
      <CloseImpersonatorSessionModal
          isOpen={impersonatorSessionModal}
          shouldShowCloseButton={false}
          handleOnClose={() => setImpersonatorSessionModal(false)}
        >
        <TitleModalText 
          title={`${t('app.containers.Header.impersonator.exitImpersonationMode')}`}
          size="xl"
          weight={600}
         />
         <TitleModalText 
          title={`${t('app.containers.Header.impersonator.exitImpersonationMode.subtitle')}`}
          size="md"
          weight={300}
         />
         <ButtonsWrapper>
          <LeftButton
              size="md"
              variant="outline"
              title={t('App.common.cancel')}
              onClick={() => setImpersonatorSessionModal(false)}
            />  
          <RightButton
              size="md"
              variant="primary"
              title={t('App.common.accept')}
              onClick={() => handleCloseImpersonationSession()}
            />  
          </ButtonsWrapper>
      </CloseImpersonatorSessionModal>
      
      <ImpersonatorModal
          isOpen={impersonatorModal}
          shouldShowCloseButton={false}
          handleOnClose={() => setImpersonatorModal(false)}
        >
        <Impersonator
          setImpersonatorModal={setImpersonatorModal}
        />
      </ImpersonatorModal>
      {
        (impersonatedSession) && (
          <ImpersonatedUserWrapper theme={theme}>
            <ViewingAs
              title={`${t('app.containers.Header.impersonator.viewingAs')}: `}
              size="sm"
              weight={300}
              color="#ffffff"
            />
            <Avtar isSmall={true} noAvatar={true} />
            <ImpersonatedUserName
              title={user?.firstName || user?.lastName ? `${user?.firstName} ${user?.lastName}` : ''}
              size="md"
              weight={600}
              color="#ffffff"
            />
            <RightIcon
              alt="closeImpersonatedSession"
              size="20px"
              src={getIcon('crossDark')}
              onMouseDown={() => setImpersonatorSessionModal(true)}
            />
          </ImpersonatedUserWrapper>
        )

      }
      <Wrapper
        theme={{
          ...theme,
          isBrandingObjectShowing,
          brandingColor,
          linearColor,
          matches,
        }}
      >
        {matches ? (
          <NavBar
            isBrandingObjectShowing={isBrandingObjectShowing}
            brandingColor={brandingColor}
            selectedLanguage={selectedLanguage ? selectedLanguage : "en"}
            setImpersonatorModal={setImpersonatorModal}
          />
        ) : (
          <SideDrawer
            selectedLanguage={selectedLanguage ? selectedLanguage : "en"}
            setImpersonatorModal={setImpersonatorModal}
          />
        )}
      </Wrapper>
    </>
  );
}

export default Header;
