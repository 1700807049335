import React, { Fragment, ReactElement } from 'react';
import Dropzone, { Accept, DropEvent, FileRejection } from 'react-dropzone';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  isDisabled: boolean;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  dropzoneText: any;
  additionalDropzoneText?: any;
  accept: Accept;
  maxSize?: number;
  maxFiles?: number;
  customClass?: string;
  hideIcon?: boolean;
};

export default class FileInput extends React.PureComponent<Props> {
  render(): ReactElement {
    return (
      <Dropzone
        accept={this.props.accept}
        disabled={this.props.isDisabled}
        onDrop={this.props.onDrop}
        maxSize={this.props.maxSize}
        maxFiles={this.props.maxFiles}
      >
        {({getRootProps, getInputProps, isDragActive}) => (
          <div
            {...getRootProps()}
            className={classNames('dropzone', this.props.customClass, {
              'dropzone--isActive': isDragActive,
              dropzone_disabled: this.props.isDisabled,
            })}
          >
            <input {...getInputProps()} />
            {
              <Fragment>
                {this.props?.hideIcon != null && (
                  <FontAwesomeIcon icon="paperclip" size="3x"/>
                )}
                <p
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '5px',
                  }}
                >
                  {this.props.dropzoneText}
                </p>
                {this.props.additionalDropzoneText != null && (
                  <p
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '5px',
                    }}
                  >
                    {this.props.additionalDropzoneText}
                  </p>
                )}
              </Fragment>
            }
          </div>
        )}
      </Dropzone>
    );
  }
}
