/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-base-to-string */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, {useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {device} from '../../styles/Device';
import ServiceRequest from '../../service/ServiceRequest';
import {LoadingContext} from '../../contexts/LoadingContext';
import {
    NOTIFICATION_TYPES,
    NotificationContext,
} from '../../contexts/NotificationContext';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';
import {PATHS} from '../App/constants';
import DhlButton from '../../components/DhlButton';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {
    DhlDropdown, DhlImage,
    DhlInputField,
    DhlSwitch,
} from '@dhl-official/ui-libraries/react-library';
import {CountryContext} from '../../contexts/CountryContext';
import {LanguageContext} from '../../contexts/LanguageContext';
import DhlText from '../../components/DhlText';
import {ThemeContext} from '../../styles/ThemeContext';
import Avtar from './components/Avtar';
import {isValidPhoneNumber} from '../../utils';
import {useNavigate} from 'react-router';
import JobFunctionType from '../../contexts/types/JobFunctionType';
import JobRoleType from '../../contexts/types/JobRoleType';
import {filterInner} from '../common';
import {isEmpty} from 'lodash';
import {IconContext} from "../../assets/IconContext";
import TermsModel from "./components/TermsModel";
import { UserContext } from '../../contexts/UserContext';

const PageWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.secondaryBg};
`;

const PageBlockWrapper = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-wrap: wrap;
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
  }
  @media ${device.laptop} {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  @media ${device.desktop} {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  width: 100%;
`;

const PageBlock = styled.div`
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    flex-wrap: nowrap;
    width: 95%;
  }
  @media ${device.laptop} {
    flex-wrap: nowrap;
    width: 60%;
  }
  @media ${device.desktop} {
    flex-wrap: nowrap;
    width: 60%;
  }
  padding: 16px;
  margin-left: 1%;
  display: flex;
  z-index: 1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  flex-direction: row;
  border-radius: 4px;
  background-color: ${(props) => props.theme.background};
`;

const PageBlock2 = styled.div`
  @media ${device.mobile} {
    flex-wrap: wrap;
    margin-top: 16px;
  }
  @media ${device.tablet} {
    flex-wrap: nowrap;
    width: 95%;
    margin-top: 16px;
  }
  @media ${device.laptop} {
    flex-wrap: nowrap;
    width: 35%;
    margin-top: 0px;
    padding-right: 30px;
  }
  @media ${device.desktop} {
    flex-wrap: nowrap;
    width: 35%;
    margin-top: 0px;
    padding-right: 52px;
  }
  display: none;
  padding: 16px;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: ${(props) => props.theme.background};
`;

const TopWrapper = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin-left: 2%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonItem = styled(DhlButton)`
  margin: 10px;
`;

// const AuditText = styled(DhlText)`
//   margin-left: 8px;
//   margin-top: 8px;
// `;

const FormWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 80%;
  }
  min-width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ImageWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 20%;
  }
  min-width: 200px;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: center;
`;

// const TitleWrapper = styled.div`
//   width: 95%;
//   padding: 8px;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
// `;
//
// const AuditWrapper = styled.div`
//   @media ${device.mobile} {
//     width: 100%;
//   }
//   @media ${device.tablet} {
//     width: 48%;
//   }
//   padding: 8px;
//   display: flex;
//   flex-direction: column;
//   flex-wrap: wrap;
//   border: 0.5px solid gray;
//   border-radius: 4px;
// `;

const FormField = styled(DhlInputField)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const FormDropDown = styled(DhlDropdown)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

// const CheckboxWrapper = styled.div`
//   @media ${device.mobile} {
//     width: 90%;
//     margin-top: 8px;
//     margin-bottom: 8px;
//   }
//   @media ${device.tablet} {
//     width: 48%;
//     margin-top: 16px;
//     margin-bottom: 16px;
//   }
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   align-items: center;
//   justify-content: space-between;
// `;

const CheckboxWrapper2 = styled.div`
  @media ${device.mobile} {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
    width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const SectorWrapper = styled.div<{ isDisable: boolean }>`
  display: flex;
  margin-top: 10px;
  padding: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${(props) => props.isDisable ? props.theme.secondaryBg : null};
  :hover {
    z-index: 1.3;
    cursor: 'pointer';
  }
`;

const CheckImage = styled(DhlImage)`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`;

const CheckSector = styled.div<{ isCheck: boolean, isDisable: boolean }>`
  padding: 4px;
  padding-left : 8px;
  padding-right : 8px;
  display: flex;
  margin-right: 10px;
  margin-top: 4px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => !props.isCheck ? null : props.isDisable ? props.theme.color.grey[500] : props.theme.color.red[400]};
  border-radius: 4px;
  border: ${(props) => !props.isCheck ? '1px solid #666666' : props.isDisable ? `1px solid #333333` : `1px solid #eb131e`};
  font-size: 14px;
  color: ${(props) => props.isCheck ? '#ffffff' : '#666666'};
  :hover {
    z-index: 3;
    transform: scale(1.02);
  }
`;

const myBreadCrumbList = [
    {label: 'App.breadcrumb.home', path: PATHS.HOME},
    {label: 'App.breadcrumb.profile', path: ''},
];

interface IFormInputs {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    country: any;
    companyName: string;
    jobRole: string;
    jobFunction: string;
    language: any;
    isSubscribed: boolean;
    hideInsights: string;
}

function ProfilePage(): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {setLoading} = useContext(LoadingContext);
    const {user} = useContext(UserContext);
    const {setType, setMessage} = useContext(NotificationContext);
    const {countries} = useContext(CountryContext);
    const {languages} = useContext(LanguageContext);
    const {getIcon} = useContext(IconContext);
    const {t, i18n} = useTranslation();
    const [sectorsList, setSectorsList] = useState<any[]>([]);
    const [selectedSectorsList, setSelectedSectorsList] = useState<any[]>([]);
    const [jobRoles, setJobRoles] = useState<JobRoleType[]>([]);
    const [jobFunctions, setJobFunction] = useState<JobFunctionType[]>([]);
    // const [created, setCreated] = useState('');
    const navigate = useNavigate();
    const [countryFilter, setCountryFilter] = useState('');
    const [languageFilter, setLanguageFilter] = useState('');
    const [jobFunctionFilter, setJobFunctionFilter] = useState('');
    const [jobRoleFilter, setJobRoleFilter] = useState('');
    const [openConsent, setOpenConsent] = useState(false);
    const [consentSubmitted, setConsentSubmitted] = useState(false);
    const [submitData, setSubmitData] = useState<any>(null);
    // const parsedResponseJson = isEmpty(created) ? null : JSON.parse(created);

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: {errors},
    } = useForm<IFormInputs>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            language: '',
            country: '',
            phoneNumber: '',
            companyName: '',
            jobRole: '',
            jobFunction: '',
            isSubscribed: false,
            hideInsights: 'F',
        },
    });

    const isInsightsSelected = watch("hideInsights");

    const getProfile = (): void => {
        ServiceRequest.getProfile()
            .then((response) => {
                setValue('firstName', response.data.first_name);
                setValue('lastName', response.data.last_name);
                setValue('email', response.data.email);
                setValue('phoneNumber', response.data.phone);
                setValue('language', response.data.language?.id?.toString());
                setValue('jobFunction', response.data.job_function?.id?.toString());
                // setValue('isSubscribed', response.data.isSubscribed);
                setValue('hideInsights', response.data.insights_visibility === true ? "T" : '');
                setValue('jobRole', response.data.job_role?.id?.toString());
                setValue('companyName', response.data.company);
                setValue('country', response.data.country?.id?.toString());
                if (response.data.insights_sectors != null && response.data.insights_sectors?.length > 0)
                    setSelectedSectorsList(response.data.insights_sectors);
                setConsentSubmitted(response.data?.terms_accepted);
                // setCreated(response.data.responseJson);
            })
            .catch((error) => {
                setMessage(error);
                setType(NOTIFICATION_TYPES.FAILED);
            }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        // GET SECTORS
        ServiceRequest.getSectors()
            .then((response) => {
                setSectorsList(response.data);
            })
            .catch((error) => {
                setMessage(error?.response?.data?.message);
                setType(NOTIFICATION_TYPES.FAILED);
            });

        // GET JOB ROLES
        ServiceRequest.getJobRole()
            .then((response) => {
                setJobRoles(response.data);
            })
            .catch((error) => {
                setMessage(error);
                setType(NOTIFICATION_TYPES.FAILED);
            });

        // GET JOB FUNCTIONS
        ServiceRequest.getJobFunction()
            .then((response) => {
                setJobFunction(response.data);
            })
            .catch((error) => {
                setMessage(error);
                setType(NOTIFICATION_TYPES.FAILED);
            })
            .finally(() => {
                // GET USER PROFILE
                getProfile();
            });
    }, []);

    const myJobFunctionData = useMemo(
        () => filterInner(jobFunctionFilter, jobFunctions, 'id'),
        [jobFunctions, jobFunctionFilter]
    );
    const myJobRoleData = useMemo(
        () => filterInner(jobRoleFilter, jobRoles, 'id'),
        [jobRoles, jobRoleFilter]
    );
    const myCountryData = useMemo(
        () => filterInner(countryFilter, countries, 'id'),
        [countries, countryFilter]
    );
    const myLanguageData = useMemo(
        () => filterInner(languageFilter, languages, 'languageId'),
        [languages, languageFilter]
    );

    const onClickSector = (sector: any, isSectorSelected: boolean): void => {
        if (isInsightsSelected === "T") {
            let myUpdatedSectorList: any[] = [];
            if (isSectorSelected) {
                myUpdatedSectorList = selectedSectorsList.filter((sec: any) => sec.id !== sector.id);
            } else {
                myUpdatedSectorList = [...selectedSectorsList, sector];
            }
            setSelectedSectorsList(myUpdatedSectorList);
        }
    }

    const onSubmitData = (data: any): void => {
        setConsentSubmitted(true);
        setOpenConsent(false);
        const objectRequest = {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            language: data.language,
            countryId: data.country,
            phone: data.phoneNumber,
            company: data.companyName,
            job_role: data.jobRole,
            job_function: data.jobFunction,
            terms_accepted: true,
            // isSubscribed: data.isSubscribed,
            // hideInsights: data.hideInsights,
            insights_visibility: data.hideInsights === 'T',
            insights_sectors: selectedSectorsList,
        };

        const selectedLanguage = languages.find(
            (lang) => String(lang.languageId) === data.language
        );

        if (!isEmpty(selectedLanguage) && !isEmpty(selectedLanguage.code)) {
            localStorage.setItem(`MSC-${process.env.REACT_APP_ENVIRONMENT}-language`, selectedLanguage.code);
            void i18n.changeLanguage(selectedLanguage.code);
        }

        setLoading(true);

        ServiceRequest.updateProfile(objectRequest)
            .then((response) => {
                if (response.status === 200) {
                    setMessage(t('App.page.profile.successUpdate'));
                    setType(NOTIFICATION_TYPES.SUCCESS);
                } else {
                    setMessage(`ERROR :- ${response.status}`);
                    setType(NOTIFICATION_TYPES.FAILED);
                }
            })
            .catch((error) => {
                if(error?.response?.data?.message !== undefined){
                    setMessage(error.response.data.message);
                } else {
                    setMessage(error.message);
                }
                setType(NOTIFICATION_TYPES.FAILED);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onClickSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
        setSubmitData(data);
        if (consentSubmitted) {
            setTimeout(() => {
                onSubmitData(data);
            }, 500);
        } else {
            setOpenConsent(true);
        }
    }
    if(user?.impersonated ?? false){
        setMessage(t('App.page.Impersonator.impersonation.notAllowedInImpersonationMode'));
        setType(NOTIFICATION_TYPES.FAILED);
        return (<></>);
    } else {
        return (
            <PageWrapper theme={theme}>
                <form onSubmit={handleSubmit(onClickSubmit)}>
                    <TopWrapper theme={theme}>
                        <DhlBreadCrumbs list={myBreadCrumbList}/>
    
                        <ButtonWrapper>
                            <ButtonItem
                                title={t('App.common.cancel')}
                                variant="outline"
                                size="sm"
                                onClick={() => navigate(-1)}
                            />
    
                            <ButtonItem
                                title={t('App.common.save')}
                                variant="primary"
                                size="sm"
                                type="submit"
                            />
                        </ButtonWrapper>
                    </TopWrapper>
    
                    <PageBlockWrapper>
    
                        <PageBlock theme={theme}>
                            <ImageWrapper theme={theme}>
                                {/* <FontAwesomeIcon icon="fa-solid fa-user fa-5x" /> */}
                                <Avtar isSmall={false}/>
                            </ImageWrapper>
    
                            <FormWrapper theme={theme}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({field: {onChange, onBlur, ...propsField}}) => (
                                        <FormField
                                            {...propsField}
                                            dataId="firstName-inputfield"
                                            type="text"
                                            isDisabled
                                            validation={
                                                errors.firstName != null && {
                                                    type: 'invalid',
                                                }
                                            }
                                            blurEvent={onBlur}
                                            inputEvent={onChange}
                                            variant={{
                                                label: t('App.page.ExternalUser.firstName'),
                                                type: 'animated',
                                            }}
                                        />
                                    )}
                                />
    
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({field: {onChange, onBlur, ...propsField}}) => (
                                        <FormField
                                            {...propsField}
                                            dataId="lastName-inputfield"
                                            type="text"
                                            isDisabled
                                            validation={
                                                errors.lastName != null && {
                                                    type: 'invalid',
                                                }
                                            }
                                            blurEvent={onBlur}
                                            inputEvent={onChange}
                                            variant={{
                                                label: t('App.page.ExternalUser.lastName'),
                                                type: 'animated',
                                            }}
                                        />
                                    )}
                                />
    
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({field: {onChange, onBlur, ...propsField}}) => (
                                        <FormField
                                            {...propsField}
                                            dataId="email-inputfield"
                                            type="text"
                                            isDisabled
                                            validation={
                                                errors.email != null && {
                                                    type: 'invalid',
                                                }
                                            }
                                            blurEvent={onBlur}
                                            inputEvent={onChange}
                                            variant={{
                                                label: t('App.page.ExternalUser.email'),
                                                type: 'animated',
                                            }}
                                        />
                                    )}
                                />
    
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    rules={{validate: (value) => isValidPhoneNumber(value)}}
                                    render={({field: {onChange, onBlur, ...propsField}}) => (
                                        <FormField
                                            {...propsField}
                                            dataId="phoneNumber-inputfield"
                                            validation={
                                                errors.phoneNumber != null && {
                                                    type: 'invalid',
                                                    message: 'Invalid Phone Number.',
                                                }
                                            }
                                            blurEvent={onBlur}
                                            inputEvent={onChange}
                                            variant={{
                                                label: t('App.page.ExternalUser.phoneNumber'),
                                                type: 'animated',
                                            }}
                                        />
                                    )}
                                />
    
                                <Controller
                                    name="companyName"
                                    control={control}
                                    render={({field: {onChange, onBlur, ...propsField}}) => (
                                        <FormField
                                            {...propsField}
                                            dataId="companyName-inputfield"
                                            type="text"
                                            validation={
                                                errors.companyName != null && {
                                                    type: 'invalid',
                                                }
                                            }
                                            blurEvent={onBlur}
                                            inputEvent={onChange}
                                            variant={{
                                                label: t('App.page.ExternalUser.companyName'),
                                                type: 'animated',
                                            }}
                                        />
                                    )}
                                />
    
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({field: {onChange, onBlur, ...propsField}}) => (
                                        <FormDropDown
                                            {...propsField}
                                            dataId="country-inputfield"
                                            data={myCountryData}
                                            validation={
                                                errors.country != null && {
                                                    type: 'invalid',
                                                }
                                            }
                                            blurEvent={onBlur}
                                            changeEvent={onChange}
                                            filterChangeEvent={(filter: string) =>
                                                setCountryFilter(filter)
                                            }
                                            label={t('App.page.ExternalUser.country')}
                                            showFilter
                                            filterPlaceholder={t('App.page.ExternalUser.searchCountry')}
                                        />
                                    )}
                                />
    
                                <Controller
                                    name="jobRole"
                                    control={control}
                                    render={({field: {onChange, onBlur, ...propsField}}) => (
                                        <FormDropDown
                                            {...propsField}
                                            dataId="jobRole-inputfield"
                                            data={myJobRoleData}
                                            blurEvent={onBlur}
                                            changeEvent={onChange}
                                            filterChangeEvent={(filter: string) =>
                                                setJobRoleFilter(filter)
                                            }
                                            label={t('App.page.ExternalUser.jobRole')}
                                            showFilter
                                            filterPlaceholder={t('App.common.search')}
                                        />
                                    )}
                                />
    
                                <Controller
                                    name="jobFunction"
                                    control={control}
                                    render={({field: {onChange, onBlur, ...propsField}}) => (
                                        <FormDropDown
                                            {...propsField}
                                            dataId="jobFunction-inputfield"
                                            data={myJobFunctionData}
                                            blurEvent={onBlur}
                                            changeEvent={onChange}
                                            filterChangeEvent={(filter: string) =>
                                                setJobFunctionFilter(filter)
                                            }
                                            label={t('App.page.ExternalUser.jobFunction')}
                                            showFilter
                                            filterPlaceholder={t('App.common.search')}
                                        />
                                    )}
                                />
    
                                {/* <CheckboxWrapper> */}
                                {/*    <DhlText */}
                                {/*        size="sm" */}
                                {/*        weight={600} */}
                                {/*        title={t('App.page.profile.optIn')} */}
                                {/*    /> */}
    
                                {/*    <Controller */}
                                {/*        name="isSubscribed" */}
                                {/*        control={control} */}
                                {/*        render={({field: {onChange, value, ...propsField}}) => ( */}
                                {/*            <DhlSwitch */}
                                {/*                {...propsField} */}
                                {/*                dataId="emailSwitch-inputfield" */}
                                {/*                isChecked={value} */}
                                {/*                changeEvent={onChange} */}
                                {/*                validation={{ */}
                                {/*                    type: 'valid', */}
                                {/*                }} */}
                                {/*            /> */}
                                {/*        )} */}
                                {/*    /> */}
                                {/* </CheckboxWrapper> */}
    
                                {/* <TitleWrapper theme={theme}> */}
                                {/*    <DhlText */}
                                {/*        size="sm" */}
                                {/*        weight={600} */}
                                {/*        title={t('App.page.profile.preference')} */}
                                {/*    /> */}
                                {/* </TitleWrapper> */}
    
                                <Controller
                                    name="language"
                                    control={control}
                                    render={({field: {onChange, onBlur, ...propsField}}) => (
                                        <FormDropDown
                                            {...propsField}
                                            dataId="language-inputfield"
                                            data={myLanguageData}
                                            blurEvent={onBlur}
                                            changeEvent={onChange}
                                            validation={
                                                errors.language != null && {
                                                    type: 'invalid',
                                                }
                                            }
                                            filterChangeEvent={(filter: string) => {
                                                setLanguageFilter(filter);
                                            }}
                                            label={t('App.page.profile.language')}
                                            showFilter
                                            filterPlaceholder={t('App.page.ExternalUser.searchLanguage')}
                                        />
                                    )}
                                />
    
                                {/* <TitleWrapper theme={theme}> */}
                                {/*    <DhlText */}
                                {/*        size="sm" */}
                                {/*        weight={600} */}
                                {/*        title={t('App.page.profile.audit')} */}
                                {/*    /> */}
                                {/* </TitleWrapper> */}
    
                                {/* <AuditWrapper> */}
                                {/*    {parsedResponseJson != null && ( */}
                                {/*        <AuditText */}
                                {/*            size="sm" */}
                                {/*            title={`Created By:  ${parsedResponseJson.createdBy}`} */}
                                {/*        /> */}
                                {/*    )} */}
                                {/*    {parsedResponseJson != null && ( */}
                                {/*        <AuditText */}
                                {/*            size="sm" */}
                                {/*            title={`Created On:  ${moment( */}
                                {/*                new Date(parsedResponseJson.createdOn) */}
                                {/*            ).format(DATE_FORMATTERS.FORMATTER2)}`} */}
                                {/*        /> */}
                                {/*    )} */}
                                {/*    <AuditText */}
                                {/*        size="sm" */}
                                {/*        title={`Last Login:  ${moment(new Date(user.lastLogin)).format( */}
                                {/*            DATE_FORMATTERS.FORMATTER2 */}
                                {/*        )}`} */}
                                {/*    /> */}
                                {/* </AuditWrapper> */}
                            </FormWrapper>
                        </PageBlock>
    
                        <PageBlock2 theme={theme}>
    
                            <CheckboxWrapper2>
                                <Controller
                                    name="hideInsights"
                                    control={control}
                                    render={({field: {onChange, value, ...propsField}}) => (
                                        <DhlSwitch
                                            {...propsField}
                                            dataId="hideInsights-inputfield"
                                            isChecked={value === 'T'}
                                            changeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setValue('hideInsights', e.target?.checked ? 'T' : 'F');
                                            }
                                            }
                                            validation={{
                                                type: 'valid',
                                            }}
                                        />
                                    )}
                                />
                                <DhlText
                                    size="md"
                                    weight={600}
                                    title={t('App.page.profile.tailoredInsights')}
                                />
                            </CheckboxWrapper2>
    
                            <DhlText
                                size="sm"
                                title={t('App.page.profile.tailoredInsightsDetails')}
                            />
    
                            {sectorsList.length > 0 && <SectorWrapper theme={theme} isDisable={isInsightsSelected !== 'T'}>
                                {sectorsList.map((sector: any) => {
                                    let isSectorSelected = false;
    
                                    selectedSectorsList.forEach((sec: any) => {
                                        if (sec.id === sector.id) {
                                            isSectorSelected = true;
                                        }
                                    });
    
                                    return <CheckSector
                                        key={sector.id}
                                        isCheck={isSectorSelected}
                                        theme={theme}
                                        isDisable={isInsightsSelected !== 'T'}
                                        onClick={() => onClickSector(sector, isSectorSelected)}>
                                        {isSectorSelected && <CheckImage src={getIcon('checkIcon')}/>}
                                        {sector.name}
                                    </CheckSector>
                                })}
                            </SectorWrapper>}
    
                        </PageBlock2>
    
                        <TermsModel
                            isOpen={openConsent}
                            handleClose={() => setOpenConsent(false)}
                            submitAccept={() => onSubmitData(submitData)}/>
    
                    </PageBlockWrapper>
                </form>
            </PageWrapper>
        );
    }

    }

export default ProfilePage;
