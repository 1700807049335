import React, {useContext} from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import {ThemeContext} from '../../../styles/ThemeContext';
import {
    DhlImage,
} from '@dhl-official/ui-libraries/react-library';
import {IconContext} from '../../../assets/IconContext';
import {isEmpty} from '../../../utils';
import {device} from "../../../styles/Device";

const BoxWrapper = styled.div`
   @media ${device.mobile} {
    margin: 6px;
  }
  @media ${device.laptop} {
    width: 200px;
    margin: 8px;
  }
  @media ${device.largeDesktop} {
    width: 250px;
    margin: 16px;
  }
  height: auto;
  padding: 4px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  box-shadow: 1px 1px 2px 1px #e6e6e6;
  background-color: ${(props) => props.theme.background};
  border-radius: 2px;
  transition: 0.2s;
  :hover {
    box-shadow: 1px 1px 2px 1px #999999;
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 67px;
  margin-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-shrink: 0;
  overflow:  hidden;
`;

const DhlImageWrapper = styled(DhlImage)`
  width: 100%;
  height: 130px;
  margin-top: 6px;
  margin-bottom: 6px;
  object-fit: contain;
  object-position: center;
`;

const TextDescWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  padding-left: 10px;
  padding-right: 4px;
  flex-direction: column;
`;

const MyDescText = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  font-size: 12px;
  text-overflow: ellipsis;
`;

const TextMoreWrapper = styled.div`
  width: 100%;
  height: 20px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
`;

function InsightsItem(props: any): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const mobileWidth = window.innerWidth-100;
    const imageWidth = window.innerWidth < 600 ? mobileWidth : '240px';

    const {
        title,
        description,
        // type,
        // language,
        url,
        // date,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        image_url
    } = props.itemDetails;

    const onClickBox = (url: string): void => {
        window.open(url);
    };

    return (
        <BoxWrapper
            data-tracking={title}
            theme={theme}>

            <TextWrapper onClick={() => onClickBox(url)}>
                <DhlText
                    size="md"
                    weight={600}
                    title={title}
                />
            </TextWrapper>

            {isEmpty(image_url) ? (
                <DhlImageWrapper src={getIcon('applicationIcon')} width={imageWidth} height="130px"/>
            ) : (
                <DhlImageWrapper theme={theme} src={image_url} width={imageWidth} height="130px"/>
            )}

            <TextDescWrapper>
              <MyDescText>{description}</MyDescText>
            </TextDescWrapper>

            <TextMoreWrapper onClick={() => onClickBox(url)}>
                <DhlText
                    size="sm"
                    weight={600}
                    title={"Learn More  >"}
                    color={'#d40511'}
                />
            </TextMoreWrapper>

        </BoxWrapper>
    );
}

export default InsightsItem;
