/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { logAuditEvent } from '../service/ServiceRequest';
import { ACTION_LOGOUT } from '../pages/App/constants';
import { msalInstance } from "../index";

export const getBackUrl = (): string => {
    if (window.location.hostname === 'localhost')
        return 'https://mysupplychain-fat.dhl.com';
    else
        return `${window.location.protocol}//${window.location.hostname}${
            window.location.port.length > 0 ? `:${window.location.port}` : ''
        }`;
};

// export const login = (): void => {
    // const backUrl = getBackUrl();
    // window.location.href = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_MSC_PATH}/login?backUrl=${backUrl}`;
// };

export const logout = (): void => {
    logAuditEvent(ACTION_LOGOUT)
      .then(() => {
            msalInstance
              .logoutRedirect()
              .catch(err => console.error(err));
        }
      )
      .catch(err => console.error(err));
};

export const fontSizeMapData = [
    {label: 'App.accessibility.extraSmall', value: 1},
    {label: 'App.accessibility.small', value: 2},
    {label: 'App.accessibility.average', value: 3},
    {label: 'App.accessibility.large', value: 4},
    {label: 'App.accessibility.extraLarge', value: 5},
];

export function isEmpty(params: any): boolean {
    if (params === '' || params === undefined || params === null) return true;
    else return false;
}

export function hasNumber(myString: any): boolean {
    return /\d/.test(myString);
}

export function isValidPhoneNumber(myString: any): boolean {
    if (isEmpty(myString)) return true;
    else return /\d[0-9]{9}$/.test(myString);
}

export function processConsent(e: any): void {
    console.error(e);
}

function dec2hex(dec: any): any {
    return dec.toString().padStart(2, "0")
}

// generateId :: Integer -> String
export function generateId(len: number): string {
    const arr = new Uint8Array(len / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('')
}

export function getErrorMessage(type: any, t: any): any {
    switch (type) {
        case 'Required' : return t('App.page.Branding.required');

    }
}

export const DATE_FORMATTERS = {
    FORMATTER1: 'DD/MM/YYYY hh:mm A',
    FORMATTER2: 'YYYY-MM-DD hh:mm',
};

export const validateDHLEmailDomain = (email: String): boolean => {
    const regExp = `^[a-zA-Z0-9._%+-]+@dhl.com$`;
    const match = email.match(regExp);
    if(match !== null){
      return true;
    } else {
      return false;
    }
}
