/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../../styles/ThemeContext';
import TableRow from '../TableRow';
import TypeTable from './TypeTable';
import { GroupType } from '../../../../contexts/types/GroupType';
import Pagination from '../Pagination';
import { Scrollbar } from 'smooth-scrollbar-react';
import TableHead from '../TableHead';

const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 70vh;
`;

const TableWrapper = styled.div`
  display: table;
`;

function GroupTable(props: TypeTable): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const {
    columns,
    data,
    filterCount,
    totalCount,
    updatePagination,
    checkedList = [],
    changeCheckedList = function () {},
    // isCheckable = false,
  } = props;
  const [isSelectAll, changeSelectAll] = useState(false);
  const [selectedPage, changeSelectedPage] = useState(1);
  const [pageSize, changePageSize] = useState(10);

  const myDataList = useMemo(() => {
    const concatedList: any[] = [];
    checkedList.forEach((dataObj) => {
      concatedList.push({ ...dataObj, isCheck: true });
    });
    data.forEach((dataObj) => {
      if (checkedList.filter((e) => e.groupId === dataObj.groupId).length < 1)
        concatedList.push({ ...dataObj, isCheck: false });
    });
    return concatedList;
  }, [data, checkedList]);

  const onCheckChange = (event: any, data: GroupType): void => {
    const isAlreadyChecked =
      checkedList.filter((e) => e.groupId === data.groupId).length > 0;

    if (isAlreadyChecked) {
      changeCheckedList(
        checkedList.filter((checkData: any) => checkData !== data)
      );
    } else changeCheckedList([...checkedList, data]);
  };

  const onCheckChangeSelectAll = (): void => {
    if (!isSelectAll) changeCheckedList(myDataList);
    else changeCheckedList([]);

    changeSelectAll(!isSelectAll);
  };

  const onPageSizeChange = (event: any): void => {
    changePageSize(event.target.value);
    updatePagination({ pageSize: event.target.value, selectedPage });
  };

  const onSelectedPageChange = (selectedPage: number): void => {
    changeSelectedPage(selectedPage);
    updatePagination({ pageSize, selectedPage });
  };

  return (
    <Scrollbar>
      <ComponentWrapper theme={theme}>
        <TableHead
          pageSize={pageSize}
          pages={[10, 25, 50, 100]}
          changePageSize={onPageSizeChange}
        />
        <TableWrapper>
          <TableRow
            id="group_table_header_row"
            isHeaderRow
            columns={columns}
            isCheck={isSelectAll}
            onCheckChange={onCheckChangeSelectAll}
          />

          {myDataList.map((dataObj) => (
            <TableRow
              key={dataObj.groupId}
              id="group_table_normal_row"
              isHeaderRow={false}
              data={dataObj}
              columns={columns}
              isCheck={dataObj.isCheck}
              onCheckChange={(opt: Event) => onCheckChange(opt, dataObj)}
            />
          ))}
        </TableWrapper>
        <Pagination
          totalCount={totalCount}
          totalFilterCount={filterCount}
          selectedEntry={pageSize}
          selectedPage={selectedPage}
          changePageNo={onSelectedPageChange}
        />
      </ComponentWrapper>
    </Scrollbar>
  );
}

export default GroupTable;
