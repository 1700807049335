/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DhlText from '../../../components/DhlText';
import { DhlDropdown, DhlModal, } from '@dhl-official/ui-libraries/react-library';
import ReactStars from 'react-rating-star-with-type';
import DhlButton from '../../../components/DhlButton';
import { ThemeContext } from '../../../styles/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { NotificationContext } from '../../../contexts/NotificationContext';
import ServiceRequest from '../../../service/ServiceRequest';
import { LoadingContext } from '../../../contexts/LoadingContext';
import { device } from '../../../styles/Device';
import BaseTextareaAutosize from '@mui/base/TextareaAutosize';

const RatingModel = styled(DhlModal)`
  margin-bottom: 16px;
  overflow: auto;
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  height: 100%;
  margin: 0px;
  overflow: auto;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const RowWrapperTopMargin = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${device.mobile} {
    margin-top: 10px;
  }
  @media ${device.tablet} {
    margin-top: 16px;
  }
`;

const RowCenterWrapperTopMargin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media ${device.mobile} {
    margin-top: 10px;
  }
  @media ${device.tablet} {
    margin-top: 16px;
  }
`;

const FilesBox = styled.div`
  width: 95%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const FileBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid ${(props) => props.theme.color.grey[300]};
  margin: 4px;
  padding: 2px;
`;

const FileCross = styled(FontAwesomeIcon)`
  padding: 2px;
  margin-left: 4px;
`;

const DhlTextMandatory = styled(DhlText)`
  &:after {
    color: var(--dui-color-red-500);
    content: ' *';
  }
`;

const TextArea = styled(BaseTextareaAutosize)`
  border: solid 1px var(--dui-color-gray-300);
  border-radius: var(--dui-size-radius-md);
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-md);
  outline: 0;
  padding: 17px var(--dui-size-space-7x) 18px var(--dui-size-space-7x);
  transition: border-color var(--dui-duration-3x);
  width: 100%;
`;


const CategoryDescText = styled(DhlText)``;

interface RatingType {
  currentRating: number;
  isOpen: boolean;
  handleClose: Function;
  submitRating: Function;
  clearForm: boolean;
  setClearForm: Function;
}

interface DhlDropDownListItem {
  label: string;
  value?: string;
  type?: string;
  description?: string;
}

interface RatingCategory {
  id: number;
  created: string;
  createdBy: string;
  updated: string| null;
  updatedBy: string| null;
  name: string;
  description: string| null;
}

export const RatingModal: React.FC<RatingType> = (props) => {
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { isOpen, handleClose, submitRating, clearForm, setClearForm } = props;
  const [rating, setRating] = useState<number>();
  const [files, setFiles] = useState<any[]>([]);
  const [message, setMessage] = useState('');
  const [noStar, setNoStar] = useState(false);
  const [noCat, setNoCat] = useState(false);
  // const notificationContext = useContext(NotificationContext);
  const { setLoading } = useContext(LoadingContext);
  const context = useContext(NotificationContext);
  const isMobile = window.innerWidth < 600;
  const [categories, setCategories] = useState<DhlDropDownListItem[]>([]);
  const [category, setCategory] = useState<DhlDropDownListItem | undefined>(
    undefined
  );

  // const maxFiles = 5;
  // const maxSize = 7000000;

  useEffect(() => {
    setNoStar(false);
    setNoCat(false);
  }, [isOpen]);

  useEffect(() => {
    console.log('clearForm', clearForm);
    if (clearForm) {
      setMessage('');
      // const element = (document.getElementById("messageTextArea") as HTMLInputElement);
      // element?.value = '';
      setRating(undefined);
      setCategory(undefined);
      setNoStar(false);
      setNoCat(false);
      setClearForm(false);
    }
  }, [clearForm]);

  const compare = (a: RatingCategory, b: RatingCategory): number => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
  
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  useEffect(() => {
    setLoading(true);
    ServiceRequest.getFiveStarRatingCategories(/* {
       bp_id: details?.selectedOrder.businessPartnerId, 
    } */)
      .then((response) => {
        setCategories(
          response.data.sort(compare).map(
            (c: { name: string; id: number; description: string }) => {
              const item: DhlDropDownListItem = {
                label: c.name,
                value: c.id.toString(),
                description: c.description,
              };
              return item;
            }
          )
        );
      })
      .catch((err) => {
        console.error(`Error loading ssis ${JSON.stringify(err)}`);
        context.setMessage('Error loading categories.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const removeFile = (file: any) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const submitRequest = (): void => {
    if (!rating) {
      setNoStar(true);
    }
    if (!category) {
      setNoCat(true);
    }
    if (!rating || !category) {
      return;
    }

    const myRequest = {
      category: category.label,
      notes: message,
      rating,
    };
    submitRating(myRequest);
  };

  return (
    <RatingModel
      isOpen={isOpen}
      handleOnClose={handleClose}
      dataId="rating-dialog"
      closeButtonAriaLabel="Close"
      shouldShowCloseButton={true}
    >
      <RatingWrapper>
        <RowWrapper slot="header">
          <DhlText
            size={isMobile ? 'md' : 'lg'}
            title={t('App.page.PublicHome.rating.abOrderRating_')}
            weight={800}
          />
        </RowWrapper>

        <RowWrapperTopMargin>
          <DhlTextMandatory
            size="sm"
            weight={600}
            title={t('App.page.PublicHome.rating.abOrderRatingSubTitle')}
            color={noStar ? 'red' : 'inherited'}
          />
        </RowWrapperTopMargin>

        <RowWrapperTopMargin>
          <ReactStars
            onChange={(val) => {
              setRating(val);
              if (val > 0) {
                setNoStar(false);
              }
            }}
            value={rating}
            isEdit={true}
            size={'3rem'}
            activeColor={'#ffcc00'}
          />
        </RowWrapperTopMargin>

        <RowWrapperTopMargin>
          <DhlTextMandatory
            size="sm"
            weight={600}
            title={t(
              'App.page.PublicHome.rating.abOrderRatingChooseCategoryTitle'
            )}
            color={noCat ? 'red' : 'inherited'}
          />
        </RowWrapperTopMargin>

        <RowWrapperTopMargin>
          <DhlDropdown
            data={categories}
            value={category?.value}
            style={{ width: '100%' }}
            changeEvent={(e: React.FormEvent<HTMLInputElement>) => {
              setCategory(
                categories.find((c) => c.value === e.currentTarget.value)
              );
              setNoCat(false);
            }}
          />
        </RowWrapperTopMargin>
        <RowWrapperTopMargin>
          {category !== undefined && (
            <CategoryDescText
              size="sm"
              color={'#333333'}
              weight={200}
              title={category?.description}
            />
          )}
        </RowWrapperTopMargin>
        <RowWrapperTopMargin>
          <DhlText
            size="sm"
            weight={600}
            title={t('App.page.PublicHome.rating.abOrderRatingNotesTitle')}
          />
        </RowWrapperTopMargin>

        <RowWrapperTopMargin>
          <TextArea
            aria-label={t('App.page.PublicHome.rating.writeYourComments').toString()}
            minRows={3}
            style={{ width: '100%' }}
            value={message}
            placeholder={t('App.page.PublicHome.rating.writeYourComments').toString()}
            onInput={(event: any) => setMessage(event.target.value)}
          />
        </RowWrapperTopMargin>

        <RowWrapperTopMargin>
          {files !== undefined && files.length > 0 && (
            <FilesBox>
              {files.map((fileData: any) => {
                const { name } = fileData;
                return (
                  <FileBox key={`${name}_file`} theme={theme}>
                    <DhlText size="xs" title={name} />
                    <FileCross icon={faXmark} onClick={removeFile(fileData)} />
                  </FileBox>
                );
              })}
            </FilesBox>
          )}
        </RowWrapperTopMargin>

        <RowCenterWrapperTopMargin>
          <DhlButton
            title={t('App.page.PublicHome.rating.absendFeedback')}
            size="sm"
            onClick={submitRequest}
          />
        </RowCenterWrapperTopMargin>
      </RatingWrapper>
    </RatingModel>
  );
};

export default RatingModal;
