import React, { useContext } from 'react';
import styled from 'styled-components';
import { TypeSelect } from './TypeSelect';
import { ThemeContext } from '../../styles/ThemeContext';
import Select from 'react-select';
import { lightTheme } from '../../styles/constants';
import DhlText from '../DhlText';
import CountryFlag from './CountryFlag';

const OptionLabelWrapper = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px;
`;

const SelectClean = styled(Select)`
  height: 60px;
`;

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    height: '100%',
    minHeight: '50px',
    borderColor: state.theme.color.white[400],
    cursor: 'pointer',
    backgroundColor: state.theme.background,
  }),

  option: (base: any, state: any) => ({
    ...base,
    marginTop: '1px',
    color: state.theme.primaryText,
    cursor: 'pointer',
    backgroundColor:
      state.isFocused === true
        ? state.theme.color.red[400]
        : state.theme.secondaryBg,
  }),
};

const style = {
  height: '20px',
  width: '30px',
  marginRight: '10px',
  display: 'inline-block',
  verticalAlign: 'middle',
};

const DhlSelect: React.FC<TypeSelect> = (props) => {
  const { theme } = useContext(ThemeContext);

  let themeProps = theme;

  if (props.isThemeDisabled === true) {
    themeProps = {
      ...theme,
      ...lightTheme,
    };
  }

  return (
    <SelectClean
      {...props}
      defaultValue={
        props.options !== undefined && props.options.length > 0
          ? props.options[0]
          : ''
      }
      options={props.options}
      filterOption={(candidate, input) =>
        // @ts-expect-error
        candidate.data.name.toLowerCase().includes(input.toLowerCase())
      }
      theme={themeProps}
      styles={customStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
      getOptionLabel={(e: any): any => (
        <OptionLabelWrapper>
          <CountryFlag countryCode={e.isoCode} style={style} />
          <DhlText size="sm" title={e.name} />
        </OptionLabelWrapper>
      )}
      isSearchable
    />
  );
};

export default DhlSelect;
