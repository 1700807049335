import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEnglish from './Translation/English/translation.json';
import translationFrench from './Translation/French/translation.json';
import translationSpanish from './Translation/Spanish/translation.json';
import translationEnglishAus from './Translation/English(au)/translation.json';
import translationChineseT from './Translation/Chinese(t)/translation.json';
import translationChineseS from './Translation/Chinese(s)/translation.json';
import translationGerman from './Translation/German/translation.json';
import translationItalian from './Translation/Italian/translation.json';
import translationJapenese from './Translation/Japenese/translation.json';
import translationKorean from './Translation/Korean/translation.json';
import translationMyanmar from './Translation/Myanmar/translation.json';
import translationPortuguese from './Translation/Portuguese/translation.json';
import translationThai from './Translation/Thai/translation.json';
import translationTurkish from './Translation/Turkish/translation.json';
import translationVietnamese from './Translation/Vietnamese/translation.json';
import translationFinnish from './Translation/Finnish/translation.json';

const resources = {
  zh: { translation: translationChineseS },
  zh_tw: { translation: translationChineseT },
  en_au: { translation: translationEnglishAus },
  en: { translation: translationEnglish },
  fr: { translation: translationFrench },
  de: { translation: translationGerman },
  it: { translation: translationItalian },
  ja: { translation: translationJapenese },
  kr: { translation: translationKorean },
  my: { translation: translationMyanmar },
  pt: { translation: translationPortuguese },
  es: { translation: translationSpanish },
  th: { translation: translationThai },
  tr: { translation: translationTurkish },
  vi: { translation: translationVietnamese },
  fi: { translation: translationFinnish },
};

void i18next
  .use(initReactI18next)
  .init({ resources, lng: 'en', fallbackLng: 'en' });

export default i18next;
