import {Configuration,  PopupRequest} from '@azure/msal-browser';

const clientId = (process.env.REACT_APP_CLIENT_ID != null) ? process.env.REACT_APP_CLIENT_ID  : 'PUT_YOUR_CLIENT_ID_HERE';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority: 'https://login.microsoftonline.com/cd99fef8-1cd3-4a2a-9bdf-15531181d65e',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
    redirectUri: 'https://mysupplychain.dhl.com/'
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [`${clientId}/.default`],
};


