/* eslint-disable react/prop-types */
import React from 'react';

const SearchInput = ({ value, onChange }) => (
  <input
    className="ag-filter-filter"
    type="text"
    placeholder="Search"
    onChange={onChange}
    value={value}
  />
);

export default SearchInput;
