/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext, useState, useEffect } from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import { ThemeContext } from '../../../styles/ThemeContext';
import BannerImage from '../../../assets/images/banner_public.png';
import './style.css';
import {
  DhlImage,
  DhlList,
  DhlListItem,
  DhlTrackingBar,
} from '@dhl-official/ui-libraries/react-library';
import MyScLogo from '../../../assets/images/mysc_logo.png';
import { device } from '../../../styles/Device';
import { useTranslation } from 'react-i18next';
import DhlSelect from '../../../components/DhlSelect';
import ServiceRequest from '../../../service/ServiceRequest';
import { CountryContext } from '../../../contexts/CountryContext';
import { createFilter } from 'react-select';
import { hasNumber, isEmpty } from '../../../utils';
// import CountryType from '../../../contexts/types/CountryType';

const Wrapper = styled.div`
  width: 100%;
  @media ${device.mobile} {
    height: 100vh;
  }
  @media ${device.tablet} {
    height: 30%;
  }
  @media ${device.largeDesktop} {
    height: 30vh;
  }
  background: url(${BannerImage}) top right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: black;
  margin-top: -10px;
  padding-top: 10px;
  display: block;
  position: relative;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: ${(props) => props.theme.spacing.xl};
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.color.black[500]} 40%,
    ${(props) => props.theme.color.black[500]} 55%,
    ${(props) => props.theme.color.black[60]},
    ${(props) => props.theme.color.black[10]},
    transparent
  );
  @media ${device.mobile} {
    align-items: center;
  }
  @media ${device.tablet} {
    padding-left: 40px;
    padding-bottom: 20px;
    align-items: flex-start;
  }
  @media ${device.laptop} {
    padding-left: 100px;
    padding-bottom: 40px;
  }
  @media ${device.desktop} {
    padding-left: 100px;
    padding-bottom: 40px;
  }
  @media ${device.largeDesktop} {
    padding-left: 100px;
    padding-bottom: 40px;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  @media ${device.mobile} {
    justify-content: center;
  }
  @media ${device.tablet} {
    justify-content: flex-start;
  }
  margin-top: ${(props) => props.theme.spacing.xs};
  margin-bottom: ${(props) => props.theme.spacing.xs};
`;

const MyTrackBarWrapper = styled.div`
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: row;
  height: 60px;
  @media ${device.mobile} {
    margin-top: ${(props) => props.theme.spacing.xxxl};
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media ${device.tablet} {
    margin-top: ${(props) => props.theme.spacing.xl};
    width: 70%;
    min-width: 500px;
    flex-wrap: nowrap;
    background-color: ${(props) => props.theme.background};
  }
  @media ${device.laptop} {
    margin-top: ${(props) => props.theme.spacing.xl};
    background-color: ${(props) => props.theme.background};
    width: 60%;
    min-width: 500px;
    flex-wrap: nowrap;
  }
`;

const MySelect = styled(DhlSelect)`
  @media ${device.mobile} {
    width: 95%;
    margin-bottom: 20px;
  }
  @media ${device.tablet} {
    width: 250px;
    margin-bottom: 0px;
  }
  @media ${device.laptop} {
    width: 250px;
    margin-bottom: 0px;
  }
  div {
    @media ${device.mobile} {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
    @media ${device.tablet} {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }
    @media ${device.laptop} {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }
    box-shadow: none;
  }
  div:hover {
    border-color: hsl(0, 0%, 70%);
    border-width: 0px;
  }
`;

const MyTrackingBar = styled(DhlTrackingBar)`
  @media ${device.mobile} {
    width: 95%;
    min-width: 300px;
  }
  @media ${device.tablet} {
    width: 100%;
    min-width: 370px;
  }
  @media ${device.laptop} {
    width: 100%;
    min-width: 370px;
  }
  .removeBorder {
    height: 60px;
  }
  .removeBorder:after {
    content: '';
    width: 2px;
    height: 50%;
    background-color: rgb(204, 204, 204);
    position: absolute;
    top: 20px;
    left: 0px;
    margin-bottom: -20px;
  }
  input {
    border-width: 0px;
    height: 60px;
    @media ${device.mobile} {
      border-bottom-left-radius: 4px;
    }
    @media ${device.tablet} {
      border-bottom-left-radius: 0px;
    }
  }
  input:focus {
    border-width: 0px !important;
    box-shadow: none !important;
  }
  .buttonContainer {
    border-width: 0px;
  }
  
  div.removeBorder + dhl-validation-feedback.hydrated dhl-validation-feedback-icon {
    display: none;
  }
  div.removeBorder input + dhl-validation-feedback.hydrated dhl-validation-feedback-icon, div.removeBorder input + dhl-icon-wrapper+ dhl-validation-feedback.hydrated {
    display: none;
  }
`;

const MyPopupWrapper = styled.div`
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};
  border: 1px solid red;
  position: absolute;
  margin-top: -30px;
  max-width: 330px;
  top: 100%;
  left: 20%;
  z-index: 2;
  padding: ${(props) => props.theme.spacing.sm};
`;

const MyValidationText = styled(DhlText)`
  margin-bottom: 10px;
`;

function TopHeader(props: any): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { countries } = useContext(CountryContext);
  const { t } = useTranslation();
  const [trackinNumber, setTrackingNumber] = useState('');
  const [isValidInput, setIsValidInput] = useState(false);
  const { selectedCountry, changeCountry } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [validationError, setValidationError] = useState(4);

  useEffect(() => {
    if (countries.length > 0)
      ServiceRequest.getCurrentCountry()
        .then((response) => {
          if (countries !== null && countries.length > 0) {
            let browserCountry = countries.find(
              (countryobj: any) =>
                countryobj.name.toLowerCase() ===
                response.data.country_name.toLowerCase()
            );

            if (browserCountry == null || browserCountry === undefined)
              browserCountry = countries[0];
            changeCountry(browserCountry);
          }
        })
        .catch((error) => {
          console.error('ERROR =', error);
          changeCountry(countries[0]);
        });
  }, [countries]);

  const validateInput = (e: any): void => {
    const newString = e.target.value;

    const firstElement = document.querySelector('div.removeBorder input + dhl-validation-feedback.hydrated dhl-validation-feedback-icon, div.removeBorder input + dhl-icon-wrapper+ dhl-validation-feedback.hydrated') as HTMLElement;
    if (firstElement != null) {
        if (newString === '') {
            firstElement.style.display = 'none';
        } else {
            firstElement.style.display = 'block';
        }
    }

    setTrackingNumber(newString);

    let isValidated = newString.length > 4;

    const trackingNumberArray = newString.split(',');

    // Tracking number must be 5 character and above
    if (!isValidated) setValidationError(4);

    const commasLength = trackingNumberArray.length - 1;

    // Should not more then 10 Orders
    if (commasLength > 9) {
      setValidationError(1);
      isValidated = false;
    }

    trackingNumberArray.forEach((trackingNoData: string | any[]) => {
      // Should not contain numbers
      if (!hasNumber(trackingNoData)) {
        setValidationError(2);
        isValidated = false;
      }

      // Should not contain @ symbol
      if (trackingNoData.includes('@')) {
        setValidationError(3);
        isValidated = false;
      }

      // Tracking number must be 5 character and above
      if (trackingNoData.length < 4) {
        isValidated = false;
        setValidationError(4);
      }
    });

    setIsValidInput(isValidated);
  };

  const submitTrackingNo = (): void => {
    if (
      selectedCountry === undefined ||
      selectedCountry === null ||
      isEmpty(trackinNumber)
    ) {
      setIsValidInput(false);
    } else if (isValidInput) {
      const myEnv = process.env.REACT_APP_ENVIRONMENT;
      let cvUrl = '';
      if (myEnv === undefined || isEmpty(myEnv) || myEnv === null) {
        cvUrl =
          'https://mysctrackandtrace.dhl.com/cview/search?query=[NUMBERS]&country=[COUNTRY_CODE]&source=MySC';
        cvUrl = cvUrl.replace('[COUNTRY_CODE]', selectedCountry.isoCode);
        cvUrl = cvUrl.replace('[NUMBERS]', trackinNumber);
      } else {
        cvUrl =
          'https://mysctrackandtrace[ENVIRONMENT].dhl.com/cview/search?query=[NUMBERS]&country=[COUNTRY_CODE]&source=MySC';
        cvUrl = cvUrl.replace('[COUNTRY_CODE]', selectedCountry.isoCode);
        cvUrl = cvUrl.replace('[NUMBERS]', trackinNumber);
        cvUrl = cvUrl.replace('[ENVIRONMENT]', `-${myEnv}`);
      }
      window.location.href = cvUrl;
    }
  };

  return (
    <Wrapper>
      <InnerWrapper theme={theme}>
        <DhlText
          id="welcome_text"
          size="md"
          color="#ffffff"
          title={t('App.page.PublicHome.welcome')}
        />

        <LogoContainer theme={theme}>
          <DhlImage alt="MySC Logo" src={MyScLogo} height="90" />
        </LogoContainer>

        <DhlText
          size="md"
          weight={100}
          color="#ffffff"
          title={t('App.page.PublicHome.oneStopSol')}
        />

        <MyTrackBarWrapper theme={theme}>
          <MySelect
            isSearchable
            options={countries}
            value={selectedCountry}
            filterOption={createFilter({
              ignoreCase: true,
              ignoreAccents: true,
              trim: true,
              // stringify: (option : CountryType) => `${option.name}`,
            })}
            onChange={(e: any) => changeCountry(e)}
          />

          <MyTrackingBar
            dataClassName="removeBorder"
            changeEvent={validateInput}
            value={trackinNumber}
            theme={theme}
            validation={{
              type: isValidInput ? 'valid' : 'Invalid',
            }}
            submitEvent={submitTrackingNo}
            isBlock={true}
            placeholder={t('App.page.PublicHome.enterTrackingNo')}
            buttonText={t('App.page.PublicHome.track')}
            onBlur={() => setIsPopupOpen(false)}
            onFocus={() => setIsPopupOpen(true)}
          />
        </MyTrackBarWrapper>
      </InnerWrapper>

      {isPopupOpen && !isValidInput && (
        <MyPopupWrapper theme={theme}>
          <MyValidationText
            size="xs"
            title={t('App.page.PublicHome.validationTitle')}
          />

          <DhlList>
            <DhlListItem>
              <DhlText
                size="xs"
                weight={validationError === 1 ? 600 : 400}
                title={t('App.page.PublicHome.validation1')}
              />
            </DhlListItem>
          </DhlList>

          <DhlList>
            <DhlListItem>
              <DhlText
                size="xs"
                weight={validationError === 2 ? 600 : 400}
                title={t('App.page.PublicHome.validation2')}
              />
            </DhlListItem>
          </DhlList>

          <DhlList>
            <DhlListItem>
              <DhlText
                size="xs"
                weight={validationError === 3 ? 600 : 400}
                title={t('App.page.PublicHome.validation3')}
              />
            </DhlListItem>
          </DhlList>

          <DhlList>
            <DhlListItem>
              <DhlText
                size="xs"
                weight={validationError === 4 ? 600 : 400}
                title={t('App.page.PublicHome.validation4')}
              />
            </DhlListItem>
          </DhlList>
        </MyPopupWrapper>
      )}
    </Wrapper>
  );
}

export default TopHeader;
