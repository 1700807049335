/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import {AgGridReact} from 'ag-grid-react';
import {SelectAllCheckBox} from './headerTemplates';
import SetFilter from './filter/SetFilter';
import TextFloatingFilter from './filter/TextFloatingFilter';
import SelectFloatingFilter from "./filter/SelectFloatingFilter";
import SetFloatingFilter from './filter/SetFloatingFilter';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import ServerSideDatasource from '../../service/ServerSideDataSource';
import './css/filters.css';
import './css/headers.css';
import './css/icons.css';
import DateFilter from './filter/dateFilter/DateFilter';
import FloatingDateFilter from './filter/dateFilter/FloatingDateFilter';
import {ConfigurationInfinite} from "./config";

const defaultId = 'myGrid';

class Table extends React.Component {
    gridApi = undefined;
    gridColumnApi = undefined;
    config = {};
    selectAllCheckBox = undefined;
    deselectedRows = {};
    allColumnIds = [];
    _unmount = false;
    // height
    state = {
        selectAll: false,
        totalRows: undefined,
        filteredColumns: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            customPage: 1,
        };
        this.selectAllCheckBox = (
            <SelectAllCheckBox onClick={this.onSelectAllClick}/>
        );
    }

    componentDidMount() {
        this._unmount = false;
        // const checkboxWrap = document.getElementById(
        //   'usersList-select-all-checkbox'
        // );
    }

    componentWillUnmount() {
        this._unmount = true;
    }

    onSelectAllClick = (event) => {
        if (this._unmount) return;
        // Todo add selection for new loaded rows
        const {checked} = event.target;

        this.gridApi &&
        this.gridApi.forEachNode(function (node) {
            node.setSelected(checked);
        });
        this.deselectedRows = {};
        this.setState({selectAll: checked});
    };

    getUnmount = () => this._unmount;

    onFailedUpdate = () => {
        console.error('FAILED..', this.props);
        const {onFailed} = this.props;
        onFailed();
    };

    onSuccessUpdate = (data, lastRow) => {
        console.log('SUCCESS..', data, lastRow);
        const {onSuccess} = this.props;
        this.setState({totalRows: lastRow});
        // this.newRowsUpdate(data, lastRow);
        onSuccess();
    };

    refreshApi = (triggerValue) => {
        if (this.config.rowModelType === 'infinite') {
            const {dataKey, totalKey, getData, showCustomPagination} = this.props;

            const queryParams = {
                getQuery: this.getQuery,
                getQueryVars: this.getQueryVars,
                dataKey,
                totalKey,
                successCallback: this.onSuccessUpdate,
                failCallback: this.onFailedUpdate,
                updatePagination: this.updatePagination,
            };

            const datasource = new ServerSideDatasource(
                this.gridApi,
                queryParams,
                this.getUnmount,
                getData,
                triggerValue,
                showCustomPagination
            );

            this.gridApi.setDatasource(datasource);
        }
    };

    updatePagination = (pageNo) => {
        this.setState({customPage: Number(pageNo)});
    }

    onGridReady = (params) => {
        const {
            getApi,
            getData,
            autoSizeColumns = false,
            sizeColumnsToFit = true,
            trigger = false,
        } = this.props;

        this.gridColumnApi = params.columnApi;

        // Server Side preparations
        if (this.config.rowModelType === 'infinite' && !trigger) {
            const {dataKey, totalKey} = this.props;

            const queryParams = {
                getQuery: this.getQuery,
                getQueryVars: this.getQueryVars,
                dataKey,
                totalKey,
                successCallback: this.onSuccessUpdate,
                failCallback: this.onFailedUpdate,
                updatePagination: this.updatePagination,
            };

            const datasource = new ServerSideDatasource(
                params.api,
                queryParams,
                this.getUnmount,
                getData
            );

            params.api.setDatasource(datasource);
        }

        this.gridApi = params.api;
        getApi && getApi(params.api, params.columnApi);

        if (!this.gridColumnApi || !this.gridApi) return;
        if (autoSizeColumns) {
            this.gridColumnApi.autoSizeColumns(this.allColumnIds);
        } else if (sizeColumnsToFit) {
            this.gridApi.sizeColumnsToFit();
        }
        this.gridApi.setDomLayout('autoHeight');
    };

    addSelectAndPlaceHolder() {
        // Adding select All checkbox
        const checkboxWrap = document.getElementById(
            'usersList-select-all-checkbox'
        );
        if (this.selectAllCheckBox && checkboxWrap) {
            ReactDOM.render(this.selectAllCheckBox, checkboxWrap);
        }
    }

    getApi = () => {
        if (this._unmount) return null;
        return this.gridApi;
    };

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.isUploading !== this.props.isUploading &&
            !nextProps.isUploading
        ) {
            this.gridApi.refreshInfiniteCache();
        }

        if (nextProps.trigger) {
            this.refreshApi(nextProps.triggerValue);
            this.props.resetTrigger();
        }
    }

    render() {
        const {
            columnDefs,
            id = defaultId,
            actionPanel,
            showCustomPagination,
            ...otherProps
        } = this.props;

        const configuration = ConfigurationInfinite;

        this.config = {...configuration, ...otherProps, columnDefs};

        return (
            <>
                <div
                    id={id}
                    style={{height: '100%', width: '100%'}}
                    className="ag-theme-material"
                >
                    <AgGridReact
                        style={{height: '100%'}}
                        reactNext
                        onGridReady={this.onGridReady}
                        {...this.config}
                        components={{
                            setFilter: SetFilter,
                            dateFilter: DateFilter,
                            dateFloatingFilter: FloatingDateFilter,
                            textFloatingFilter: TextFloatingFilter,
                            selectFloatingFilter: SelectFloatingFilter,
                            setFloatingFilter: SetFloatingFilter,
                        }}
                    />
                </div>
            </>
        );
    }
}

export default Table;
