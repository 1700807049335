const size = {
  mobile: '320px',
  tablet: '600px',
  laptop: '1024px',
  desktop: '1800px',
  largeDesktop: '2200px',
};

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
  largeDesktop: `(min-width: ${size.largeDesktop})`,
};
