import React, { createContext, useState } from 'react';
import { getThemeData } from './constants';
import { ThemeProps } from './types/ThemeProps';

export const ThemeContext = createContext({
  isDarkTheme: false,
  toggleTheme: () => {},
  theme: getThemeData(false, 2),
  fontSize: 2,
  changeFontSize: (fontSize: number) => {},
});

const ThemeProvider: React.FC<ThemeProps> = (props) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [fontSize, updateFontSize] = useState(2);
  const [theme, updateTheme] = useState(getThemeData(false, 2));

  const toggleTheme = (): void => {
    const updatedDarkTheme = !isDarkTheme;
    setIsDarkTheme(updatedDarkTheme);
    updateTheme(getThemeData(updatedDarkTheme, fontSize));
  };

  const changeFontSize = (fontSize: number): void => {
    updateFontSize(fontSize);
    updateTheme(getThemeData(isDarkTheme, fontSize));
  };

  return (
    <ThemeContext.Provider
      value={{ isDarkTheme, toggleTheme, theme, fontSize, changeFontSize }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
