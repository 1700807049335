import React, { createContext, useState } from 'react';
import CommonType from './types/CommonType';

export const NotificationContext = createContext({
  type: '',
  setType: (data: string) => {},
  message: '',
  setMessage: (data: string) => {},
});

export const NOTIFICATION_TYPES = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  WARNING: 'Warning',
};

const NotificationProvider: React.FC<CommonType> = (props) => {
  const [type, setTypeData] = useState('');
  const [message, setMessageData] = useState('');

  const setType = (typeData: string): void => {
    setTypeData(typeData);
  };

  const setMessage = (messageData: string): void => {
    setMessageData(messageData);
  };

  return (
    <NotificationContext.Provider
      value={{ type, setType, message, setMessage }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
