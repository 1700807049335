/* eslint-disable react/prop-types */
// @flow
import React from 'react';
import debounce from 'lodash/debounce';
import {TextField} from "@mui/material";

export default class TextFloatingFilter extends React.PureComponent {
    updateAndNotifyAgGrid = (dict) => {
    };

    defaultModel = null;

    constructor(props) {
        super(props);
        const {debounceMs, parentFilterInstance} = this.props;
        const onFloatingFilterChanged = (input) => parentFilterInstance(instance => instance.onFloatingFilterChanged(input?.model?.type, input?.model?.filter));
        const model = this.getModel();
        this.state = {
            value: (model && model.filter) || '',
        };
        this.updateAndNotifyAgGrid = onFloatingFilterChanged;
        if (debounceMs)
            this.updateAndNotifyAgGrid = debounce(
                onFloatingFilterChanged,
                debounceMs,
            );
    }

    getModel() {
        const {
            api,
            column: {colId},
        } = this.props;
        const filtersModel = api.getFilterModel();
        const model = (filtersModel && filtersModel[colId]) || '';
        return model;
    }

    onParentModelChanged(parentModel) {
        this.setState({value: (parentModel && parentModel.filter) || ''});
    }

    valueChanged = (event) => {
        event.preventDefault();
        this.setState({value: event.target.value}, () => {
            this.updateAndNotifyAgGrid({model: this.buildModel()});
        });
    };

    getModelKey(model = {}) {
        const {filterType} = model;
        switch (filterType) {
            case 'number':
                return 'filter';
            case 'text':
                return 'filter';
        }
        return 'filter';
    }

    getDefaultModel() {
        if (this.defaultModel) return this.defaultModel;
        const {
            column: {colDef},
        } = this.props;
        this.defaultModel = {
            type: 'contains',
            filterType: 'text',
            filter: '',
        };
        if (!colDef) {
            return this.defaultModel;
        }
        const {filter, filterParams} = colDef;
        const filterOptions = (filterParams && filterParams.filterOptions) || null;
        if (filter === 'agNumberColumnFilter') {
            this.defaultModel.filterType = 'number';
            this.defaultModel.type =
                (filterOptions && filterOptions.length > 0 && filterOptions[0]) ||
                'equals';
        }

        return this.defaultModel;
    }

    buildModel() {
        // ag-grid will call us here when in need to check what the current date value is hold by this
        // component.
        const {value} = this.state;
        const model = this.getModel() || this.getDefaultModel();
        const newModel = {...model, filter: value};
        return newModel;
    }

    clearValue = (event) => {
        event.preventDefault();
        this.setState({value: ''}, () =>
            this.updateAndNotifyAgGrid({model: this.buildModel()}),
        );
    };

    render() {
        const {value} = this.state;

        return (
            <TextField
                placeholder="Filter"
                id="outlined-size-small"
                sx={{
                    "& .MuiInputBase-root": {
                        height: "40px",
                        paddingBottom: "10px"
                    }
                }}
                value={value || ''}
                size="small"
                onChange={this.valueChanged}
            />
        );
    }
}
