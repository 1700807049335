/* eslint-disable */
import React, {useContext, useEffect, useState} from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import {ThemeContext} from '../../../styles/ThemeContext';
import {DhlCheckbox, DhlIcon, DhlLoader} from "@dhl-official/ui-libraries/react-library";
import {IconContext} from "../../../assets/IconContext";
import BusinessFunctionType from "../../../contexts/types/BusinessFunctionType";
import _ from 'lodash';

const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
`;

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cccccc;
  margin-top: 10px;
  background-color: ${(props) => props.theme.color.grey[100]};
`;


const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const ClickableArrow = styled.div`
  display: flex;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
`;


const OptionsWrapper = styled.div<{ isOpen: boolean }>`
  display: ${(props) => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  transition: 0.2s;
`;

const OptionsItemWrapper = styled.div`
  padding: 10px;
  width: 98%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cccccc;
  margin-top: 10px;
  margin-left: 2%;
`;

const MyCheckboxIcon = styled(DhlCheckbox)`
margin-right: 10px;
`;

const MyNavigateIcon = styled(DhlIcon)`
margin-right: 10px;
`;

interface AccordionType {
    listData: any[],
    selectedList: any[],
    changeSelectedList: Function,
    isBfLoading: boolean,
}

function getSelectedCount(selectedList: any[], myBfData: any): any {
    let totalSelectedCount = 0;
    myBfData.childList.forEach((bfChildData: any) => {
        if (_.find(selectedList, bfChildData)) {
            totalSelectedCount = totalSelectedCount + 1;
        }
    })
    return totalSelectedCount;
}

function MyAccordion(props: AccordionType): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const [openRow, setOpenRow] = useState<any>(null);
    const [businessFunctionsGrouping, setBusinessFunctionsGrouping] = useState<any[]>([]);

    const {listData, selectedList, changeSelectedList, isBfLoading} = props;

    const onClickOpenClose = (bfId: string | number): void => {
        if (bfId === openRow)
            setOpenRow(null);
        else
            setOpenRow(bfId);
    }

    const updateList = (bfData: any): void => {
        let myUpdatedList = selectedList;
        if (_.find(selectedList, bfData)) {
            myUpdatedList = myUpdatedList.filter(x => x.id !== bfData.id);
        } else {
            const myBfData = {...bfData, selected: true};
            myUpdatedList = [...myUpdatedList, myBfData];
        }
        changeSelectedList(myUpdatedList);
    }

    const bulkUpdateList = (bfData: any, isUpdatedCheck: boolean): void => {
        let myUpdatedList = selectedList;
        bfData.childList.forEach((bfChildData: any) => {
            if (!isUpdatedCheck) {
                myUpdatedList = myUpdatedList.filter(x => x.id !== bfChildData.id);
            } else {
                const myBfData = {...bfChildData, selected: true};
                myUpdatedList = [...myUpdatedList, myBfData];
            }
        })
        changeSelectedList(myUpdatedList);
    }

    useEffect(() => {
        let myFilterObject = {};

        if (listData.length > 0) {
            listData.forEach((bfData: BusinessFunctionType) => {
                if ('parent_id' in bfData) {
                    // @ts-ignore
                    if (bfData?.parent_id in myFilterObject) {
                        // @ts-ignore
                        myFilterObject[bfData?.parent_id] = [...myFilterObject[bfData?.parent_id], bfData];
                    } else {
                        // @ts-ignore
                        myFilterObject[bfData?.parent_id] = [bfData];
                    }
                }
            })
        }

        const myFilterList: any[] = [];
        const myAllKeys = Object.keys(myFilterObject);
        myAllKeys.forEach((key: string) => {
            const mainObject = listData.find((bf: BusinessFunctionType) => bf.id === Number(key));
            // @ts-ignore
            myFilterList.push({id: key, name: mainObject?.name, childList: myFilterObject[key]})
        })

        setBusinessFunctionsGrouping(myFilterList);
    }, [listData]);

    return (
        <ComponentWrapper theme={theme}>
            {!isBfLoading ? businessFunctionsGrouping.map((bfData: any) => {
                const isOpenList = bfData.id === openRow;
                const totalSelectedCount = getSelectedCount(selectedList, bfData);
                const isCheckedMain = totalSelectedCount === bfData.childList.length;
                return <ItemWrapper key={bfData.id}>
                    <HeaderWrapper theme={theme} onClick={() => onClickOpenClose(bfData.id)}>
                        <HeaderTitleWrapper>
                            <MyCheckboxIcon
                                isChecked={isCheckedMain}
                                changeEvent={() => bulkUpdateList(bfData, !isCheckedMain)}
                            />
                            <DhlText
                                title={`${bfData.name} (${totalSelectedCount}/${bfData.childList.length})`}
                                size={'md'} weight={800}/>
                        </HeaderTitleWrapper>

                        <ClickableArrow onClick={() => onClickOpenClose(bfData.id)}>
                            <MyNavigateIcon src={getIcon(isOpenList ? 'downArrow' : 'upArrow')} size={'1rem'}/>
                        </ClickableArrow>
                    </HeaderWrapper>

                    <OptionsWrapper isOpen={isOpenList}>
                        {bfData.childList.map((bfChildData: any) => {
                            const isChecked = _.find(selectedList, bfChildData);
                            return <OptionsItemWrapper key={bfChildData.id} theme={theme}>
                                <HeaderTitleWrapper>
                                    <MyCheckboxIcon isChecked={isChecked}
                                                    changeEvent={() => updateList(bfChildData)}/>
                                    <DhlText title={bfChildData.name} size={'md'}/>
                                </HeaderTitleWrapper>
                            </OptionsItemWrapper>
                        })}
                    </OptionsWrapper>
                </ItemWrapper>
            }) : <DhlLoader size={'sm'}/>}
        </ComponentWrapper>
    );
}

export default MyAccordion;
