/* eslint-disable react/prop-types */
import React from 'react';
import { getInnerHeight } from "../utils";

export default class LogoCell extends React.PureComponent {
    render() {

        const { data } = this.props;

        if (data && data.mediumLogo) {
            return (
                <img
                    style={{ maxWidth: '100%', maxHeight: `${getInnerHeight()}px` }}
                    src={`data:${data.mediumLogo.mimeType};base64,${data.mediumLogo.content}`}
                />
            );
        }
        return <span />;
    }
}
