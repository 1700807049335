/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios, {AxiosResponse} from 'axios';
import {InteractionRequiredAuthError, SilentRequest} from "@azure/msal-browser";
import {loginRequest} from "../authConfig";
import {msalInstance} from "../index";
import cookies from 'js-cookie';

const baseURL = process.env.REACT_APP_BASE_URL;
const msGraphURL = 'https://graph.microsoft.com/v1.0';

const http =
    baseURL != null
        ? axios.create({
            baseURL: `${baseURL}/`,
            timeout: 300000,
        })
        : axios.create({
            timeout: 300000,
        });

const httpPrivate = axios.create({
    baseURL: baseURL != null ? `${baseURL}/` : undefined,
    timeout: 300000
});

const httpMsGraph =
    baseURL != null
        ? axios.create({
            baseURL: `${msGraphURL}/`,
            timeout: 300000,
        })
        : axios.create({
            timeout: 300000,
        });

httpPrivate.interceptors.request.use(async (config) => {
    config.withCredentials = true;
    return config;
});

httpPrivate.interceptors.request.use(
    async (config) => {
        const accessToken = await getAccessToken((config as any).preventRedirect);
        if (accessToken !== '') {
            cookies.set('loginAccessToken', accessToken, {path: '', secure: process.env.NODE_ENV === 'production'});
        } else {
            cookies.remove('loginAccessToken', {path: ''});
        }

        if (config.headers != null) {
            const msGraphAccessToken = await getAccessToken(true, {
                scopes: [`User.Read`],
            });
            if (accessToken !== '') {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            if (msGraphAccessToken !== '') {
                config.headers.Msgraphtoken = msGraphAccessToken;
            }
            config.headers['Content-Type'] =
                config.headers['Content-Type'] ?? 'application/json';
        }
        return config;
    },
    (error) => {
        void Promise.reject(error);
    }
)

httpMsGraph.interceptors.request.use(
    async config => {
        if (config.headers != null) {
            const accessToken = await getAccessToken(true, {
                scopes: [`User.Read`],
            });
            if (accessToken !== '') {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
        }
        return config;
    }
)

httpPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
        // eslint-disable-next-line no-debugger,@typescript-eslint/no-non-null-assertion
        const originalRequest = error.config!;
        console.warn(msalInstance.getAllAccounts());
        if (error.response?.status === 401 && !(originalRequest._retry === true)) {
            const accessToken = await getAccessToken(originalRequest.preventRedirect);
            originalRequest._retry = true;
            originalRequest.headers.Authorization = `Bearer ${accessToken}`
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            return await axios(originalRequest);
        }
        return await Promise.reject(error);
    }
)

export const getAccessToken = async (preventRedirect: (boolean | undefined) = false, loginRequestIn: SilentRequest = loginRequest): Promise<string> => {
    try {
        const response = await msalInstance
            .acquireTokenSilent({
                ...loginRequestIn,
                account: (msalInstance.getAllAccounts())[0]
            });
        return response.accessToken;
    } catch (error) {
        if ((error instanceof InteractionRequiredAuthError || (msalInstance.getAllAccounts() == null || msalInstance.getAllAccounts().length === 0)) &&
            !preventRedirect
        ) {
            try {
                console.info("REDIRECTING TO AZURE FROM AXIOS", error)
                await msalInstance
                    .acquireTokenRedirect(loginRequest);

            } catch (popuperror) {
                console.warn(popuperror);
            }
        }
    }
    return '';
}


http.interceptors.request.use(async (config) => {
    config.withCredentials = true;
    return config;
});


// Add a response interceptor
// TO DO HANDLE UNAUTHORIZED INTERCEPTOR
http.interceptors.response.use(
    (response) => response,
    async function (error) {
        console.warn("error", error);
        if (error.response != null && (error.response.status === 420 || error.response.status === 302)) {
            if (window.location.pathname !== '/') {
                const win: Window = window;
                win.location = `${baseURL}/expired`;
                return;
            }
        }
        return await Promise.reject(error);
    }
);

async function get(
    url: string,
    headers = {},
    params = {}
): Promise<AxiosResponse<any, any>> {
    return await http.get(url, {
        ...params,
        headers: {
            ...headers,
        },
    });
}

async function post(
    url: string,
    data: any,
    headers = {},
    params = {}
): Promise<AxiosResponse<any, any>> {
    return await http.post(url, data, {
        ...params,
        headers,
    });
}

async function put(
    url: string,
    data: any,
    headers = {}
): Promise<AxiosResponse<any, any>> {
    return await http.put(url, data, {headers});
}

async function deleteRequest(
    url: string,
    headers = {},
    params = {}
): Promise<AxiosResponse<any, any>> {
    return await http.delete(url, {
        ...params,
        headers,
    });
}

export default {
    http,
    httpPrivate,
    httpMsGraph,
    baseURL,
    get,
    post,
    put,
    deleteRequest,
};
