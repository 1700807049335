/* eslint-disable  */
import React from 'react';
import {AgGridReact} from 'ag-grid-react';
import {SelectAllCheckBox} from './headerTemplates';
import SetFilter from './filter/SetFilter';
import TextFloatingFilter from './filter/TextFloatingFilter';
import SelectFloatingFilter from "./filter/SelectFloatingFilter";
import SetFloatingFilter from './filter/SetFloatingFilter';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import './css/filters.css';
import './css/headers.css';
import './css/icons.css';
import DateFilter from './filter/dateFilter/DateFilter';
import FloatingDateFilter from './filter/dateFilter/FloatingDateFilter';
import Pagination from "../../pages/CoList/components/Pagination";
import {ConfigurationStatic} from "./config";

const defaultId = 'myGrid';

class StaticTable extends React.Component {
    gridApi = undefined;
    gridColumnApi = undefined;
    config = {};
    selectAllCheckBox = undefined;
    deselectedRows = {};
    allColumnIds = [];
    _unmount = false;
    // height
    state = {
        selectAll: false,
        totalRows: undefined,
        filteredColumns: '',
        isFilterUpdate: false
    };

    constructor(props) {
        super(props);
        this.selectAllCheckBox = (
            <SelectAllCheckBox onClick={this.onSelectAllClick}/>
        );
    }

    componentDidMount() {
        this._unmount = false;
        // const checkboxWrap = document.getElementById(
        //   'usersList-select-all-checkbox'
        // );
    }

    componentWillUnmount() {
        this._unmount = true;
    }

    onSelectAllClick = (event) => {
        if (this._unmount) return;
        // Todo add selection for new loaded rows
        const {checked} = event.target;

        this.gridApi &&
        this.gridApi.forEachNode(function (node) {
            node.setSelected(checked);
        });
        this.deselectedRows = {};
        this.setState({selectAll: checked});
    };

    onGridReady = (params) => {
        const {
            getApi,
            autoSizeColumns = false,
            sizeColumnsToFit = true,
        } = this.props;

        this.gridColumnApi = params.columnApi;

        this.gridApi = params.api;
        getApi && getApi(params.api, params.columnApi);

        if (!this.gridColumnApi || !this.gridApi) return;
        if (autoSizeColumns) {
            this.gridColumnApi.autoSizeColumns(this.allColumnIds);
        } else if (sizeColumnsToFit) {
            this.gridApi.sizeColumnsToFit();
        }
        this.gridApi.setDomLayout('autoHeight');
    };

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.isUploading !== this.props.isUploading &&
            !nextProps.isUploading
        ) {
            this.gridApi.refreshInfiniteCache();
        }
    }

    render() {
        const {
            columnDefs,
            id = defaultId,
            actionPanel,
            showCustomPagination,
            totalResult,
            onFilterChanged,
            onPaginationChanged,
            ...otherProps
        } = this.props;

        const configuration = ConfigurationStatic;

        this.config = {...configuration, ...otherProps, columnDefs};
        
        console.log("CONFIG ==> ", this.config);

        return (
            <>
                <div
                    id={id}
                    style={{height: '100%', width: '100%'}}
                    className="ag-theme-material"
                >
                    <AgGridReact
                        style={{height: '100%'}}
                        reactNext
                        onGridReady={this.onGridReady}
                        onFilterChanged={(e) => {
                            this.setState({isFilterUpdate: true});
                            this.gridApi.paginationGoToPage(0);
                            return onFilterChanged(e);
                        }}
                        {...this.config}
                        components={{
                            setFilter: SetFilter,
                            dateFilter: DateFilter,
                            dateFloatingFilter: FloatingDateFilter,
                            textFloatingFilter: TextFloatingFilter,
                            selectFloatingFilter: SelectFloatingFilter,
                            setFloatingFilter: SetFloatingFilter,
                        }}
                    />
                </div>

                {showCustomPagination && <Pagination
                    totalResults={totalResult === undefined ? 0 : totalResult}
                    api={this.gridApi}
                    isFilterUpdate={this.state.isFilterUpdate}
                    updateIsFilter={(val) => this.setState({isFilterUpdate: val})}
                    onPaginationChanged={onPaginationChanged}
                />}

            </>
        );
    }
}

export default StaticTable;
