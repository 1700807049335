/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios, {AxiosResponse} from 'axios';
import httpService from './index';
import {GroupTypeApiResponse} from '../contexts/types/GroupType';
import {NOTIFICATION_TYPES} from '../contexts/NotificationContext';
import platform from 'platform';
import {isEmpty} from 'lodash';

const getCountries = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.get(`/${process.env.REACT_APP_MSC_PATH}/rafadmin/country`);

const getLanguages = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.get(`/${process.env.REACT_APP_MSC_PATH}/rafadmin/language`);

const getCurrentCountry = async (): Promise<AxiosResponse<any, any>> =>
    await axios.get('https://ipapi.co/json/');

const getCustomers = async (queryParams: string): Promise<AxiosResponse<any, any>> =>
    await httpService.get(`/${process.env.REACT_APP_MSC_PATH}/customers/list?${queryParams}`);

const getUser = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_MSC_PATH}/rafadmin/user/currentUser`,
        {preventRedirect: true} as any
    );

const logLogin = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_MSC_PATH}/rafadmin/user/login`
    );

const getValidateImpersonatedUserEmail = async (impersonatedUserEmail: string): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_MSC_PATH}/rafadmin/user/validateImpersonatedUserEmail?impersonatedUserLogin=${impersonatedUserEmail}`);
    
const getImpersonateUser = async (impersonatedUserLogin: string): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_MSC_PATH}/rafadmin/user/impersonateUser?impersonatedUserLogin=${impersonatedUserLogin}`);

const getStopImpersonatedUserSession = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_MSC_PATH}/rafadmin/user/stopImpersonatedUserSession`);

/**
 *  Using standard fetch for below as axios always follow redirects and this behaviour can't be changed (only in nodejs).
 *  Below API returns 302 in case that session was expired and BE sends redirection to azure which works for old FE (users, groups) but not for new one
 */
const getUserOld = async (): Promise<Response> =>
    await fetch(
        `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_MSC_PATH}/rafadmin/user/currentUser`,
        {
            credentials: 'include',
            redirect: 'manual',
        }
    );

const activateUser = async (token: string): Promise<AxiosResponse<any, any>> =>
    await httpService.get(
        `/${process.env.REACT_APP_MSC_PATH}/api/admin/activateUser?token=${token}`
    );

const getCustomerList = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/customer_objects/list`,
        data
    );

const getCoList = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.get(
        `/${process.env.REACT_APP_MSC_PATH}/customer_objects?${data}`,
    );

const getDscUmUsersList = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.get(
        `/${process.env.REACT_APP_MSC_PATH}/dsc-users?${data}`,
    );

const exportDscUmUsers = async (request: string): Promise<AxiosResponse<any[], any>> =>
    await httpService.get(
        `/${process.env.REACT_APP_MSC_PATH}/dsc-users/export?${request}`, {}, {responseType: 'blob'}
    );

const getBrandingObjects = async (
    data: any
): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/branding_objects/list`,
        data
    );

const getBrandingObjects2 = async (queryParams: string): Promise<AxiosResponse<any, any>> =>
    await httpService.get(
        `/${process.env.REACT_APP_MSC_PATH}/branding_objects/list?${queryParams}`
    );

const getBrandingObject = async (id: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/branding_objects/${id}`
    );

const getBusinessFunctions = async (search: string): Promise<AxiosResponse<any, any>> =>
    await httpService.get(
        `/${process.env.REACT_APP_MSC_PATH}/business_functions?${search}`
    );

const saveBrandingObject = async ({
                                      brandingId,
                                      data,
                                  }: {
    brandingId: any;
    data: any;
}): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.put(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/branding_objects/${brandingId}`,
        data
    );

export const createBrandingObject = async ({
                                               data,
                                           }: {
    data: any;
}): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/branding_objects`,
        data
    );

const getGroups = async (
    data: any
): Promise<AxiosResponse<GroupTypeApiResponse, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/group/search`,
        data
    );

const getUsers = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/user/list`,
        data
    );

const getUsers2 = async (search: string): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(`/${process.env.REACT_APP_MSC_PATH}/users/list?userFilter=${search}`);

const findUser = async (email: string): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${
            process.env.REACT_APP_MSC_PATH
        }/rafadmin/user/find?name=${encodeURIComponent(email)}`
    );

const findUserByQuery = async (
    query: string
): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${
            process.env.REACT_APP_MSC_PATH
        }/rafadmin/user/findByQuery?query=${encodeURIComponent(query)}`
    );

const inviteUser = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/azure/api/invite`,
        data
    );

const getRegion = async (): Promise<AxiosResponse<any[], any>> =>
    await httpService.get(`/${process.env.REACT_APP_MSC_PATH}/regions/list`);

const getInsights = async (sectorFilter = '', offset = 0, limit = 20): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(`/${process.env.REACT_APP_MSC_PATH}/insights/list?sectorFilter=${sectorFilter}&offset=${offset}&limit=${limit}`);

const getCustomerObject = async (nameFilter = '', offset = 0, limit = 5000): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/customer_objects?nameFilter=${nameFilter}&offset=${offset}&limit=${limit}`
    );

const saveCustomerObject = async ({
                                      customerId,
                                      data,
                                  }: {
    customerId: any;
    data: any;
}): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.put(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/customer_objects/${customerId}`,
        data
    );

const saveCustomerObject2 = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.post(
        `/${process.env.REACT_APP_MSC_PATH}/customer_objects`,
        data
    );

const updateCustomerObject = async (id: any, data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.put(
        `/${process.env.REACT_APP_MSC_PATH}/customer_objects/${id}`,
        data
    );

const exportCustomers = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/customer_objects/export`,
        data,
        {responseType: 'blob'}
    );

const exportCustomers2 = async (request: string): Promise<AxiosResponse<any[], any>> =>
    await httpService.get(
        `/${process.env.REACT_APP_MSC_PATH}/customer_objects/export?${request}`, {}, {responseType: 'blob'}
    );

const getTemplates = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/template/customer`,
        data
    );

const exportBrandings = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/branding_objects/export`,
        data,
        {responseType: 'blob'}
    );

const filterMdmCustomers = async (
    nameFilter: any
): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/mdm/customer${
            nameFilter ? `?nameFilter=${encodeURIComponent(nameFilter)}` : ''
        }`
    );

const findMdmVdcsForCustomer = async (
    cid: string
): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/mdm/customer/${cid}/vdc`
    );

const findMdmFacilitiesForCustomer = async (
    cid: string
): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/mdm/customer/${cid}/facility`
    );

const getCustomer = async (id: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/customer_objects/${id}`
    );

const getCustomerObjectData = async (id: any): Promise<AxiosResponse<any, any>> =>
    await httpService.get(
        `/${process.env.REACT_APP_MSC_PATH}/customer_objects/${id}`
    );

const createCustomer = async ({
                                  data,
                              }: {
    data: any;
}): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/customer_objects`,
        data
    );

const updateCustomer = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.put(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/customer_objects/${data.id}`,
        data
    );

const deleteCustomer = async (id: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.delete(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/customer_objects/${id}`
    );

const updateProfile = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.put(`/${process.env.REACT_APP_MSC_PATH}/user/profile`, data);

const getProfile = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(`/${process.env.REACT_APP_MSC_PATH}/user/profile`);

const getJobRole = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(`/${process.env.REACT_APP_MSC_PATH}/rafadmin/job_role`);

const getSectors = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(`/${process.env.REACT_APP_MSC_PATH}/sectors/list`);

const getJobFunction = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/job_function`
    );

export const getStylesheets = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/branding_objects/stylesheets/enumerate`
    );

export const getBrandings = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/branding_objects/enumerate`
    );

export const getRegions = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.get(`/${process.env.REACT_APP_MSC_PATH}/rafadmin/region`);

const getNewsList = async (
    data: any,
    custObjectId: string | null
): Promise<AxiosResponse<any, any>> => {
    delete data.trigger;
    const path =
        isEmpty(custObjectId) || custObjectId === null
            ? `/${process.env.REACT_APP_MSC_PATH}/rafadmin/news/list`
            : `/${process.env.REACT_APP_MSC_PATH}/rafadmin/news/list/${custObjectId}`;
    return await httpService.httpPrivate.post(path, data);
};

const getNewsDetails = async (
    newsId: string | null
): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/news/${newsId}`
    );

const createNews = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/news`,
        data
    );

const updateNews = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.put(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/news/${data.id}`,
        data
    );

const editNewsStatus = async (
    data: any,
    coObjectId: string
): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.put(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/news/${data.id}/${coObjectId}`,
        data
    );

const editBulkNewsSequence = async (
    data: any[],
    coObjectId: string | null
): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.put(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/news/bulkSeqNoUpdate/${coObjectId}`,
        data
    );

export interface RequestContext {
    inputData: any;
    request: (inputData: any) => Promise<AxiosResponse<any, any>>;
    prefetch?: () => void;
    postfetch?: () => void;
    setLoading: (data: boolean) => void;
    setType: (data: string) => void;
    setMessage: (data: string) => void;
    responseHandler: (response: AxiosResponse<any, any>) => void;
}

const processRequest = (request: RequestContext): void => {
    request.prefetch?.();
    request.setLoading(true);
    request
        .request(request.inputData)
        .then((response) => {
            if (response.status === 200) {
                request.responseHandler(response);
            } else {
                request.setType(NOTIFICATION_TYPES.FAILED);
                request.setMessage(String(response));
            }
        })
        .catch((error) => {
            request.setType(NOTIFICATION_TYPES.FAILED);
            if(error?.response?.data?.message !== undefined){
                request.setMessage(error.response.data.message);
            } else {
                request.setMessage(error.message);
            }
        })
        .finally(() => {
            request.setLoading(false);
            request.postfetch?.();
        });
};

// ======= MS GRAPH APIS ============= //
const getProfilePicture = async (): Promise<AxiosResponse<any, Blob>> =>
    await httpService.httpMsGraph.get('me/photo/$value', {
        headers: {
            'Content-Type': 'image/jpeg',
        },
        responseType: 'blob'
    });

const getMediaTypes = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/mediatype`
    );

export const logAuditEvent = async (
    eventType: string,
    appId?: number
): Promise<AxiosResponse<any, any>> => {
    const data: any = {};

    // detect browser features
    data.browserName = platform.name;
    data.browserVersion = platform.version;
    data.product = platform.product;
    data.manufacturer = platform.manufacturer;
    data.layout = platform.layout;
    if (platform.os) {
        data.os = platform.os.family;
        data.osVersion = platform.os.version;
    }
    data.description = platform.description;

    if (appId) {
        data.scopeId = appId;
    }

    return await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/audit/events/${eventType}`,
        data
    );
};

const getFiveStarRatingCategories = async (): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/categories/five_star_rating_categories`,
        {},
    );


const submitRating = async (
    request: any
): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/rating/rateOrderInternal`,
        request
    );

const getCategories = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/categories/list`,
        data
    );

const getCategory = async (id: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.get(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/categories/${id}`
    );

const saveCategory = async ({
                                id,
                                data,
                            }: {
    id: any;
    data: any;
}): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.put(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/categories/${id}`,
        data
    );

export const createCategory = async ({
                                         data,
                                     }: {
    data: any;
}): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/categories`,
        data
    );

const exportCategory = async (data: any): Promise<AxiosResponse<any, any>> =>
    await httpService.httpPrivate.post(
        `/${process.env.REACT_APP_MSC_PATH}/rafadmin/categories/export`,
        data,
        {responseType: 'blob'}
    );

export default {
    getCountries,
    getLanguages,
    getCurrentCountry,
    getCustomers,
    getUser,
    getUserOld,
    logLogin,
    getUsers,
    getUsers2,
    findUser,
    findUserByQuery,
    activateUser,
    getCustomerList,
    getCoList,
    getBrandingObjects,
    getBrandingObjects2,
    getBrandingObject,
    getBusinessFunctions,
    getGroups,
    inviteUser,
    getRegion,
    getCustomerObject,
    getCustomerObjectData,
    saveCustomerObject,
    saveCustomerObject2,
    updateCustomerObject,
    saveBrandingObject,
    createBrandingObject,
    exportCustomers,
    exportCustomers2,
    getTemplates,
    exportBrandings,
    filterMdmCustomers,
    findMdmVdcsForCustomer,
    findMdmFacilitiesForCustomer,
    getCustomer,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    updateProfile,
    getProfile,
    getJobRole,
    getSectors,
    getJobFunction,
    processRequest,
    getStylesheets,
    getBrandings,
    getRegions,
    getMediaTypes,
    getNewsList,
    getNewsDetails,
    createNews,
    updateNews,
    editNewsStatus,
    editBulkNewsSequence,
    getFiveStarRatingCategories,
    submitRating,
    getCategories,
    getCategory,
    saveCategory,
    createCategory,
    exportCategory,
    getProfilePicture,
    getInsights,
    getDscUmUsersList,
    exportDscUmUsers,
    getValidateImpersonatedUserEmail,
    getImpersonateUser,
    getStopImpersonatedUserSession
};
