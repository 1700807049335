import React, {useContext} from 'react';
import {TypeIcon} from './TypeIcon';
import styled from 'styled-components';
import {ThemeContext} from '../../styles/ThemeContext';
import {
    DhlIconWrapper,
    DhlIcon,
} from '@dhl-official/ui-libraries/react-library';
import DhlText from '../DhlText';
import {IconContext} from '../../assets/IconContext';
import {lightTheme} from '../../styles/constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Avtar from '../../pages/ProfilePage/components/Avtar';
import downArrowLight from '../../assets/images/downArrowLight.svg';
import useMediaQuery from "../../styles/MediaQueryHooks";

const ItemWrapper = styled.div`
  width: auto !important;
  width: 250px;
  height: 50px;
  display: flex;
  margin-top: 1px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
`;

const LeftIconWrapper = styled.div`
  width: 30px;
  height: 50px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextWrapper = styled.div`
  width: 90%;
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const ItemIconRightBranding = styled(DhlIconWrapper)`
  margin-left: 10px;
  background-color: ${(props) =>
    props.theme.isBrandingObjectShowing === true && props.theme.type === 'label'
        ? props.theme.textColor
        : null};
  width: 15px;
  height: 15px;
  mask-size: 15px;
  mask-image: url(${(props) =>
    props.theme.isBrandingObjectShowing === true
        ? props.theme.imageUrl
        : null});
`;

const ItemIconRight = styled(DhlIconWrapper)`
  margin-left: 10px;
`;

const MenuItem: React.FC<TypeIcon> = (props) => {
    const theme = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const {
        type,
        label,
        onSelect,
        icon,
        isProfile,
        isBrandingObjectShowing,
        brandingTextColor,
    } = props;

    const updatedTheme = type === 'options' ? theme : lightTheme;

    const onClick = (e: any): void => {
        if (onSelect !== undefined) onSelect();
    };

    const textColor = (): string => {
        if (
            isBrandingObjectShowing !== undefined &&
            isBrandingObjectShowing &&
            brandingTextColor !== undefined
        ) {
            return brandingTextColor;
        } else {
            return '#000000';
        }
    };

    const imageUrl = downArrowLight.substring(downArrowLight.indexOf(':'));

    const renderArrowIcon = (): JSX.Element => {

        const matches = useMediaQuery('(min-width: 900px)');

        if (!matches && isBrandingObjectShowing === true && type === 'label') {
            return (
                <ItemIconRightBranding
                    size="sm"
                    theme={{
                        ...theme,
                        isBrandingObjectShowing,
                        imageUrl,
                        textColor,
                        type,
                    }}
                ></ItemIconRightBranding>
            );
        } else {
            return (
                <ItemIconRight siz e="sm">
                    <DhlIcon src={getIcon(type === 'label' ? 'downArrow' : 'upArrow')} size="1rem"/>
                </ItemIconRight>
            );
        }
    };
    return (
        <ItemWrapper theme={updatedTheme} onClick={onClick}>
            <LeftIconWrapper>
                {isProfile === true ? (
                    <Avtar isSmall/>
                ) : (
                    <FontAwesomeIcon icon={icon} size="xl"/>
                )}
            </LeftIconWrapper>

            <TextWrapper>
                <DhlText
                    size={'sm'}
                    weight={type !== 'options' ? 800 : 400}
                    title={label}
                    color={type === 'label' ? textColor() : undefined}
                />

                {/* <DhlText
          size={'xs'}
          title={role}
          color={type === 'label' ? '#000000' : undefined}
        /> */}
            </TextWrapper>

            {type !== 'options' ? renderArrowIcon() : null}
        </ItemWrapper>
    );
};

export default MenuItem;
