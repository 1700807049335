/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../../../styles/ThemeContext';
import TypePagination from './TypePagination';
import DhlText from '../../../../components/DhlText';
import { useTranslation } from 'react-i18next';
import { DhlInputField } from '@dhl-official/ui-libraries/react-library';
import { hasNumber } from '../../../../utils';

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  min-width: 100%;
  border-top: 1px solid #ebebeb;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 4px;
  min-width: 50%;
`;

const PagingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  max-width: 50%;
`;

const MyButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 10px;
`;

const MyInputField = styled(DhlInputField)`
  max-width: 60px;
  margin-left: 4px;
  margin-right: 4px;
  input {
    border-width: 0px;
    height: 20px;
    margin-bottom: 12px;
  }
`;

const MyButton = styled.div<{ isSelected: boolean; isDisabled: boolean }>`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: ${(props) => props.theme.color.grey[600]};
  border: ${(props) => (props.isSelected ? `2px solid red` : '')};
  border-radius: 4px;
`;

export interface TypePaging {
  selectedPage: number;
  changeSelectedPage: Function;
  totalPageCount: number;
}

export const PaginationWrapper = (props: TypePaging): JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const { selectedPage, changeSelectedPage, totalPageCount } = props;

  if (selectedPage === 0) return <></>;

  return (
    <MyButtonGroup>
      <MyButton
        theme={theme}
        isDisabled={selectedPage === 1}
        isSelected={false}
        onClick={() => {
          if (selectedPage !== 1) changeSelectedPage(selectedPage - 1);
        }}
      >
        {'<'}
      </MyButton>

      <MyButton
        theme={theme}
        isDisabled={selectedPage === 1}
        isSelected={selectedPage === 1}
        onClick={() => {
          if (selectedPage !== 1) changeSelectedPage(1);
        }}
      >
        {'1'}
      </MyButton>

      <MyButton
        theme={theme}
        isDisabled={selectedPage === 2}
        isSelected={selectedPage === 2}
        onClick={() => {
          if (selectedPage !== 2) changeSelectedPage(2);
        }}
      >
        {'2'}
      </MyButton>

      <MyButton
        theme={theme}
        isDisabled={selectedPage === 3}
        isSelected={selectedPage === 3}
        onClick={() => {
          if (selectedPage !== 3) changeSelectedPage(3);
        }}
      >
        {'3'}
      </MyButton>

      <MyButton
        theme={theme}
        isDisabled={selectedPage === 4}
        isSelected={selectedPage === 4}
        onClick={() => {
          if (selectedPage !== 4) changeSelectedPage(4);
        }}
      >
        {'4'}
      </MyButton>

      <MyButton
        theme={theme}
        isDisabled={selectedPage === totalPageCount}
        isSelected={false}
        onClick={() => {
          if (selectedPage !== totalPageCount)
            changeSelectedPage(selectedPage + 1);
        }}
      >
        {'>'}
      </MyButton>
    </MyButtonGroup>
  );
};

function Pagination(props: TypePagination): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const {
    totalCount,
    totalFilterCount,
    selectedEntry,
    selectedPage,
    changePageNo,
  } = props;

  const [pageEntered, setEnteredPage] = useState<string | number>(selectedPage);

  const validCountForCalculation =
    totalFilterCount > 0 ? totalFilterCount : totalCount;

  const totalPageCount = Math.ceil(validCountForCalculation / selectedEntry);

  const onKeyPressEvent = (event: KeyboardEvent): void => {
    if (event.key === 'Enter' && hasNumber(pageEntered)) {
      changePageNo(Number(pageEntered));
    }
  };

  return (
    <ComponentWrapper theme={theme}>
      <TextWrapper>
        <DhlText size="sm" title={t('App.component.table.showingPage')} />
        <MyInputField
          type={'text'}
          value={pageEntered}
          keyDownEvent={onKeyPressEvent}
          inputEvent={(event: any) => setEnteredPage(event.target.value)}
        />
        <DhlText size="sm" title={`Out of ${totalPageCount}`} />
      </TextWrapper>
      <PagingWrapper>
        <PaginationWrapper
          selectedPage={selectedPage}
          totalPageCount={totalPageCount}
          changeSelectedPage={changePageNo}
        />
      </PagingWrapper>
    </ComponentWrapper>
  );
}

export default Pagination;
