import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../../../styles/ThemeContext';
import TypeTableHead from './TypeTableHead';
import { DhlDropdown } from '@dhl-official/ui-libraries/react-library';
import { useTranslation } from 'react-i18next';

const TableHeadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const MyEntryDropDown = styled(DhlDropdown)`
  min-width: 130px;
`;

function TableHead(props: TypeTableHead): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { pageSize, pages, changePageSize } = props;
  const { t } = useTranslation();

  const myEntriesData = pages.map((entry) => ({
    label: String(entry),
    value: String(entry),
  }));

  return (
    <TableHeadWrapper theme={theme}>
      <MyEntryDropDown
        value={String(pageSize)}
        label={t('App.component.table.itemsPerPage')}
        data={myEntriesData}
        changeEvent={changePageSize}
      />
    </TableHeadWrapper>
  );
}

export default TableHead;
