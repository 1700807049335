import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import { BreadCrumbType } from './BreadCrumbType';
import DhlButton from '../DhlButton';
import DhlText from '../DhlText';
import { isEmpty } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  width: auto !important;
  max-height: 50px;
  display: flex;
  margin-top: 4px;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const MyDhlText = styled(DhlText)<{ isDisabled: boolean }>`
  text-decoration: ${(props) => (props.isDisabled ? '' : 'underline')};
`;

export interface BreadCrumbsList {
  list: BreadCrumbType[];
}

const DhlBreadCrumbs: React.FC<BreadCrumbsList> = (props) => {
  const { theme, isDarkTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { list } = props;

  const onClick = (item: BreadCrumbType): void => {
    navigate(item.path);
  };

  return (
    <Wrapper theme={theme}>
      {list.map((item, index) => {
        const isLastIndedx = index + 1 === list.length;
        return (
          <TextWrapper key={`breadcrumb-${item.path}`}>
            <DhlButton
              size="xs"
              weight={600}
              title={
                <MyDhlText
                  title={t(item.label)}
                  size={'xs'}
                  isDisabled={isEmpty(item.path)}
                  color={
                    isEmpty(item.path)
                      ? '#b2b2b2'
                      : isDarkTheme
                      ? '#ffffff'
                      : '#000000'
                  }
                />
              }
              onClick={() => onClick(item)}
              variant="text"
              textSize="sm"
              isDisabled={isEmpty(item.path)}
            />
            {!isLastIndedx && <DhlText size="sm" weight={400} title=">" />}
          </TextWrapper>
        );
      })}
    </Wrapper>
  );
};

export default DhlBreadCrumbs;
