/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../styles/ThemeContext';
import { device } from '../../styles/Device';
import DhlText from '../../components/DhlText';
import { useTranslation } from 'react-i18next';
import {
  DhlDropdown,
  DhlInputField,
  DhlIcon,
  DhlIconWrapper,
} from '@dhl-official/ui-libraries/react-library';
import { CountryContext } from '../../contexts/CountryContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DhlBreadCrumbs from '../../components/DhlBreadCrumbs';
import ServiceRequest from '../../service/ServiceRequest';
import { LoadingContext } from '../../contexts/LoadingContext';
import {
  NOTIFICATION_TYPES,
  NotificationContext,
} from '../../contexts/NotificationContext';
import GroupTable from './components/GroupTable';
import TypeHeaderCell from './components/TableHeaderCell/TypeHeaderCell';
import {
  EmptyResponse,
  GroupTypeApiResponse,
} from '../../contexts/types/GroupType';
import { TypeFilter } from './TypeFilter';
import { PATHS } from '../App/constants';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '../../utils/debounce-hooks';
import { isEmpty, isValidPhoneNumber } from '../../utils';
import {
  BottomWrapper,
  BreadCrumbsWrapper,
  ButtonItem,
  ButtonWrapper,
  filterInner,
  HeaderWrapper,
  PageWrapper,
} from '../common';
import JobRoleType from '../../contexts/types/JobRoleType';
import JobFunctionType from '../../contexts/types/JobFunctionType';
import { icons } from '@dhl-official/ui-libraries/assets/icons';
import RegionType from '../../contexts/types/RegionType';
import { UserContext } from '../../contexts/UserContext';
import {CustomerObjectContext} from "../../contexts/CustomerObjectContext";

const ReedemLinkWrapper = styled.div`
  width: 100%;
  padding: 26px;
  margin: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.background};
  box-shadow: 1px 1px 2px 1px #e6e6e6;
`;

const ReedemLinkLeft = styled.div`
  margin: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100px;
  align-items: center;
`;

const ReedemLinkRight = styled.div`
  margin: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LeftFormWrapper = styled.div<{ isDhl: boolean }>`
  @media ${device.mobile} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: ${(props) => (props.isDhl ? '60%' : '100%')};
  }
  padding: 16px;
  margin: 4px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};
  box-shadow: 1px 1px 2px 1px #e6e6e6;
`;

const FormWrapper = styled.div`
  width: 100%;
  min-width: 200px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const RightFormWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 38%;
  }
  min-width: 200px;
  min-height: 200px;
  padding: 16px;
  margin: 4px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};
  box-shadow: 1px 1px 2px 1px #e6e6e6;
`;

const MyDivider = styled.div`
  width: 100%;
  height: 2px;
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.divider};
`;

// const CheckboxWrapper = styled.div`
//   @media ${device.mobile} {
//     width: 90%;
//     margin-top: 8px;
//     margin-bottom: 8px;
//   }
//   @media ${device.tablet} {
//     width: 48%;
//     margin-top: 16px;
//     margin-bottom: 16px;
//   }
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   align-items: center;
//   justify-content: space-between;
// `;

const FormField = styled(DhlInputField)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const FormDropDown = styled(DhlDropdown)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  @media ${device.tablet} {
    width: 48%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const ReedemLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

interface IFormInputs {
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  language: any;
  country: any;
  region: any;
  phoneNumber: string;
  companyName: string;
  jobRole: string;
  jobFunction: string;
  customerObject: any;
  genericAccountOwner: string;
  emailSwitch: boolean;
}

const initialFilterForGroupSearch = {
  draw: 1,
  columns: [
    {
      data: 'groupId',
      name: '',
      searchable: true,
      orderable: false,
      search: { value: '', regex: false },
    },
    {
      data: 'groupName',
      name: '',
      searchable: true,
      orderable: false,
      search: { value: '', regex: false },
    },
    {
      data: 'groupDescr',
      name: '',
      searchable: true,
      orderable: false,
      search: { value: '', regex: false },
    },
  ],
  order: [
    {
      column: 0,
      dir: 'asc',
    },
  ],
  start: 0,
  length: 10,
  search: { value: '', regex: false },
};

function Externaluser(): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { countries } = useContext(CountryContext);
  const { languages } = useContext(LanguageContext);
  const { setLoading } = useContext(LoadingContext);
  const { setType, setMessage } = useContext(NotificationContext);
  const { customerObjectList } = useContext(CustomerObjectContext);
  const { user } = useContext(UserContext);
  const [searchedGroup, setSearchedGroup] = useState('');
  const [countryFilter, setCountryFilter] = useState('');
  const [languageFilter, setLanguageFilter] = useState('');
  const [regionFilter, setRegionFilter] = useState('');
  const [customerObjectFilter, setCustomerObjectFilter] = useState('');
  const [regionData, setRegionData] = useState<RegionType[]>([]);
  const [customerObjectData, setCustomerObject] = useState<any>([]);
  const [groupApiFilter, updateGroupApiFilter] = useState<TypeFilter>(
    initialFilterForGroupSearch
  );
  const [selectedGroup, changeSelectedGroup] = useState<Number[]>([]);
  const [groupData, setGroupData] =
    useState<GroupTypeApiResponse>(EmptyResponse);
  const [jobFunctionFilter, setJobFunctionFilter] = useState('');
  const [jobRoleFilter, setJobRoleFilter] = useState('');
  const [jobRoles, setJobRoles] = useState<JobRoleType[]>([]);
  const [jobFunctions, setJobFunction] = useState<JobFunctionType[]>([]);
  const [reedem, setReedem] = useState<string>(''); // https://login.microsoftonline.com/redeem?rd=https%3a%2f%2finvitations.microsoft.com%2fredeem%2f%3ftenant%3dcd99fef8-1cd3-4a2a-9bdf-15531181d65e%26user%3d9094bb3b-8bfc-45d3-b82c-616fb8444fd3%26ticket%3d7knq5EmdW%25252f1HzeSto%25252bpf4EMU1XQuS4lXL2kfnizn6yo%25253d%26ver%3d2.0");
  const [showReedem, setShowReedem] = useState(false);
  const [reedemStatus, setReedemStatus] = useState<string>('SUCCESS');
  const [reedemGroup, setReedemGroup] = useState<string>(''); // MSC3333");
  const isMobile = window.innerWidth < 600;

  const navigate = useNavigate();

  const GroupApiSearch = (): void => {
    setLoading(true);
    ServiceRequest.getGroups(groupApiFilter)
      .then((response) => {
        setGroupData(response.data);
      })
      .catch((error) => {
        console.error(error);
        // This is commented to fix the MSCTT-2108
        // setType(NOTIFICATION_TYPES.FAILED);
        // setMessage(error.message);
      })
      .finally(() => {
        // setGroupData(DummyResponse);
        setLoading(false);
      });
  };

  const getRegionData = (): void => {
    setLoading(true);
    ServiceRequest.getRegion()
      .then((response) => {
        setRegionData(response.data);
      })
      .catch((error) => {
        console.error(error);
        // This is commented to fix the MSCTT-2108
        // setType(NOTIFICATION_TYPES.FAILED);
        // setMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getJobRoles = (): void => {
    setLoading(true);
    // GET JOB ROLES
    ServiceRequest.getJobRole()
      .then((response) => {
        setJobRoles(response.data);
      })
      .catch((error) => {
        console.error(error);
        // This is commented to fix the MSCTT-2108
        // setMessage(error);
        // setType(NOTIFICATION_TYPES.FAILED);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getJobFunctions = (): void => {
    setLoading(true);
    // GET JOB FUNCTIONS
    ServiceRequest.getJobFunction()
      .then((response) => {
        setJobFunction(response.data);
      })
      .catch((error) => {
        console.error(error);
        // This is commented to fix the MSCTT-2108
        // setMessage(error);
        // setType(NOTIFICATION_TYPES.FAILED);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if(customerObjectList && customerObjectList.length > 0) {
        customerObjectList.forEach((co:any) => {
            if(co.value) {
                co.value = co.value.toString();
            }
        });
      setCustomerObject(customerObjectList);
    }
  }, [customerObjectList]);

    useEffect(() => {
        GroupApiSearch();
        getRegionData();
        getJobRoles();
        getJobFunctions();
    }, []);

  useEffect(() => {
    if (user.isDhl) GroupApiSearch();
  }, [groupApiFilter]);

  const debounceApiCall = useDebounce(() => {
    updateGroupApiFilter({
      ...groupApiFilter,
      search: {
        ...groupApiFilter.search,
        value: searchedGroup,
      },
      draw: groupApiFilter.draw + 1,
    });
  }, 1000);

  useEffect(() => {
    debounceApiCall();
  }, [searchedGroup]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<IFormInputs>({
    defaultValues: {
      userName: '',
      email: '',
      firstName: '',
      lastName: '',
      language: '',
      country: '',
      region: '',
      phoneNumber: '',
      companyName: '',
      jobRole: '',
      jobFunction: '',
      customerObject: '',
      genericAccountOwner: '',
      emailSwitch: false,
    },
  });

  const myCountryData = useMemo(
    () => filterInner(countryFilter, countries, 'id'),
    [countries, countryFilter]
  );
  const myLanguageData = useMemo(
    () => filterInner(languageFilter, languages, 'languageId'),
    [languages, languageFilter]
  );
  const myRegionData = useMemo(
    () => filterInner(regionFilter, regionData, 'regionId'),
    [regionData, regionFilter]
  );
  const myJobFunctionData = useMemo(
    () => filterInner(jobFunctionFilter, jobFunctions, 'id'),
    [jobFunctions, jobFunctionFilter]
  );
  const myJobRoleData = useMemo(
    () => filterInner(jobRoleFilter, jobRoles, 'id'),
    [jobRoles, jobRoleFilter]
  );
  useEffect(() => {
    if (myLanguageData.length > 0) setValue('language', '1');
  }, [myLanguageData]);

  useEffect(() => {
    if (myRegionData.length > 0) setValue('region', '1');
  }, [myRegionData]);

  const myCustomerObjectData = useMemo(() => {
    if (isEmpty(customerObjectFilter)) {
      return customerObjectData;
    } else {
      const filterCoList = customerObjectData.filter(
        (customerObject: { label: string }) =>
          customerObject.label
            .toLowerCase()
            .includes(customerObjectFilter.toLowerCase())
      );
      return filterCoList;
    }
  }, [customerObjectData, customerObjectFilter]);

  const onSubmitData: SubmitHandler<IFormInputs> = (data) => {
    const myGroupData: Number[] = [];
    selectedGroup.forEach((element: any) => {
      myGroupData.push(element.groupId);
    });

    const objectRequest = {
      username: data.userName,
      languageId: data.language,
      firstName: data.firstName,
      lastName: data.lastName,
      countryId: data.country,
      phone: data.phoneNumber,
      company: data.companyName,
      isSubscribed: String(data.emailSwitch),
      genericAccountOwner: data.genericAccountOwner,
      jobRoleId: data.jobRole,
      jobFunctionId: data.jobFunction,
      emailAddress: data.email,
      groups: myGroupData,
      regionId: data.region,
      mscCid: data.customerObject,
      messageType: 0,
      // DataTables_Table_0_length: '10'
    };

    setLoading(true);
    ServiceRequest.inviteUser(objectRequest)
      .then((data) => {
        // eslint-disable-next-line no-debugger
        // debugger;
        const redeemAndGroup: string[] = data?.data?.message.split('^');
        setReedem(redeemAndGroup.length > 0 ? redeemAndGroup[0] : '');
        setReedemStatus(data?.data?.status);
        setReedemGroup(redeemAndGroup.length > 1 ? redeemAndGroup[1] : '');
        setShowReedem(true);
      })
      .catch((error) => {
        setType(NOTIFICATION_TYPES.FAILED);
        if(error?.response?.data?.message !== undefined){
          setMessage(error.response.data.message);
        } else {
          setMessage(error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const myBreadCrumbList = [
    { label: 'App.breadcrumb.home', path: PATHS.HOME },
    { label: 'App.breadcrumb.createExternalUser', path: '' },
  ];

  const GroupColumns: TypeHeaderCell[] = [
    {
      id: 147,
      field: 'groupId',
      label: '',
      isSelectable: true,
      isSortable: false,
      isFilterable: false,
    },
    {
      id: 123,
      field: 'groupName',
      label: t('App.page.ExternalUser.groupName'),
      isSelectable: false,
      isSortable: false,
      isFilterable: false,
    },
    {
      id: 456,
      field: 'groupDescr',
      label: t('App.page.ExternalUser.groupDescription'),
      isSelectable: false,
      isSortable: false,
      isFilterable: false,
    },
  ];

  const updatePagination = (pagination: any): void => {
    updateGroupApiFilter({
      ...groupApiFilter,
      length: pagination.pageSize,
      start: pagination.pageSize * pagination.selectedPage,
      draw: groupApiFilter.draw + 1,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <PageWrapper theme={theme}>
        <BreadCrumbsWrapper>
          <DhlBreadCrumbs list={myBreadCrumbList} />
        </BreadCrumbsWrapper>
        <HeaderWrapper>
          <DhlText
            size="md"
            weight={800}
            title={t('App.page.ExternalUser.createExternalUser')}
          />

          <ButtonWrapper>
            <ButtonItem
              title={t('App.page.ExternalUser.upload')}
              variant="outline"
              size={isMobile ? "xs" : "sm"}
              dataAriaLabel={t('App.page.ExternalUser.upload')}
              onClick={() => navigate(PATHS.UPLOAD_USER)}
            />

            <ButtonItem
              title={t('App.page.ExternalUser.createUser')}
              variant="primary"
              size={isMobile ? "xs" : "sm"}
              dataAriaLabel={t('App.page.ExternalUser.createUser')}
              type="submit"
            />
          </ButtonWrapper>
        </HeaderWrapper>
        {showReedem && (
          <BottomWrapper>
            <ReedemLinkWrapper theme={theme}>
              <ReedemLinkLeft>
                {reedemStatus === 'WARNING' && (
                  <DhlIconWrapper animation="spin" size="xl">
                    <DhlIcon src={icons.illuError} size={40}></DhlIcon>
                  </DhlIconWrapper>
                )}
                {reedemStatus === 'SUCCESS' && (
                  <DhlIconWrapper animation="spin" size="xl">
                    <DhlIcon src={icons.illuSuccess} size={40}></DhlIcon>
                  </DhlIconWrapper>
                )}
              </ReedemLinkLeft>
              <ReedemLinkRight>
                <DhlText
                  size="sm"
                  title={
                    <>
                      <DhlText
                        size="sm"
                        weight={800}
                        title={
                          reedemStatus === 'WARNING'
                            ? `The user already exist in Azure!`
                            : `Invitation has been created and here is the Redeem link: `
                        }
                      />
                      {reedemStatus === 'SUCCESS' && (
                        <DhlText
                          size="sm"
                          dataAriaLabel={"copy to clipboard"}
                          title={
                            <ReedemLink title={reedem}>
                              {`${reedem}`}
                              <DhlIconWrapper animation="spin" title={'copy to clipboard'}>
                                <DhlIcon
                                  src={icons.copy}
                                  alt={'copy to clipboard'}
                                  title={'copy to clipboard'}
                                  dataAriaLabel={"copy to clipboard"}
                                  onClick={() => {
                                    void navigator.clipboard.writeText(reedem);
                                  }}
                                />{' '}
                              </DhlIconWrapper>
                            </ReedemLink>
                          }
                        />
                      )}
                    </>
                  }
                />

                <DhlText
                  size="sm"
                  title={
                    <>
                      <DhlText
                        size="sm"
                        weight={800}
                        title={`User has been assigned to MySupplyChain application: `}
                      />
                      <DhlText
                        size="sm"
                        title={<ReedemLink title={reedemGroup}>{`${reedemGroup}`}</ReedemLink>}
                      />
                    </>
                  }
                />
              </ReedemLinkRight>
            </ReedemLinkWrapper>
          </BottomWrapper>
        )}
        <BottomWrapper>
          <LeftFormWrapper isDhl={user.isDhl} theme={theme}>
            <DhlText
              size="sm"
              weight={600}
              title={t('App.page.ExternalUser.userDetails')}
            />

            <FormWrapper>
              <Controller
                name="userName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormField
                    {...propsField}
                    dataId="userName-inputfield"
                    type="text"
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    validation={
                      errors.userName && {
                        type: 'invalid',
                        message: t('App.page.Branding.required'),
                      }
                    }
                    variant={{
                      label: `${t('App.page.ExternalUser.userName')} *`,
                      placeholder: `${t('App.page.ExternalUser.userName')} *`,
                      type: 'animated',
                    }}
                  />
                )}
              />

              <Controller
                name="email"
                control={control}
                rules={{
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/,
                  required: true,
                }}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormField
                    {...propsField}
                    dataId="email-inputfield"
                    type="email"
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    validation={
                      errors.email && {
                        type: 'invalid',
                        message: 'Invalid email address.',
                      }
                    }
                    variant={{
                      label: `${t('App.page.ExternalUser.email')} *`,
                      placeholder: `${t('App.page.ExternalUser.email')} *`,
                      type: 'animated',
                    }}
                  />
                )}
              />

              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormField
                    {...propsField}
                    dataId="firstName-inputfield"
                    type="text"
                    validation={
                      errors.firstName && {
                        type: 'invalid',
                        message: t('App.page.Branding.required'),
                      }
                    }
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    variant={{
                      label: `${t('App.page.ExternalUser.firstName')} *`,
                      placeholder: `${t('App.page.ExternalUser.firstName')} *`,
                      type: 'animated',
                    }}
                  />
                )}
              />

              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormField
                    {...propsField}
                    dataId="lastName-inputfield"
                    type="text"
                    validation={
                      errors.lastName && {
                        type: 'invalid',
                        message: t('App.page.Branding.required'),
                      }
                    }
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    variant={{
                      label: `${t('App.page.ExternalUser.lastName')} *`,
                      placeholder: `${t('App.page.ExternalUser.lastName')} *`,
                      type: 'animated',
                    }}
                  />
                )}
              />

              <Controller
                name="language"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="language-inputfield"
                    data={myLanguageData}
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    validation={
                      errors.language && {
                        type: 'invalid',
                        message: t('App.page.Branding.required'),
                      }
                    }
                    filterChangeEvent={(filter: string) =>
                      setLanguageFilter(filter)
                    }
                    placeholder={`${t('App.page.ExternalUser.language')} *`}
                    // label={t('App.page.ExternalUser.language')}
                    showFilter
                    filterPlaceholder={t(
                      'App.page.ExternalUser.searchLanguage'
                    )}
                  />
                )}
              />

              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="country-inputfield"
                    data={myCountryData}
                    validation={
                      errors.country && {
                        type: 'invalid',
                        message: t('App.page.Branding.required'),
                      }
                    }
                    placeholder={`${t('App.page.ExternalUser.country')} *`}
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    filterChangeEvent={(filter: string) =>
                      setCountryFilter(filter)
                    }
                    // label={t('App.page.ExternalUser.country')}
                    showFilter
                    filterPlaceholder={t('App.page.ExternalUser.searchCountry')}
                  />
                )}
              />

              <Controller
                name="region"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="region-inputfield"
                    data={myRegionData}
                    placeholder={t('App.page.ExternalUser.region')}
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    filterChangeEvent={(filter: string) =>
                      setRegionFilter(filter)
                    }
                    showFilter
                    filterPlaceholder={t('App.page.ExternalUser.searchRegion')}
                    // label={t('App.page.ExternalUser.region')}
                  />
                )}
              />

              <Controller
                name="phoneNumber"
                control={control}
                rules={{ validate: (value) => isValidPhoneNumber(value) }}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormField
                    {...propsField}
                    dataId="phoneNumber-inputfield"
                    type="number"
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    validation={
                      errors.phoneNumber != null && {
                        type: 'invalid',
                        message: 'Invalid Phone Number.',
                      }
                    }
                    variant={{
                      label: t('App.page.ExternalUser.phoneNumber'),
                      placeholder: t('App.page.ExternalUser.phoneNumber'),
                      type: 'animated',
                    }}
                  />
                )}
              />

              <Controller
                name="companyName"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormField
                    {...propsField}
                    dataId="companyName-inputfield"
                    type="text"
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    variant={{
                      label: t('App.page.ExternalUser.companyName'),
                      placeholder: t('App.page.ExternalUser.companyName'),
                      type: 'animated',
                    }}
                  />
                )}
              />

              <Controller
                name="jobRole"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="jobRole-inputfield"
                    data={myJobRoleData}
                    filterChangeEvent={(filter: string) =>
                      setJobRoleFilter(filter)
                    }
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    showFilter
                    filterPlaceholder={t('App.common.search')}
                    placeholder={t('App.page.ExternalUser.jobRole')}
                  />
                )}
              />

              <Controller
                name="jobFunction"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="jobFunction-inputfield"
                    data={myJobFunctionData}
                    filterChangeEvent={(filter: string) =>
                      setJobFunctionFilter(filter)
                    }
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    showFilter
                    filterPlaceholder={t('App.common.search')}
                    placeholder={t('App.page.ExternalUser.jobFunction')}
                  />
                )}
              />

              <Controller
                name="customerObject"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormDropDown
                    {...propsField}
                    dataId="customerObject-inputfield"
                    data={myCustomerObjectData}
                    validation={
                      errors.customerObject && {
                        type: 'invalid',
                        message: t('App.page.Branding.required'),
                      }
                    }
                    blurEvent={onBlur}
                    changeEvent={onChange}
                    filterChangeEvent={(filter: string) =>
                      setCustomerObjectFilter(filter)
                    }
                    filterPlaceholder={t(
                      'App.page.ExternalUser.searchCustomerObject'
                    )}
                    placeholder={`${t(
                      'App.page.ExternalUser.customerObject'
                    )} *`}
                    // label={t('App.page.ExternalUser.customerObject')}
                    showFilter
                  />
                )}
              />

              <Controller
                name="genericAccountOwner"
                control={control}
                render={({ field: { onChange, onBlur, ...propsField } }) => (
                  <FormField
                    {...propsField}
                    dataId="genericAccountOwner-inputfield"
                    type="text"
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    variant={{
                      label: t('App.page.ExternalUser.genericAccountOwner'),
                      placeholder: t(
                        'App.page.ExternalUser.genericAccountOwner'
                      ),
                      type: 'animated',
                    }}
                  />
                )}
              />

              {/* <CheckboxWrapper>
                                <DhlText
                                    size="sm"
                                    weight={600}
                                    title={t('App.page.ExternalUser.switchEmailText')}
                                />

                                <Controller
                                    name="emailSwitch"
                                    control={control}
                                    render={({field: {onChange, value, ...propsField}}) => (
                                        <DhlSwitch
                                            {...propsField}
                                            dataId="emailSwitch-inputfield"
                                            isChecked={value}
                                            changeEvent={onChange}
                                            validation={{
                                                type: 'valid',
                                            }}
                                        />
                                    )}
                                />
                            </CheckboxWrapper> */}
            </FormWrapper>
          </LeftFormWrapper>

          {user.isDhl && (
            <RightFormWrapper theme={theme}>
              <DhlText
                size="sm"
                weight={600}
                title={t('App.page.ExternalUser.selectGroup')}
              />

              <FormField
                dataId="search-group-inputfield"
                type="text"
                value={searchedGroup}
                inputEvent={(val: any) => setSearchedGroup(val.target.value)}
                searchIcon={true}
                variant={{
                  label: '',
                  placeholder: t('App.page.ExternalUser.searchGroup'),
                  type: 'animated',
                }}
              />

              <MyDivider theme={theme} />

              <GroupTable
                id="group_table"
                columns={GroupColumns}
                data={groupData.data}
                filterCount={groupData.recordsFiltered}
                totalCount={groupData.recordsTotal}
                updatePagination={updatePagination}
                isCheckable
                checkedList={selectedGroup}
                changeCheckedList={(val: any) => changeSelectedGroup(val)}
              />
            </RightFormWrapper>
          )}
        </BottomWrapper>
      </PageWrapper>
    </form>
  );
}

export default Externaluser;
