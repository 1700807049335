/* eslint-disable react/prop-types */
import React from 'react';

export default class LinkCell extends React.PureComponent {
  render() {
    const { data } = this.props;

    if (data && data.url) {
      return (
        <a href={data.url}>{data.url}</a>
      );
    }
    return <span />;
  }
}
