/* eslint-disable */
import React, {useContext} from 'react';
import {ThemeContext} from "../../../styles/ThemeContext";
import styled from "styled-components";
import DhlText from "../../../components/DhlText";
import {DhlAccordion, DhlPanel} from "@dhl-official/ui-libraries/react-library";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 10px;
  margin-bottom: 16px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px; 
  padding: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const AccordionWrapper = styled.div`
    width: 100%;
    margin-top: 16px;
    margin-bottom: 12px;
    padding: 10px 4px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
`;

const AccordionItem = styled.div`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
`;

const AccordionTitle = styled.div`
    width: 100%;
    padding: 6px 6px 6px 20px;
    display: flex;
    flex-direction: row;
    background-color: #e6e6e6;
`;

const MyDhlAccordion = styled(DhlAccordion)`
    width: 100%;
`;

const MyDhlPanel = styled(DhlPanel)`
    width: 100%;
`;

const ItemTags = styled.div`
  width: 100%;
  padding: 4px;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Tag = styled.div`
  width: fit-content;
  padding: 4px;
  margin: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 12px;
  background-color: #e6e6e6;
  border: 1px solid #666666;
  border-radius: 4px;
`;


function CustomerObjectProfileComponent(props: any): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {userData} = props;
    const copData = userData?.user_roles_and_permissions?.customer_object;
    console.log("COP LIST ===> ", copData);

    if(copData?.customer == null)
        return <></>;

    else
        return (
        <Wrapper theme={theme}>
                <TitleWrapper>
                    <DhlText title={copData?.customer} size={"md"} weight={600} />
                    <Tag>{copData?.region}</Tag>
                </TitleWrapper>

            <AccordionWrapper>

                {copData?.customer_object_profiles?.length > 0 && copData?.customer_object_profiles?.map((item: any) => {
                    const myAllParentsIncludingDuplicates = item?.business_functions.map((itemData: any) => itemData?.parent_name);
                    console.log("MyAllParents-1", myAllParentsIncludingDuplicates);
                    const myAllParentsUnique = myAllParentsIncludingDuplicates?.filter((item: any,index: number) => myAllParentsIncludingDuplicates?.indexOf(item) === index);
                    console.log("MyAllParents-2", myAllParentsUnique);

                    return <AccordionItem key={item?.name}>
                        <AccordionTitle>
                            <DhlText title={item?.name} size={"sm"} weight={600}/>
                        </AccordionTitle>
                            <MyDhlAccordion>
                                {myAllParentsUnique?.length > 0 && myAllParentsUnique.map((itemShort: any, index: number) => {
                                    const allTheItemsWithCurrentParent = item?.business_functions?.filter((itemData: any) => itemData?.parent_name === itemShort);
                                    return <MyDhlPanel key={`${itemShort}-bpItem-${index}`}>
                                        <span slot="heading">{itemShort}</span>
                                        <ItemTags> {allTheItemsWithCurrentParent.length > 0 ?allTheItemsWithCurrentParent.map((itemParent: any) => <Tag key={`cop-${itemParent?.name}`}>{itemParent?.name}</Tag>) : null}</ItemTags>
                                    </MyDhlPanel>
                                })}
                            </MyDhlAccordion>
                </AccordionItem>})}

            </AccordionWrapper>
        </Wrapper>

    );
}

export default CustomerObjectProfileComponent;
