import { ThemeType } from './types/ThemeType';

export const color = {
  black: {
    300: '#333333',
    400: '#191919',
    500: '#000000',
    5: 'rgba(0, 0, 0, 0.05)',
    8: 'rgba(0, 0, 0, 0.08)',
    10: 'rgba(0, 0, 0, 0.1)',
    20: 'rgba(0, 0, 0, 0.2)',
    30: 'rgba(0, 0, 0, 0.3)',
    45: 'rgba(0, 0, 0, 0.45)',
    60: 'rgba(0, 0, 0, 0.6)',
    80: 'rgba(0, 0, 0, 0.8)',
  },
  grey: {
    30: '#fafafa',
    50: '#f2f2f2',
    100: '#ebebeb',
    200: '#e5e5e5',
    300: '#cccccc',
    400: '#b2b2b2',
    500: '#8c8c8c',
    600: '#666666',
    700: '#333333',
  },
  red: {
    400: '#eb131e',
    500: '#d40511',
    600: '#9a0000',
    10: 'rgba(235, 19, 30, 0.1)',
  },
  white: {
    400: '#ffffff',
    45: 'rgba(255, 255, 255, 0.45)',
    60: 'rgba(255, 255, 255, 0.6)',
    80: 'rgba(255, 255, 255, 0.8)',
  },
  green: {
    400: '#78bd22',
    500: '#007c39',
    10: 'rgba(120, 189, 34, 0.1)',
  },
  yellow: {
    300: '#fff0b2',
    400: '#ffe57f',
    500: '#ffcc00',
    600: '#e37729',
    10: 'rgba(255, 240, 178, 0.1)',
  },
  blue: {
    400: '#3640ff'
  }
};

export const lightTheme = {
  background: color.white[400],
  secondaryBg: color.grey[30],
  primaryText: color.black[500],
  secondaryText: color.black[300],
  divider: color.grey[50],
  color,
};

export const darkTheme = {
  background: color.black[400],
  secondaryBg: color.black[300],
  primaryText: color.white[400],
  secondaryText: color.grey[100],
  divider: color.grey[600],
  color,
};

function getFonts(fontSize: number): object {
  const sizePivot =
    fontSize === 1
      ? 0.6
      : fontSize === 2
      ? 0.8
      : fontSize === 4
      ? 1.2
      : fontSize === 5
      ? 1.4
      : 1;

  return {
    xxs: `${sizePivot * 0.5}rem`,
    xs: `${sizePivot * 0.75}rem`,
    sm: `${sizePivot * 1.0}rem`,
    md: `${sizePivot * 1.25}rem`,
    lg: `${sizePivot * 1.5}rem`,
    xl: `${sizePivot * 1.75}rem`,
    xxl: `${sizePivot * 2.0}rem`,
    xxxl: `${sizePivot * 2.25}rem`,
    heading1: `${sizePivot * 2.5}rem`,
    heading2: `${sizePivot * 3.5}rem`,
    heading3: `${sizePivot * 4.5}rem`,
    heading4: `${sizePivot * 5.5}rem`,
    heading5: `${sizePivot * 6}rem`,
  };
}

function getSpacing(fontSize: number): object {
  const sizePivot = 1;

  return {
    xs: `${sizePivot * 4}px`,
    sm: `${sizePivot * 8}px`,
    md: `${sizePivot * 12}px`,
    lg: `${sizePivot * 16}px`,
    xl: `${sizePivot * 20}px`,
    xxl: `${sizePivot * 28}px`,
    xxxl: `${sizePivot * 34}px`,
  };
}

export function getThemeData(
  isDarkTheme: boolean,
  fontSize: number
): ThemeType {
  const theme = isDarkTheme ? darkTheme : lightTheme;

  return {
    ...theme,
    fontSize: getFonts(fontSize),
    spacing: getSpacing(fontSize),
  };
}
