/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { defaultModel, dateFormat } from './constants';
import { dateFromString } from '../../utils';
import DateFilterPickers from './DateFilterPickers';

class FloatingDateFilter extends React.PureComponent {
  ref = undefined;

  updateAndNotifyAgGrid = this.updateGrid;

  colId = undefined;

  getModel() {
    const {
      api,
      column: { colId },
    } = this.props;
    const filtersModel = api.getFilterModel();
    const model = (filtersModel && filtersModel[colId]) || null;
    this.colId = colId;
    return model;
  }

  constructor(props) {
    super(props);
    const { debounceMs } = this.props;
    const model = this.getModel();
    let startSate = null;
    let endDate = null;
    let operator = 'equals';
    if (model) {
      const { dateFrom, dateTo, type } = model;
      startSate = dateFrom;
      endDate = dateTo;
      operator = type;
    }
    this.state = {
      dateFrom: dateFromString(startSate),
      dateTo: dateFromString(endDate),
      type: operator,
    };
    this.ref = React.createRef;
    if (debounceMs) {
      this.updateAndNotifyAgGrid = debounce(this.updateGrid, debounceMs);
    } else {
      this.updateAndNotifyAgGrid = this.updateGrid;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setDateFromModel(this.getModel());
    }
  }

  onParentModelChanged = (parentModel) => {
    this.setDateFromModel(parentModel);
    // note that the filter could be anything here, but our purposes we're assuming a greater than filter only,
    // so just read off the value and use that
  };

  render() {
    const { dateFrom, dateTo, type } = this.state;
    const startDate = (dateFrom && dateFrom.toDate()) || null;
    const endDate = (dateTo && dateTo.toDate()) || null;
    return (
      <DateFilterPickers
        isFloating
        startDate={startDate}
        endDate={endDate}
        type={type}
        onDateFromChange={this.onDateFromChange}
        onDateToChange={this.onDateToChange}
        onModelModified={this.onParentModelChanged}
        column={this.colId}
      />
    );
  }

  buildModel() {
    // ag-grid will call us here when in need to check what the current date value is hold by this
    // component.
    const { dateFrom, dateTo, type } = this.state;
    const model = this.getModel() || defaultModel;
    const startDate = (dateFrom && dateFrom.format(dateFormat)) || null;
    const endDate = (dateTo && dateTo.format(dateFormat)) || null;
    const newModel = { ...model, type, dateFrom: startDate, dateTo: endDate };
    return newModel;
  }

  setDateFromModel(model) {
    const { dateFrom, dateTo } = this.state;
    if (!model) {
      (dateFrom || dateTo) && this.setState({ dateFrom: null, dateTo: null });
      return;
    }
    // ag-grid will call us here when it needs this component to update the date that it holds.
    this.setState({
      dateFrom: dateFromString(model.dateFrom),
      dateTo: dateFromString(model.dateTo),
      type: model.type,
    });
  }

  updateGrid = () => {
    const model = this.buildModel();
    this.props.parentFilterInstance(instance => instance.onFloatingFilterChanged(model.type, model.dateFrom != null ? new Date(model.dateFrom) : null))
  };

  onDateFromChange = (date) => {
    const newDate = (date && moment(date)) || null;
    this.setState({ dateFrom: newDate }, () => this.updateAndNotifyAgGrid());
  };

  onDateToChange = (date) => {
    const newDate = (date && moment(date)) || null;
    this.setState({ dateTo: newDate }, () => this.updateAndNotifyAgGrid());
  };
}

export default FloatingDateFilter;
