/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

export default class CellLink extends React.PureComponent {
  render() {
    const { value, path } = this.props;
    const id = !this.props.data ? null : this.props.linkField ? this.props.data[this.props.linkField] : this.props.data.id;
    if (!id || !path) return <span />;
    const to = path.replace(':id', id);
    return <Link to={to}>{value}</Link>;
  }
}
