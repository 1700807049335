/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import classnames from 'classnames';
import { IconButton } from "@mui/material";

const Input = ({
  value,
  onChange,
  onClear,
  placeholder,
  error,
  inputProps = {},
  translate = false,
  label,
}) => {
  const className = classnames('text-floating-filter', {
    'text-floating-filter__is_active': !!value,
    'text-floating-filter__error': error,
  });
  return (
    <div className={className}>
      {label && <label>{label}</label>}
      <input
        className="text-floating-filter__input"
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder || 'Search'}
        {...inputProps}
      />
      <IconButton type='button' onClick={onClear} className="text-floating-filter__button">
        <div className="clear-button-icon" />
      </IconButton>
    </div>
  );
};

export default Input;
