/* eslint-disable react/prop-types */
import React from 'react';
import styled from "styled-components";

const MyImg = styled.img`
  width: auto;
  height: 35px;
  object-fit: scale-down;
`;

export default class ImageCell extends React.PureComponent {
    render() {
        const {data} = this.props;

        if (data && data.image) {
            return (
                <MyImg src={`data:${data.image.mimeType};base64,${data.image.content}`}/>
            );
        }
        return <span/>;
    }
}
