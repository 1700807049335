/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

import {Control, Controller} from 'react-hook-form';
import React, {
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormField,
    innerSpacing, isRoleCoAdminOnly,
    MainFormWrapper,
    outerSpacing,
    TextAreaFieldMaterial,
} from '../common';
import {ThemeContext} from '../../styles/ThemeContext';
import {Box, Grid, styled} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {
    DhlCheckbox,
    DhlDropdown,
} from '@dhl-official/ui-libraries/react-library';
import FormAudit from '../../components/FormAudit/FormAudit';
import ServiceRequest from '../../service/ServiceRequest';
import {NotificationContext} from '../../contexts/NotificationContext';
import {LoadingContext} from '../../contexts/LoadingContext';
import DhlText from '../../components/DhlText';
import {device} from '../../styles/Device';
import {UserContext} from "../../contexts/UserContext";

const StyledDhlText = styled(DhlText)`
  padding-left: 15px;
  padding-top: 10px;
  @media ${device.mobile} {
    margin-top: -10px;
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    margin-top: 30px;
    margin-bottom: 32px;
  }
`;

interface BrandingObjectInput {
    control: Control<any, any>;
    brandingData: any;
    getValues?: Function;
}

function BrandingObject({
                            control,
                            brandingData,
                            getValues,
                        }: BrandingObjectInput): ReactElement {
    const {t} = useTranslation();
    const {theme} = useContext(ThemeContext);
    const [stylesheets, setStylesheets] = useState<any>([]);
    const {setLoading} = useContext(LoadingContext);
    const {setType, setMessage} = useContext(NotificationContext);
    const { user } = useContext(UserContext);

    const isCoAdminRole = isRoleCoAdminOnly(user?.roles);

    const styleSheetSelectData = useMemo(
        () =>
            stylesheets.map((styleSheet: any) => ({
                label: styleSheet.name,
                value: String(styleSheet.id),
            })),
        [stylesheets]
    );

    useEffect(() => {
        ServiceRequest.processRequest({
            setLoading,
            setType,
            setMessage,
            inputData: {},
            request: ServiceRequest.getStylesheets,
            responseHandler: (response) => {
                setStylesheets(response.data);
            },
        });
    }, []);

    return (
        <MainFormWrapper theme={theme}>
            <Grid container spacing={outerSpacing}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={innerSpacing}>
                        <Grid item xs={12} md={6}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <h2 style={{flex: '1 1 auto'}}>
                                    {t('App.page.Branding.title')}
                                </h2>
                                <Controller
                                    name="active"
                                    control={control}
                                    render={({
                                                 field: {onChange, onBlur, value, ...propsField},
                                             }) => (
                                        <DhlCheckbox
                                            {...propsField}
                                            changeEvent={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                onChange(e.target?.checked)
                                            }
                                            isChecked={value}
                                            isDisabled={isCoAdminRole}
                                            size="sm"
                                        >
                                            <DhlText
                                                title={t('App.page.Branding.active')}
                                                size="md"
                                            />
                                        </DhlCheckbox>
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={innerSpacing}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{required: true}}
                                render={({
                                             field: {onChange, onBlur, ...propsField},
                                             fieldState: {error},
                                         }) => (
                                    <FormField
                                        {...propsField}
                                        InputLabelProps={{shrink: true}}
                                        dataId="name-inputfield"
                                        type="text"
                                        blurEvent={onBlur}
                                        inputEvent={onChange}
                                        isDisabled={isCoAdminRole}
                                        variant={{
                                            label: t('App.page.Branding.name'),
                                            placeholder: t('App.page.Branding.name'),
                                            type: 'animated',
                                        }}
                                        validation={
                                            error != null && {
                                                type: 'invalid',
                                                message: t('App.page.Branding.required'),
                                            }
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="stylesheetId"
                                control={control}
                                rules={{required: true}}
                                render={({
                                             field: {onChange, onBlur, ...propsField},
                                             fieldState: {error},
                                         }) => (
                                    <DhlDropdown
                                        {...propsField}
                                        dataId="stylesheetName-inputfield"
                                        data={styleSheetSelectData}
                                        blurEvent={onBlur}
                                        changeEvent={onChange}
                                        isDisabled={isCoAdminRole}
                                        validation={
                                            error != null && {
                                                type: 'invalid',
                                                message: t('App.page.Branding.required'),
                                            }
                                        }
                                        placeholder={t('App.page.Brandings.stylesheetName')}
                                        label={t('App.page.Brandings.stylesheetName')}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="notes"
                                control={control}
                                render={({
                                             field: {onChange, onBlur, value, ...propsField},
                                         }) => (
                                    <>
                                        <StyledDhlText
                                            size="xs"
                                            weight={400}
                                            color="var(--dui-color-gray-500)"
                                            title={t('App.page.Branding.notes')}
                                        />
                                        <TextAreaFieldMaterial
                                            id="notes-inputfield"
                                            placeholder={t('App.page.Branding.notes') as string}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            minRows={6}
                                            disabled={isCoAdminRole}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormAudit
                        idLabel="App.page.customer.mscId"
                        auditData={brandingData}
                    />
                </Grid>
            </Grid>
        </MainFormWrapper>
    );
}

export default BrandingObject;
