import React, { useState, useEffect, useContext } from 'react';
import ServiceRequest from '../../service/ServiceRequest';
import { LoadingContext } from '../../contexts/LoadingContext';
import {
    NOTIFICATION_TYPES,
    NotificationContext,
} from '../../contexts/NotificationContext';
import styled from 'styled-components';
import {
    DhlIcon,
    DhlInputField,
} from '@dhl-official/ui-libraries/react-library';
import { useTranslation } from 'react-i18next';
import { IconContext } from '../../assets/IconContext';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DhlButton from "../DhlButton";
import { ThemeContext } from '../../styles/ThemeContext';
import { Tooltip } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import DhlText from '../DhlText';
import { device } from '../../styles/Device';
import Avtar from '../../pages/ProfilePage/components/Avtar';
import { PATHS } from '../../pages/App/constants';
import { Variants } from "@dhl-official/ui-libraries/stencil-library"


const FormField = styled(DhlInputField)`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none !important;
  width: 100%;
`;

const CloseIcon = styled(DhlIcon)`
  float: right;
`;

const ModalContent = styled.div`
  width: 95%;
  margin: auto;
  min-height: 250px;
`;

const ButtonItem = styled(DhlButton)`
  right: 4%;
  top: 9px;
  position: absolute;
  
`;

const ButtonItemImpersonate = styled(DhlButton)`
  @media ${device.mobile} {
      top: 65px;
      position: none;
      display: flex;
  }
  @media ${device.tablet} {
      right: 4%;
      top: 9px;
      position: absolute;
  }
  
`;

const HeaderContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 0px;
  opacity: 1;
`;

const LeftIconWrapper = styled.div`
  width: 30px;
  height: 50px;
  margin: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Form = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  position: relative;
  margin-top: 50px;
`;

const H1 = styled.h1`
  text-align: left;
  font: normal normal bold 24px/27px Delivery;
  letter-spacing: 0px;
  color: #323232;
  padding-left: 12px;
  opacity: 1;
`;

const H2 = styled.h2`
  text-align: left;
  font: normal normal normal 16px/19px Delivery;
  letter-spacing: 0px;
  color: #323232;
  margin-bottom: 20px;
  padding-left: 12px;
`;

const H2Error = styled.h2`
  text-align: left;
  font: normal normal normal 16px/19px Delivery;
  letter-spacing: 0px;
  color: #d40511;
  padding-left: 5px;
`;

const ErrorIcon = styled(DhlIcon)`
  display: flex;
`;

const CustomErrorsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const ImpersonatedUserWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.color.grey[400]};
  border-radius: 4px;
  @media ${device.mobile} {
    height: 126px;
    flex-direction: column;
  }
  @media ${device.tablet} {    
    height: 66px;
    flex-direction: row;
  }
  align-items: center;
  display: flex;  
  position: relative;
`;

const TextWrapper = styled.div`
  width: 90%;
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const StyledText = styled.div<{ fontSize: number }>`
  font-size: ${(props) => props.fontSize * 6.5}px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const ItemWrapper = styled.div`
  width: auto !important;
  width: 250px;
  height: 50px;
  display: flex;
  margin-top: 1px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  @media ${device.mobile} {
    margin-right: 20px;
  }
  @media ${device.tablet} {  
    margin-right: 0px;
  }
`;

const ResultsWrapper = styled(DhlText)`
  margin-top: 50px;
  margin-bottom: 5px;
`;

interface IFormInputs {
    userEmail: string;
}

interface ImpersonatedUserType {
    firstName: string;
    lastName: string;
    login: string;
    email: string;
}

const Impersonator: React.FC<{
    setImpersonatorModal: Function;
}> = ({
    setImpersonatorModal,
}) => {
        // eslint-disable-line react/prefer-stateless-function
        const { setLoading } = useContext(LoadingContext);
        const { setType, setMessage } = useContext(NotificationContext);
        const { t } = useTranslation();
        const { setUser } = useContext(UserContext);
        const { theme, fontSize } = useContext(ThemeContext);
        const [userEmail, setUserEmail] = useState<string>('');
        const [sendUserEmailValidationForm, setSendUserEmailValidationForm] = useState<Boolean>(false);
        const [currentUserEmailError, setCurrentUserEmailError] = useState<string | undefined>(undefined);
        const [impersonatedUserInfo, setImpersonatedUserInfo] = useState<ImpersonatedUserType>();
        const navigate = useNavigate();
        const { getIcon } = useContext(IconContext);

        const headerText = t('App.page.Impersonator.headerText').split('\n');
        const headerSubText = t('App.page.Impersonator.headerSubText').split('\n');

        useEffect(() => {
            // tracking
            if (
                sendUserEmailValidationForm === true &&
                userEmail !== undefined
            ) {
                setLoading(true);
                ServiceRequest.getValidateImpersonatedUserEmail(userEmail)
                    .then((response) => {
                        if (response?.status === 200) {
                            const impersonatedUser: ImpersonatedUserType = {
                                firstName: response?.data?.firstName,
                                lastName: response?.data?.lastName,
                                login: response?.data?.login,
                                email: response?.data?.email,
                            }
                            setImpersonatedUserInfo(impersonatedUser);
                            setCurrentUserEmailError(undefined);
                        } else {
                            setMessage(t('App.page.Impersonator.form.userEmailNotFound'));
                            setType(NOTIFICATION_TYPES.FAILED);
                        }
                    })
                    .catch((error) => {
                        if (error?.response?.data?.message !== undefined) {
                            setCurrentUserEmailError(error?.response?.data?.message)
                        } else {
                            setCurrentUserEmailError(t('App.page.Impersonator.form.userEmailNotFound') ?? undefined);
                        }
                        setMessage(error?.response?.data?.message);
                        setType(NOTIFICATION_TYPES.FAILED);
                    }
                    )
                    .finally(() => {
                        setSendUserEmailValidationForm(false);
                        setLoading(false);
                    });
            }
        }, [sendUserEmailValidationForm, userEmail]);


        const impersonate = (): void => {
            if (
                impersonatedUserInfo?.email !== undefined
                && impersonatedUserInfo?.email !== null
            ) {
                setLoading(true);
                ServiceRequest.getImpersonateUser(impersonatedUserInfo?.email)
                    .then((response) => {
                        if (response.status === 200) {
                            setUser({
                                ...response.data,
                                avatar: null
                            });
                            setMessage(t('App.page.Impersonator.impersonation.success'));
                            setType(NOTIFICATION_TYPES.SUCCESS);
                            setSendUserEmailValidationForm(false);
                            setImpersonatorModal(false);
                            localStorage.clear();
                            navigate(PATHS.HOME);
                            handleClose();
                        } else {
                            setMessage(t('App.page.Impersonator.impersonation.error'));
                            setType(NOTIFICATION_TYPES.FAILED);
                        }
                    })
                    .catch((error) => {
                        setMessage(error?.response?.data?.message);
                        setType(NOTIFICATION_TYPES.FAILED);
                    }
                    )
                    .finally(() => {
                        setLoading(false);
                    });
            }
        };

        const submitForm: SubmitHandler<IFormInputs> = (data): void => {
            setSendUserEmailValidationForm(true);
        };

        const {
            handleSubmit,
            control,
            setValue,
            clearErrors,
            formState: { errors },
        } = useForm<IFormInputs>({
            defaultValues: {
                userEmail: ''
            },
            mode: 'onChange',
        });

        const handleClose = (): void => {
            setUserEmail('');
            clearErrors()
            setValue('userEmail', '');
            setCurrentUserEmailError(undefined);
            setImpersonatorModal(false);
            setImpersonatedUserInfo(undefined);
        };


        const handleOnChange = (onChange: (...event: any[]) => void, e: React.FormEvent<HTMLInputElement>): void => {
            onChange(e.currentTarget.value);
            setUserEmail(e.currentTarget.value);
            setCurrentUserEmailError(undefined);
        };

        return (
            <>
                <CloseIcon src={getIcon('close')} onClick={() => handleClose()} />
                <ModalContent>
                    <HeaderContainer>
                        <H1>
                            {
                                headerText.map((line) => (
                                    <>
                                        {line}
                                        <br />
                                    </>
                                ))
                            }
                        </H1>
                        <H2>
                            {
                                headerSubText.map((line) => (
                                    <>
                                        {line}
                                        <br />
                                    </>
                                ))
                            }
                        </H2>
                    </HeaderContainer>

                    {/* eslint-disable @typescript-eslint/no-misused-promises */}
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Form>
                            <Controller
                                name="userEmail"
                                control={control}
                                rules={{
                                    required: true,
                                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/,
                                }}
                                render={({ field: { onChange, onBlur, ...propsField } }) => (
                                    <Tooltip
                                        title={
                                            <p>
                                                {t(
                                                    'App.page.Impersonator.form.tooltipEmailformatLine1'
                                                )}
                                                <br />
                                                {t(
                                                    'App.page.Impersonator.form.tooltipEmailformatLine2'
                                                )}
                                            </p>
                                        }
                                    >
                                        <FormField
                                            {...propsField}
                                            dataId="email-inputfield"
                                            type="email"
                                            blurEvent={onBlur}
                                            inputEvent={(event: any) =>
                                                handleOnChange(onChange, event)}
                                            validation={
                                                (errors?.userEmail?.type === 'required') ? {
                                                    type: Variants.invalid,
                                                    message: `${t('app.common.fieldIsMandatory')}`,
                                                } :
                                                    ((errors?.userEmail !== undefined && errors?.userEmail !== null) ? {
                                                        type: Variants.invalid,
                                                        message: `${t('app.common.invalidEmail')}`,
                                                    } : undefined)
                                            }
                                            variant={{
                                                label: `${t('App.page.Impersonator.usersEmail')}`,
                                                type: Variants.animated,
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            />
                            <ButtonItem
                                size="sm"
                                textSize="xs"
                                textVariant="primary"
                                title={t('App.common.search')}
                                key={t('App.common.search')}
                                type="submit"
                                isDisabled={userEmail === '' || errors?.userEmail !== undefined}
                            />
                        </Form>


                        {currentUserEmailError !== undefined && (
                            <CustomErrorsWrapper>
                                <ErrorIcon size={"0.9rem"} src={getIcon('errorCircle')} />
                                <H2Error>{currentUserEmailError}</H2Error>
                            </CustomErrorsWrapper>

                        )}

                    </form>
                    {impersonatedUserInfo?.firstName !== undefined || impersonatedUserInfo?.lastName !== undefined ?
                        (<>
                            <ResultsWrapper
                                size={'md'}
                                weight={400}
                                title={`${t('App.page.Impersonator.form.results')}: `}
                            />
                            <ImpersonatedUserWrapper theme={theme}>
                                <ItemWrapper
                                    theme={theme}
                                >
                                    <LeftIconWrapper>
                                        {
                                            <>
                                                <Avtar isSmall={true} noAvatar={true} userName={{firstName: impersonatedUserInfo?.firstName, lastName: impersonatedUserInfo?.lastName}}/>
                                                {/* <FontAwesomeIcon icon={'circle-user'} size="xl" /> 
                                                */ // for impersonated user we can't get the image from azure
                                                }
                                            </>
                                        }
                                    </LeftIconWrapper>

                                    <TextWrapper>
                                        <StyledText fontSize={fontSize} theme={theme}>
                                            {`${impersonatedUserInfo?.firstName ?? ''} ${impersonatedUserInfo?.lastName ?? ''}`}
                                        </StyledText>
                                    </TextWrapper>
                                </ItemWrapper>
                                <ButtonItemImpersonate
                                    size="md"
                                    textSize="xs"
                                    variant="outline"
                                    title={t('App.page.Impersonator.impersonate')}
                                    key={t('App.page.Impersonator.impersonate')}
                                    onClick={() => impersonate()}
                                />
                            </ImpersonatedUserWrapper>
                        </>
                        )
                        : ''
                    }

                </ModalContent>
            </>
        );
    };

export default Impersonator;
