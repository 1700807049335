/* eslint-disable react/prop-types */
import React, {useContext} from 'react';
import styled from "styled-components";
import {ThemeContext} from "../../../styles/ThemeContext";

const TopButtons = styled.div<{ status: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-top: 10px;
  margin-left: 14px;
  height: 30px;
  width: ${(props) => props.status === 'In Progress' ? '100px' : '70px'};
  border-radius: 4px;
  color: ${(props) => props.status === 'In Progress' ? props.theme.theme.color.black[500] : props.theme.theme.color.white[400]};
  background-color: ${(props) => props.status === 'ACTIVE' ? props.theme.theme.color.green[400] : props.status === 'NEW' ? props.theme.theme.color.blue[400] : props.theme.theme.color.yellow[400]};
`;

function StatusView(props: any): any {
    const {data} = props;
    const theme = useContext(ThemeContext);

    if (data !== undefined)
        return (
            <TopButtons theme={theme} status={data.status}>
                {data.status}
            </TopButtons>
        );
}

export default StatusView;
