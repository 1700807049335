import React, {useContext, useEffect} from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import {ThemeContext} from '../../../styles/ThemeContext';
import {DhlImage} from '@dhl-official/ui-libraries/react-library';
import {IconContext} from '../../../assets/IconContext';
import {useTranslation} from 'react-i18next';
import {isEmpty} from '../../../utils';
import ApplicationType from '../../../contexts/types/ApplicationType';
import DhlLogo from '../../../assets/images/dhl-logo.svg';
import {logAuditEvent} from '../../../service/ServiceRequest';
import {ACTION_BUSINESS_SERVICE} from '../../App/constants';
import {device} from "../../../styles/Device";

const BoxWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
  margin: 8px;
  }
  @media ${device.laptop} {
    width: 250px;
  margin: 16px;
  }
  height: 100px;
  display: block;
  box-shadow: 1px 1px 2px 1px #e6e6e6;
  transition: 0.2s;
  border-radius: 10px;
  background-color: ${(props) => props.theme.color.black[300]};
  :hover {
    transform: scale(1.02);
    z-index: 2;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div<{
    thumbnail: string;
    dhlLogo: string;
    loading: boolean;
    error: boolean;
}>`
  width: 160px;
  height: 100%;
  margin-left: 45px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('${(props) =>
    props.error || props.loading ? props.dhlLogo : props.thumbnail}');
`;

const InnerWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
  }
  @media ${device.laptop} {
    width: 250px;
  }
  height: 100px;
  display: flex;
  position: relative;
  bottom: 100px;
  left: 0;
  z-index: 2;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background: #0000006c;
  border-radius: 10px;
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 45%;
  margin-top: 55px;
  align-items: center;
  z-index: 2;
`;

const ImageWrapper2 = styled(DhlImage)`
  width: 20%;
  height: 20%;
  margin-top: 20px;
  background-size: contain;
  background-repeat: no-repeat;
`;

const MyText = styled(DhlText)`
  margin-top: -20px;
  text-align: left;
  padding-left: 20px;
  z-index: 2;
`;

const SubTitle = styled(DhlText)`
  text-align: left;
  padding-left: 20px;
  z-index: 2;
`;

export interface AppItemProperties {
    app: ApplicationType;
}

function VideoItem({app}: AppItemProperties): JSX.Element {
    const {theme} = useContext(ThemeContext);
    const {getIcon} = useContext(IconContext);
    const {t} = useTranslation();
    const {appIconNameLabelId, name, childApps, link, icon, integrated, id} =
        app;
    const [isImageLoaded, setImageLoaded] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        const img = new Image();

        img.onload = () => setImageLoaded(true);
        img.onerror = () => setHasError(true);

        img.src = icon !== undefined ? icon : DhlLogo;
    }, [icon]);

    const onClickBox = (): void => {
        if ((childApps === null || childApps?.length === 0) && !isEmpty(link)) {
            void logAuditEvent(ACTION_BUSINESS_SERVICE, id);
            if (integrated != null && integrated) {
                window.open(link, '_self');
            } else {
                window.open(link, '_blank', 'noopener,noreferrer');
            }
        }
    };

    return (
        <BoxWrapper theme={theme} onClick={onClickBox} data-tracking={app.name}>
            <ImageWrapper
                thumbnail={icon}
                loading={!isImageLoaded}
                error={hasError}
                dhlLogo={DhlLogo}
            />

            <InnerWrapper>
                <ImageWrapper2 src={getIcon('videoPlay')}/>

                <TextWrapper>
                    <MyText size="sm" weight={600} color="#ffff" title={name}/>
                    <SubTitle
                        size="sm"
                        weight={400}
                        color="#ffff"
                        title={!isEmpty(appIconNameLabelId) ? t(appIconNameLabelId) : null}
                    />
                </TextWrapper>
            </InnerWrapper>
        </BoxWrapper>
    );
}

export default VideoItem;
