/* eslint-disable */
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {ThemeContext} from '../../../styles/ThemeContext';
import DhlText from "../../../components/DhlText";
import {useTranslation} from "react-i18next";
import DhlButton from "../../../components/DhlButton";
import {CardTitle, HeaderTitleWrapper} from "../index";
import {device} from "../../../styles/Device";
import {Grid, TextField} from "@mui/material";
import {Controller, useForm, useWatch} from 'react-hook-form';
import {isEmpty} from "lodash";
import {Button, ButtonWrapper, filterInner} from "../../common";
import {CountryContext} from "../../../contexts/CountryContext";
import HeaderBfSelection from "./HeaderBfSelection";
import MyAccordion from "./MyAccordion";
import {DhlInputField} from "@dhl-official/ui-libraries/react-library";
import {useDebounce} from "../../../utils/debounce-hooks";
import ServiceRequest from "../../../service/ServiceRequest";
import BusinessFunctionType from "../../../contexts/types/BusinessFunctionType";
import {generateId} from "../../../utils";
import AutoCompleteView from "../../../components/DhlAutoCompleteView";

const ComponentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px;
  overflow-y:auto;
`;

const SectionWrapper = styled.div`
   @media ${device.mobile} {
    width: 96%;
    margin-left: 4px;
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    width: 98%;
    margin-left: 4px;
    flex-wrap: nowrap;
  }
  min-height: 170px;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 4px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  padding: 12px;
`;

const FormWrapper = styled.form`
    width: 100%;
    `;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 60px;
  margin-top: 8px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.background};
`;

const DhlInput = styled(DhlInputField)`
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

function createQueryParamsForBf(custId: string, topFilter: string): string {
    return `customerId=${custId}&topFilter=${topFilter}`;
    // return `customerId=asda&topFilter=${topFilter}`;
}

function ProfilePage(props: any): JSX.Element {
    const theme = useContext(ThemeContext);
    const {countries} = useContext(CountryContext);
    const {t} = useTranslation();
    const [countryFilter, setCountryFilter] = useState('');
    const [topBfFilter, setTopBfFilter] = useState('');
    const [isBfLoading, setIsBfLoading] = useState(false);
    const [selectedBfList, setSelectedBfList] = useState<any[]>([]);
    const [businessFunctions, setBusinessFunctions] = useState<BusinessFunctionType[]>([]);
    const [mainBfList, setMainBfList] = useState<BusinessFunctionType[]>([]);
    const {isOpen, onClose, customer, updateBp, originalWidth, bpDetailsForEdit} = props;

    const topRef = useRef(null);
    const mainRef = useRef(null);

    const defaultFormValues = {
        name: '',
        status: {value: "Active", label: "Active"},
        country: '',
        notes: ''
    }

    const statusData = [
        {value: "Active", label: "Active"},
        {value: "Passive", label: "Passive"}
    ]

    const countrySelectData = useMemo(
        () => {
            let myFilterList = [];
            if (!isEmpty(countryFilter)) {
                myFilterList = filterInner(countryFilter, countries, 'id');
            } else {
                myFilterList = countries.map((country: any) => ({
                    value: String(country.id),
                    label: country.name,
                    isoCode: country.isoCode,
                }));
            }

            return myFilterList;
        },
        [countries, countryFilter]
    );

    const {
        control,
        reset,
        trigger,
        setValue,
        setError,
        formState: {errors}
    } =
        useForm<any>({
            defaultValues: defaultFormValues,
            mode: 'all',
        });

    const results = useWatch({control});

    useEffect(() => {
        if (bpDetailsForEdit !== null) {
            const myCountry = countrySelectData.find((countryData: any) => Number(countryData.value) === Number(bpDetailsForEdit.country));
            const myStatus = statusData.find((status: any) => status.value === bpDetailsForEdit.status);
            setValue("name", bpDetailsForEdit.name);
            setValue("country", myCountry);
            setValue("notes", bpDetailsForEdit.notes);
            setSelectedBfList(bpDetailsForEdit.bfList);
            if(myStatus !== undefined)
                setValue("status", myStatus);
        }
    }, [bpDetailsForEdit]);

    useEffect(() => {
        let myFilterList: BusinessFunctionType[] = [];
        if (!isEmpty(topBfFilter)) {
            mainBfList.filter((bfData: any) => {
                if (bfData?.name?.toLowerCase().includes(topBfFilter.toLowerCase()) || bfData?.parent_name?.toLowerCase().includes(topBfFilter.toLowerCase())) {
                    myFilterList.push(bfData);
                    if (bfData.hasOwnProperty("parent_name")) {
                        const myFilterFindings = myFilterList.filter((filterData: any) => filterData.id === bfData.parent_id);
                        if (myFilterFindings.length < 1)
                            myFilterList.push({id: bfData.parent_id, name: bfData.parent_name});
                    }
                }

            });
            setBusinessFunctions(myFilterList);
        } else {
            setBusinessFunctions(mainBfList);
        }
        //     getBusinessFunctions();
    }, [topBfFilter]);

    useEffect(() => {
        if (isOpen) {
            if (!isEmpty(customer) && businessFunctions.length < 1)
                getBusinessFunctions();
        }

    }, [isOpen, customer]);

    const getBusinessFunctions = useDebounce(() => {
        setIsBfLoading(true);
        ServiceRequest.getBusinessFunctions(createQueryParamsForBf(customer.value, topBfFilter))
            .then((response) => {
                setBusinessFunctions(response.data);
                setMainBfList(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsBfLoading(false);
            });
    }, 700);

    const onCloseBusinessProfile = (): void => {
        setCountryFilter("");
        setTopBfFilter("");
        reset();
        onClose();
        setSelectedBfList([]);
        if (topRef?.current != null && mainRef?.current !== null) {
            // @ts-ignore
            mainRef?.current?.scrollTo({
                top: topRef.current,
                behavior: 'smooth',
            });
        }
    }

    const onSubmitBusinessProfile = async () => {
        const isValid = await trigger();
        if (isValid) {
            let myUpdatedObj: any = {};
            if (bpDetailsForEdit === null) {
                myUpdatedObj = {
                    ...results,
                    id: generateId(5),
                    country: Number(results.country.value),
                    bfList: selectedBfList,
                    status: results.status.value,
                    isNewBp: true,
                }
            } else {
                myUpdatedObj = {
                    ...results,
                    id: bpDetailsForEdit.id,
                    country: Number(results.country?.value),
                    bfList: selectedBfList,
                    status: results.status?.value,
                    isNewBp: false,
                }
            }
            if (isEmpty(results.country))
                delete myUpdatedObj?.country;

            updateBp(myUpdatedObj);
            onCloseBusinessProfile();
        }
    }

    return (
        <ComponentWrapper ref={mainRef} theme={theme}>
            <HeaderTitleWrapper ref={topRef}>
                <DhlText
                    title={t(bpDetailsForEdit === null ? "App.page.customer.addNewProfile" : "App.page.customer.updateProfile")}
                    size={"xl"} weight={900}/>
                <DhlButton title={"X"} size={"md"} variant={"text"} onClick={onCloseBusinessProfile}/>
            </HeaderTitleWrapper>

            <FormWrapper id="bp-form">
                <SectionWrapper theme={theme}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="name"
                                rules={{required: true}}
                                control={control}
                                render={({field: {onChange, ...propsField}}) => (
                                    <TextField
                                        {...propsField}
                                        sx={{width: "100%", marginTop: "14px"}}
                                        label={`${t('App.page.Customers.name')} *`}
                                        error={Boolean(errors?.name)}
                                        InputLabelProps={{shrink: false}}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            onChange(event.target.value);
                                        }}
                                    />
                                    // render={({
                                    //              field: {onChange, onBlur, ...propsField},
                                    //              fieldState: {error},
                                    //          }) => (
                                    //     <FormField
                                    //         {...propsField}
                                    //         InputLabelProps={{shrink: true}}
                                    //         dataId="name-inputField"
                                    //         type="text"
                                    //         validation={
                                    //             error != null && {
                                    //                 type: 'invalid',
                                    //                 message: t('App.page.Branding.required'),
                                    //             }
                                    //         }
                                    //         blurEvent={onBlur}
                                    //         inputEvent={onChange}
                                    //         variant={{
                                    //             label: `${t('App.page.Customers.name')} *`,
                                    //             type: '',
                                    //         }}
                                    //     />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="status"
                                control={control}
                                render={({field: {onChange, ...propsField}}) => (
                                    <AutoCompleteView
                                        {...propsField}
                                        loading={false}
                                        label={t('App.page.customer.status')}
                                        error={''}
                                        options={statusData}
                                        onChange={(_: any, newVal: string) => {
                                            onChange(newVal);
                                        }}
                                        // onInputChange={(_: any, newVal: string) => {
                                        //     setFilterValue(newVal)
                                        // }}
                                    />
                                    // render={({
                                    //              field: {onBlur, onChange, value, ...propsField},
                                    //              fieldState: {error},
                                    //          }) => (
                                    //     <Dropdown
                                    //         {...propsField}
                                    //         dataId="status-inputField"
                                    //         data={statusData}
                                    //         value={value}
                                    //         blurEvent={onBlur}
                                    //         changeEvent={onChange}
                                    //         label={`${t('App.page.customer.status')}`}
                                    //     />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="country"
                                control={control}
                                render={({field: {onChange, ...propsField}}) => (
                                    <AutoCompleteView
                                        {...propsField}
                                        loading={false}
                                        label={t('App.page.ExternalUser.country')}
                                        error={''}
                                        options={countrySelectData}
                                        onChange={(_: any, newVal: string) => {
                                            onChange(newVal);
                                        }}
                                        // onInputChange={(_: any, newVal: string) => {
                                        //     setFilterValue(newVal)
                                        // }}
                                    />
                                    // render={({
                                    //              field: {onBlur, onChange, value, ...propsField},
                                    //              fieldState: {error},
                                    //          }) => (
                                    //     <Dropdown
                                    //         {...propsField}
                                    //         dataId="country-inputField"
                                    //         id="country-inputField"
                                    //         data={countrySelectData}
                                    //         value={value}
                                    //         blurEvent={onBlur}
                                    //         changeEvent={onChange}
                                    //         showFilter
                                    //         filter={countryFilter}
                                    //         filterChangeEvent={(filter: string) =>
                                    //             setCountryFilter(filter)
                                    //         }
                                    //         label={`${t('App.page.ExternalUser.country')}`}
                                    //     />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="notes"
                                control={control}
                                render={({field: {onChange, ...propsField}}) => (
                                    <TextField
                                        {...propsField}
                                        sx={{width: "100%", marginTop: "14px"}}
                                        label={t('App.page.customer.notes')}
                                        error={false}
                                        InputLabelProps={{shrink: false}}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            onChange(event.target.value);
                                        }}
                                    />
                                    // render={({
                                    //              field: {onChange, onBlur, ...propsField},
                                    //              fieldState: {error},
                                    //          }) => (
                                    //     <FormField
                                    //         {...propsField}
                                    //         InputLabelProps={{shrink: true}}
                                    //         dataId="name-inputField"
                                    //         type="text"
                                    //         blurEvent={onBlur}
                                    //         inputEvent={onChange}
                                    //         variant={{
                                    //             label: `${t('App.page.customer.notes')}`,
                                    //             type: '',
                                    //         }}
                                    //     />
                                )}
                            />
                        </Grid>
                    </Grid>
                </SectionWrapper>

                <SectionWrapper theme={theme}>

                    <CardTitle size="md" weight={800} title={t('App.page.customer.businessFunctions')}/>

                    <SearchWrapper>
                        <DhlInput
                            searchIcon
                            value={topBfFilter}
                            inputEvent={(e: any) => setTopBfFilter(e.target.value)}
                            variant={{
                                label: "",
                                placeholder: t('App.page.customer.searchBusinessFunctions'),
                                type: "animated"
                            }}/>
                    </SearchWrapper>

                    {selectedBfList.length > 0 && <HeaderBfSelection
                        originalWidth={originalWidth}
                        selectedList={selectedBfList}
                        changeSelectedList={(changeList: any[]) => setSelectedBfList(changeList)}
                    />}

                    <MyAccordion
                        listData={businessFunctions}
                        isBfLoading={isBfLoading}
                        selectedList={selectedBfList}
                        changeSelectedList={(changeList: any[]) => setSelectedBfList(changeList)}
                    />

                </SectionWrapper>

                <ButtonRow>
                    <ButtonWrapper>
                        <Button
                            size="md"
                            title={t('App.page.customer.discard')}
                            variant="outline"
                            dataAriaLabel={t('App.page.customer.discard')}
                            onClick={onCloseBusinessProfile}
                        />

                        <Button
                            size="md"
                            title={t(bpDetailsForEdit === null ? 'App.page.customer.add' : "App.common.update")}
                            variant="primary"
                            dataAriaLabel={t('App.page.customer.add')}
                            onClick={onSubmitBusinessProfile}
                        />
                    </ButtonWrapper>
                </ButtonRow>

            </FormWrapper>

        </ComponentWrapper>
    );
}

export default ProfilePage;
