import React, { useContext } from 'react';
import DhlText from '../../../components/DhlText';
import styled from 'styled-components';
import { ThemeContext } from '../../../styles/ThemeContext';
import './style.css';
import { device } from '../../../styles/Device';
import DottedCircles from './DottedCircles';
import { useTranslation } from 'react-i18next';
import DhlButton from '../../../components/DhlButton';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  @media ${device.mobile} {
    flex-direction: column;
    height: 100%;
  }
  @media ${device.tablet} {
    flex-direction: row;
    height: 100%;
  }
  @media ${device.desktop} {
    flex-direction: row;
    height: 100%;
  }
  @media ${device.largeDesktop} {
    flex-direction: row;
    height: 49vh;
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  margin-right: 30px;
`;

const TextWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 30px;
    text-align: left;
  }
  @media ${device.tablet} {
    width: 50%;
    margin-left: 10px;
    align-items: flex-start;
  }
  @media ${device.laptop} {
    width: 50%;
    align-items: flex-start;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SolutionText = styled.div`
  margin-top: 20px;
  @media ${device.mobile} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 70%;
  }
  @media ${device.laptop} {
    width: 60%;
  }
  text-align: left;
  font-weight: 200;
  font-size: ${(props) => props.theme.fontSize.md};
  color: ${(props) => props.theme.primaryText};
`;

const LearnMoreButton = styled(DhlButton)`
  margin-top: 20px;
`;

function BottomContent(): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Wrapper theme={theme}>
      <ImageWrapper theme={theme}>
        <DottedCircles />
      </ImageWrapper>

      <TextWrapper>
        <DhlText size="xxl" weight={800} title={t("App.page.PublicHome.whatIs")} />
        <SolutionText theme={theme}>
          {t('App.page.PublicHome.oneStopSol')}
        </SolutionText>
        <SolutionText theme={theme}>
          <span className="titleData">{t('App.applicationName')}</span>{' '}
          {t('App.page.PublicHome.details')}
        </SolutionText>

        <LearnMoreButton
          title={t('App.page.PublicHome.learnMore')}
          size="sm"
          onClick={() => {
            window.open(
              'https://dhlinsights.dhlsupplychain.dhl.com/ao_mysupplychain',
              '_blank'
            );
          }}
        />
      </TextWrapper>
    </Wrapper>
  );
}

export default BottomContent;
