import PackageJson from '../../../package.json';

export const metaData = [
    {
        href: 'https://www.dhl.com/global-en/home/footer/terms-of-use.html',
        external: true,
        label: 'App.footer.termsOfUser',
        dataTracking: 'Terms of Service',
    },
    {
        href: '/privacyNotice',
        dataTracking: 'Privacy & Cookies',
        external: false,
        label: 'App.footer.privacyNotice',
    },
    {
        href: '#cookiepref',
        dataTracking: 'CookieSettings',
        external: false,
        label: 'App.footer.CookieSettings',
    },
];

export const versionData = {
    version: PackageJson.version,
    releaseDate: PackageJson.releaseDate,
};

export const PATHS = {
    HOME: '/',
    PORTAL: '/portal',
    PUBLIC: '/public',
    ERROR: '/error',
    INACTIVE: '/inactive',
    EXPIRED: '/expired',
    LOGOUT: '/loggedOut',
    PROFILE: '/profile',
    EXTERNAL_USER: '/externalUser',
    UPLOAD_USER: '/uploadUser',
    CATEGORIES: '/categories',
    CUSTOMERS: '/customers',
    CUSTOMER_DETAIL: `/customers/:id`,
    CUSTOMER_ADD: `/customers/add`,
    ADD_BRANDING_OBJECT: `/brandings/add`,
    BRANDING_OBJECT_DETAIL_DETAIL: `/brandings/:id`,
    BRANDING_OBJECT_ADD: `/brandings/add`,
    BRANDING_LIST: `/brandings`,
    PRIVACY_NOTICE: `/privacyNotice`,
    APP_DETAILS: `/app/:appId`,
    NEWS_LIST: `/news/list`,
    NEWS_ADD: `/news/add`,
    NEWS_EDIT: `/news/edit`,
    CATEGORIES_DETAIL_DETAIL: `/categories/:id`,
    CATEGORIES_ADD: `/categories/add`,
    USERS: `/users`,
};

export const ROLES = {
    COADMIN: 'COADMIN',
    MSCADMIN: 'MSCADMIN',
    SCOPEADMIN: 'SCOPEADMIN',
    AZURE_EXTERNAL: 'AZURE_EXTERNAL',
};

export const ORIGINAL_TIMEZONES = 'ORIGINAL_TIMEZONES';

export const DEFAULT_TIMEZONE_OBJECT = {
    id: ORIGINAL_TIMEZONES,
};

export const ACTION_BUSINESS_SERVICE = 'bfonclickevent';

export const ACTION_LOGOUT = 'logout';
