/**
 *
 * AutoCompleteView
 *
 */

import React, {useContext} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import {CircularProgress, MenuItem, TextField} from "@mui/material";
import styled from "styled-components";
import {IconContext} from "../../assets/IconContext";
import {isEmpty} from "lodash";
import {DhlIcon} from "@dhl-official/ui-libraries/react-library";
import {getErrorMessage} from "../../utils";
import {useTranslation} from "react-i18next";

const NoPaddingAutocomplete = styled(Autocomplete)`

`;

const MyIcon = styled(DhlIcon)`
 width: 24px;
 height: 24px;
 padding: 4px;
 margin-right: -30px;
 :hover {
    cursor: pointer;
  }
`;

// const NoPaddingAutocomplete = withStyles({
//     inputRoot: {
//         minHeight: 35,
//         '&&[class*="MuiOutlinedInput-root"] $input': {
//             padding: 3,
//         },
//     },
//     input: {},
// })(Autocomplete);

// eslint-disable-next-line react/prop-types
function AutoCompleteView({loading, options, value, label, error, onChange, ...props}) {
    const {getIcon} = useContext(IconContext);
    const {t} = useTranslation();

    // @ts-ignore
    return (<NoPaddingAutocomplete
        {...props}
        options={options}
        autoHighlight
        blurOnSelect
        value={value}
        onChange={onChange}
        clearIcon={<div style={{width: 0, height: 0}}/>}
        clearText=""
        getOptionLabel={(opt) => opt && opt.label ? opt.label : ""}
        limitTags={2}
        loading={loading}
        renderOption={(props, option) => (<MenuItem {...props}>{option.label}</MenuItem>)}
        renderInput={(params) => (<TextField
            {...params}
            variant="outlined"
            fullWidth
            label={label}
            margin="normal"
            error={error}
            helperText={getErrorMessage(error?.type, t)}
            InputLabelProps={{shrink: false}}
            InputProps={{
                ...params.InputProps, endAdornment: (<React.Fragment>
                    {!isEmpty(value) ? <MyIcon
                        src={getIcon("cross")}
                        onClick={() => onChange("")}
                    /> : loading ? (<CircularProgress color="inherit" size={20}/>) : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>),
            }}
        />)}
    />);
}

AutoCompleteView.propTypes = {
    loading: PropTypes.bool,
    options: PropTypes.array,
    error: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default AutoCompleteView;
