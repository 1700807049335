/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TetherComponent from 'react-tether';
import moment from 'moment';
import classnames from 'classnames';
import TextInput from '../TextInput';
import { dateFormat, maxYear, minYear } from './constants';

// type Props = {
//   selected?: Date | null,
//   onChange: Function,
//   label?: string,
//   dpProps?: Object, // Date picker props
//   className?: string,
// };

// type StateProps = {
//   startDate?: Date | null,
//   textDate: string,
//   open: boolean,
//   error: boolean,
// };

class TableDatePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    const { selected } = this.props;
    this.state = {
      open: false,
      startDate: selected || null,
      textDate: selected ? moment(selected).format(dateFormat) : '',
      error: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      const date = this.props.selected;
      this.setState({
        startDate: date,
        textDate: date ? moment(date).format(dateFormat) : '',
        error: false,
      });
    }
  }

  onClick = () => {
    this.setState({ open: true });
  };

  closeCalendar = () => {
    const { onChange } = this.props;
    const { startDate } = this.state;
    this.setState({ open: false });
    onChange(startDate);
  };

  onDatePick = (date) => {
    const { onChange } = this.props;
    this.setState({
      startDate: date,
      textDate: moment(date).format(dateFormat),
      open: false,
      error: false,
    });
    onChange(date);
  };

  onInputChange = (e) => {
    const { textDate, startDate } = this.state;
    const newDate = e.target.value;
    if (newDate === textDate) return;
    if (!newDate) {
      this.setState({ textDate: '', startDate: null, error: false });
      return;
    }
    const date = moment(newDate, dateFormat);
    let newStartDate = startDate;
    if (date.isValid() && date.year() >= minYear && date.year() <= maxYear) {
      newStartDate = date.toDate();
    }
    this.setState({ textDate: newDate, startDate: newStartDate, error: false });
  };

  clearValue = () => {
    const { onChange } = this.props;
    this.setState({ textDate: '', startDate: null, open: false, error: false });
    onChange(null);
  };

  onEnter = (event) => {
    const { onChange } = this.props;

    // enter
    if (event.keyCode === 13) {
      const { startDate } = this.state;
      this.setState({ open: false });
      onChange(startDate);
    }
  };

  render() {
    const { open, startDate, textDate, error } = this.state;
    const { dpProps = {}, label, className = '' } = this.props;
    const dpClassName = classnames('date-picker', { [className]: className });
    return (
      <TetherComponent
        attachment="top left"
        targetAttachment="bottom left"
        constraints={[
          { to: 'scrollParent' },
          { to: 'window', pin: ['bottom'] },
        ]}
        renderTarget={ref => (
          <div ref={ref} className={dpClassName}>
            <TextInput
              value={textDate}
              onChange={this.onInputChange}
              onClear={this.clearValue}
              placeholder={dateFormat.toLowerCase()}
              inputProps={{
                onKeyDown: this.onEnter,
                onClick: this.onClick,
              }}
              error={error}
              label={label}
            />
          </div>
        )}
        renderElement={ref =>
          open && (
            <div ref={ref} className="ag-custom-component-popup">
              <DatePicker
                inline
                selected={startDate}
                onChange={this.onDatePick}
                onClickOutside={this.closeCalendar}
                {...dpProps}
              />
            </div>
          )
        }
      />
    );
  }
}

export default TableDatePicker;
