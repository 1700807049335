/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

import { Control, Controller } from 'react-hook-form';
import React, {
  ReactElement,
  useContext,
} from 'react';
import {
  FormField,
  innerSpacing,
  MainFormWrapper,
  outerSpacing,
  TextAreaFieldMaterial,
} from '../common';
import { ThemeContext } from '../../styles/ThemeContext';
import { Box, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormAudit from '../../components/FormAudit/FormAudit';
import DhlText from '../../components/DhlText';
import { device } from '../../styles/Device';

const StyledDhlText = styled(DhlText)`
  padding-left: 15px;
  padding-top: 10px;
  @media ${device.mobile} {
    margin-top: -10px;
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    margin-top: 30px;
    margin-bottom: 32px;
  }
`;

interface CategoryInput {
  control: Control<any, any>;
  categoryData: any;
  getValues?: Function;
}

function Category({
  control,
  categoryData,
  getValues,
}: CategoryInput): ReactElement {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <MainFormWrapper theme={theme}>
      <Grid container spacing={outerSpacing}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={innerSpacing}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <h2 style={{ flex: '1 1 auto' }}>
                  {t('App.page.Categories.title')}
                </h2>                
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={innerSpacing}>
            <Grid item xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, ...propsField },
                  fieldState: { error },
                }) => (
                  <FormField
                    {...propsField}
                    InputLabelProps={{ shrink: true }}
                    dataId="name-inputfield"
                    type="text"
                    blurEvent={onBlur}
                    inputEvent={onChange}
                    variant={{
                      label: t('App.page.Categories.name'),
                      placeholder: t('App.page.Categories.name')
                    }}
                    validation={
                      error != null && {
                        type: 'invalid',
                        message: t('App.common.required'),
                      }
                    }
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({
                  field: { onChange, onBlur, value, ...propsField },
                }) => (
                  <>
                    <StyledDhlText
                      size="xs"
                      weight={400}
                      color="var(--dui-color-gray-500)"
                      title={t('App.page.Categories.description')}
                    />
                    <TextAreaFieldMaterial
                      id="description-inputfield"
                      placeholder={t('App.page.Categories.description') as string}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      minRows={6}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              
            </Grid>            
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormAudit
            idLabel="App.page.Categories.id"
            auditData={categoryData}
          />
        </Grid>
      </Grid>
    </MainFormWrapper>
  );
}

export default Category;
