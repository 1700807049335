/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import httpService from '../../service/index';
import { AxiosResponse } from 'axios';

export const getExternalUsersHistoryTable = async (
  values: any
): Promise<AxiosResponse<any, any>> =>
  await httpService.post(
    `/${process.env.REACT_APP_MSC_PATH}/rafadmin/upload/users/external/list`,
    values
  );

export const getFile = async (
  id: any,
  failed: any
): Promise<AxiosResponse<any, any>> =>
  await httpService.get(
    `${process.env.REACT_APP_MSC_PATH}/rafadmin/upload/users/external/file?id=${id}&failed=${failed}`,
    {},
    { responseType: 'blob' }
  );

export const getTemplate = async (): Promise<AxiosResponse<any, any>> =>
  await httpService.get(
    `${process.env.REACT_APP_MSC_PATH}/rafadmin/upload/user/external/template`,
    {
      Accept: 'application/vnd.ms-excel',
    },
    { responseType: 'blob' }
  );

export const postFile = async (data: any): Promise<AxiosResponse<any, any>> =>
  await httpService.post(
    `/${process.env.REACT_APP_MSC_PATH}/rafadmin/upload/user/external`,
    data,
    {},
    { timeout: 0 }
  );
