import * as React from "react";
import {getBase64Flag} from './CountryFlagsBase64';

interface FlagProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    suffix?: string;
    url?: string;
    countryCode: string;
    style?: React.CSSProperties;
}


const CountryFlag: React.FC<FlagProps> = ({
  style,
  url = './country_flags',
  suffix = 'svg',
  countryCode,
  ...props
}) =>  <img
      {...props}
      src={getBase64Flag(countryCode.toUpperCase())}
      style={{
        display: 'inline-block',
        width: '1em',
        height: '1em',
        verticalAlign: 'middle',
        ...style,
      }}
    />;


export default CountryFlag;

