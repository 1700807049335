/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../../../styles/ThemeContext';
import DhlText from '../../../../components/DhlText';
import TypeNormalCell from './TypeNormalCell';
import { DhlCheckbox } from '@dhl-official/ui-libraries/react-library';

const TableCellWrapper = styled.div`
  width: auto;
  display: table-cell;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
`;

function TableNormalCell(props: TypeNormalCell): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const { id, item, label, isSelectable, checkChange, isCheck = false } = props;

  const isItemEmpty = item === null || item === undefined;

  return (
    <TableCellWrapper key={id} theme={theme}>
      <ComponentWrapper>
        {isSelectable && (
          <DhlCheckbox
            size="sm"
            isChecked={isCheck}
            changeEvent={checkChange}
          />
        )}
        {!isSelectable && isItemEmpty && (
          <DhlText size="xs" weight={400} title={label} />
        )}
        {!isItemEmpty && item}
      </ComponentWrapper>
    </TableCellWrapper>
  );
}

export default TableNormalCell;
