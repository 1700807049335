// @flow

import React from 'react';
import classnames from 'classnames';

const Checkbox = (id, checked) => {
  const className = classnames({
    'ag-icon': true,
    'ag-icon-checkbox-checked': checked,
    'ag-icon-checkbox-unchecked': !checked,
  });

  return (
    <div id={id} className="ag-filter-checkbox">
      <span className={className} />
    </div>
  );
};

export default Checkbox;
